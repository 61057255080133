import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Button, Tooltip, message } from "antd";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { debounce } from "lodash";

import {
  fnChangeImageMap,
  fnChangeTerrainMode,
  moveViewerPositionByCesiumEntity,
} from "../../../xitecloud3D/xiteCloud.dev";
import { removeEntityById } from "utils/CesiumUtils";
import EsriWorldStreetMap from "../../../assets/img/ImageryProviders/esriWorldStreetMap.png";
import OpenStreetMap from "../../../assets/img/ImageryProviders/openStreetMap.png";
import CesiumWorldTerrain from "../../../assets/img/TerrainProviders/CesiumWorldTerrain.png";
import Ellipsoid from "../../../assets/img/TerrainProviders/Ellipsoid.png";
import EsriImageryMap from "../../../assets/img/ImageryProviders/esriWorldImagery.png";
import { ReactComponent as IconLocationSearch } from "../../../assets/icons-2/Icon_Location_Point.svg";
import "./mapController.less";

function MapController(_props) {
  const { t } = useTranslation();

  const { coordSystem } = useSelector(
    (state) => ({
      coordSystem: state.currentProjectReducer.currentProject.f_coord_system,
    }),
    shallowEqual
  );

  const entityRef = useRef(null);
  const locationIntervalRef = useRef(null);
  const permissionChangeHandlerRef = useRef(null);
  const permissionStatusRef = useRef(null);
  const isMoveDoneRef = useRef(false);

  const [visible, setVisible] = useState(false);
  const [coordinates, setCoordinates] = useState("");
  const [useLocation, setUseLocation] = useState(false);

  const handleActive = () => setVisible(!visible);

  const [backgroundImg, setBackgroundImg] = useState(OpenStreetMap);

  const handleLocationClick = () => {
    setUseLocation((prev) => {
      if (prev) {
        removeRealTimeLocationEntity();
      }

      return !prev;
    });
    isMoveDoneRef.current = false;
  };

  const handleSelectMap = (key, item) => {
    setBackgroundImg(item.imageSrc);
    if (key === "terrain") {
      return fnChangeTerrainMode(item.value);
    }

    return fnChangeImageMap(item.value);
  };

  const zoomIn = () => {
    if (window.viewer) {
      window.viewer.controls.zoomIn();
    }
  };

  const zoomOut = () => {
    if (window.viewer) {
      window.viewer.controls.zoomOut();
    }
  };

  const moveRealTimeLocationEntity = () => {
    const ett = window.cesiumViewer.entities.getById("real_time_location_entity");
    moveViewerPositionByCesiumEntity(ett, coordSystem, 600, true);
  };

  const removeRealTimeLocationEntity = () => {
    removeEntityById(window.cesiumViewer.entities, REAL_TIME_LOCATION_ENTITY_ID);
    entityRef.current = null;
  };

  const geoLocationErrorHandler = (error) => {
    let text = "";
    switch (error.code) {
      case error.PERMISSION_DENIED:
        text = "위치 사용권한이 없습니다.";
        break;
      case error.POSITION_UNAVAILABLE:
      case error.TIMEOUT:
      case error.UNKNOWN_ERROR:
      default:
        text = "위치 권한 오류";
        break;
    }

    message.error({
      key: 0,
      content: t(`message$${text}`),
    });
    setUseLocation((prev) => {
      if (prev) {
        removeRealTimeLocationEntity();
      }

      return !prev;
    });
    isMoveDoneRef.current = false;
  };

  const updateRealTimeEntityPosition = (entity) => {
    if (navigator.geolocation && entity) {
      const successCallback = (geolocation) => {
        const { longitude, latitude } = geolocation.coords;
        const position = window.Cesium.Cartesian3.fromDegrees(longitude, latitude, 0.0);
        entity.position = position;

        if (!isMoveDoneRef.current) {
          moveRealTimeLocationEntity();
          isMoveDoneRef.current = true;
        }
      };

      const errorCallback = (error) => geoLocationErrorHandler(error);

      const options = {
        enableHighAccuracy: true, // 고정밀 위치
        maximumAge: 0, // 캐시된 위치 정보 사용x
        timeout: 5000, // 5초 대기
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
    }
  };

  const createRealTimePositionEntity = () => {
    if (navigator.geolocation) {
      const successCallback = (geolocation) => {
        const { longitude, latitude } = geolocation.coords;
        const position = window.Cesium.Cartesian3.fromDegrees(longitude, latitude, 0.0);

        entityRef.current = window.cesiumViewer.entities.add({
          id: REAL_TIME_LOCATION_ENTITY_ID,
          name: moment().unix(),
          position: position,
          show: true,

          billboard: {
            image:
              'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><circle cx="18" cy="18" r="10" stroke="white" stroke-width="3" fill="blue" /></svg>',
            width: 36,
            height: 36,
            verticalOrigin: window.Cesium.VerticalOrigin.CENTER,
            heightReference: window.Cesium.HeightReference.CLAMP_TO_GROUND,
            alignedAxis: window.Cesium.Cartesian3.UNIT_Z,
            rotation: new window.Cesium.CallbackProperty(
              () => window.Cesium.Math.toRadians(50),
              false
            ),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });

        moveRealTimeLocationEntity();
        isMoveDoneRef.current = true;
      };

      const errorCallback = (error) => geoLocationErrorHandler(error);

      const options = {
        enableHighAccuracy: true, // 고정밀 위치
        maximumAge: 0, // 캐시된 위치 정보 사용x
        timeout: 5000, // 5초 대기
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
    }
  };

  const setGeolocationChangeCallback = useCallback(() => {
    navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
      permissionStatusRef.current = permissionStatus;

      // 기존 이벤트 핸들러 제거
      if (permissionChangeHandlerRef.current) {
        permissionStatus.onchange = null;
        return;
      }

      const handlePermissionChange = () => {
        if (permissionStatus.state === "granted") {
          message.success(t("message$위치 권한 설정"));
        } else if (permissionStatus.state === "denied") {
          setUseLocation(false);
          removeRealTimeLocationEntity();
          message.error(t("message$위치 권한 해제"));
        }
      };

      permissionStatus.onchange = handlePermissionChange;
      permissionChangeHandlerRef.current = handlePermissionChange;
    });
  }, []);

  const searchLocationCallback = useCallback(() => {
    if (!useLocation) {
      if (locationIntervalRef.current) {
        clearInterval(locationIntervalRef.current);
        locationIntervalRef.current = null;
      }
      return;
    }

    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted" || permissionStatus.state === "prompt") {
            if (!entityRef.current) {
              createRealTimePositionEntity();
            }

            if (!locationIntervalRef.current) {
              locationIntervalRef.current = setInterval(() => {
                updateRealTimeEntityPosition(entityRef.current);
              }, 2000);
            }
          } else if (permissionStatus.state === "denied") {
            throw new Error();
          }
        })
        .catch(() => geoLocationErrorHandler(new Error("PERMISSION_DENIED")));
    } else {
      return geoLocationErrorHandler(new Error());
    }
  }, [useLocation]);

  useEffect(() => {
    searchLocationCallback();

    return () => {
      if (locationIntervalRef.current) {
        clearInterval(locationIntervalRef.current);
        locationIntervalRef.current = null;
      }
    };
  }, [searchLocationCallback]);

  useEffect(() => {
    setGeolocationChangeCallback();

    return () => {
      if (permissionStatusRef.current) {
        permissionStatusRef.current.onchange = null;
      }

      if (locationIntervalRef.current) {
        clearInterval(locationIntervalRef.current);
        locationIntervalRef.current = null;
      }

      permissionChangeHandlerRef.current = null;
      setUseLocation(false);
      removeRealTimeLocationEntity();
    };
  }, [setGeolocationChangeCallback]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.xitecloud.CurrentCodinate.mapCodinate !== undefined)
        setCoordinates(`${window.xitecloud.CurrentCodinate.mapCodinate}`);
    }, 10);
    return () => {
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const terrainProviders = [
    {
      title: t("title$2D_Terrain"),
      imageSrc: Ellipsoid,
      value: false,
    },
    {
      title: t("title$3D_Terrain"),
      imageSrc: CesiumWorldTerrain,
      value: true,
    },
  ];

  const imageryProviders = [
    {
      title: t("title$OpenStreet-Map"),
      imageSrc: OpenStreetMap,
      value: 0,
    },
    {
      title: t("title$ESRI_World_Street_Map"),
      imageSrc: EsriWorldStreetMap,
      value: 1,
    },
    {
      title: t("title$ESRI_IMAGERY_Map"),
      imageSrc: EsriImageryMap,
      value: 2,
    },
  ];

  const hasBackgroundImgStyle = {
    transition: "unset",
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    border: visible ? "solid 2px #346cee" : "2px solid rgba(255, 255, 255, 0)",
  };

  return (
    <article className="map-controller">
      <ul className="group">
        <li className="map-controller__expand">
          <Tooltip placement={"left"} title={t("title$My Location")}>
            <i className="anticon anticon-plus">
              <IconLocationSearch
                width={20}
                height={20}
                fill={useLocation ? "#1890ff" : "#fff"}
                onClick={debounce(handleLocationClick, 300)}
              />
            </i>
          </Tooltip>
        </li>
        <li className="map-controller__expand">
          <Icon type="plus" title={t("title$Zoom in")} onClick={zoomIn} />
        </li>
        <li className="map-controller__reduce">
          <Icon type="minus" title={t("title$Zoom out")} onClick={zoomOut} />
        </li>
        <li className="map-controller__expand">
          <Button
            className="map-controller__Button"
            style={backgroundImg ? hasBackgroundImgStyle : null}
            size="large"
            onClick={handleActive}
          />
        </li>
        <li className="map-controller__location">
          {t("title$Map_Location_Information")} {coordinates}
        </li>
      </ul>
      {visible && (
        <div className="map-controller__active" onClick={handleActive}>
          <h4>{t("title$GIS_map_Imagery_Control")}</h4>
          <div className="map-controller__list">
            <ul>
              {terrainProviders.map((terrain, i) => (
                <li key={i} onClick={() => handleSelectMap("terrain", terrain)}>
                  <img src={terrain.imageSrc} alt="terrain background" />
                  <span className="acumin">{terrain.title}</span>
                </li>
              ))}
            </ul>
            <ul>
              {imageryProviders.map((imagery, i) => (
                <li key={i} onClick={() => handleSelectMap("imagery", imagery)}>
                  <img src={imagery.imageSrc} alt="imagery background" />
                  <span className="acumin">{imagery.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </article>
  );
}

export default MapController;

const REAL_TIME_LOCATION_ENTITY_ID = "real_time_location_entity";
