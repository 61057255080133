//actions
import {
    // VIEWDATASET,
    VIEWDATASET_LAYERS,
    VIEWDATASET_ANNOTATION,
    VIEWDATASET_INIT,
    VIEWDATASET_PROJECT_KEY,
    VIEWDATASET_MANY_ANNOTATION,
    VIEWDATASET_MANY_LAYERS
} from '../actions/viewDataSet';

const initializeViewDataState = {
    projectKey: null,
    projectViewDataSetModel: {
        /**
         * project -> currentProjectReducer
         */
        layer: {
            topo: [],
            subsurface: [],
            target: [],
            overlay: []
        },
        annotations: {
            onsite: [],
            offsite: [],
            analytics: [],
        }
    },
    layerCount: 0,
    annotationCount: 0
}

export default function ViewDataSetReducer(state = initializeViewDataState, action) {
    switch (action.type) {
        // case VIEWDATASET: return { ...state, viewDataSet: action.obj };
        case VIEWDATASET_INIT: return initializeViewDataState;
        case VIEWDATASET_PROJECT_KEY: 
        return {
            ...state, projectKey: action.projectKey
        }
        //layers
        case VIEWDATASET_LAYERS: return {
            ...state,
            projectViewDataSetModel: {
                ...state.projectViewDataSetModel,
                layer: {
                    ...state.projectViewDataSetModel.layer,
                    [action.key]: action.obj
                }
            }
        };

        //annoataions
        case VIEWDATASET_ANNOTATION: return {
            ...state,
            projectViewDataSetModel: {
                ...state.projectViewDataSetModel,
                annotations: {
                    ...state.projectViewDataSetModel.annotations,
                    [action.key]: action.obj
                }
            }
        };

        //many annoataions, layers
        case VIEWDATASET_MANY_ANNOTATION: return {
            ...state,
            projectViewDataSetModel: {
                ...state.projectViewDataSetModel,
                annotations: action.annotations
            },
            annotationCount: action.totalCount
        };

        case VIEWDATASET_MANY_LAYERS: return {
            ...state,
            projectViewDataSetModel: {
                ...state.projectViewDataSetModel,
                layer: action.layers
            },
            layerCount: action.totalCount
        };
        default: return state;
    }
}

// VIEWDATASET_ONSITE,
//     VIEWDATASET_OFFSITE,
//     VIEWDATASET_ANALYTICS
//  //surface
//  case VIEWDATASET_SUBSURFACE: return {
//     ...state,
//     projectViewDataSetModel: {
//         ...state.projectViewDataSetModel,
//         layer: {
//             ...state.projectViewDataSetModel.layer,
//             subsurface: action.subsurfaces
//         }
//     }
// };
// //target
// case VIEWDATASET_TARGET: return {
//     ...state,
//     projectViewDataSetModel: {
//         ...state.projectViewDataSetModel,
//         layer: {
//             ...state.projectViewDataSetModel.layer,
//             target: action.targets
//         }
//     }
// };
// //overlay
// case VIEWDATASET_OVERLAY: return {
//     ...state,
//     projectViewDataSetModel: {
//         ...state.projectViewDataSetModel,
//         layer: {
//             ...state.projectViewDataSetModel.layer,
//             overlay: action.overlays
//         }
//     }
// };

// //offsite
// case VIEWDATASET_OFFSITE: return {
//     ...state,
//     projectViewDataSetModel: {
//         ...state.projectViewDataSetModel,
//         annotations: {
//             ...state.projectViewDataSetModel.annotations,
//             offsite: action.offsite
//         }
//     }
// };
// //analytics
// case VIEWDATASET_ANALYTICS: return {
//     ...state,
//     projectViewDataSetModel: {
//         ...state.projectViewDataSetModel,
//         annotations: {
//             ...state.projectViewDataSetModel.annotations,
//             analytics: action.analytics
//         }
//     }
// };