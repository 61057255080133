import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setADDRESS } from "../../../store/actions/createAndEditProject";
import { Modal } from "antd";
import { useRef } from "react";
import "./index.less";

const GoogleMap = (props) => {
  const { user, currentView } = useSelector((state) => ({
    user: state.appState.user,
    currentView: state.appState.view,
  }));

  const dispatch = useDispatch();

  let lat = null;
  let lng = null;
  let formatted_address = null;

  const buttonRef = useRef(null);
  const mapRef = useRef(null);
  const [initialCenter, setInitialCenter] = useState({
    lat: 37.5676311,
    lng: 126.9883972,
  });
  const [loader, setLoader] = useState();

  const setMapAsync = async (loader) => {
    try {
      /**
       * importLibrary
       * See https://developers.google.com/maps/documentation/javascript/reference/top-level#google.maps.importLibrary
       */
      const maps = await loader.importLibrary("maps");
      const core = await loader.importLibrary("core");
      const map = new maps.Map(mapRef.current, {
        center: initialCenter,
        zoom: 12,
        streetViewControl: false,
        fullscreenControl: false,
        // See https://developers.google.com/maps/documentation/javascript/reference/control?hl=ko#ControlPosition
        mapTypeControlOptions: {
          position: core.ControlPosition.BLOCK_START_INLINE_END,
        },
        zoomControlOptions: {
          position: core.ControlPosition.INLINE_END_BLOCK_END,
        },
      });

      const { InfoWindow } = maps;
      const { Autocomplete } = await loader.importLibrary("places");
      const { Geocoder } = await loader.importLibrary("geocoding");
      const { Marker } = await loader.importLibrary("marker");
      try {
        if (user) {
          /**
           * map click event
           */
          new core.event.addListener(map, "click", function (event) {
            const latlng = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            };

            if (window.location.pathname !== "/") {
              new Geocoder().geocode({ location: latlng }, (results, status) => {
                if (status === "OK") {
                  formatted_address = results[0].formatted_address;
                  lat = event.latLng.lat();
                  lng = event.latLng.lng();

                  Modal.confirm({
                    content: formatted_address,
                    onOk: () => {
                      dispatch(
                        setADDRESS({
                          latlng: `${lat},${lng}`,
                          address: formatted_address,
                        })
                      );
                    },
                  });
                }
              });
            }
          });

          /**
           *  autocomplate (places)
           * */

          if (buttonRef.current) {
            const autoComplate = new Autocomplete(buttonRef.current);

            new core.event.addListener(autoComplate, "place_changed", (e) => {
              let thisplace = autoComplate.getPlace();
              if (thisplace.geometry) {
                lat = thisplace.geometry.location.lat();
                lng = thisplace.geometry.location.lng();
                formatted_address = thisplace.formatted_address;
                map.setCenter({ lat, lng });
                map.setZoom(19);
              }
            });
          }

          /**
           * get project List
           *  -> react ui 로 전환 필요
           */
          const result = await Axios.get("/projectList", {
            params: {
              user: user.user_mng_sq,
            },
          });

          let locations = result.data;
          const ownedProjectsCount = locations.length;

          let bounds = new core.LatLngBounds();
          let markerPositions = locations.map(function (projectInfo) {
            // let beach = projectInfo
            let latAndLng = projectInfo.f_latlng.split(",");

            if (window.location.pathname.indexOf("edit") !== -1) {
              const id = window.location.pathname.split("/");

              if (+id[id.length - 1] === projectInfo.project_mng_sq) {
                setInitialCenter({
                  lat: +latAndLng[0],
                  lng: +latAndLng[1],
                });
                map.setZoom(9);
              }
            }

            if (latAndLng.length === 2) {
              let myLatLng = new core.LatLng(latAndLng[0], latAndLng[1]);
              bounds.extend(myLatLng);
            }

            return {
              latLng: {
                lat: +latAndLng[0],
                lng: +latAndLng[1],
              },
              title: projectInfo.f_proj_nm,
              project_mng_sq: projectInfo.project_mng_sq,
            };
          });

          map.fitBounds(bounds);
          /**
           * marker controller
           */
          const markers = markerPositions.map(function (project) {
            const infoWindow = new InfoWindow({
              content: `<div class="info__window_wrap" onClick="goProject(${project.project_mng_sq})">
                            ${project.title}
                        </div>`,
            });

            const markerObj = new Marker({
              position: project.latLng,
              map: map,
              title: project.title,
            });

            markerObj.addListener("click", function (e) {
              infoWindow.open(map, markerObj);
            });

            // 접근 권한 프로젝트가 1개 일 때, Zoom Out
            markerObj.map.setZoom(ownedProjectsCount === 1 ? 12 : markerObj.map.zoom);

            return markerObj;
          });

          new window.MarkerClusterer(map, markers, {
            imagePath:
              "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
          });
        }
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw new Error("importLibrary " + error);
    }
  };

  const initMapCallbackAsync = useCallback(async () => {
    try {
      const loader = new Loader({
        apiKey: "AIzaSyDnsRus6WotX6hWpxgBK424v2rzAnY_GAU",
        region: "KR",
        language: props.language === "ko-KR" ? "ko" : "en",
        libraries: ["places"],
      });
      setLoader(loader);

      try {
        await setMapAsync(loader);
      } catch (error) {
        console.log(error);
      }
    } catch (_error) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initMapCallbackAsync();

    return () => setLoader(null);
  }, [initMapCallbackAsync]);

  useEffect(() => {
    if (loader) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.language]);

  const cssStyle = {
    map: {
      position: "absolute",
      width: "100%",
      height: "100vh",
      zIndex: 1,
    },
    searchBox: {
      display: currentView === "home/create" ? null : "none",
    },
  };

  return (
    <>
      <div className="google-map-wrapper">
        <input id="search-box" ref={buttonRef} style={cssStyle.searchBox} />
        <div className="google-map-div" ref={mapRef} style={cssStyle.map}>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default GoogleMap;
