import React from "react";
import { useTranslation } from "react-i18next";
import "./dialogs.less";
import { Modal, Icon, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { setModalInit } from "../../../store/actions/Modal";
import { capitalizeFirstLetter } from "utils/StringHelper";

// const footerInterface = [
//   <Button className="dialogs-modal__modal__confirm" key="submit">
//     Confirm
//   </Button>,
// ];

/**
 * @param { handlerOk, handlerCancel, footer, success, warning, message }
 */

const Dialogs = (props) => {
  const { t } = useTranslation();
  const localHandler = () => {
    props.setModalInit();
  };

  return (
    <div className="dialogs-modal">
      <Modal
        visible={true}
        onOk={props.handleOk ? props.handleOk : localHandler}
        onCancel={props.handleCancel ? props.handleCancel : localHandler}
        className="dialogs-modal__modal"
        width={props.width ? props.width : 350}
        centered
        footer={
          props.footer ? (
            props.footer
          ) : (
            <Button onClick={props.handleOk ? props.handleOk : localHandler}>
              {capitalizeFirstLetter(t("button$OK"))}
            </Button>
          )
        }
        {...props}
      >
        {props.success && <CheckOutlined className="i-successfully" />}
        {props.warning && <Icon className="i-exclamation" type="exclamation" />}
        {props?.innerTitleVisible && <p className="dialogs-modal__modal__title">{props.title}</p>}
        <p className="dialogs-modal__modal__content">{props.message}</p>
      </Modal>
    </div>
  );
};

export default connect((state) => state, { setModalInit })(Dialogs);
