import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { createAnnotation } from "../util/fnCreateAnnotaion";
import { configAndSave } from "../util/save";
import ContentTitle from "../../../components/elements/text/contentTitle";
import WorkspaceTitle from "../children/WorkspaceTitle";
import AnnotationFooter from "../children/AnnotationFooter";
import MeasurementVisualization from "../children/MeasurementVisualization";
import { setHandleVisible, setHandleTargetName } from "../../../store/actions/saveHandle";
import { titleAnnotationVisible } from "../util/initialize";
import { CoordinateForm } from "../children/CoordinateForm";
import "./index.less";

// createAnnotation
const MeasurementPoint = (props) => {
  const { t } = useTranslation();
  const measure = props.projectChildrenComponents.currentMeasure;

  const [zPoints, setZPoints] = useState([]);

  // let points = [];
  const lastPoint = useRef(null);

  const findPointCloudPoint = (object3D, inputX, inputY) => {
    if (object3D.geometry) {
      findClosetPoint(
        object3D.geometry.attributes.position.array,
        object3D.matrixWorld,
        inputX,
        inputY
      );
    }

    // 자식노드 체크
    for (let i = 0; i < object3D.children.length; i++) {
      findPointCloudPoint(object3D.children[i], inputX, inputY);
    }
  };

  const getDistanceXY = (pos1, pos2) => {
    const x = Math.abs(pos1.x - pos2.x);
    const y = Math.abs(pos1.y - pos2.y);
    return x * x + y * y;
  };

  const findClosetPoint = (positions, matrixWorld, inputX, inputY) => {
    const inputPosition = new window.THREE.Vector3(inputX, inputY, 0); // 사용자가 입력한 3D 좌표

    let closestPoint = null;
    let closestDistance = Infinity;

    for (let j = 0; j < positions.length; j += 3) {
      const x = positions[j];
      const y = positions[j + 1];
      const z = positions[j + 2];

      const position = new window.THREE.Vector3(x, y, z);
      position.applyMatrix4(matrixWorld);

      const distance = getDistanceXY(inputPosition, position);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestPoint = position;
      }
    }

    if (lastPoint.current === null) {
      lastPoint.current = closestPoint;
    } else {
      const distance = getDistanceXY(inputPosition, lastPoint.current);
      if (distance > closestDistance) {
        lastPoint.current = closestPoint;
      }
    }
  };

  const getZPoint = (positionValue) => {
    let newPosition = positionValue || measure.measure.points[0].position;
    if (!newPosition) return;

    const pointCloud = window.viewer.scene.pointclouds[0];
    lastPoint.current = null;
    findPointCloudPoint(pointCloud, newPosition.x, newPosition.y);

    if (lastPoint.current) newPosition = lastPoint.current;
    const position = newPosition;

    Axios.get("/api/PRJ020301_01", {
      params: {
        project_mng_sq: props.currentProjectReducer.currentProject.project_mng_sq,
        inputdate: props.currentProjectReducer.selectedDate,
        point: `${position.x} ${position.y} ${position.z}`,
      },
    }).then(({ data }) => {
      if (data.data) {
        let rst = data.data.map((x) => {
          if (x.gbn === "TOPO") {
            x.z = position.z;
          }

          x.z = x.z === null ? "" : (Math.floor(x.z * 100) / 100).toFixed(2);

          return x;
        });
        setZPoints(rst);
      }
    });
  };

  const onSave = () => {
    measure.measure._cancel && measure.measure._cancel();
    configAndSave(false, createAnnotation());
  };

  useEffect(() => {
    if (measure !== null) getZPoint();
    titleAnnotationVisible(false);

    return () => {
      titleAnnotationVisible(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure]);

  return (
    <div className="measurement">
      <WorkspaceTitle wrapperName="measurement" />
      <section className="measurement__content">
        <article className="measurement__content__part">
          <ContentTitle title={t("title$Result", { count: 2 })} style={{ color: "#182e63" }} />
          <CoordinateForm
            type={"point"}
            points={[measure.measure.points[0]]}
            onSetCallback={(points) => getZPoint(points)}
          />
          <p
            style={{
              paddingTop: "30px",
              paddingBottom: "15px",
            }}
          >
            {t("title$Altitude_of_Each_Layer")}
          </p>

          {zPoints.map((point, index) => (
            <div className="altitude-prop-wrap" key={index}>
              {`${point.gbn} ${point.layer_name}`}
              <span className="altitude-prop-input">&nbsp;{point.z}</span>
            </div>
          ))}
        </article>
        <MeasurementVisualization type="Point" />
      </section>
      <AnnotationFooter onClick={onSave} className="measurement_footer" />
    </div>
  );
};

// export default Measurement;
export default connect((state) => state, {
  setHandleVisible,
  setHandleTargetName,
  // setData,
})(React.memo(MeasurementPoint));
