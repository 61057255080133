import React from "react";
import PointCloud from "../../../views/pointCloud";
import Measurement from "../../Measurement";
import ContourMap from "../../contourMap";
import OverlayWorkspace from "../../overlays/OverlayWorkspace";

import { 
  // connect, 
  shallowEqual, useSelector } from "react-redux";

const CurrentProjectChildren = (props) => {
  const { measurementComponent, currentMeasure, pointCloudComponent, contourMapComponent } = useSelector(state => ({
    contourMapComponent: state.projectChildrenComponents.contourMapComponent,
    pointCloudComponent: state.projectChildrenComponents.pointCloudComponent,
    measurementComponent: state.projectChildrenComponents.measurementComponent,
    currentMeasure: state.projectChildrenComponents.currentMeasure,
    // fleetGeofence: state.projectChildrenComponents.fleetGeoComponent,
  }), shallowEqual);

  const workspace = useSelector(state => state.OverlayReducer.workspace);
  
  return (
    <>
      {measurementComponent !== false && (
        <Measurement
          measure={currentMeasure}
          measurementType={currentMeasure.type}
        />
      )}
      {pointCloudComponent && <PointCloud />}
      {contourMapComponent && <ContourMap />}
      {workspace && <OverlayWorkspace />}
      {/* {fleetGeofence && <OverlayWorkspace />} */}
    </>
  );
};

// export default connect((state) => state, {})(CurrentProjectChildren);
export default CurrentProjectChildren;
