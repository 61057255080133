import React, { useState } from "react";
import ChartTable from "./ChartTable";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setChartAnalysis } from "../../../../views/Measurement/CroSSection/utils/setChartData";

const HocChartTable = (props) => {
  const [analysis, setAnalysis] = useState({
    analysis: [],
  });

  useEffect(() => {
    if (Object.keys(props.crossSectionDatasetArr).length) {
      setChartAnalysis(props.crossSectionDatasetArr, setAnalysis)
    }
  }, [props.crossSectionDatasetArr]);

  return <ChartTable data={analysis} />;
};

export default connect((state) => ({crossSectionDatasetArr: state.CrossSectionReducer.crossSectionDatasetArr}), {})(HocChartTable);
