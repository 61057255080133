import React from 'react';
import './contentTitle.less';

class ContentTitle extends React.Component {
  render() {
    return (
      <>
        <p className={`caption content-title ${this.props.className && this.props.className}`} onClick={this.props.onClick} style={this.props.style}>{this.props.title} {this.props.children} </p>
      </>
      
    )
  }
}

export default ContentTitle;
