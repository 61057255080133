//actions
import {
  HANDLE_ASSET,
  ORIGINAL_ASSET,
  DETAIL_ASSET,
  CREATE_GEO_FENCE,
  HANDLE_GEO_FENCE,
  UPDATE_DETAIL_ASSET,
  CESIUM_ASSET_ID,
  CHANGE_GEO_FENCE,
  VIEW_FLEET_TASK,
  MEASUREDATA,
  FLEET_MODAL_RENDER,
  COUNT_METHOD,
  MARK_PRESET,
  SHOW_ASSET,
  UPDATE_TIME,
  ASSET_SPEC,
} from "../actions/fleet.asset";

const initializeFleetAssetState = {
  assetItems: {},
  originalAssetItems: [],
  detailItem: {},
  createFleetGeo: false,
  updateFleetGeoId: "",
  geofenceItems: [],
  cesium_asset_id: { status: false, assetId: "" },
  changeGeofemce: {},
  viewFleetTask: false,
  measuredata: "",
  FleetModalRender: "",
  countMethod: "",
  markPreset: {},
  showAsset: { isShowOnlyPoint: false, isShowAssetName: true },
  updateTime: 0,
  assetSpecs: [],
};

export default function FleetReducer(state = initializeFleetAssetState, action) {
  switch (action.type) {
    case HANDLE_GEO_FENCE:
      return { ...state, geofenceItems: action.geofences };
    case CREATE_GEO_FENCE:
      return { ...state, createFleetGeo: action.bool, updateFleetGeoId: action.geoId };
    case HANDLE_ASSET:
      return { ...state, assetItems: action.assets };
    case ORIGINAL_ASSET:
      return { ...state, originalAssetItems: action.assets };
    case DETAIL_ASSET:
      return {
        ...state,
        detailItem:
          { ...state.originalAssetItems.find((x) => x.asset_id === action.assetId) } || {},
      };
    case UPDATE_DETAIL_ASSET:
      return { ...state, detailItem: action.detailItem };
    case CESIUM_ASSET_ID:
      return { ...state, cesium_asset_id: action.assets };
    case CHANGE_GEO_FENCE:
      return { ...state, changeGeofemce: action.assets };
    case VIEW_FLEET_TASK:
      return { ...state, viewFleetTask: action.bool };
    case MEASUREDATA:
      return { ...state, measuredata: action.measuredata };
    case FLEET_MODAL_RENDER:
      return { ...state, FleetModalRender: action.assets };
    case COUNT_METHOD:
      return { ...state, countMethod: action.countMethod };
    case MARK_PRESET:
      return { ...state, markPreset: action.markPreset };
    case SHOW_ASSET:
      return { ...state, showAsset: action.showAsset };
    case UPDATE_TIME:
      return { ...state, updateTime: action.updateTime };
    case ASSET_SPEC:
      return { ...state, assetSpecs: action.assetSpecs };
    default:
      return state;
  }
}
