import React, { createRef, useCallback, useState } from "react";
import { axiosForChat } from "../env";
import { List, Comment } from "antd";

import moment from "moment";

import tempIcon from "../temp/KakaoTalk_20200427_111500160.jpg";
import ImageChatMessage from "./ImageChatMessage";
import IsVideoOrAnotherFile from "./IsVideoOrAnotherFile";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatLimit,
  setGetMsg,
  setPastMessages,
} from "../../../../../store/actions/chatReducer";
import { useEffect } from "react";
import { store } from "../../../../../store/store";



function ChatListComponent(props) {
  const dispatch = useDispatch();

  const projectSq = props.match.params.projectId;

  // const [currentMsgLimit, setcurrentMsgLimit] = useState(2);

  const { user, items } = useSelector((state) => ({
    user: state.appState.user,
    items: state.ChatReducer.items,
  }));

  const chatListEventHandler = async () => {
    const currentMsgLimit = store.getState().ChatReducer.limitPage;
    const localitems = store.getState().ChatReducer.items;

    const scrollEl = document.querySelector(".ant-drawer .ant-drawer-body ul");
  
    if (scrollEl.scrollTop === 0) {
      const { data } = await axiosForChat.get(`/getChatMsg`, {
        params: {
          user: {
            name: user.f_user_nm,
            email: user.user_email_id,
          },
          room: projectSq,
          currentMsgLimit,
        },
      });
  
      if (data.done) {
        store.dispatch(
          setPastMessages([
            {
              notice: true,
              message: data.msg,
            },
            ...localitems,
          ])
        );
  
        return document
          .querySelector(".ant-drawer .ant-drawer-body ul")
          .removeEventListener("scroll", chatListEventHandler);
      }
      
      store.dispatch(setChatLimit())
      // setcurrentMsgLimit(currentMsgLimit + 1);
      console.log(data)
      store.dispatch(setPastMessages([...data, ...localitems]));
  
      return document.querySelector(".ant-list-items").scrollTo(0, 200);
    }
  }

  const chatScrollEventSet = () => {
    dispatch(setGetMsg(false));

    document
      .querySelector(".ant-list-items")
      .scrollTo(0, document.querySelector(".ant-list-items").scrollHeight);
      
    document
      .querySelector(".ant-drawer .ant-drawer-body ul")
      .addEventListener("scroll", chatListEventHandler);
  };

  const myRef = createRef();
  useEffect(() => {
    if (myRef.current !== null && myRef.current !== undefined) {
      chatScrollEventSet();
    }

    return () => {
      dispatch(setGetMsg(true));
    };
  }, [myRef.current]);

  return (
    <List
      className="msg-wrap"
      size="large"
      bordered
      dataSource={items}
      header={<h3>{projectSq}</h3>}
      renderItem={(item) => {
        // console.log(item)
        const itemUser = item.user;
        const isMe = item.user && itemUser.email === user.user_email_id;
        // const hasAuthor = (isMe ? null : itemUser.name);
        // const hasAvatar = (isMe ? null : 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png')
        // const isNotice = item.notice ? null : isMe;
        return (
          <>
            <li
              ref={myRef}
              className={
                item.notice
                  ? "msg-wrap-notice"
                  : itemUser.email === user.user_email_id
                  ? "msg-wrap-post"
                  : "msg-wrap-get"
              }
            >
              {
                <Comment
                  author={item.notice ? null : isMe ? null : itemUser.name}
                  avatar={item.notice ? null : isMe ? null : tempIcon}
                  content={
                    <>
                      <div className="contents">
                        {item.file ? (
                          item.file.mimeType &&
                          item.file.mimeType.toString().indexOf("image") !== -1 ? (
                            <ImageChatMessage item={item} />
                          ) : (
                            <IsVideoOrAnotherFile item={item} />
                          )
                        ) : (
                          item.message
                        )}
                      </div>
                      <span className="msg-time">
                        {item.notice
                          ? ""
                          : isMe
                          ? ""
                          : moment(item.created).format("LT")}
                      </span>
                    </>
                  }
                  // datetime={item.created}
                />
              }
            </li>
          </>
        );
      }}
    />
  );
}

export default React.memo(ChatListComponent);

// class ChatListComponent extends React.Component {
//   state = {
//     /*
//     * 처음 뿌려오는 길이 10
//     * skip 할 길이 = 불러오는 길이 5 * currentMsgLimit
//     */
//     currentMsgLimit: 2,
//   }
//   myRef = createRef();

//   chatScrollEventSet = () => {
//     this.props.changeGetMsg(false);

//     document.querySelector('.ant-list-items').scrollTo(0, document.querySelector('.ant-list-items').scrollHeight);
//     document.querySelector('.ant-drawer .ant-drawer-body ul').addEventListener('scroll', this.chatListEventHandler)
//   }

//   chatListEventHandler = () => {
//     if (document.querySelector('.ant-drawer .ant-drawer-body ul').scrollTop === 0) {
//       axiosForChat.get(`/getChatMsg`, {
//         params: {
//           user: {
//             name: this.props.user.f_user_nm,
//             email: this.props.user.user_email_id
//           },
//           room: this.props.ROOM,
//           currentMsgLimit: this.state.currentMsgLimit
//         }
//       })
//         .then(({ data }) => {
//           if (data.done) {
//             this.props.setMsgApi([{
//               notice: true,
//               message: data.msg
//             }])
//             document.querySelector('.ant-drawer .ant-drawer-body ul').removeEventListener('scroll', this.chatListEventHandler);
//             return;
//           }
//           this.setState({
//             currentMsgLimit: this.state.currentMsgLimit + 1
//           }, () => {
//             this.props.setMsgApi(data.reverse())
//             document.querySelector('.ant-list-items').scrollTo(0, 200);
//             return;
//           });
//         })
//     }
//   }

//   componentDidMount = () => {
//     if (this.myRef.current !== null && this.props.getMsg === true) {
//       this.chatScrollEventSet();
//     }
//   }

//   componentDidUpdate = () => {
//     if (this.myRef.current !== null && this.props.getMsg === true) {
//       //target is dom element
//       this.chatScrollEventSet();
//     }
//   }

//   componentWillUnmount = () => {
//     this.props.changeGetMsg(true);
//   }

//   render() {
//     return (
//       <List
//         className="msg-wrap"
//         size="large"
//         bordered
//         dataSource={this.props.items}
//         header={<h3>{this.props.ROOM}</h3>}
//         renderItem={item => {
//           const itemUser = item.user;
//           const isMe = item.user && itemUser.email === this.props.user.user_email_id;
//           // const hasAuthor = (isMe ? null : itemUser.name);
//           // const hasAvatar = (isMe ? null : 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png')
//           // const isNotice = item.notice ? null : isMe;
//           return (
//             <>
//               <li ref={this.myRef} className={item.notice ? 'msg-wrap-notice' : itemUser.email === this.props.user.user_email_id ? 'msg-wrap-post' : 'msg-wrap-get'}>
//                 {
//                   <Comment
//                     author={item.notice ? null : (isMe ? null : itemUser.name)}
//                     avatar={item.notice ? null : (isMe ? null : tempIcon)}
//                     content={
//                       <>
//                         <div className="contents">
//                           {
//                             item.file ? (
//                               item.file.mimeType && item.file.mimeType.toString().indexOf('image') > -1 ? <ImageChatMessage item={item} /> :
//                                 <IsVideoOrAnotherFile item={item} />
//                             ) : item.message
//                           }

//                         </div>
//                         <span className="msg-time">
//                           {item.notice ? "" : (isMe ? "" : moment(item.created).format('LT'))}
//                         </span>
//                       </>
//                     }
//                   // datetime={item.created}
//                   />
//                 }
//               </li>
//             </>
//           )
//         }}
//       />)
//   }
// }
