import React from "react";
import { Modal, Button, Radio } from "antd";
import { withTranslation } from "react-i18next";

import IconPlusRectangle from "../../../assets/icons/plus-rectangle.svg";
import { setModelLayerDate } from "../../../store/actions/modelLayer";
import "./uploadModal.less";
import { connect } from "react-redux";
import FileUpload from "../../blocks/modelUpload/fileUpload";
import DefaultInput from "../../elements/input/DefaultInput";
import {
  setOverlayWorkspace,
  setOverlayObject,
  setOverlayTypeAndTitle,
  setOverlayInit,
} from "../../../store/actions/overlay";
import {
  fnLoadDXF,
  fnLoadPLYforOveray,
  fnLoadIMGforOverayAndTransform,
  fnLoadTIFforOveray,
} from "../../../xitecloud3D/xiteCloud.dev";
import { setModalWarning } from "../../../store/actions/Modal";
import Axios from "axios";
import { setLayers } from "../../../store/actions/viewDataSet";
import { warnOverlayExtent } from "views/overlays/OverlayWorkspace";

const acceptTypeList = ["dxf", "ply", "image", "tif", "dxfwms"];

class UploadModal extends React.Component {
  state = {
    visible: false,
    show: false,
    radioSelected: 1,
    overlayTitle: "",
  };

  toggleCalendar = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  onRadioSelect = (e) => {
    this.setState({
      radioSelected: e.target.value,
    });
  };

  modelUpload = () => {
    if (this.state.overlayTitle <= 1) {
      this.props.setModalWarning({
        warning: true,
        title: this.props.t("title$check overlay name"),
        message: this.props.t("message$please enter overlay name"),
      });
      return;
    }

    if (!this.props.OverlayReducer.file) {
      this.props.setModalWarning({
        warning: true,
        title: this.props.t("title$check overlay file"),
        message: this.props.t("message$please enter overlay file"),
      });
      return;
    }

    (async () => {
      this.props.setOverlayTypeAndTitle({
        fileType: acceptTypeList[this.state.radioSelected],
        title: this.state.overlayTitle,
        overlayKey: null,
      });

      let idx = this.props.OverlayReducer.file.name.lastIndexOf(".");
      let ext = this.props.OverlayReducer.file.name.slice(idx + 1).toLowerCase();

      if (
        acceptTypeList[this.state.radioSelected] === "image" &&
        this.props.OverlayReducer.file.type.indexOf("image") !== -1
      ) {
        this.setState({
          visible: false,
          overlayTitle: "",
        });
        return this.props.setOverlayObject(
          await fnLoadIMGforOverayAndTransform(this.props.OverlayReducer.file)
        );
      }

      if (acceptTypeList[this.state.radioSelected] === "dxf" && ext === "dxf") {
        this.setState({
          visible: false,
          overlayTitle: "",
        });
        return this.props.setOverlayObject(await fnLoadDXF(this.props.OverlayReducer.file));
      }

      if (
        acceptTypeList[this.state.radioSelected] === "ply" &&
        this.props.OverlayReducer.file.name.indexOf("ply") !== -1
      ) {
        this.setState({
          visible: false,
          overlayTitle: "",
        });
        return this.props.setOverlayObject(
          await fnLoadPLYforOveray(this.props.OverlayReducer.file)
        );
      }

      if (
        (acceptTypeList[this.state.radioSelected] === "tif" && ext === "tif") ||
        (acceptTypeList[this.state.radioSelected] === "dxfwms" && ext === "dxf")
      ) {
        this.setState({
          visible: false,
          overlayTitle: "",
        });

        let stat = this.props.setOverlayObject(
          await fnLoadTIFforOveray(this.props.OverlayReducer.file),
          false
        );

        // save 실행
        {
          const formData = new FormData();
          const hasData = this.props.ViewDataSetReducer.projectViewDataSetModel.layer.overlay.find(
            (overlay) => overlay._3DObject.id === this.props.OverlayReducer._3dObject.id
          );
          let createPath = "/overlay";
          if (hasData) {
            formData.append("overlayId", hasData.dbObj.overlay_data_sq);
            createPath = createPath + `/${hasData.dbObj.overlay_data_sq}`;
            console.log(createPath);
          }
          formData.append("overlayFile", this.props.OverlayReducer.file);
          formData.append(
            "projectId",
            this.props.currentProjectReducer.currentProject.project_mng_sq
          );
          formData.append("inputDate", this.props.currentProjectReducer.selectedDate);
          formData.append("layerName", this.props.OverlayReducer.layerName);
          formData.append("fileType", this.props.OverlayReducer.fileType);

          Axios.post(createPath, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(async ({ data }) => {
            // Extent 비정상일 때, 경고 출력
            if (!JSON.parse(data[0].ol_description).check_extent_result) {
              warnOverlayExtent(this.props.t, JSON.parse(data[0].ol_description));
            }

            if (hasData === undefined || hasData === null) {
              this.props.OverlayReducer.overlayKey = data[0].overlay_data_sq;

              const operation = data.map((obj) => ({
                gbn: "Layers",
                group: "overlay",
                dataType: this.props.OverlayReducer.fileType,
                title: obj.f_layer_nm,
                dbObj: obj,
                _3DObject: this.props.OverlayReducer._3dObject,
                visible: false,
                disable: true,
                hasArrow: null, // 저장 완료 후 화살표 나오게하는 부분
                isOverlay: true,
              }));

              this.props.setLayers(
                this.props.ViewDataSetReducer.projectViewDataSetModel.layer.overlay.concat(
                  operation
                ),
                "overlay"
              );
            }

            this.props.setOverlayInit();
          });
        }

        //let stat = this.props.setOverlayWorkspace(false);

        return stat;
      }

      return this.props.setModalWarning({
        warning: true,
        title: this.props.t("title$File Type"),
        message: this.props.t("message$File Type is Not Supported"),
      });
      // return alert('File Type is Not Supported');
    })();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  setOverlayTitle = (e) =>
    this.setState({
      overlayTitle: e.target.value,
    });

  render() {
    const { t } = this.props;

    return (
      <div
        className="upload-modal"
        style={{
          lineHeight: "19px",
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <img
          className="i-plus-rectangle"
          src={IconPlusRectangle}
          onClick={this.showModal}
          alt="plus rectangle icon"
        />
        <Modal
          title={t("title$Upload_Overlays_Information_Data")}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="upload-modal__modal"
          width={520}
          centered
          footer={[
            <Button
              className="upload-modal__modal__confirm"
              key="submit"
              onClick={this.modelUpload}
            >
              {t("button$Upload")}
            </Button>,
          ]}
        >
          <section className="model-upload acumin">
            {/* <article className="model-upload__top caption">
              <p>{this.props.name}</p>
              {/* <Icon type="reload" /> 
            </article>   
              */}
            <article className="model-upload__content">
              <Radio.Group
                value={this.state.radioSelected}
                style={{
                  textAlign: "center",
                }}
                onChange={this.onRadioSelect}
              >
                {acceptTypeList.map((type, index) => (
                  <Radio value={index} key={index}>
                    {type}
                  </Radio>
                ))}
              </Radio.Group>
              <div className="upload-content-inner">
                <span className="w-50">{t("title$Name")}</span>
                <span className="w-50">
                  <DefaultInput
                    placeholder={t("placeholder$Overlay_name")}
                    style={{
                      width: "200px",
                    }}
                    value={this.state.overlayTitle}
                    onChange={this.setOverlayTitle}
                  />
                </span>
                <span className="w-50">
                  <FileUpload fileKey="overlay" />
                </span>
              </div>

              {/* <ul> */}
              {/* <Radio.Group value={this.state.radioSelected} onChange={this.onChange}> */}
              {/* <li className="group"> */}
              {/* <Radio>New Upload<Icon type="plus" /></Radio> */}
              {/* <div className="model-upload__content__inputs group"> */}

              {/* <DropDown items={[
                        { name: "Point Cloud", className: "i-upload", icon: IconUpload },
                        { name: "Triangle Mesh", className: "i-upload", icon: IconUpload },
                        { name: "Boring Log Data", className: "i-upload", icon: IconUpload },
                        { name: "Delete Files", className: "i-delete", icon: IconDelete }
                      ]} /> */}

              {/* <InputText placeholder="Enter name" width={this.props.filePath ? "200px" : "309px"} /> : null */}
              {/* } */}

              {/* {this.props.filePath ? <span className="file-path"><InputText placeholder="File path" width="100px" /></span> : null} */}
              {/* </div>
                  </li>
                </Radio.Group> */}
              {/* </ul> */}
            </article>
          </section>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(
  connect((state) => state, {
    setModelLayerDate,
    setOverlayWorkspace,
    setOverlayObject,
    setOverlayTypeAndTitle,
    setModalWarning,
    setOverlayInit,
    setLayers,
  })(UploadModal)
);
