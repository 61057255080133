import React, { useState } from "react";
import { InputNumber } from "antd";

import iconsphere from "./colorsphere.png";
import { connect } from "react-redux";
import "./index.less";

import { useEffect } from "react";

import { setMeasureColorVisible, setColor } from "../../../store/actions/MeasurementColor";
import MeasureColorPicker from "../../../components/blocks/MeasureColorPicker/MeasureColorPicker";
const colorsArray = [
  {
    threeColor: "#346cee",
    hex: "#346cee",
  },
  {
    threeColor: 0xa8f853,
    hex: "#a8f853",
  },
  {
    threeColor: 0xfece4b,
    hex: "#fece4b",
  },
  {
    threeColor: 0xfc944e,
    hex: "#fc944e",
  },
  {
    threeColor: 0xf76969,
    hex: "#f76969",
  },
  {
    threeColor: 0xd354f6,
    hex: "#d354f6",
  },
  {
    threeColor: 0x8563ff,
    hex: "#8563ff",
  },
  {
    threeColor: 0x81c7f2,
    hex: "#81c7f2",
  },
];

function Colors(props) {
  const measure = props.projectChildrenComponents.currentMeasure;
  // console.log('in Color')

  const grepColorType = () => {
    switch (props.type) {
      case "Point":
        return `#${measure.measure.pointColor.getHexString()}`;
      case "Line":
        return `#${measure.measure.lineColor.getHexString()}`;
      case "Area":
        return `#${measure.measure.faceColor.getHexString()}`;
      default:
        return `#${measure.measure.color.getHexString()}`;
    }
  };

  const [selectedColor, setSelectedColor] = useState(grepColorType());
  const [colorVisible, setColorVisible] = useState(false);
  // const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const colorChange = (hex) => {
    let hexCode = hex.indexOf("#") === -1 ? `#${hex}` : hex;
    const color = new window.THREE.Color(hexCode);
    /**
     * props.selected 의 기본 값 Line
     *  변경 시 Fill
     *
     * Line 시에는 점, 선, 면 색상 변경 가능
     * Fill 선택 시 measure.measure.fillColor.r 등의 기능 추가 필요.
     */
    const white = new window.THREE.Color("#ffffff");
    measure.measure.pointColor.r = white.r;
    measure.measure.pointColor.g = white.g;
    measure.measure.pointColor.b = white.b;

    if (props.type === "Point") {
      measure.measure.pointColor.r = color.r;
      measure.measure.pointColor.g = color.g;
      measure.measure.pointColor.b = color.b;
    } else {
      if (props.selected === "Line") {
        measure.measure.lineColor.r = color.r;
        measure.measure.lineColor.g = color.g;
        measure.measure.lineColor.b = color.b;
      } else {
        measure.measure.faceColor.r = color.r;
        measure.measure.faceColor.g = color.g;
        measure.measure.faceColor.b = color.b;
      }
    }

    setSelectedColor(hexCode);
  };

  useEffect(() => {
    if (props.MeasureColorReducer.selectedColor) {
      colorChange(props.MeasureColorReducer.selectedColor);
    }
  }, [props.MeasureColorReducer.selectedColor]);

  useEffect(() => {
    props.setColor(selectedColor);
  }, []);

  const onLineThickness = (val) => (measure.measure.lineThickness = val);

  const paletteHandle = () => {
    props.setMeasureColorVisible(false);
    setColorVisible(!colorVisible);
  };

  const pickerHandle = () => {
    props.setMeasureColorVisible(true);
    setColorVisible(false);
  };

  return (
    <>
      <div className="select-color">
        <span onClick={paletteHandle} style={{ backgroundColor: selectedColor }}></span>
        <span onClick={pickerHandle}>
          <img className="i-gradient" src={iconsphere} alt="gradient icon" />
        </span>
      </div>
      {props.type === "Line" && (
        <InputNumber
          className="line-thickness-wrap"
          size="small"
          defaultValue={3}
          min={0}
          max={20}
          formatter={(value) => `${value}px`}
          parser={(value) => value.replace("px", "")}
          onChange={onLineThickness}
        />
      )}

      <MeasureColorPicker />
      {colorVisible && (
        <div className="visualization-option-color-picker">
          {colorsArray.map((color, index) => (
            <div
              className="colors"
              key={index}
              onClick={() => colorChange(color.hex)}
              style={{ backgroundColor: color.hex }}
            />
          ))}
        </div>
      )}
    </>
  );
}

// export default Colors;
export default connect((state) => state, {
  // currentMeasure,
  setColor,
  setMeasureColorVisible,
})(Colors);

// {
//   /* {colorPickerVisible && ( */
// }
// {
//   /* // <Tooltip placement="bottom" title={"description description"}>
//         // <div
//         //   className="measurement-sketch-picker"
//         //   onDoubleClick={saveLocalStorage}
//         // >
//         // <Draggable
//         //     handle="div"
//         //     scale={1}
//         //     onMouseDown={onMouseDown}
//         //     onStart={dragStart}
//         //     // onDrag={onDrag}
//         //     // onStop={dragEnd}
//         //   >
//         //   <div>
//         //     <SketchPicker
//         //       color={selectedColor}
//         //       presetColors={localStorageColors}
//         //       onChange={(color) => colorChange(color.hex)}
//         //     />
//         //   </div>
//         // </Draggable>

//         // </div>
//         // </Tooltip>
//       // )}
// */
// }
