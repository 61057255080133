import React from "react";
import "./contourMap.less";
import {
  Button,
  //  Icon, Checkbox, Input
} from "antd";

import ContentTitle from "../../components/elements/text/contentTitle";
import SelectBox from "../../components/elements/select";
import ColorSelectBox from "../../components/elements/select/colorSelect";
// import Slider from "../../components/elements/slider";

// import CalendarSelector from "../../components/blocks/calendarSelector";
// import Comment from "../../components/blocks/comment";
// import ColorPicker from "../../components/blocks/colorPicker";

// import IconEdit from '../../assets/icons/edit.svg';
// import IconDelete from '../../assets/icons/delete.svg';
import HideDashboard from "../../components/blocks/hideDashboard";
import { connect } from "react-redux";
import { initializeTopo } from "../../components/blocks/model/utils/modelVisible";
import { setCurrentModel } from "../../store/actions/currentModel";
import DefaultButton from "../../components/elements/button/DefaultButton";
import { useTranslation } from "react-i18next";

const lineTypes = ["solid", "dashed", "dotted", "double"];
const lineThickness = ["1px", "2px", "3px", "4px"];

const indexTermItems = [5, 10, 20];
const interTermItems = [1, 2, 3, 4];

// const lineTypeItems = lineTypes.map((type,i) => <span style={{display: 'inline-block', verticalAlign: 'middle', width: '75px', borderTop: `2px ${type} #828b99`}} key={i}></span>)
// const lineTicknessItems = lineThickness.map((thickness,i) => <div style={{fontSize: '12px', textAlign: 'center'}}>{thickness}<span style={{display: 'inline-block', verticalAlign: 'middle', width: '50px', marginLeft: '16px', borderTop: `${thickness} solid #828b99`}} key={i}></span></div>)

function ContourMap(props) {
  const { t } = useTranslation();
  // props.currentModelReducer
  const onChange = (e) => {
    props.currentModelReducer.resetColor();
  };

  const onIndexColorChange = (e) => {
    props.currentModelReducer.setIndexColor(e);
    props.currentModelReducer.update();
  };

  const onInterColorChange = (e) => {
    props.currentModelReducer.setInterColor(e);
    props.currentModelReducer.update();
  };

  const onIndexTermChange = (e) => {
    props.currentModelReducer.setIndexTerm(indexTermItems[e]);
    props.currentModelReducer.indexTerm = +indexTermItems[e];
    props.setCurrentModel(props.currentModelReducer);
    props.currentModelReducer.update();
  };

  const onInterTermChange = (e) => {
    props.currentModelReducer.setInterTerm(interTermItems[e]);
    props.currentModelReducer.interTerm = +interTermItems[e];
    props.setCurrentModel(props.currentModelReducer);
    props.currentModelReducer.update();
  };

  return (
    <div className="contour-map">
      <section className="contour-map__top">
        <Button className="contour-map__top__back" icon="left" onClick={initializeTopo} />
        <h4>{`${props.currentModelReducer.title} ${t("title$Option")}`}</h4>
        {/* <h4>Contour Map</h4> */}
        <HideDashboard />
        {/* <div className="contour-map__top__actions">
          <img className="i-edit" src={IconEdit} alt="edit icon"/>
          <img className="i-delete" src={IconDelete} alt="delete icon"/>
        </div> */}
        {/* <div className="contour-map__top__description"> */}
        {/* <CalendarSelector/> */}
        {/* <Button>Search Layers<Icon type="caret-down" /></Button> */}
        {/* </div> */}
      </section>
      <section className="contour-map__content">
        <article className="contour-map__content__part">
          <ContentTitle title={t("title$Property", { count: 2 })} />
          {/* <Checkbox className="contour-map__content__checkbox">Index Contour Line</Checkbox> */}
          <div className="line group">
            <div className="line__top">
              <SelectBox
                label={t("title$Interval")}
                items={indexTermItems}
                default={`${props.currentModelReducer.indexTerm}`}
                select={onIndexTermChange}
              />
              <ColorSelectBox
                label={t("title$Line_Color")}
                items={["#FF0000", "#000000"]}
                default="#FF0000"
                onChange={onIndexColorChange}
              />
            </div>
            {/* <div className="line__bottom">
              <SelectBox label="Line Types" items={lineTypeItems} default={0}/>
              <SelectBox label="Line Thickness" items={lineTicknessItems} default={0}/>
            </div> */}
          </div>
          {/* <Checkbox className="contour-map__content__checkbox">Intermediate Contour Line</Checkbox> */}
          <div className="line group">
            <div className="line__top">
              <SelectBox
                label={t("title$Interval")}
                items={interTermItems}
                default={`${props.currentModelReducer.interTerm}`}
                select={onInterTermChange}
              />
              <ColorSelectBox
                label={t("title$Line_Color")}
                items={["#00FF00", "#0000FF"]}
                default="#00FF00"
                onChange={onInterColorChange}
              />
            </div>
            {/* <div className="line__bottom">
              <SelectBox label="Line Types" items={lineTypeItems} default={0}/>
              <SelectBox label="Line Thickness" items={lineTicknessItems} default={0}/>
            </div> */}
          </div>
          {/* <Checkbox className="contour-map__content__checkbox">Elevation</Checkbox>
          <ColorPicker/>
          <Slider label="Opacity" min={0} max={100} visibleMinMax/> */}
        </article>
        <article className="contour-map__content__part">
          <DefaultButton onClick={onChange}>{t("button$Reset_Color")}</DefaultButton>
          {/* <Input type="number" onChange={onChange} defaultValue={1} min={0} max={6} /> */}
        </article>
      </section>
      {/* <section className="contour-map__bottom">
        <Comment/>
      </section> */}
    </div>
  );
}

export default connect((state) => state, { setCurrentModel })(ContourMap);
