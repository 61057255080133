import React from "react";
import { Collapse, Icon, Modal, message } from "antd";
import { ReactComponent as IconEdit } from "../../../assets/icons-2/Icon_Edit.svg";
import { ReactComponent as IconDelete } from "../../../assets/icons-2/Icon_Delete.svg";
import IconUnfolded from "../../../assets/icons-2/Icon_Unfolded_Folder.svg";
import Axios from "axios";
import { connect } from "react-redux";
import { setFolders } from "../../../store/actions/projectList";
import { setModalSuccess, setModalInit } from "../../../store/actions/Modal";
import DragAndDrop from "../../../views/home/children/DragAndDrop";
import EditFolder from "./children/EditFolder";
import { withTranslation } from "react-i18next";
import { consoleError } from "middleware";
import { configReportAndFolder } from "views/documents/utils/report";
import "./folder.less";

const { Panel } = Collapse;

class DocumentFolder extends React.Component {
  state = {
    visible: false,
    title: this.props.folder.f_group_nm,
    key: this.props.folder.document_group_sq,
    activeArr: [],
    editVisible: false,
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      activeArr: e,
    });
  };

  handleUpdateFolder = () => {
    Axios.post("/updateFolder", {
      params: {
        title: this.state.title,
        key: this.state.key,
      },
    });
  };

  handleEditFolderChange = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleCancel = () => {
    this.setState({
      title: this.props.folder.f_group_nm,
      editVisible: false,
    });
  };

  handleEditClick = () => this.setState({ editVisible: true });
  handleDeleteClick = () => {
    const handleOKAsync = async () => {
      try {
        if (!this.state.key) return new Error("no folder id");

        await Axios.post(`/reportFolder/${this.state.key}/remove`);
        await configReportAndFolder();

        message.success(this.props.t("message$Successful_delete"), 3);
      } catch (err) {
        consoleError(err);
      }
    };

    Modal.confirm({
      content: this.props.t("message$Do_you_want_to_delete?"),
      onOk: handleOKAsync,
    });
  };

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    const isActive = this.state.activeArr.find((x) => +this.state.key === +x);
    const FolderIcons = (isActive) =>
      isActive ? (
        <img src={IconUnfolded} alt="folder icon" />
      ) : (
        <Icon type="folder" theme="filled" />
      );

    return (
      <div className="folder-section" data-folder={this.state.key}>
        {isActive && (
          <div className="folder__item__more_extends">
            <IconEdit width={10} height={10} onClick={this.handleEditClick} />
            <IconDelete width={10} height={10} onClick={this.handleDeleteClick} />
          </div>
        )}
        <Collapse
          className="folder"
          bordered={false}
          activeKey={this.state.activeArr}
          onChange={this.handleChange}
          expandIcon={({ isActive }) => FolderIcons(isActive)}
        >
          <Panel
            className={`folder__item font-sofia ${isActive ? "base-5" : ""}`}
            header={`${this.state.title} (${this.props.folder.projects.length})`}
            key={this.state.key}
            style={
              isActive
                ? {
                    backgroundColor: "var(--base5)",
                  }
                : {
                    backgroundColor: this.state.key % 2 ? "#7482a1" : " #a2abc0",
                  }
            }
          >
            {this.props.folder.projects
              .filter((p) =>
                p.f_report_name.toLowerCase().includes(this.props.keyword.toLowerCase())
              )
              .map((project, i) => (
                <DragAndDrop
                  history={this.props.history}
                  key={i}
                  isDocument={true}
                  onClick={this.props.documentCardonClick}
                  handle="div"
                  folderKey={this.state.key}
                  item={project}
                  itemKey={project.document_mng_sq}
                />
              ))}
          </Panel>
        </Collapse>

        <EditFolder
          isDocument={true}
          visible={this.state.editVisible}
          onCancel={this.handleCancel}
          onChange={this.handleEditFolderChange}
          value={this.state.title}
          folderId={this.state.key}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect((state) => state, { setFolders, setModalSuccess, setModalInit })(DocumentFolder)
);
