import React from "react";
import MeasurementPoint from "./Point/MeasurementPoint";
import MeasurementLine from "./Line/MeasurementLine";
import MeasurementPolygon from "./Polygon/MeasurementPolygon";
import MeasurementAnnotation from "./Annotation/MeasurementAnnotation";
import MeasurementVolume from "./Volume/PolygonVolume";
import CroSSection from "./CroSSection/CroSSection";
import PropTypes from "prop-types";
import "./index.less";

const componentsCheck = [
  {
    type: "point",
    component: MeasurementPoint,
  },
  {
    type: "line",
    component: MeasurementLine,
    crossection: CroSSection,
  },
  {
    type: "annotation",
    component: MeasurementAnnotation,
  },
  {
    type: "area",
    component: MeasurementPolygon,
    volume: MeasurementVolume,
  },
];

const main = (props) => {
  const MeasureComponent = componentsCheck.find((x) => x.type === props.measurementType);

  if (props.measure.toolObj.crossection) {
    return <MeasureComponent.crossection {...props} />;
  } else if (props.measure.toolObj.value.indexOf("a2") > -1) {
    return <MeasureComponent.volume {...props} />;
  } else {
    return <MeasureComponent.component {...props} />;
  }
};

main.propTypes = {
  measurementType: PropTypes.string.isRequired,
  measure: PropTypes.object,
};

export default main;
