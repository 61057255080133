import { store } from "../../../store/store";
import { setAPITopoDate, setSelectTopoDate, setSelectTargetDate, setAPITargetDate, setSelectSurfaceDate, setAPISurfaceDate } from "../../../store/actions/modelLayer";
import Axios from "axios";
import moment from 'moment'


export async function loadProjectLayerApi(projectId) {
    const params = {
        params: {
            projectId
        }
    }

    const topo = await Axios.get('/modelLayerTopo', params)
    //redux 관리
    if (topo.data.length) {
        const topoDateList = topo.data.map(x => moment(x.f_input_date).format('YYYY.MM.DD')).sort()
        store.dispatch(setAPITopoDate(topoDateList));
        store.dispatch(setSelectTopoDate(topoDateList.reverse()[0]));
    }

    const surface = await Axios.get('/modelLayerSurface', params)
    //redux 관리
    if (surface.data.length) {
        const surfaceDateList = surface.data.map(x => moment(x.f_input_date).format('YYYY.MM.DD')).sort();
        store.dispatch(setAPISurfaceDate(surface.data.map(x => moment(x.f_input_date).format('YYYY.MM.DD')).sort()))
        store.dispatch(setSelectSurfaceDate(surfaceDateList.reverse()[0]))
    }

    const target = await Axios.get('/modelLayerTarget', params)

    //redux 관리
    if (target.data.length) {
        const targetDateList = target.data.map(x => moment(x.f_input_date).format('YYYY.MM.DD')).sort();
        store.dispatch(setAPITargetDate(targetDateList))
        store.dispatch(setSelectTargetDate(targetDateList.reverse()[0]))
    }
}

