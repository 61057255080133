import React, { useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  setCrossLongitudinalWidth,
  setLongChartOptions,
} from "../../../../store/actions/crossSection";
import { drawChart } from "../DrawChart/DrawChart";

const LongitudinalChart = (props) => {
  console.log(`hk props`, props);
  const chartEl = useRef(null);
  const chartWrapperEl = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.data && Object.keys(props.data).length) {
      const summary = props.dataSet.t_summary;
      const min = summary ? summary.zmin : 0;
      const max = summary ? summary.zmax : 40;

      const ChartObject = drawChart(
        chartEl.current,
        props.data,
        props.label,
        props.isLong,
        min,
        max,
        chartWrapperEl.current
      );
      console.log(ChartObject, "dispatch");
      dispatch(
        setLongChartOptions({
          width: ChartObject.width,
          ticks: ChartObject.scales["x-axis-1"].ticks,
          gridItem: ChartObject.scales["x-axis-1"]["_labelItems"],
        })
      );

      if (chartEl.current && props.isLong) {
        let width = chartEl.current.style.width
          ? chartEl.current.style.width
          : chartEl.current.width;
        props.setCrossLongitudinalWidth(width);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartEl.current, props.data]);

  return (
    <article className="ctx-chart" ref={chartWrapperEl}>
      <canvas id="longitudinal-Chart" ref={chartEl} />
    </article>
  );
};

export default connect((state) => state, { setCrossLongitudinalWidth })(LongitudinalChart);
