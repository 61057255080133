// 21.3.3 token 추가
import {TOKEN} from '../actions/token';

const initializeToken = {
    userToken: null
}

export default function TokenReducer (state = initializeToken, action) {
    switch (action.type) {
        
        case TOKEN : return { ...state, userToken: action.userToken}; 

        default: return state;
    }
}