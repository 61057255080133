import React from "react";

import { fnChangeView, fnFlyRockLayer } from "../../../xitecloud3D/xiteCloud.dev";

import ToolsUI from "./template";
import {
  visibleMeasurement,
  currentMeasure,
  initMeasure,
} from "../../../store/actions/projectChildrenComponent";
import { connect } from "react-redux";
import { toolsChange } from "../../../views/Measurement/util/initialize";

class Tools extends React.Component {
  state = {
    flyVisible: false,
    selectedMeasure: null,

    onChangeTools: (value, selectedOptions) => this.onChangeTools(value, selectedOptions),
    onChangeView: (value, selectedOptions) => fnChangeView(value[0]),
    onChangeImagery: (value) => this.onChangeImagery(value),
  };

  /**
   * 1. fnFlyRockLayer: this.state.flyVisible :bool
   * 2. fnChangeTerrainMode: handle for 3d js (options -> window.GTerrainMode :bool)
   * 3. fnChangeImageMap: value: number / change Cesium ImageryProvider
   */
  onChangeImagery = (value) => {
    // if (value[0] === 'print') return fnSaveScene()

    // <MapController /> 로 이전
    // if (value[0] !== 'flyVisible') {
    //   return typeof value[0] === 'boolean' ? fnChangeTerrainMode(value[0]) : fnChangeImageMap(value)
    // }

    //re-rendering x
    return fnFlyRockLayer(!window.xitecloud.GFlyrocklayer, this.props.layerAndAnnotations);
  };

  /**
   * @param {:staring} target
   */
  isVolume = (target) => target === "a2";
  onChangeTools = (value, selectedOptions) => {
    toolsChange(selectedOptions);
  };

  /**
   * @param {:object} _item
   *  _item: selectedOption
   *  measure: 3D object Measure of Potree
   * @description
   *  1. default | transfer datasetAnnotationObj to redux store
   *  2. isVolume | set datasetAnnotationObj.value
   *  3. isCrossSection | call fnCrossectionLoad (3d handler js)
   */
  _toolsCallback = (_item, measure) => {
    if (!this.isVolume(_item.value) && measure.points.length === 0) return;
    const datasetAnnotationObj = {
      gbn: "Annotations",
      annotationGroup: _item.annotationGroup,
      title: _item.label,
      type: _item.measureType,
      _3DObject: {},
      toolObj: _item,
      measure: measure,
      visible: true,
      makeMeasure: true,
    };

    if (_item.crossection && _item.crossection === true)
      datasetAnnotationObj.crossSectionChildren = [];
    if (this.isVolume(_item.value)) datasetAnnotationObj.toolObj.value = _item.value;
    // if (_item.crossection === true) {
    //   fnCrossectionLoad(
    //     datasetAnnotationObj,
    //     this.props.ViewDataSetReducer.projectViewDataSetModel,
    //     this.props.currentProjectReducer.currentProject
    //   );
    // }

    this.setCurrentMeasureAndVisible(datasetAnnotationObj);
    this.setState({ selectedMeasure: datasetAnnotationObj });
  };

  /**
   * @param {:object} datasetAnnotationObj
   * @description
   *  set currentMeasure for redux store
   *  set visible true for redux store
   */
  setCurrentMeasureAndVisible = (datasetAnnotationObj) => {
    this.props.currentMeasure(datasetAnnotationObj);
    this.props.visibleMeasurement(datasetAnnotationObj === null ? false : true);
  };

  render = () => <ToolsUI {...this.state} />;
}

// export default Tools;
export default connect(
  (state) => ({
    layerAndAnnotations: state.ViewDataSetReducer.projectViewDataSetModel,
  }),
  { visibleMeasurement, currentMeasure, initMeasure }
)(Tools);
