import { isArrayLikeObject } from "lodash";
import { store } from "../../../store/store";
import {
  visibleMeasurement,
  currentMeasure,
  currentBackupMeasure,
} from "../../../store/actions/projectChildrenComponent";
import { setHandleInit } from "../../../store/actions/saveHandle";
import { nowProcessing } from "../../../utils/Hibrary";
import { initializeTopo } from "../../../components/blocks/model/utils/modelVisible";
import { startInsertion } from "../../../xitecloud3D/xiteCloud.dev";

export function initialize(mesureVisible = false) {
  const state = store.getState().projectChildrenComponents.currentMeasure;

  if (state) {
    if (!state.annotation_mng_sq) {
      window.viewer.scene.removeMeasurement(state.measure);
      window.viewer.scene.removeAnnotation(state.titleAnnotation);
      if (state.crossSectionChildren) {
        state.crossSectionChildren.map((x) => window.viewer.scene.removeMeasurement(x));
      }
    }
  }

  initArea3d();
  initDraw3D();
  titleAnnotationVisible(true);

  store.dispatch(visibleMeasurement(mesureVisible));
  if (mesureVisible === false) store.dispatch(currentMeasure(null));
  store.dispatch(currentBackupMeasure(null));
  store.dispatch(setHandleInit());
}

export const initDraw3D = () => {
  const measure = store.getState().projectChildrenComponents.currentMeasure;
  if (measure && measure.cross3DObject) {
    const {
      _3DObject_lon,
      _3DObject_lat,
      _3DObject_baseline,
      annotation_lon,
      annotation_lat,
    } = measure.cross3DObject;
    const findTarget = (object) => (x) => x.uuid === object.uuid;

    [_3DObject_lon, _3DObject_lat, _3DObject_baseline, annotation_lon, annotation_lat].map(
      (target3dObject) => {
        const parent = target3dObject.parent;
        if (parent) {
          parent.remove(parent.children.find(findTarget(target3dObject)));
        }

        return false;
      }
    );

    measure.cross3DObject = undefined;
  }
};

/**
 * @name
 *    - initArea3d
 * @param
 *    - annotationKey { annotation_mng } {:string}
 *    - measure { Dataset }
 **/
export const initArea3d = (annotationKey = "annotation_mng_sq") => {
  const measure = store.getState().projectChildrenComponents.currentMeasure;
  if (measure && !measure[annotationKey]) {
    handleAnnotationAndObject3d(measure, remove3D);
  }
};

export const remove3D = (object3D) => (object3D.parent ? object3D.parent.remove(object3D) : null);
export const Object3DVisibleHandle = (object3D, bool) => {
  if (object3D) object3D.visible = bool;
};

/**
 *
 * @param {:objcet} target
 * @param {:function} handleCallback
 * @param {:bool} bool
 *
 */
export const handleAnnotationAndObject3d = (target, handleCallback, bool) =>
  [handleObject3D, handlePotreeAnnotation].map((handler) => handler(target, handleCallback, bool));

export const handleObject3D = (target, handleCallback, bool) => {
  if (target._3DObject) {
    /**
     * volume 과 그 외 다른 3d의 데이터 타입이 다름.
     *    volume -> object
     *    etc -> array
     */
    if (isArrayLikeObject(target._3DObject)) target._3DObject.map((item) => handleCallback(item));
    if (Object.keys(target._3DObject).length) handleCallback(target._3DObject, bool);
  }
};

export const handlePotreeAnnotation = (target, handleCallback, bool) => {
  /**
   * annotation parent is null?:scene
   */
  if (target.potreeAnnotation) {
    target.potreeAnnotation.map((item) => handleCallback(item, bool));
  }
};

/**
 * @name
 *    - titleAnnotationVisible
 * @param {:bool}
 **/
export function titleAnnotationVisible(bool) {
  const measure = store.getState().projectChildrenComponents.currentMeasure;
  /**
   * tools measure object hasn't measure.titleAnnotation
   */
  if (measure && measure.titleAnnotation && Object.keys(measure.titleAnnotation).length) {
    measure.titleAnnotation.title = measure.title;
    measure.titleAnnotation.visible = bool;
  }
}

export const isVolume = (target) => target === "a2";
export const toolsChange = (option, isRedraw = false) => {
  if (nowProcessing()) return;
  initialize(isRedraw);
  if (isRedraw === false) {
    initializeTopo();
  }

  // 기존 2LEVEL에서 3 LEVEL로 기획 수정되어 로직 추가.
  if (option.length === 2 && option[0].value === "l2") {
    return isVolume(option[0].value)
      ? toolsCallback(option[1], null)
      : startInsertion(option[1], toolsCallback);
  } else {
    return isVolume(option[0].value)
      ? toolsCallback(option[0], null)
      : startInsertion(option[0], toolsCallback);
  }
};

export const toolsCallback = (_item, measure) => {
  let datasetAnnotationObj = {
    gbn: "Annotations",
    annotationGroup: _item.annotationGroup,
    title: _item.label,
    type: _item.measureType,
    _3DObject: {},
    toolObj: _item,
    measure: measure,
    visible: true,
    makeMeasure: true,
  };

  if (!isVolume(_item.value) && measure.points.length === 0) {
    return;
    // 되돌리기
    // const backupMeasure = store.getState().projectChildrenComponents.backupMeasure;
    // if (backupMeasure) {
    //   window.viewer.scene.addMeasurement(backupMeasure);
    //   datasetAnnotationObj.measure = backupMeasure;
    //   store.dispatch(currentBackupMeasure(null));
    // }
  }

  if (_item.crossection && _item.crossection === true)
    datasetAnnotationObj.crossSectionChildren = [];
  if (isVolume(_item.value)) datasetAnnotationObj.toolObj.value = _item.value;

  store.dispatch(currentMeasure(datasetAnnotationObj));
  store.dispatch(visibleMeasurement(datasetAnnotationObj !== null));
};
