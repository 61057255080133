import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ContentTitle from "../../../components/elements/text/contentTitle";
import AnnotationProperties from "../children/AnnotationProperties";
import Axios from "axios";
import {
  fnMakeParamFromMeasure,
  makeParamPoints,
  fnCalcArea,
} from "../../../xitecloud3D/xiteCloud.dev";
import { preCalcResult, fnCalcAreaJobDataSet } from "./util";
import { setAnnotations } from "../../../store/actions/viewDataSet";
import WorkspaceTitle from "../children/WorkspaceTitle";
import AnnotationFooter from "../children/AnnotationFooter";
import MeasurementVisualization from "../children/MeasurementVisualization";
import { createAnnotation } from "../util/fnCreateAnnotaion";
import { configAndSave } from "../util/save";
import { setHandleVisible, setHandleTargetName } from "../../../store/actions/saveHandle";
import { titleAnnotationVisible, initArea3d, initialize } from "../util/initialize";
import { currentMeasure } from "../../../store/actions/projectChildrenComponent";
import DefaultButton from "../../../components/elements/button/DefaultButton";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { addCommaWithRound, capitalizeFirstLetter } from "utils/StringHelper";
import { ROUND_OFF_THE_NUMBERS_AREA, ROUND_OFF_THE_NUMBERS_LENGTH } from "config/measure";
import { CoordinateForm } from "../children/CoordinateForm";
import "./index.less";

const MeasurementPolygon = (props) => {
  const { t, i18n } = useTranslation();

  const measure = props.projectChildrenComponents.currentMeasure;

  const defaultAttributes = [
    {
      title: t("title$Horizontal_Area"),
      value: "00",
      key: 0,
    },
    {
      title: t("title$Surface_Area"),
      value: "00",
      key: 1,
    },
    {
      title: t("title$Elevation"),
      value: ["Max", "Min"],
      key: 2,
    },
  ];
  const [attributes, setAttributes] = useState(defaultAttributes);

  const getResultAttr = () => {
    if (measure.f_result) {
      setAttributes([
        {
          title: t("title$Horizontal_Area"),
          value: addCommaWithRound(measure.f_result.h_area, ROUND_OFF_THE_NUMBERS_AREA),
          key: 0,
        },
        {
          title: t("title$Surface_Area"),
          value: addCommaWithRound(measure.f_result.area, ROUND_OFF_THE_NUMBERS_AREA),
          key: 1,
        },
        {
          title: t("title$Elevation"),
          value: [
            addCommaWithRound(measure.f_result.zmax, ROUND_OFF_THE_NUMBERS_LENGTH),
            addCommaWithRound(measure.f_result.zmin, ROUND_OFF_THE_NUMBERS_LENGTH),
          ],
          key: 2,
        },
      ]);
    } else {
      setAttributes(defaultAttributes);
    }
  };

  const getPolygonAttr = () => {
    initArea3d();

    if (measure.measure.points.length > 1) {
      const position = measure.measure.points.map((item, index) => {
        const { x, y, z } = item.position;
        return `${x} ${y} ${z}`;
      });

      position.push(position[0]);

      const topo_data_sq = props.ViewDataSetReducer.projectViewDataSetModel.layer.topo //arrays
        .find((x) => x.dataType && x.dataType === "pointcloud").dbObj.topo_data_sq;

      (async () => {
        try {
          const { data } = await Axios.get("/api/PRJ020306_01", {
            params: {
              topo_data_sq,
              points: position.join(),
            },
          });

          /**
           * polygon realtime preCalc
           */
          if (!measure.f_param) {
            const realDrawVolumOption = await preCalcResult(analysisAttr(), props, measure);

            fnCalcArea({}, realDrawVolumOption);
          }

          setAttributes([
            {
              title: t("title$Horizontal_Area"),
              value: addCommaWithRound(data.data.h_area, ROUND_OFF_THE_NUMBERS_AREA),
              key: 0,
            },
            {
              title: t("title$Surface_Area"),
              value: addCommaWithRound(data.data.s_area, ROUND_OFF_THE_NUMBERS_AREA),
              key: 1,
            },
            {
              title: t("title$Elevation"),
              value: [
                addCommaWithRound(data.data.zmax, ROUND_OFF_THE_NUMBERS_LENGTH),
                addCommaWithRound(data.data.zmin, ROUND_OFF_THE_NUMBERS_LENGTH),
              ],
              key: 2,
            },
          ]);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  };

  useEffect(() => {
    getResultAttr();
    initArea3d();
    titleAnnotationVisible(false);

    return () => {
      titleAnnotationVisible(true);
    };
  }, [measure]);

  const analysisAttr = () => {
    const srid = props.currentProjectReducer.currentProject.f_epsg;

    var points = fnMakeParamFromMeasure(measure.measure);

    const boundryLayer = "";
    const _targetBoundUse = false;

    return {
      srid: srid,
      points: points,
      targetLayer: "",
      // topoLayer: topo,
      topoLayer: `layer_${props.currentProjectReducer.currentProject.project_mng_sq}_${props.currentProjectReducer.selectedDate}_original`,
      boundryLayer: boundryLayer,
      targetBoundUse: _targetBoundUse,
    };
  };

  const configDbParamJob = () => {
    return new Promise((resolve, reject) => {
      preCalcResult(analysisAttr(), props, measure)
        .then((realDrawVolumOption) => {
          const rtnGeom = {};
          const dbParam = fnCalcAreaJobDataSet(rtnGeom, realDrawVolumOption);

          dbParam.f_location = makeParamPoints(measure.measure);
          // dbParam.f_create_date = props.currentProjectReducer.selectedDate;

          return resolve(dbParam);
        })
        .catch((err) => {
          return reject("param is not valid");
        });
    });
  };

  const onSave = async () => {
    let isJob = false;
    let dbParm;

    if (!measure.annotation_mng_sq) {
      isJob = true;
      dbParm = await configDbParamJob();

      if (dbParm === "param is not valid") {
        message.error(t("message$Failed_to_save_please_try_again"), 3);
        return initialize();
      }
    } else {
      dbParm = createAnnotation();
    }

    dbParm.f_line_color = `#${measure.measure.lineColor.getHexString()}`;
    dbParm.f_fill_color = `#${measure.measure.faceColor.getHexString()}`;

    return configAndSave(isJob, dbParm);
  };

  useEffect(() => {
    setAttributes(
      attributes.map((attr, i) => ({
        ...attr,
        title: defaultAttributes[i].title,
      }))
    );
  }, [i18n.language]);
  return (
    <div className="measurement">
      <WorkspaceTitle wrapperName="measurement" />
      <section className="measurement__content">
        <article className="measurement__content__part">
          <ContentTitle title={t("title$Result", { count: 2 })} style={{ color: "#182e63" }} />
          <CoordinateForm type={"polygon"} points={measure.measure.points} />
          <AnnotationProperties properties={attributes} />
          <DefaultButton onClick={getPolygonAttr}>{t("button$RESULT")}</DefaultButton>
        </article>
        <MeasurementVisualization type="Area" />
      </section>
      <AnnotationFooter onClick={onSave} className="measurement_footer" />
    </div>
  );
};

export default connect((state) => state, {
  setAnnotations,
  setHandleVisible,
  setHandleTargetName,
  currentMeasure,
  // setData,
})(MeasurementPolygon);
