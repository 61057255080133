//actions
import { ROCK_TYPE } from '../actions/rockTypes';

const initializeRockTypesState = {
    count: 2,
    rockTypes: []
}

export default function RockTypeReducer (state = initializeRockTypesState, action) {
    switch (action.type) {
        case ROCK_TYPE : return { ...state, rockTypes: action.types };
        default: return state;
    }
}