import Chart from "chart.js";
import { uniq } from "lodash";

export const defaultColorArr = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(204, 0, 254, 1)",
];

export function createLegend(labels, originalChartData) {
  const docfrag = document.createDocumentFragment();

  const createUl = document.createElement("ul");
  createUl.className = "legend";
  createUl.style.textAlign = "center";

  labels.map((title) => {
    /**
     * legend text
     */
    const createList = document.createElement("li");
    createList.textContent = title;
    createList.style.display = "inline-block";
    createList.style.margin = "8px";

    /**
     * legend color
     */
    const createSpan = document.createElement("span");
    createSpan.style.display = "inline-block";
    createSpan.style.marginLeft = "4px";
    createSpan.style.width = "8px";
    createSpan.style.height = "8px";

    createSpan.style.backgroundColor = originalChartData.find((x) => x.label === title).borderColor;

    createList.appendChild(createSpan);
    createUl.appendChild(createList);
  });

  docfrag.appendChild(createUl);

  return docfrag;
}

export function drawChart(targetCtx, chartData, label, isLong, min, max, chartWrapperEl) {
  let yMin = min;
  let yMax = max;
  chartData.map((x, index) => {
    const colorOption = targetCtx.getContext("2d").createLinearGradient(0, 0, 0, 450);

    // x.borderColor = defaultColorArr[index];

    /**
     * background rgba setting option
     */
    // colorOption.addColorStop(0, defaultColorArr[index].replace("1)", "0)"));
    // colorOption.addColorStop(0.5, defaultColorArr[index].replace("1)", "0.5)"));
    // colorOption.addColorStop(1, defaultColorArr[index]);

    let yDatas = x.data.map((d) => d.y).sort((a, b) => a - b);
    let yDataMin = yDatas[0];
    let yDataMax = yDatas[yDatas.length - 1];
    yMin = yMin > yDataMin ? yDataMin : yMin;
    yMax = yMax < yDataMax ? yDataMax : yMax;

    return (x.backgroundColor = "rgba(0, 0, 0, 0)");
  });

  /**
   * hasn't Legend ?
   */
  if (!chartWrapperEl.getElementsByClassName("legend").length) {
    const docfrag = createLegend(uniq(chartData.map((y) => y.label)), chartData);
    chartWrapperEl.insertBefore(
      docfrag,
      chartWrapperEl.children[chartWrapperEl.children.length - 1]
    );
  }

  const sectionChart = new Chart(targetCtx, {
    type: "scatter",
    // showXLabels: 20,
    data: {
      labels: label,
      datasets: chartData,
    },
    options: {
      autoSkip: false,
      responsive: true,
      scales: {
        yAxes: [
          {
            gridLines: {
              // color: 'rgba(0, 0, 0, 0)',
            },
            ticks: {
              beginAtZero: true,
              // steps: 10,
              // stepValue: 5,
              min: Math.round(yMin - 5),
              max: Math.round(yMax + 5),
            },
          },
        ],
        xAxes: [
          {
            type: "linear",
            gridLines: {
              color: "rgba(52, 108, 238, 0.5)",
              lineWidth: 1,
              borderDash: [6, 1],
            },
            // ticks: {
            //   min: -20,
            //   max: 20
            // }
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0.000001,
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.yLabel;
          },
        },
      },
    },
  });

  // console.log(sectionChart, '@@');

  return sectionChart;
}
