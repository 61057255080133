import React, { useState, useEffect } from "react";
import Axios from "axios";
import ContentTitle from "../../components/elements/text/contentTitle";
import DefaultButton from "../../components/elements/button/DefaultButton";
import OverlayProperty from "./children/OverlayProperty";
import { store } from "../../store/store";
import { connect } from "react-redux";
import { Modal } from "antd";
import { setLayers } from "../../store/actions/viewDataSet";
import { visibleHandle } from "./util/_3dVisible";
import { setOverlayObject, setOverlayInit, setOverlayTitles } from "../../store/actions/overlay";
import OverlayTitle from "./children/OverlayTitle";
import { Trans, useTranslation } from "react-i18next";
import "./index.less";

const locationTemp = { x: 0, y: 0, z: 0 };

export const warnOverlayExtent = (t, data) => {
  Modal.warning({
    title: t(`title$면적 경고`),
    content: (
      <Trans
        i18nKey="html$overlay 면적 경고 모달"
        values={{
          criteria: data.criteria.toLocaleString(),
          extent: data.dxf_extent.toLocaleString(),
        }}
      >
        <li>업로드한 DXF 파일의 면적이 허용 범위를 초과합니다. 확인해 주십시오.</li>{" "}
        <li>
          <p>{`허용치: %{criteria}`}</p>
          <p>{`업로드: %{extent}`}</p>
        </li>
      </Trans>
    ),
    okText: t(`button$Confirm`),
    onOk() {},
  });
};

const OverlayWorkspace = (props) => {
  const { t } = useTranslation();
  const temp = [
    {
      title: t("title$Position"),
      editable: true,
      location: locationTemp,
    },
    {
      title: t("title$Scale"),
      editable: true,
      location: locationTemp,
    },
    {
      title: t("title$Rotation"),
      editable: true,
      location: locationTemp,
    },
  ];
  const [state, setstate] = useState(temp);
  const [backstate, setbackstate] = useState(temp);

  const createState = () => {
    let data = temp;

    if (props.OverlayReducer._3dObject.position !== undefined) {
      data = [
        {
          title: t("title$Position"),
          editable: true,
          location: props.OverlayReducer._3dObject.position,
          onChange,
        },
        {
          title: t("title$Scale"),
          editable: true,
          location: props.OverlayReducer._3dObject.scale,
          onChange,
        },
        {
          title: t("title$Rotation"),
          editable: true,
          location: {
            x: props.OverlayReducer._3dObject.rotation.x,
            y: props.OverlayReducer._3dObject.rotation.y,
            z: props.OverlayReducer._3dObject.rotation.z,
          },
          onChange,
        },
      ];
    }

    return data;
  };

  const onChange = (item, value, title) => {
    props.OverlayReducer._3dObject[title.toLowerCase()][item] = value;
    setstate(createState());
  };

  let time = null;
  const onChange3DModel = () => {
    if (time !== null) return;

    time = setTimeout(() => {
      setstate(createState());
      time = null;
    }, 2000);
  };

  const onSave = () => {
    const formData = new FormData();
    const hasData = props.ViewDataSetReducer.projectViewDataSetModel.layer.overlay.find(
      (overlay) => overlay._3DObject.id === props.OverlayReducer._3dObject.id
    );

    let createPath = "/overlay";
    if (hasData) {
      formData.append("overlayId", hasData.dbObj.overlay_data_sq);
      createPath = createPath + `/${hasData.dbObj.overlay_data_sq}`;
      console.log(createPath);
    }

    formData.append("overlayFile", props.OverlayReducer.file);
    formData.append("projectId", props.currentProjectReducer.currentProject.project_mng_sq);
    formData.append("inputDate", props.currentProjectReducer.selectedDate);
    formData.append("layerName", props.OverlayReducer.layerName);
    formData.append("fileType", props.OverlayReducer.fileType);
    formData.append(
      "f_overlay_xy",
      JSON.stringify({
        position: props.OverlayReducer._3dObject.position,
        rotation: {
          x: props.OverlayReducer._3dObject.rotation.x,
          y: props.OverlayReducer._3dObject.rotation.y,
          z: props.OverlayReducer._3dObject.rotation.z,
        },
        scale: props.OverlayReducer._3dObject.scale,
      })
    );

    Axios.post(createPath, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(({ data }) => {
      // Extent 정상 여부 함수
      if (!JSON.parse(data[0].ol_description).check_extent_result) {
        warnOverlayExtent(t, JSON.parse(data[0].ol_description));
      }

      if (hasData === undefined || hasData === null) {
        props.OverlayReducer.overlayKey = data[0].overlay_data_sq;
        const operation = data.map((obj) => ({
          gbn: "Layers",
          group: "overlay",
          dataType: props.OverlayReducer.fileType,
          title: obj.f_layer_nm,
          dbObj: obj,
          _3DObject: props.OverlayReducer._3dObject,
          visible: false,
          hasArrow: "overlay", // 저장 완료 후 화살표 나오게하는 부분
        }));

        // visibleHandle(props.OverlayReducer._3dObject, false); // 주석 : 저장 완료 후 체크버튼 기능이 안됌
        props.setLayers(
          props.ViewDataSetReducer.projectViewDataSetModel.layer.overlay.concat(operation),
          "overlay"
        );
      }

      window.viewer.inputHandler.deselect(props.OverlayReducer._3dObject);
      props.OverlayReducer._3dObject.addEventListener("select", (e) => {
        if (
          store.getState().OverlayReducer.workspace &&
          store.getState().OverlayReducer._3dObject &&
          store.getState().OverlayReducer._3dObject.uuid === e.target.uuid
        ) {
          return;
        }

        return window.viewer.inputHandler.deselect(e.target);
      });

      return props.setOverlayInit();
    });
  };

  useEffect(() => {
    setstate(createState());
    setbackstate({
      position: Object.assign({}, props.OverlayReducer._3dObject.position),
      scale: Object.assign({}, props.OverlayReducer._3dObject.scale),
      rotation: Object.assign({}, props.OverlayReducer._3dObject.rotation),
    });

    if (window.viewer.inputHandler.blacklist.size > 0) {
      window.viewer.inputHandler.blacklist.clear();
    }

    if (props.OverlayReducer._3dObject.position !== undefined) {
      visibleHandle(props.OverlayReducer._3dObject, true);
      props.OverlayReducer._3dObject.addEventListener("position_changed", onChange3DModel);
      props.OverlayReducer._3dObject.addEventListener("orientation_changed", onChange3DModel);
    }

    return () => {
      if (props.OverlayReducer._3dObject.position !== undefined) {
        props.OverlayReducer._3dObject.removeEventListener("position_changed", onChange3DModel);
        props.OverlayReducer._3dObject.removeEventListener("orientation_changed", onChange3DModel);

        window.viewer.inputHandler.deselect(props.OverlayReducer._3dObject);

        if (!props.OverlayReducer.overlayKey && props.OverlayReducer._3dObject.parent) {
          if (props.OverlayReducer._3dObject.parent) {
            props.OverlayReducer._3dObject.parent.remove(props.OverlayReducer._3dObject);
          }
        }
      }

      props.setOverlayInit();
    };
  }, []);

  return (
    <div className="overlay__wrap">
      <OverlayTitle className="overlay__top" backstate={backstate} />
      <section className="overlay__content">
        {state.map((property, index) => (
          <article className="overlay__content__part" key={index}>
            <OverlayProperty
              property={property}
              disabled={props.OverlayReducer.fileType !== "image"}
            >
              <ContentTitle title={property.title} />
            </OverlayProperty>
          </article>
        ))}

        <article className="overlay__content__part">
          <ContentTitle title={t("title$Opacity")} />
        </article>
      </section>
      <section className="overlay__bottom">
        <DefaultButton block onClick={onSave}>
          {t("button$Save")}
        </DefaultButton>
      </section>
    </div>
  );
};

export default connect((state) => state, {
  setOverlayObject,
  setLayers,
  setOverlayInit,
  setOverlayTitles,
})(OverlayWorkspace);
