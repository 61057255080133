import React, { useRef, useState } from "react";
import ProjectCard from "../../../components/blocks/projectCard";
import Draggable from "react-draggable";
import { onDrag, dragEnd, isDocDragEnd } from "../utils/dragAndDrop";
import DocumentCard from "../../../components/blocks/projectCard/DocumentCard";
import { combineClassName } from "utils/StringHelper";

const DragAndDrop = (props) => {
  const { itemKey, item, parentRef } = props;

  const draggableRef = useRef(null);

  // 하위 이벤트 처리용
  const [isDragging, setIsDragging] = useState(false);

  /**
   * @description
   * - convert dragEndApi to dragEnd and add parameter - props
   * @type {import("react-draggable").DraggableEventHandler}
   */
  const dragEndApi = (evt, data) => {
    setIsDragging(false);
    props.isDocument ? isDocDragEnd(evt, data, props, props.isDocument) : dragEnd(evt, data, props);
  };

  /**
   * Draggable limit bounds
   */
  const getLimitBounds = () => {
    if (parentRef && parentRef.current && draggableRef.current) {
      const node = draggableRef.current.findDOMNode();
      if (node) {
        const { scrollHeight: parentHeight } = parentRef.current;
        const { ownerDocument, offsetTop, clientHeight } = node;
        const { getComputedStyle } = ownerDocument.defaultView;

        const { paddingBottom: parentPaddingBottom, paddingTop: parentPaddingTop } =
          getComputedStyle(parentRef.current);
        const { marginBottom } = getComputedStyle(node);
        return {
          bottom:
            parentHeight -
            parseInt(parentPaddingBottom, 10) -
            parseInt(parentPaddingTop, 10) -
            parseInt(marginBottom, 10) -
            clientHeight -
            offsetTop,
        };
      }
    }
  };

  const Components = props.isDocument ? DocumentCard : ProjectCard;

  /**
   * @description
   *    - has folderKey
   *    - folder , {count:1}
   */
  return (
    <Draggable
      key={itemKey}
      ref={draggableRef}
      handle={props.handle}
      cancel={".no-drag"}
      scale={1}
      position={{ x: 0, y: 0 }}
      axis="y"
      // defaultClassNameDragging이 onDrag보다 빨라서 대체
      defaultClassName={combineClassName(`react-draggable`, isDragging ? "dragging" : "")}
      bounds={getLimitBounds()}
      onDrag={(evt, data) => {
        setIsDragging(true);
        onDrag(evt, data);
      }}
      onStop={dragEndApi}
    >
      {props.folderKey ? (
        <div data-project={itemKey} key={itemKey}>
          <Components
            key={itemKey}
            item={item}
            itemKey={itemKey}
            folderKey={props.folderkey}
            {...props}
          />
        </div>
      ) : (
        <li data-project={itemKey} key={itemKey}>
          <Components
            key={itemKey}
            item={item}
            itemKey={itemKey}
            folderKey={props.folderkey}
            {...props}
          />
        </li>
      )}
    </Draggable>
  );
};

export default DragAndDrop;
