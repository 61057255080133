import { cloneDeep } from "lodash";
import {
  visibleMeasurement,
  currentMeasure,
  currentBackupMeasure,
} from "../../../store/actions/projectChildrenComponent";
import { store } from "../../../store/store";
import { titleAnnotationVisible } from "./initialize";
import { setAnnotationMany } from "../../../store/actions/viewDataSet";

export function pushCurrentMeasure(item) {
  const measure = store.getState().projectChildrenComponents.currentMeasure;

  /**
   * item hasn't measure
   */
  if (!Object.keys(item.measure).length) return;
  if (measure) titleAnnotationVisible(true);

  if (!item.visible) {
    item.visible = true;
    const annotations = store.getState().ViewDataSetReducer.projectViewDataSetModel.annotations;

    store.dispatch(
      setAnnotationMany(annotations, store.getState().ViewDataSetReducer.annotationCount)
    );
  }

  if (!item.measure.points) return;

  //if user cancel using object
  store.dispatch(
    currentBackupMeasure({
      points: cloneDeep(item.measure.points),
      title: item.title,
    })
  );
  store.dispatch(currentMeasure(item));
  store.dispatch(visibleMeasurement(true));
}
