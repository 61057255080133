import React, { useRef, useState, useEffect } from "react";
import { store } from "../../../../store/store";
import { useTranslation } from "react-i18next";

const ChartTable = (props) => {
  const { t, i18n } = useTranslation();

  const articleEl = useRef(null);

  const [tableData, setTableData] = useState([
    {
      key: "cut_z",
      data: [],
    },
    {
      key: "fill_z",
      data: [],
    },
    {
      key: "bottom_z",
      data: [],
    },
    {
      key: "top_z",
      data: [],
    },
    {
      key: "ex_dist",
      data: [],
    },
    {
      key: "pt_dist",
      data: [],
    },
    {
      key: "st_idx",
      data: [],
    },
  ]);

  const columnTitles = [
    t("column$절토고"),
    t("column$성토고"),
    t("column$계획고"),
    t("column$지반고"),
    t("column$누가거리"),
    t("column$점간거리"),
    t("column$측점"),
  ];

  useEffect(() => {
    if (Object.keys(props.data).length) {
      tableData.map((state) => (state.data = []));
      props.data.analysis.map((x) => {
        tableData
          .find((state) => state.key === "fill_z")
          .data.push({ key: x.pt_idx, value: Math.round(x.fill_z * 100) / 100 });
        tableData
          .find((state) => state.key === "bottom_z")
          .data.push({ key: x.pt_idx, value: Math.round(x.bottom_z * 100) / 100 });
        tableData
          .find((state) => state.key === "cut_z")
          .data.push({ key: x.pt_idx, value: Math.round(x.cut_z * 100) / 100 });
        tableData
          .find((state) => state.key === "ex_dist")
          .data.push({ key: x.pt_idx, value: Math.round(x.ex_dist * 100) / 100 });
        tableData
          .find((state) => state.key === "pt_dist")
          .data.push({ key: x.pt_idx, value: Math.round(x.pt_dist * 100) / 100 });
        tableData
          .find((state) => state.key === "top_z")
          .data.push({ key: x.pt_idx, value: Math.round(x.top_z * 100) / 100 });
        tableData
          .find((state) => state.key === "st_idx")
          .data.push({ key: x.pt_idx, value: Math.round(x.st_idx * 100) / 100 });
        return tableData;
      });

      console.log(tableData);
    }
    setTableData([].concat(tableData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, props.data]);

  const LongitudinalChartWidth = store.getState().CrossSectionReducer.LongitudinalChartWidth;
  const widthIsString = typeof LongitudinalChartWidth === "string";
  const isChartLeftStartMargin = 40;
  return (
    <>
      <article className="chart-tables" ref={articleEl}>
        <section>
          {tableData.map((x, index) => (
            <dl className="chart-table-row" key={`${x.key}_${index}`}>
              <dt
                style={{
                  width: 120,
                }}
              >
                {columnTitles[index]}
              </dt>
              {x.data.map((y, valueIndex) => (
                <dd
                  key={`${x.key}_${index}_${y.key}_${valueIndex}`}
                  style={{
                    alignSelf: "center",
                    width: Math.ceil(
                      widthIsString
                        ? (+LongitudinalChartWidth.replace("px", "") - isChartLeftStartMargin) /
                            props.data.analysis.length
                        : (LongitudinalChartWidth - isChartLeftStartMargin) /
                            props.data.analysis.length
                    ),
                  }}
                >
                  {y.value}
                </dd>
              ))}
            </dl>
          ))}
        </section>
      </article>
    </>
  );
};
export default ChartTable;
