import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { setColor, setMeasureColorVisible } from "../../../store/actions/MeasurementColor";
import { SketchPicker } from "react-color";

import "./index.less";

const MeasureColorPicker = (props) => {
  const myRef = useRef(null);
  useEffect(() => {
    if (document.getElementsByClassName("sketch-picker")) {
      document.getElementsByClassName("sketch-picker")[0].style.boxShadow = "unset";
    }
  }, [myRef.current]);

  if (!localStorage.getItem("pick-colors")) {
    localStorage.setItem("pick-colors", JSON.stringify([]));
  }

  const [localStorageColors, setLocalStorageColors] = useState(
    JSON.parse(localStorage.getItem("pick-colors"))
  );

  const saveLocalStorage = () => {
    let localItemColors = JSON.parse(localStorage.getItem("pick-colors"));
    localItemColors.push(props.MeasureColorReducer.selectedColor);
    if (localItemColors.length > 8) {
      localItemColors = localItemColors.reverse().slice(0, 8).reverse();
    }

    setLocalStorageColors(localItemColors);
    localStorage.setItem("pick-colors", JSON.stringify(localItemColors));
  };

  const onChange = (color) => {
    props.setColor(color.hex);
  };

  return (
    <>
      <Draggable handle="div" scale={1} cancel=".sketch-picker">
        <div
          className="measurement-sketch-picker"
          style={{
            display: props.MeasureColorReducer.visible ? "block" : "none",
          }}
        >
          <div className="drag-handler">
            <p>
              <span onClick={() => props.setMeasureColorVisible(false)} />
            </p>
            <div draggable="false" onDoubleClick={saveLocalStorage}>
              <SketchPicker
                ref={myRef}
                color={props.MeasureColorReducer.selectedColor}
                presetColors={localStorageColors}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default connect((state) => state, {
  setColor,
  setMeasureColorVisible,
})(MeasureColorPicker);
