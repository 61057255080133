import React, { useState } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { setPreviewerVisible, setPreviewerList } from "../../../store/actions/previewImage";
import { useEffect } from "react";
import { removeItem } from "../../blocks/tables/utils";
import Arrow from "../../elements/Arrow/Arrow";
import IconDelete from "../../../assets/icons/delete-white.svg";
import IconUpload from "../../../assets/icons/upload-white.svg";
import "./index.less";

const PreviewImages = (props) => {
  const [state, setstate] = useState(props.PreviewerReducer.selectedImage || 0);
  const increment = () => {
    let index = state + 1;
    if (state + 1 > fileList.length - 1) {
      index = 0;
    }
    setstate(index);
  };
  const decrement = () => {
    let index = state - 1;
    if (state - 1 < 0) {
      index = fileList.length - 1;
    }
    setstate(index);
  };

  const [fileList, setfileList] = useState([]);

  useEffect(() => {
    setfileList(
      props.PreviewerReducer.fileList.map((x) => ({
        name: x.f_file_name,
        src: "/" + x.f_file_path,
        file_mng_sq: x.file_mng_sq,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.PreviewerReducer.fileList.length]);

  // const fileList = [
  //     {
  //         src: 'https://images.unsplash.com/photo-1593874222307-f08a678926d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1251&q=80'
  //     },
  //     {
  //         src: 'https://images.unsplash.com/photo-1594481939273-42601fd2dbc2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80'
  //     }
  // ]
  const onRemove = (key) => {
    removeItem({
      onOk: () => {
        Axios.post(`/annotationFiles/${key}/remove`)
          .then((res) => {
            if (props.PreviewerReducer.fileList.filter((x) => x.file_mng_sq !== key).length !== 0) {
              decrement();
            } else {
              onClick();
            }

            props.setPreviewerList(
              props.PreviewerReducer.fileList.filter((x) => x.file_mng_sq !== key)
            );
          })
          .catch((err) => {
            console.log(err, "err");
          });
      },
      onCancel() {},
    });
  };

  const onClick = () => {
    props.setPreviewerVisible(false);
  };

  return (
    <div className="previewer__wrapper" draggable="false">
      {fileList.length > 0 && (
        <>
          <div className="previewer__header">
            <p className="previewer__header__left">
              <span onClick={onClick}>
                <Arrow
                  white
                  style={{
                    transform: "rotate(180deg)",
                    opacity: 1,
                    width: 15,
                    height: 15,
                  }}
                />
              </span>
              <span className="previewer__header__title">{fileList[state].name}</span>
            </p>
            <p className="previewer__header__right">
              <span>
                <img className="i-upload" src={IconUpload} alt="upload icons" />
              </span>
              <span onClick={() => onRemove(fileList[state].file_mng_sq)}>
                <img className="i-delete" src={IconDelete} alt="delete icons" />
              </span>
            </p>
          </div>
          <div className="previewer__body">
            <img src={fileList[state].src} alt="previewer" />
          </div>

          <div className="previewer__controller">
            <span className="controller_left" onClick={increment}></span>
            <span className="controller_right" onClick={decrement}></span>
          </div>
        </>
      )}
    </div>
  );
};

export default connect((state) => state, {
  setPreviewerVisible,
  setPreviewerList,
})(PreviewImages);
