import React, { useState } from "react";
import { Button, Modal } from "antd";
import Tables from "../../tables/index";
import TypeModal from "./TypeModal";
import DefaultButton from "../../../elements/button/DefaultButton";
import IconUpload from "../../../../assets/icons-2/Icon_Upload_white.svg";
import { useTranslation } from "react-i18next";
// const spanStyle = {
//     display: 'inline-block',
//     width: '189px',
//     paddingLeft: '4px',
//     verticalAlign: 'top',
//   }

const BoreholeModal = (props) => {
  const { t } = useTranslation();
  /**
   * Rock Table Modal
   */
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  /**
   * Type Table Modal
   */
  const [modalVisible, setModalVisible] = useState(false);
  const handleTypeModal = () => setModalVisible(!modalVisible);

  return (
    <>
      {/* <span style={spanStyle}></span> */}
      <DefaultButton
        className="toggle-borehole-btn"
        style={props.disabled ? { backgroundColor: "#9ca4ad" } : {}}
        disabled={props.disabled}
        radius={"2px"}
        onClick={showModal}
      >
        <img
          src={IconUpload}
          alt="Data Upload"
          style={{
            marginRight: "8px",
            width: "11px",
            height: "18px",
          }}
        />
        {t("button$Input_Borehole_Data")}
      </DefaultButton>

      <Modal
        width="76%"
        className="text-right"
        title={t("title$Borehole Data")}
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        footer={[
          <Button key="soiltype" onClick={handleTypeModal}>
            {t("button$Set_Soil_Type", { count: 2 })}
          </Button>,
          // <Button key="submit" type="primary" onClick={hideModal}>
          //   save
          // </Button>,
        ]}
      >
        {/* 테이블 */}
        <Tables />
        {/* 타입 모달 */}
        <TypeModal typesVisible={modalVisible} showType={handleTypeModal} />
      </Modal>
    </>
  );
};

export default BoreholeModal;
