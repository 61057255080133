import React from "react";
import { Menu, Dropdown, Button } from "antd";
import { ReactComponent as IconMore } from "../../../assets/icons/more.svg";
import { ReactComponent as IconMoreWhite } from "../../../assets/icons-2/Icon_More_White.svg";
import ModalEventRecords from "../../modals/eventRecords";
import "./dropdown.less";
import { map } from "lodash";

const DropDown = (props) => {
  const modals = {
    ModalEventRecords: <ModalEventRecords type={"dropdown"} />,
  };

  return (
    <div id="components-dropdown-demo-dropdown-button">
      <Dropdown
        className="dropdown no-drag"
        overlay={
          <Menu className="dropdown__menu">
            {map(props.items, (item, i) => {
              return (
                <Menu.Item key={i} className="dropdown__menu__item" onClick={item.onClick}>
                  {item.modalName ? (
                    modals[item.modalName]
                  ) : (
                    <span>
                      {item.icon}
                      {item.name}
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        }
        trigger={["click"]}
      >
        <Button>
          {props.white ? (
            <IconMoreWhite className="icon i-more" fill={"#FFFFFF"} />
          ) : (
            <IconMore className="icon i-more" />
          )}
        </Button>
      </Dropdown>
    </div>
  );
};

export default DropDown;
