import React from "react";
import { withTranslation } from "react-i18next";
import { Select } from "antd";
import "../index.less";
import "./colorSelect.less";
import { capitalizeFirstLetter } from "utils/StringHelper";

const { Option } = Select;

// default selectbox width 100%
// required props - items
// optional props - label, default, width
class ColorSelectBox extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="select-box group">
        {this.props.label ? <label className="select-box__label">{this.props.label}</label> : null}
        <Select
          className="select-box__select"
          defaultValue={this.props.default || capitalizeFirstLetter(t("placeholder$text"))}
          style={{ width: this.props.width }}
          onChange={this.props.onChange}
        >
          {this.props.items.map((x, i) => {
            return (
              <Option value={x} key={i}>
                <span className="select-box__select__tile" style={{ backgroundColor: x }}></span>
                {x}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  }
}

export default withTranslation()(ColorSelectBox);
