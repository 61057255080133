import { store } from "../../../../store/store";
import { setSelectTopoDate, setSelectSurfaceDate, setSelectTargetDate, setModelLayerDate } from "../../../../store/actions/modelLayer";
import Axios from "axios";
import moment from 'moment';
export async function grepSelectedInfo(selectedDate) {
    /**
     * set selected date for store
     */
    store.dispatch(setModelLayerDate(moment(selectedDate).format('YYYY.MM.DD')));
    
    const layerRow = await Axios.get(`/layerDateInfo/show`, {
        params: {
            project_mng_sq: store.getState().currentProjectReducer.currentProject.project_mng_sq,
            input_date: moment(selectedDate).format('YYYYMMDD')
        }
    });

    /**
     * has layer?
     */
    if (layerRow.data.length) {
        /**
         * select first item
         */
        const { f_topo_input_date, f_subsurface_input_date, f_target_input_date } = layerRow.data[0]

        if (f_topo_input_date)
            store.dispatch(setSelectTopoDate(moment(f_topo_input_date).format('YYYY.MM.DD')))

        if (f_subsurface_input_date)
            store.dispatch(setSelectSurfaceDate(moment(f_subsurface_input_date).format('YYYY.MM.DD')))

        if (f_target_input_date)
            store.dispatch(setSelectTargetDate(moment(f_target_input_date).format('YYYY.MM.DD')))
    }
}