import React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "antd";
import "./eventRecords.less";
import IconProfile from "../../../assets/icons/profile.svg";
import IconProfileOn from "../../../assets/icons/profile-on.svg";
import IconEvent from "../../../assets/icons/event.svg";

class eventRecordsModal extends React.Component {
  state = {
    visible: false,
    records: [
      {
        id: 1,
        isLogin: true,
        message: "Logan updated geofence: Stock pile 01",
        updatedDate: "20190101, 13:24",
      },
      {
        id: 2,
        isLogin: true,
        message: "Sooha created “stockpile 13”",
        updatedDate: "20190228, 15:50",
      },
      {
        id: 3,
        isLogin: true,
        message:
          "Seungsoo’s massage: Hard rock was founded at Zone 1. So, Exc. attachment change is required.",
        updatedDate: "20190130, 15:33",
      },
      {
        id: 4,
        isLogin: false,
        message: "Sooha created “stockpile 13”",
        updatedDate: "20190228, 15:50",
      },
      {
        id: 5,
        isLogin: false,
        message: "Logan updated geofence: Stock pile 01 (20190101, 13:24)",
        updatedDate: "20190101, 13:24",
      },
      {
        id: 6,
        isLogin: false,
        message:
          "Seungsoo’s massage: Hard rock was founded at Zone 1. So, Exc. attachment change is required.",
        updatedDate: "20190130, 15:33",
      },
      {
        id: 7,
        isLogin: false,
        message: "Sooha created “stockpile 13”",
        updatedDate: "20190228, 15:50",
      },
    ],
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { t } = this.props;
    const types = {
      dropdown: (
        <label className="event-records__label" onClick={this.showModal}>
          <img className="icon i-event" src={IconEvent} alt="event icon" />
          {t("button$Events")}
        </label>
      ),
      icon: (
        <img
          className="event-records__icon"
          src={IconEvent}
          alt="event icon"
          onClick={this.showModal}
        />
      ),
    };
    return (
      <div className="event-records">
        {types[this.props.type]}
        <Modal
          title={t("title$Event_Records")}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="event-records__modal"
          width={500}
          centered
          footer={[]}
        >
          <ul>
            {this.state.records.map((record) => {
              return (
                <li key={record.id} className="event-records__modal__item group">
                  <img src={record.isLogin ? IconProfileOn : IconProfile} alt="profile icon" />
                  <p>
                    {record.message} ({record.updatedDate})
                  </p>
                </li>
              );
            })}
          </ul>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(eventRecordsModal);
