import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import DefaultInput from "../../../elements/input/DefaultInput";
import DefaultButton from "../../../elements/button/DefaultButton";

import { Modal, message } from "antd";
import { connect } from "react-redux";
import { setFolders } from "../../../../store/actions/projectList";
import { setModalSuccess } from "../../../../store/actions/Modal";
import { setReportFolder } from "../../../../store/actions/report";
import { capitalizeFirstLetter } from "utils/StringHelper";
/**
 * @component
 *    - EditFolder
 * @description
 *    - Folder Data handle UI
 *    - HTTP Request
 */
const EditFolder = (props) => {
  const { t } = useTranslation();
  const reportFolders = props.ReportReducer.folders;
  const projectFolders = props.projectReducer.folders;

  const [value, setValue] = useState(props.value);

  const handleInputChange = (evt) => setValue(evt.target.value);

  const handleConfirm = async () => {
    const { data } = await Axios.post("/updateFolder", {
      title: value,
      folderId: props.folderId,
      document: props.isDocument,
    });

    if (props.isDocument) {
      reportFolders.find((x) => x.document_mng_sq === data[0].document_mng_sq).f_group_nm =
        data[0].f_group_nm;
      props.setReportFolder(reportFolders);
    } else {
      projectFolders.find((x) => x.project_group_sq === data[0].project_group_sq).f_group_nm =
        data[0].f_group_nm;
      props.setFolders(projectFolders);
    }

    message.success(t("message$Successful_Save"));

    /**
     * close event
     */
    setTimeout(() => {
      if (props.visible) props.onCancel();
    }, 200);
  };

  return (
    <Modal
      title={t("title$Edit_Folder_Name")}
      visible={props.visible}
      onOk={handleConfirm}
      onCancel={props.onCancel}
      className="create-folder__modal"
      width={350}
      centered
      footer={
        <DefaultButton className="create-folder__modal__confirm" onClick={handleConfirm}>
          {capitalizeFirstLetter(t("button$OK"))}
        </DefaultButton>
      }
    >
      <p className="create-folder__modal__title caption">{t("title$Edit_Folder_Name")}</p>
      <DefaultInput value={value} onChange={handleInputChange} />
    </Modal>
  );
};

export default connect((state) => state, { setFolders, setModalSuccess, setReportFolder })(
  EditFolder
);
