export default (errorMessage, i18n) => {
  if (errorMessage.indexOf("403") !== -1) {
    return {
      message: i18n.t(`message$권한이 없습니다!`),
      description: ``,
    };
    // if (window.confirm("권한이 없습니다!.")) {
    //   // return window.location.reload('/');
    //   return (window.location.href = "/");
    // } else {
    //   return {
    //     message: `Request Error:`,
    //     description: errorMessage,
    //   };
    // }
  }

  return {
    message: `Request Error:`,
    description: errorMessage,
  };
};
