//types
export const FOLDER_LIST = 'PROJECT/SET/FOLDER_LIST';
export const PROJECT_LIST = 'PROJECT/SET/LIST';
export const PROJECT_ALL_LIST = 'PROJECT/SET/ALL_LIST';
export const PROJECT_ALL_SET = 'PROJECT/SET/ALL_SET';

export const PROJECT_SEARCH_KEYWORD = 'PROJECT/SET/KEYWORD';
export const PROJECT_WORKSPACE_VISIBLE = 'PROJECT/SET/WORKSPACE_VISIBLE';


//config options
export function configProjectObj(project) {
  return {
    title: project.f_proj_nm,
    caption: project.f_description,
    weather: 'rainy', // sunny
    temperature: "",
    date: project.f_create_date,
    city: "",
    country: "",
    folder: null || project.project_group_sq_t_project_group,
    id: project.project_mng_sq,
    dbData: project
  }
}

//setter
export const setAllProject = projectAllList => ({ type: PROJECT_ALL_LIST, projectAllList });
export const setProjects = projects => ({ type: PROJECT_LIST, projects });
export const setFolders = folders => ({ type: FOLDER_LIST, folders });
export const configProject = ({ projects, folders }) => {
  const _folders = [];
  const _projects = [];

  for (let key in folders) {
    folders[key].projects = [];

    for (let projectKey in projects) {
      let setProject = configProjectObj(projects[projectKey]);
      if (folders[key].project_group_sq === projects[projectKey].project_group_sq_t_project_group) {
        folders[key].projects.push(setProject);
      }
    }

    _folders.push(folders[key]);
  }

  for (let projectKey in projects) {
    let setProject = configProjectObj(projects[projectKey])

    if (projects[projectKey].project_group_sq_t_project_group === null) {
      _projects.push(setProject);
    }
  }

  return ({
    type: PROJECT_ALL_SET,
    projectAllList: projects,
    projects: _projects,
    folders: _folders
  })
}

export const setWorkspaceVisible = () => ({ type: PROJECT_WORKSPACE_VISIBLE });

export const setSearch = keyword => ({ type: PROJECT_SEARCH_KEYWORD, keyword });