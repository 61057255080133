//types
export const PUBLISH_DATASET = "REPORT/SET/DATASET";
export const PUBLISH_INIT = "REPORT/SET/INIT";
export const REPORT_LIST = "REPORT/SET/LIST";
export const PUBLISH_VISIBLE = "REPORT/SET/VISIBLE";

export const setReportVisible = (visible) => ({ type: PUBLISH_VISIBLE, visible });

export const setReportList = (list) => ({ type: REPORT_LIST, list });

export const setPublishDataSet = (dataset) => ({ type: PUBLISH_DATASET, dataset });
export const setPublishInit = () => ({ type: PUBLISH_INIT });

export const REPORT_UN_GROUP = "REPORT/SET/UNGROUPS";
export const setReportFolder = (folders) => ({ type: REPORT_FOLDER, folders });

export const REPORT_FOLDER = "REPORT/SET/FOLDER";
export const setReportUnGroups = (reports) => ({ type: REPORT_UN_GROUP, reports });

export const PUBLISH_KEYWORD = "REPORT/SET/KEYWORD";
export const setReportKeyword = (keyword) => ({ type: PUBLISH_KEYWORD, keyword });
