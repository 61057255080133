import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Button, AutoComplete } from "antd";
import IconProfile from "../../../assets/icons/profile.svg";
import IconProfileOn from "../../../assets/icons/profile-on.svg";
import IconTeamWhite from "../../../assets/icons/team-white.svg";
import SelectBox from "../../elements/select";

import "./index.less";
import Text from "antd/lib/typography/Text";
import { capitalizeFirstLetter } from "utils/StringHelper";

const Organization = (props) => {
  const { t } = useTranslation();
  // const [users, setusers] = useState([
  //   {
  //     id: 1,
  //     isLogin: true,
  //     name: "Jongmin Lee",
  //     role: "Admin",
  //     email: 'dump.xitecloud.io',
  //   },
  //   {
  //     id: 2,
  //     isLogin: true,
  //     name: "Wonki Min",
  //     role: "Guest",
  //     email: 'dump.xitecloud.io',
  //   },
  // ]);

  const users = [
    {
      id: 1,
      isLogin: true,
      name: "Jongmin Lee",
      role: "Admin",
      email: "dump.xitecloud.io",
    },
    {
      id: 2,
      isLogin: true,
      name: "Wonki Min",
      role: "Guest",
      email: "dump.xitecloud.io",
    },
  ];

  //modal visible, handler
  const [visible, setvisible] = useState(false);
  const showModal = () => {
    return !props.disabled && setvisible(true);
  };
  const handleOk = (e) => setvisible(false);
  const handleCancel = (e) => setvisible(false);

  //search state, handler
  const [dataSource, setdataSource] = useState([]);
  const onSearch = (searchText) =>
    setdataSource(!searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)]);

  return (
    <div className="anticon">
      <label className="organization__label" onClick={showModal}>
        <img
          className="organization__flat"
          src={IconTeamWhite}
          style={{
            opacity: props.disabled ? 0.7 : 1,
          }}
          alt="team icon"
        />
      </label>
      <Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="organization__modal"
        width={500}
        centered
        closable={false}
        footer={[]}
      >
        <article className="organization__modal__top group">
          <AutoComplete
            className="search"
            dataSource={dataSource}
            onSearch={onSearch}
            placeholder={t("placeholder$Search_a_person_or_type_an_email")}
          />
          <SelectBox
            items={[
              t("option$Full_Access"),
              t("option$Can_Edit"),
              t("option$Can_Comment"),
              t("option$Can_Read"),
            ]}
          />
          <Button>{t("button$Invite")}</Button>
        </article>
        <article className="organization__modal__middle">
          <p className="title">
            <Trans i18nKey="html$Organization 모달 Users Permissions">
              Users
              <span className="sub-title">Permissions</span>
            </Trans>
          </p>
          <ul>
            {users.map((user) => {
              return (
                <li key={user.id} className="item group">
                  <img src={user.isLogin ? IconProfileOn : IconProfile} alt="profile icon" />
                  <div className="user-info">
                    <span className="name">{user.name}</span>
                    <span className="email">{user.email}</span>
                  </div>

                  <Text className="user-role">{user.role}</Text>
                </li>
              );
            })}
          </ul>
        </article>
      </Modal>
    </div>
  );
};

export default Organization;
