import _ from "lodash";

/**
 * 문자열 공백 여부 확인
 * @param {string | null} str
 */
export const isNullOrWhiteSpace = (str) => {
  if (str === null) return true;

  if (typeof str !== "string") {
    throw new TypeError("The str is not String.");
  }

  return str === "" || str.match(/^ *$/) !== null;
};

/**
 * string 파라미터 비교, 숫자 비교 포함
 * @param {string | undefined | null} a
 * @param {string | undefined | null} b
 */
export const compareTwoStrings = (a, b) => {
  if (a === null || a === undefined || isNullOrWhiteSpace(a)) return 1;
  if (b === null || b === undefined || isNullOrWhiteSpace(b)) return -1;

  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
};

/**
 * className merge용 함수
 * 고정 className을 유지하면서 합칠때 사용
 * @param  {string | string[]} className
 * @returns {string}
 */
export const combineClassName = (...classNames) => {
  return classNames.filter((name) => typeof name === "string").join(" ");
};

/**
 * 첫글자 대문자화
 * @param {string} str
 * @returns
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * 숫자 천단위 콤마 추가
 * @param {*} value
 * @returns
 */
export const addComma = (value) => {
  return value
    .toString()
    .replace(/(\..*)$|(\d)(?=(\d{3})+(?!\d))/g, (digit, fract) => fract || digit + ",");
};

export const addCommaWithRound = (value, num) => {
  return addComma(_.round(value, num).toFixed(num));
};

/**
 * 문자열 자리 계수
 * @param {*} string 대상 문자열
 * @returns Integer
 */
export const countCharacters = (string) => String(string).length;

/**
 * 휴대전화 번호 여부
 * @param {*} telno 전화번호 문자열
 * @returns true/false
 */
export const isPhoneNum = (telNum) =>
  String(telNum)?.replace(/[- ]/g, "").substring(0, 3) === "010";

/**
 * 문자열 공백 및 하이픈 제거
 * @param {*} string 대상 문자열
 * @returns String
 */
export const removeDashAndSpace = (string) => String(string)?.replace(/[- ]/g, "");

/**
 * 휴대 전화 번호 하이픈 추가
 * @param {*} _telno 하이픈 없는 숫자 문자열
 * @returns
 */
export const telnoWithDash = (phoneNum) => {
  const telNum = removeDashAndSpace(phoneNum);
  let telnumLength = countCharacters(telNum);

  return telnumLength === 11 && telNum.slice(0, 3) === "010"
    ? `${telNum.slice(0, 3)}-${telNum.slice(3, 7)}-${telNum.slice(7, 12)}`
    : telNum;
};

/**
 * 사업자번호 하이픈 추가
 * @param {*} _bizNo 입력받은 문자열
 * @returns
 */
export const bizNoWithDash = (bizNo) => {
  const _bizNo = removeDashAndSpace(bizNo);
  return countCharacters(bizNo) !== 10
    ? bizNo
    : `${_bizNo.slice(0, 3)}-${_bizNo.slice(3, 5)}-${_bizNo.slice(5, _bizNo.length)}`;
};

/**
 * 문자열의 JSON 여부 반환
 * @param {*} str 문자열
 * @returns true/false
 */
export const isJsonString = (str) => {
  try {
    let json = JSON.parse(str);
    return typeof json === "object" && json.constructor.name === "Object";
  } catch (e) {
    return false;
  }
};

/**
 * JSON 객체의 각 요소의 문자열 앞뒤 공백 및 탭 제거(Trim)
 * @param {*} _json JSON 객체
 * @returns 각 요소가 Trim 처리된 JSON 객체
 */
export const trimStringsInJson = (_json) => {
  // 객체가 null이거나 배열인 경우 그대로 반환
  if (_json === null || Array.isArray(_json)) return _json;

  // 객체 순회
  for (const _key in _json) {
    if (typeof _json[_key] === "string") {
      _json[_key] = _json[_key].trim().replace(/^\t+|\t+$/g, "");
    } else if (typeof _json[_key] === "object") {
      _json[_key] = trimStringsInJson(_json[_key]);
    }
  }

  return _json;
};

/**
 * 단일 JSON 객체 내 빈값이 1개 이상 존재 여부 반환
 * @param {*} _json JSON 객체
 * @param {*} _except 빈 값 확인을 제외할 key 이름 배열
 * @returns true/false
 */
export const isNullValues = (_json, _except) => {
  let isNull = false;
  for (let _key in _json) {
    if (
      !_except.includes(_key) &&
      (_json[_key] === "" ||
        _json[_key] === " " ||
        _json[_key] === " " ||
        String(_json[_key]).length === 0 ||
        _json[_key] === undefined ||
        _json[_key] === null)
    ) {
      isNull = true;
      break;
    }
  }

  return isNull;
};

/**
 * 단일 JSON 객체 내 빈값이 1개 이상 존재 여부 반환
 * @param {*} _json JSON 객체
 * @param {*} _include 빈 값 확인할 key 이름 배열
 * @returns true/false
 */
export const isNullValuesIncludes = (_json, _include) => {
  let isNull = false;
  for (let _key in _json) {
    if (
      _include.includes(_key) &&
      (_json[_key] === "" ||
        _json[_key] === " " ||
        _json[_key] === " " ||
        String(_json[_key]).length === 0 ||
        _json[_key] === undefined ||
        _json[_key] === null)
    ) {
      isNull = true;
      break;
    }
  }

  return isNull;
};

export const hasDifferentValue = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Key 길이가 다르면 Value가 다른 것으로 판단
  if (keys1.length !== keys2.length) return true;

  // 동일 Key 중 Value가 다른 것이 있는지 확인
  return keys1.some((key) => obj1[key] !== obj2[key]);
};
