/**
 * @param {string} hex
 */
const isValidHex = (hex) => {
  return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
};

/**
 * @param {string} rgba
 */
const isValidRgbaStr = (rgba) => {
  return /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/gm.test(
    rgba
  );
};

/**
 *
 * @param {string} string
 * @param {number} chunkSize
 */
const getChunksFromString = (string, chunkSize) => {
  return string.match(new RegExp(`.{${chunkSize}}`, "g"));
};

/**
 * @param {number | undefined} a 0 ~ 255
 * @param {number | undefined} alpha 0 ~ 1
 */
const getAlpha = (a, alpha) => {
  if (typeof a !== "undefined") {
    return a / 255;
  }
  if (typeof alpha != "number" || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

/**
 *
 * @param {string} hex
 * @param {number} alpha
 *
 * @param {"array" | "object" | "string"} returnType 반환 타입 설정
 * @returns
 */
export const hex2Rgba = (hex, alpha = 1, returnType = "array") => {
  if (!isValidHex(hex)) return hex;

  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);

  //convert
  const [r, g, b, a] = hexArr.map((hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16));
  switch (returnType) {
    case "array":
      return [r, g, b, alpha * 255];
    case "object":
      return {
        r,
        g,
        b,
        a: getAlpha(a, alpha)
      };
    case "string":
      return `rgba(${r}, ${g}, ${b}, ${getAlpha(a, alpha)})`;
    default:
      return;
  }
};

/**
 * @param {import("react-color").RGBColor | string} rgba
 * @param {boolean | undefined} isRemoveAlpha 알파값 추가 여부
 *
 * @return {string} hex
 */
export const rgba2Hex = (rgba, isRemoveAlpha = true) => {
  // hex 값이면 그대로 반환
  if (!rgba || isValidHex(rgba)) return rgba;

  if (isValidRgbaStr(rgba)) {
    return (
      "#" +
      rgba
        ?.replace(/^rgba?\(|\s+|\)$/g, "")
        .split(",")
        .filter((string, index) => !isRemoveAlpha || index !== 3)
        .map((string) => parseFloat(string))
        .map((number, index) => (index === 3 ? Math.round(number * 255) : number))
        .map((number) => number.toString(16))
        .map((string) => (string.length === 1 ? "0" + string : string))
        .join("")
    );
  }

  // RGBColor type
  if (typeof rgba === "object" && rgba.hasOwnProperty("r")) {
    let r = (+rgba.r).toString(16),
      g = (+rgba.g).toString(16),
      b = (+rgba.b).toString(16),
      a = rgba.a ? Math.round(rgba.a * 255).toString(16) : "";

    if (r.length === 1) r = "0" + r;
    if (g.length === 1) g = "0" + g;
    if (b.length === 1) b = "0" + b;
    if (a.length === 1) a = "0" + a;

    return isRemoveAlpha ? "#" + r + g + b : "#" + r + g + b + a;
  }

  return;
};
