import { Measure } from "../../../../xitecloud3D/Measure";
import { store } from "../../../../store/store";

const THREE = window.THREE;
export const softMakeMeasure = (
  coordinate,
  {
    pointColor,
    lineColor,
    faceColor,
    lineThickness,
    closed = true,
    showArea = false,
    showDistances = true,
    showAngles = false,
    showCoordinates = false,
    maxMarkers = Infinity,
  }
) => {
  const measure = new Measure();

  measure.pointColor = pointColor;
  measure.lineColor = lineColor;
  measure.faceColor = faceColor;
  measure.lineThickness = lineThickness;
  measure.closed = closed;
  measure.showArea = showArea;
  measure.showDistances = showDistances;
  measure.showAngles = showAngles;
  measure.showCoordinates = showCoordinates;
  measure.maxMarkers = maxMarkers;

  for (let item of coordinate) {
    measure.addMarker(new THREE.Vector3(Number(item.x), Number(item.y), Number(item.z)));
  }

  window.viewer.scene.addMeasurement(measure);
  measure.visible = true;
  return measure;
};

export const softMakeAddPoint = (coordinate, pickCrosspointMeasure) => {
  store
    .getState()
    .CrossSectionReducer.addPoints.map((measure) => window.viewer.scene.removeMeasurement(measure));

  return coordinate.map((item) => {
    const measure = new Measure();

    measure.pickCrosspointMeasure = pickCrosspointMeasure;
    measure.addMarker(new THREE.Vector3(Number(item.x), Number(item.y), Number(item.z)));
    measure.crossPoint.crossPoint = new THREE.Vector3(
      Number(item.x),
      Number(item.y),
      Number(item.z)
    );
    measure.crossPoint.targetMeaure = pickCrosspointMeasure;

    window.viewer.scene.addMeasurement(measure);

    return measure;
  });
};
