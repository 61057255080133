//actions
import {
  CURRENT_PROJECT_INIT,
  CURRENT_PROJECT,
  PROJECT_DATE_SET,
  PROJECT_SELECTED_DATE_SET,
  CURRENT_PROJECT_ALL,
  CURRENT_PROJECT_OPTION,
} from "../actions/currentProject";

const currentProjectObject = {
  currentProject: {
    project_mng_sq: null,
    f_proj_nm: "",
    f_create_date: "",
    f_address: "",
    f_latlng: "",
    c_construction_type: "",
    c_system_unit: "",
    f_coord_system: "",
    f_timezone: null,
    f_description: "",
    project_group_pk_t_project_group: null,
    f_option: {},
  },
  selectedDate: "",
  dateSet: [],
};

export default function CurrentProjectReducer(state = currentProjectObject, action) {
  switch (action.type) {
    case CURRENT_PROJECT_INIT:
      return currentProjectObject;
    case CURRENT_PROJECT_ALL:
      return { ...state, currentProject: action.project, dateSet: action.dataList };
    case CURRENT_PROJECT:
      return { ...state, currentProject: action.project };
    case CURRENT_PROJECT_OPTION:
      return { ...state, currentProject: { ...state.currentProject, f_option: action.option } };
    case PROJECT_DATE_SET:
      return { ...state, dateSet: action.date };
    case PROJECT_SELECTED_DATE_SET:
      return { ...state, selectedDate: action.date };

    default:
      return state;
  }
}

// option 값 참조용
// const defaultOptions = {
//   fleet_summary: {
//     overall: {
//       category: ["f_driver_company", "f_specification_eng", "c_equip_type_nm"],
//       column: [
//         { key: "f_driver_company", checked: true },
//         { key: "f_busi_regi_num", checked: false },
//         { key: "c_equip_type_nm", checked: true },
//         { key: "f_specification_eng", checked: true },
//         { key: "asset_name", checked: true },
//         { key: "f_equip_serialnumber", checked: false },
//         { key: "f_driver_name", checked: true },
//         { key: "daily_summary", checked: true },
//         { key: "f_driving_distance", checked: false },
//         { key: "f_driving_time", checked: false },
//         { key: "f_idle_time", checked: false },
//         { key: "f_cycle_cnt", checked: false },
//         { key: "f_speed_max", checked: false },
//         { key: "f_speed_avg", checked: false },
//       ],
//     },
//     dump: {
//       category: {
//         type: "Company",
//         main: ["f_driver_company"],
//         second: [
//           { key: "f_driver_company", title: "Company", checked: false },
//           { key: "f_start_fence_nm.f_end_fence_nm", title: "Load & Dump Geofence", checked: false },
//           { key: "f_asset_name", title: "Asset Name", checked: false },
//           { key: "f_rock_type", title: "Soil Type", checked: true },
//           { key: "f_specification_eng", title: "Asset Specification", checked: true },
//           { key: "f_start_fence_nm", title: "Load Geofence", checked: false },
//           { key: "f_end_fence_nm", title: "Dump Geofence", checked: false },
//         ],
//       },
//       column: [
//         { key: "f_driving_dt", checked: true },
//         { key: "f_driver_company", checked: true },
//         { key: "f_asset_nm_eng", checked: true },
//         { key: "f_specification_eng", checked: true },
//         { key: "f_asset_name", checked: true },
//         { key: "f_driver_name", checked: true },
//         { key: "f_start_time", checked: true },
//         { key: "f_end_time", checked: true },
//         { key: "f_start_fence_nm", checked: true },
//         { key: "f_end_fence_nm", checked: true },
//         { key: "f_rock_type", checked: true },
//         { key: "load_to_dump", checked: true },
//         { key: "dump_to_load", checked: true },
//         { key: "f_description", checked: true },
//       ],
//     },
//   },
//   fleet_method: 0,
//   fleet_hauling: {
//     dump: { color: "#1890FF", style: "0", thickness: 6, point: 25 },
//     load: { color: "#1890FF", style: "0", thickness: 6, point: 25 },
//   },
// };
