import {
  SET_MODEL_OVERLAY_FILE,
  INIT_MODEL_OVERLAY,
  SET_OVERLAY_WORKSPACE,
  SET_OVERLAY_3D_OBJECT,
  SET_OVERLAY_TITLE_AND_TYPE,
  SET_OVERLAY_TITLE,
} from "../actions/overlay";

const initializeOverlayState = {
  layerName: "",
  file: null,
  fileType: "",
  workspace: false,
  overlayKey: null,
  _3dObject: null,
};

export default function overlayReducer(state = initializeOverlayState, action) {
  switch (action.type) {
    case INIT_MODEL_OVERLAY:
      return initializeOverlayState;
    case SET_MODEL_OVERLAY_FILE:
      return { ...state, file: action.file };
    case SET_OVERLAY_WORKSPACE:
      return { ...state, workspace: action.visible };
    case SET_OVERLAY_TITLE:
      return { ...state, layerName: action.title };
    case SET_OVERLAY_TITLE_AND_TYPE:
      return {
        ...state,
        layerName: action.title,
        fileType: action.fileType,
        overlayKey: action.overlayKey,
      };
    case SET_OVERLAY_3D_OBJECT:
      return { ...state, _3dObject: action.obj, workspace: action.workspace };

    default:
      return state;
  }
}
