import { addCommas, fnPreCalc } from "../../../xitecloud3D/xiteCloud.dev";
import { Measure } from "../../../xitecloud3D/Measure";
import { consoleError } from "../../../middleware";
import { message } from "antd";
import { store } from "../../../store/store";
import Axios from "axios";
const THREE = window.THREE;
const xitecloud = window.xitecloud;

export function setPreCalcParamCallback(
  _result,
  { srid, target, targetBoundUse, measure, points, viewData }
) {
  let measureClone = measure;
  let result = _result.fnprecalc.r_result_array;
  let srcLayers = _result.fnprecalc.r_result_layer_array
    ? _result.fnprecalc.r_result_layer_array
    : [];

  if (measureClone === null) {
    try {
      measureClone = new Measure();

      let _points = result[0].target_boundry
        .split("((")[1]
        .replace("))", "")
        .split(",");

      for (let _pt of _points) {
        measureClone.points.push({
          position: new THREE.Vector3(
            Number(_pt.split(" ")[0]),
            Number(_pt.split(" ")[1]),
            xitecloud.bore_zmin
          ),
        });
      }
    } catch (err) {
      consoleError(err);
    }
  }

  let _tmpObj = {
    area: Number(result[0].param_area).toFixed(2),
    "Layer Count :": srcLayers.length,
    sectorCnt: result.length,
    avg_area: Number(result[0].area_avg).toFixed(2),
  };

  let _messageStr = `Selected Area:   ${addCommas(_tmpObj.area, "A")} 
        Divide Sector Count: ${_tmpObj.sectorCnt} (Average ${addCommas(_tmpObj.avg_area, "A")})
        Calculate few Minuates`;

  // let _messageStr = `Selected Area:   ${addCommas(_tmpObj.area, 'A')}
  // Divide Sector Count: ${_tmpObj.sectorCnt} (Average ${addCommas(_tmpObj.avg_area, 'A')})
  // Calculate few Minuates Continue ?`;

  message.warning(_messageStr, 5);
  // let _tmp = window.alert(_messageStr);
  // let rtnGeom = {};

  // const objParam = {
  //     "srid": SRID,
  //     "srcLayer": srcLayers,
  //     "targetLayer": targetLayer,
  //     "points": param,
  //     "sectors": result,
  //     "measure": measure,
  //     "viewData": viewData,
  //     "targetBoundUse" : _targetBoundUse,
  //     "boundryLayer" : boundryLayer
  // }

  return {
    srid: srid,
    srcLayer: srcLayers,
    targetLayer: target,
    points: points,
    sectors: result,
    measure: measureClone,
    viewData: viewData,
    targetBoundUse: targetBoundUse,
    boundryLayer: target,
  };

  //realTime
  // if (_tmp) {
  //     fnVolumeGeomLoadProc(objParam);
  // } else {
  // //background job
  //     fnCalcVolumeJob(rtnGeom, objParam);

  //     viewData.measure.visible = false;
  //     viewData.measure.dispose();
  // };
}

export function asyncPreCalcParam(calcParam) {
  const state = store.getState();
  const measure = state.projectChildrenComponents.currentMeasure;

  return new Promise((resolve, reject) => {
    fnAsyncPreCalc(calcParam).then((data) => {
      return resolve(
        setPreCalcParamCallback(data, {
          srid: calcParam.srid,
          target: calcParam.targetLayer,
          targetBoundUse: calcParam.targetBoundUse,
          measure: measure.measure,
          points: calcParam.points,
          viewData: measure,
        })
      );
    });

    // fnPreCalc(
    //     calcParam,
    //     state.ViewDataSetReducer.projectViewDataSetModel,
    //     (data) => {

    //         return resolve(setPreCalcParamCallback(data, {
    //             srid: calcParam.srid,
    //             target: calcParam.targetLayer,
    //             targetBoundUse: calcParam.targetBoundUse,
    //             measure: measure.measure,
    //             points: calcParam.points,
    //             viewData: measure,
    //         }))
    //     }
    // );
  });
}

export async function fnAsyncPreCalc(param) {
  const state = store.getState();

  // Polgon Area에서 호출시 파라미터 누락으로 임시 처리
  if (param.targetLayer == "" || param.targetLayer == undefined) {
    param.targetLayer = state.ViewDataSetReducer.projectViewDataSetModel.layer.target[0].dbObj;
  }

  if (param.boundryLayer == "" || param.boundryLayer == undefined) {
    param.boundryLayer = state.ViewDataSetReducer.projectViewDataSetModel.layer.target[0].dbObj;
  }

  let objParamPre = {
    srid: param.srid,
    points: param.points,
    topoLayer: `layer_${state.currentProjectReducer.currentProject.project_mng_sq}_${state.currentProjectReducer.selectedDate}_original`,
    targetLayer: param.targetLayer.f_geometry_tbl_nm,
    targetBoundUse: param.targetBoundUse,
    boundryLayer: param.boundryLayer.f_geometry_tbl_nm,
  };

  const { data } = await Axios.get("/api/preCalc", {
    params: objParamPre,
  });

  return data;
}
