import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Button, Modal, Select } from "antd";
import { SplitComponent } from "./SplitComponent";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "./index.less";

// 20210429 최대한 LayerSplit 기능 추가
function LayerSplitContainer() {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [selectDateL, setSelectDateL] = useState("");
  const [selectDateR, setSelectDateR] = useState("");
  const [selectTopoDateL, setSelectTopoDateL] = useState("");
  const [selectTopoDateR, setSelectTopoDateR] = useState("");

  const { t } = useTranslation();

  const { projectDateList, select_date } = useSelector(
    (state) => ({
      projectDateList: state.currentProjectReducer.dateSet,
      select_date: state.currentProjectReducer.selectedDate,
    }),
    shallowEqual
  );

  useEffect(() => {
    fnInitDate();
  }, []);

  const grepData = () => {
    onModal();
  };

  const onModal = () => {
    setVisible(!visible);
    fnInitDate();
  };

  const onChangeDateL = (selectedDate) => {
    if (selectedDate === selectDateR) {
      alert(`${t("message$같은_날짜는_선택할_수_없습니다")}.`);
    } else {
      setSelectDateL(selectedDate);
      setSelectTopoDateL(fnGetTopoDate(selectedDate));
    }
  };

  const onChangeDateR = (selectedDate1) => {
    if (selectedDate1 === selectDateL) {
      alert(`${t("message$같은_날짜는_선택할_수_없습니다")}.`);
    } else {
      setSelectDateR(selectedDate1);
      setSelectTopoDateR(fnGetTopoDate(selectedDate1));
    }
  };

  const fnInitDate = () => {
    let initDate = projectDateList.filter((x) => x.f_input_date === select_date)[0]
      .f_topo_input_date;
    setSelectTopoDateL(initDate);
    setSelectTopoDateR(initDate);
    setSelectDateL(select_date);
    setSelectDateR(select_date);
  };

  const fnGetTopoDate = (dt) => {
    let rst = "";

    let arrTopoDate = projectDateList.filter((x) => x.f_input_date === dt);

    if (arrTopoDate) {
      rst = arrTopoDate[0].f_topo_input_date;
    }

    return rst;
  };

  return (
    <>
      <div style={{ padding: "5px 10px" }}>
        <Button type="primary" id="temp-btn" onClick={grepData} block>
          {t("button$Compare_TopoGraphy")}
        </Button>
      </div>
      <Modal
        // title="레이어스플릿"
        visible={visible}
        onOk={onModal}
        onCancel={onModal}
        wrapClassName="layer-wrapper"
        height={"100vh"}
        width={"100%"}
        footer={null}
        closeIcon={<CloseOutlined style={{ backgroundColor: "#555555", padding: "3px" }} />}
      >
        <div id="header" style={{ width: "100%" }}>
          <div id="leftHeader" style={{ float: "left", width: "50%", height: "100%", top: "50%" }}>
            <Select
              showSearch
              value={selectDateL}
              placeholder={t("placeholder$Select_a_date")}
              optionFilterProp="children"
              onChange={onChangeDateL}
              style={{ left: "50%", width: "200px", top: "50%", transform: "translate(-50%)" }}
            >
              {projectDateList.map((date, index) => (
                <Option value={date.f_input_date} key={index}>
                  {date.f_title} ({date.f_input_date}){" "}
                </Option>
              ))}
            </Select>
          </div>

          <div
            id="rightHeader"
            style={{ float: "right", width: "50%", height: "100%", top: "50%" }}
          >
            <Select
              showSearch
              value={selectDateR}
              placeholder={t("placeholder$Select_a_date")}
              optionFilterProp="children"
              onChange={onChangeDateR}
              style={{ left: "50%", width: "250px", top: "50%", transform: "translate(-50%)" }}
            >
              {projectDateList.map((date, index) => (
                <Option value={date.f_input_date} key={index} style={{ width: "250px" }}>
                  {date.f_title} ({date.f_input_date}){" "}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        {visible && <SplitComponent leftData={selectTopoDateL} rightData={selectTopoDateR} />}
      </Modal>
    </>
  );
}
export default LayerSplitContainer;
