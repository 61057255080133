import React, { useState, useEffect, useRef } from "react";
import { Collapse, Tooltip, Modal, Checkbox, Button } from "antd";
import CheckBox from "../../elements/input/checkbox";

import UploadOverlayModal from "../../modals/upload/overlaysForm";
import HoulingRouteNavigatorModal from "../../modals/houlingRouteNavigator";
import "./model.less";
//redux
import {
  connect,
  // connect,
  useDispatch,
  useSelector,
  shallowEqual,
} from "react-redux";
import IconDelete from "../../../assets/icons/delete.svg";
import { setCurrentModel } from "../../../store/actions/currentModel";
import { setLayers } from "../../../store/actions/viewDataSet";
import { visiblePointCloud, visibleContour } from "../../../store/actions/projectChildrenComponent";
import {
  setOverlayObject,
  setOverlayWorkspace,
  setOverlayTypeAndTitle,
} from "../../../store/actions/overlay";
import Arrow from "../../elements/Arrow/Arrow";
import { OverlayRangeViewer } from "./utils/OverlayRangeViewer";
import { CaretDownOutlined } from "@ant-design/icons";
import { pushCurrentMeasure } from "../../../views/Measurement/util/pushState";
import { initializeTopo, visibleHandleOnChange } from "./utils/modelVisible";
import ModelFilterIcon from "./children/ModelFilterIcon";
import { visibleHandle } from "../../../views/overlays/util/_3dVisible";
import { LAYERTAB_PROGRESS_INFO_INTERVAL_SECONDS } from "../../../config/timeIntervalInfo";
import { isJsonString } from "../../../utils/StringHelper";
import Axios from "axios";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

function Model(props) {
  const [visibleViewProblemModal, setVisibleViewProblemModal] = useState(false);
  const [renderOverlayData, setRenderOverlayData] = useState({});
  const { t } = useTranslation();

  const { projectSelectedDate } = useSelector(
    (state) => ({
      projectSelectedDate: state.currentProjectReducer.selectedDate,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const { controlsView = true, defaultActiveKey = [0, 1, 2, 3] } = props;
  /**
   * Collapse State
   */
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const onChange = (activeArrays) => setActiveKey(activeArrays.map((x) => parseInt(x, 10)));

  /**
   * extra Switch State
   */
  const [switchKey, setSwitchKey] = useState([0, 1, 2, 3]);

  const onClick = (item) => {
    try {
      initializeTopo();

      if (!item.hasArrow) return;

      if (item.hasArrow === "overlay") {
        dispatch(
          setOverlayTypeAndTitle({
            fileType: item.dbObj.f_file_type,
            title: item.dbObj.f_layer_nm,
            overlayKey: item.dbObj.overlay_data_sq,
          })
        );

        dispatch(setOverlayObject(item._3DObject));
      }

      /**
       * pointcloud
       */
      if (item.hasArrow === "pointcloud") {
        dispatch(setCurrentModel(item));
        dispatch(visiblePointCloud(true));
      }

      /**
       * contour
       */
      if (item.hasArrow === "contour") {
        dispatch(setCurrentModel(item));
        dispatch(visibleContour(true));
      }

      if (item.hasArrow === "annotations") {
        /**
         * polygon & volume hasn't result
         */
        if (item.type === "area" && item.resultStatus !== null && item.resultStatus !== 2) return;
        if (item.toolObj.value === "l2" && item.resultStatus !== null && item.resultStatus !== 2)
          return;

        pushCurrentMeasure(item);
      }
    } catch (e) {
      // console.log(`ttt e`, e);
    }
  };

  const extra = (key, title, modelKey) => {
    const onSwitch = (key, e) => {
      setSwitchKey(
        /**
         * has key -> [remove key] : [add key]
         * key === 0 -> first key
         */
        switchKey.find((x) => x === key) || switchKey.find((x) => x === key) === 0
          ? switchKey.filter((x) => x !== key)
          : [key, ...switchKey]
      );

      //Collapse component reload
      setActiveKey([...activeKey]);
    };

    return (
      <div onClick={(event) => event.stopPropagation()}>
        <ModelFilterIcon modelKey={modelKey} />
        <ul className="model__tools group">
          {title === t("title$Overlays_Information") && (
            <li>
              <UploadOverlayModal />
            </li>
          )}
          {/* layer has visible switch
           * 버튼 기능 확인 필요
           */}
          {/* {props.name === capitalizeFirstLetter(t("title$Layer", { count: 2 })) && (
            <li>
              <Switch
                size="small"
                onClick={(e) => onSwitch(key, e)}
                checked={switchKey.find((x) => x === key) !== undefined ? true : false}
              />
            </li>
          )} */}
        </ul>
      </div>
    );
  };

  const onOverlayRemove = (item) => {
    Modal.confirm({
      content: `${t("message$Do_you_want_to_delete?")}`,
      onOk: () => {
        let key = item.dbObj.overlay_data_sq;

        Axios.post(`/overlay/${key}/remove`)
          .then(() => {
            Modal.success({
              content: t("message$Successful_deleted_overlay_item"),
              onOk: () => {
                //삭제된 대상을 제외한 배열
                props.setLayers(
                  props.ViewDataSetReducer.projectViewDataSetModel.layer.overlay.filter(
                    (x) => x.dbObj.overlay_data_sq !== key
                  ),
                  "overlay"
                );

                if (["tif", "dxfwms"].includes(item.dataType)) {
                  let layer = window.cesiumViewer.imageryLayers._layers.filter(
                    (x) => x._imageryProvider === item._3DObject
                  );
                  if (layer.length > 0) layer[0].show = false;
                } else {
                  visibleHandleOnChange(false, item);
                }

                if (
                  ["tif", "dxfwms"].includes(item.dataType) === false &&
                  item.OverlayReducer !== undefined
                ) {
                  item.OverlayReducer.overlayKey = null;
                }

                if (item.position !== undefined) {
                  if (!item.OverlayReducer.overlayKey) {
                    item.OverlayReducer._3dObject.parent.remove(item.OverlayReducer._3dObject);
                  }
                  visibleHandle(item.OverlayReducer._3dObject, false);
                }

                props.setOverlayWorkspace(false);
              },
            });
          })
          .catch((err) => {
            console.log(err);
            // Modal.error({
            //   content: t("failed_delete_overlay_item"),
            //   onOk: () => {},
            // });
          });
      },
    });
  };

  const handleCheckBoxChange = () => {
    if (props.onChange) props.onChange();
  };

  useInterval(() => {
    // Layers 탭 선택 및 프로젝트 ID와 선택일자 존재할 때만(Xite Analyst일 경우)
    if (
      props.name === t("title$Visualization") &&
      props.history &&
      props.history.location.pathname.includes("/projects/show/") &&
      props.ViewDataSetReducer.projectKey &&
      projectSelectedDate
    ) {
      updateProjectInfo(props.ViewDataSetReducer.projectKey, projectSelectedDate);
    }
  }, LAYERTAB_PROGRESS_INFO_INTERVAL_SECONDS * 1000);

  // Topo, Overlay Service Broker 처리 진행률 표시 업데이트
  const updateProjectInfo = async (projectId, selectedDate) => {
    // 작업 중 확인
    const filterData = props.models.filter((x) => ["topo", "overlay"].includes(x.key));

    let jobCount = 0;
    filterData.map((x) => {
      x.items.map((item) => {
        if (
          item.dbObj.f_description &&
          item.dbObj.f_description !== "Complete" &&
          item.dbObj.job_status === "0"
        )
          jobCount++;
      });
    });

    if (jobCount === 0) return;

    const { data } = await Axios.get("/loadProjectInfoAll/check", {
      params: {
        project_mng_sq: projectId,
        data_date: selectedDate,
      },
    });

    for (let i = 0; i < props.models.length; i++) {
      // Topo
      if (
        props.models[i].key === "topo" &&
        props.models[i].items.length > 0 &&
        data.topo.length > 0
      ) {
        props.models[i].items.map((_item1) => {
          data.topo.map((_item2) => {
            if (_item1.title.includes(" WMS")) {
              _item1.title = makeTitleString(
                _item2.job_status,
                _item2.f_description,
                _item2.f_layer_nm,
                _item1.title
              );
            }
          });
        });
      }
      // Overlay
      if (
        props.models[i].key === "overlay" &&
        props.models[i].items.length > 0 &&
        data.overlay.length > 0
      ) {
        props.models[i].items.map((_item1) => {
          data.overlay.map((_item2) => {
            if (_item2.overlay_data_sq === _item1.dbObj.overlay_data_sq) {
              _item1.title = makeTitleString(
                _item2.job_status,
                _item2.f_description,
                _item2.f_layer_nm,
                _item1.title
              );
            }
          });
        });
      }
    }
  };

  const isLinkOverlay = (item) => {
    if (item.dbObj.ol_description) {
      const isObject =
        isJsonString(item.dbObj.ol_description) &&
        (item.dbObj.ol_description.indexOf("check_extent_result") > 0 ||
          item.dbObj.coordinates_dxf.check_extent_result);
      return isObject && item.dbObj.ol_description
        ? !JSON.parse(item.dbObj.ol_description).check_extent_result
        : false;
    } else {
      return false;
    }
  };

  const onClickViewProblem = (item) => {
    if (item.dbObj.ol_description || item.coordinates_dxf) {
      const overlayData = item.dbObj.ol_description
        ? JSON.parse(item.dbObj.ol_description)
        : JSON.parse(item.coordinates_dxf);
      const renderLabel = `${props.currentProjectReducer.currentProject.project_mng_sq}_failed_overlay_${item.dbObj.overlay_data_sq}`;

      const renderData = {
        renderLabel: renderLabel,
        coordinates: overlayData.geom_polygon,
      };
      setRenderOverlayData(renderData);
      setVisibleViewProblemModal(true);
    } else {
      Modal.error({
        title: t("message$Check_Loading_Failed_Overlay"),
        content: "",
        okText: t(`button$Confirm`),
        onOk() {},
      });
    }
  };

  const onClickViewProblemCancel = () => {
    setVisibleViewProblemModal(false);
  };

  // Model 제목(Title) 진행률 표시 치환
  const makeTitleString = (jobStatus, description, layerName, originTitle) => {
    if (description && jobStatus === "0" && description !== "Complete") {
      const progressDigit = description.split("_")[1]; // 'PER_20_BT', 'PER_90_OT'
      const prefix = originTitle.includes("WMS") ? "WMS" : "";
      const type = description.includes("BT") ? "BaseTile" : "OverviewTile";

      return `${layerName} ${prefix} (${type}: ${progressDigit}%)`;
    } else {
      return originTitle.includes("WMS") ? `${layerName} WMS` : layerName;
    }
  };

  return (
    <>
      <Collapse
        bordered={false}
        activeKey={activeKey}
        onChange={onChange}
        //models has item
        expandIcon={(iconProps) =>
          props.models[iconProps.panelKey].items.length >= 1 ? (
            <CaretDownOutlined rotate={iconProps.isActive ? false : -90} />
          ) : null
        }
      >
        {props.models.map((model, i) => (
          <Panel
            className="model"
            header={model.title}
            key={i}
            extra={controlsView && extra(i, model.title, model.key)}
          >
            <ul
              style={
                props.listHeight
                  ? { maxHeight: props.listHeight, overflowY: "scroll" }
                  : { maxHeight: "none", overflowY: "initial" }
              }
            >
              {model.items.map((item, j) => (
                <li key={j}>
                  <Tooltip title={item.title} mouseEnterDelay={1}>
                    {props.onCustomCheckboxChange ? (
                      // 커스텀 체크박스
                      <Checkbox onChange={(evt) => props.onCustomCheckboxChange(evt, item)}>
                        {item.title}
                      </Checkbox>
                    ) : (
                      <CheckBox
                        text={
                          item.gbn === "Annotations" ? `${item.title} for ${item.type}` : item.title
                        }
                        switchKey={switchKey.filter((x) => x === i).length === 1}
                        nodeItem={item}
                        onChange={handleCheckBoxChange}
                      />
                    )}
                    {item.navigator && controlsView && <HoulingRouteNavigatorModal />}
                    {/* has Arrow [all annotations, pointcloud, overlay, contour] */}
                    {controlsView && (
                      <div
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          width: "20",
                        }}
                      >
                        {item.group === "overlay" && isLinkOverlay(item) && (
                          <Button
                            className="view-problem"
                            type="link"
                            disabled={false}
                            onClick={() => onClickViewProblem(item)}
                          >
                            {t("button$View_Problem")}
                          </Button>
                        )}
                        {item.group === "overlay" && (
                          <img
                            className="i-delete"
                            style={{ cursor: "pointer", opacity: 0.6 }}
                            onClick={() => onOverlayRemove(item)}
                            src={IconDelete}
                            alt="delete icon"
                          />
                        )}

                        {item.hasArrow && <Arrow onClick={() => onClick(item)} />}
                      </div>
                    )}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </Panel>
        ))}
      </Collapse>
      <Modal
        className="map-viewer-modal__modal"
        title={t("title$Uploded_Overlay_Viewer")}
        closable={true}
        mask={true}
        maskClosable={false}
        visible={visibleViewProblemModal}
        okText={t("button$Confirm")}
        cancelText={t("button$Close")}
        onOk={onClickViewProblemCancel}
        onCancel={onClickViewProblemCancel}
        centered
        footer={[
          <Button className="map-viewer-modal__modal__confirm" onClick={onClickViewProblemCancel}>
            {t("button$Confirm")}
          </Button>,
        ]}
      >
        <OverlayRangeViewer overlayData={renderOverlayData} />
      </Modal>
    </>
  );
}

export default connect((state) => state, {
  setLayers,
  setOverlayWorkspace,
})(Model);
