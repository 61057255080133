import React from "react";
import { Upload, notification, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  setSubSurface,
  setFileNames,
  setTopographicFile,
} from "../../../store/actions/uploadModel";
import { setLayer } from "../../../store/actions/modelLayer";

import { connect } from "react-redux";
import { setOverlayFile } from "../../../store/actions/overlay";
import DefaultButton from "../../elements/button/DefaultButton";
import { i18n } from "react-i18next/i18next";
import { withTranslation } from "react-i18next";

class FileUpload extends React.Component {
  state = {
    fileList: [],
    uploading: false,
    fileName: "",
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    this.setState({
      uploading: true,
    });
  };

  openNotificationWithIcon = () => {
    notification["warning"]({
      message: i18n.t("message$File_Type_is_Not_Supported"),
    });
  };

  render() {
    const {
      // uploading,
      fileList,
    } = this.state;
    const { t } = this.props;

    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState({ fileName: file.name });
        let type;

        if (this.props.detailKey && this.props.detailKey === "pointCloud") type = "xyz";
        if (this.props.detailKey && this.props.detailKey === "orthophoto") {
          type = ["png", "jpg", "jpeg", "tif"].filter(
            (img) => `${file.name}`.toLowerCase().indexOf(img) > -1
          )[0];
        }

        if (this.props.fileKey === "targetDesign") type = "dxf"; //dwg

        if (this.props.fileKey === "overlay") {
          type = ["png", "jpg", "jpeg", "dxf", "ply", "tif"].filter(
            (img) => `${file.name}`.toLowerCase().indexOf(img) > -1
          )[0];
        }

        if (`${file.name}`.toLowerCase().indexOf(type) === -1) {
          // file type is not supported
          return this.openNotificationWithIcon();
        }

        if (this.props.fileKey === "topographic") {
          if (
            this.props.modelLayerReducer.files[this.props.fileKey][this.props.detailKey].formData
              .length < 1
          ) {
            this.props.modelLayerReducer.files[this.props.fileKey][this.props.detailKey] = {
              formData: [
                ...this.props.modelLayerReducer.files[this.props.fileKey][this.props.detailKey]
                  .formData,
                file,
              ],
            };
          } else {
            // 이전 데이터 초기화
            this.props.modelLayerReducer.files[this.props.fileKey][this.props.detailKey] = {
              formData: [],
            };
            this.props.modelLayerReducer.files[this.props.fileKey][this.props.detailKey] = {
              formData: [
                ...this.props.modelLayerReducer.files[this.props.fileKey][this.props.detailKey]
                  .formData,
                file,
              ],
            };
          }

          this.props.setTopographicFile(file.name, this.props.detailKey);
        } else if (this.props.fileKey === "overlay") {
          this.props.setOverlayFile(file);
          // this.props.setTopographicFile(file.name, this.props.detailKey);
        } else {
          this.props.modelLayerReducer.files[this.props.fileKey] = {
            formData: [...this.props.modelLayerReducer.files[this.props.fileKey].formData, file],
          };
          this.props.setFileNames(file.name, this.props.fileKey);
        }

        this.props.setLayer(this.props.modelLayerReducer.files);

        return false;
      },
      fileList,
    };

    const extra = () => {
      return (
        <DefaultButton
          style={this.props.disabled ? { backgroundColor: "#9ca4ad" } : {}}
          disabled={this.props.disabled}
          radius={"2px"}
        >
          <UploadOutlined />

          {this.props.fileKey === "overlay"
            ? this.props.OverlayReducer.file
              ? this.props.OverlayReducer.file.name
              : t("button$Select_File")
            : this.state.fileName.length > 1
            ? this.state.fileName
            : t("button$Select_File")}
        </DefaultButton>
      );
    };
    return (
      <div
        className="layer-upload"
        style={{
          display: "inline-block",
        }}
      >
        <Upload {...props} disabled={this.props.disabled}>
          {this.props.fileKey === "overlay" ? (
            extra()
          ) : !this.props.disabled && this.state.fileName.length > 1 ? (
            <Tooltip title={this.state.fileName}>{extra()}</Tooltip>
          ) : (
            extra()
          )}
          {/*         
          <DefaultButton
            style={this.props.disabled ? { backgroundColor: "#9ca4ad" } : {}}
            disabled={this.props.disabled}
            radius={"2px"}
          >
            <UploadOutlined />
            
            {this.state.fileName.length > 1 ? this.state.fileName: 'Select File'}
          </DefaultButton> */}

          {/* <Button disabled={this.props.disabled}>
            
          </Button> */}
        </Upload>
      </div>
    );
  }
}

export default withTranslation()(
  connect((state) => state, {
    setSubSurface,
    setFileNames,
    setTopographicFile,
    setLayer,
    setOverlayFile,
  })(FileUpload)
);
