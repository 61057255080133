import Axios from "axios";
import { configProject } from "../../../store/actions/projectList";
import { store } from "../../../store/store";

export async function getProjectListAll() {
  const props = store.getState();
  const projectAndFolder = await Promise.all([
    Axios.get('/projectList', {
      params: {
        user: props.appState.user.user_mng_sq
      }
    }),
    Axios.get('/projectFolder', {
      params: {
        companyId: props.appState.user.company_mng_sq_t_company_mng
      }
    })
  ])

  const [project, folder] = projectAndFolder;
  // if (project.data.length === props.projectReducer.allProjects.length || props.projectReducer.folders.length === folder.data.length) {
  //   /**
  //    * 프로젝트 및 폴더 추가 생성, 삭제 없음.
  //    */
  //   return;
  // } 

  return store.dispatch(
    configProject({
      projects: project.data,
      folders: folder.data,
    })
  )
}