import React from "react";
import { Checkbox, message } from "antd";
import { connect } from "react-redux";
import { setAnnotationMany } from "../../../../store/actions/viewDataSet";
import { initializeTopo, visibleHandleOnChange } from "../../../blocks/model/utils/modelVisible";
import { fnAddAnnotation, makeMeasure } from "xitecloud3D/xiteCloud.dev";
import { initialize } from "views/Measurement/util/initialize";
import { pushCurrentMeasure } from "views/Measurement/util/pushState";
import { visibleTileMapLayer, visibleWebMapLayer } from "../../../../utils/CesiumUtils";
import "./checkbox.less";

function CheckBox(props) {
  const nodeItem = props.nodeItem;

  // useEffect(() => {
  //   if (nodeItem.gbn === "Layers") {
  //     /**
  //      * all check
  //      */
  //     nodeItem.visible = props.switchKey;
  //     const annotations = props.ViewDataSetReducer.projectViewDataSetModel.annotations;
  //     props.setAnnotationMany(annotations, props.ViewDataSetReducer.annotationCount);
  //   }
  // }, [props.switchKey]);

  const handleCheckBoxChange = (e) => {
    if (nodeItem.measure === null && e.target.checked) {
      const _item = nodeItem.preItems;
      const _toolObjValue = _item.f_fence_annotation_flag.split("|");
      nodeItem.measure = makeMeasure(_item, null, () => {
        nodeItem.titleAnnotation = fnAddAnnotation(nodeItem.measure.points, nodeItem.title, {
          customHighlighted: true,
          classNames: "annotation__measurement-title",
          customCallback: () => {
            initialize();
            initializeTopo();
            const { type, resultStatus } = nodeItem;
            if (
              (type === "area" && resultStatus !== null && resultStatus !== 2) ||
              (_toolObjValue[0] === "l2" && resultStatus !== null && resultStatus !== 2)
            ) {
              message.warning(`The "${_item.f_fence_nm}" model is being processed.`, 2);
            }
            pushCurrentMeasure(nodeItem);
          },
        });
      });

      if (_toolObjValue[0] === "a1" || _toolObjValue[0] === "a2" || _toolObjValue[0] === "l2") {
        nodeItem.visible = false;
        nodeItem.toolObj.modelDraw = false;
      }
      if (nodeItem.toolObj && nodeItem.toolObj === true) nodeItem.crossSectionChildren = [];
    }

    nodeItem.visible = e.target.checked;
    visibleHandleOnChange(e.target.checked, nodeItem);

    const annotations = props.ViewDataSetReducer.projectViewDataSetModel.annotations;
    props.setAnnotationMany(annotations, props.ViewDataSetReducer.annotationCount);

    if (["tif", "wms", "dxfwms"].includes(nodeItem.dataType)) {
      visibleTileMapLayer(window.cesiumViewer, nodeItem._3DObject, e.target.checked);
    }

    if (nodeItem.dataType === "GIS") {
      visibleWebMapLayer(window.cesiumViewer, nodeItem._3DObject, e.target.checked);
    }

    if (props.onChange) props.onChange();
  };

  /**
   * disabled options
   *    - success status 2
   *    - if target type is area or section for line has result?
   */
  const getIsDisabled = () => {
    if (["tif", "dxfwms"].includes(nodeItem.dataType) && nodeItem.disable) {
      nodeItem.visible = false;
      visibleHandleOnChange(false, nodeItem);
      return true;
    }

    if (nodeItem.toolObj) {
      const isNull = nodeItem.resultStatus === null;
      const isSuccessFullyFinishJob = nodeItem.resultStatus === 2;

      const isVolumeAndPolygon = nodeItem.type === "area" && !isNull && !isSuccessFullyFinishJob;
      const isSection = nodeItem.toolObj.value === "l2" && !isNull && !isSuccessFullyFinishJob;

      // overay tif or dxfwms 일경우 작업 중일경우 disable 처리함
      if (["tif", "dxfwms"].includes(nodeItem.dataType) && nodeItem.dbObj.f_status === 0) {
        return true;
      }

      return isSection || isVolumeAndPolygon;
    }

    return false;
  };

  return (
    <Checkbox
      className="checkbox"
      checked={nodeItem.visible}
      defaultChecked={nodeItem.visible}
      disabled={getIsDisabled()}
      onChange={handleCheckBoxChange}
    >
      {nodeItem.gbn === "Annotations"
        ? `${nodeItem.title} for ${nodeItem.type === "area" && nodeItem.toolObj.value === "a2" ? "volume" : nodeItem.type}`
        : nodeItem.title}
    </Checkbox>
  );
}

export default connect((state) => ({ ViewDataSetReducer: state.ViewDataSetReducer }), {
  setAnnotationMany,
})(CheckBox);
