import React from 'react';
import IconArrowRight from '.././../../assets/icons-2/Icon_Aroow_Right.svg';
import IconArrowWhite from '.././../../assets/icons-2/Icon_Aroow_White.svg';
import './index.less';

const Arrow = (props) => {

    return (
        <img className="i-arrow" src={props.white ? IconArrowWhite : IconArrowRight} {...props} alt="arrow icon" />
    );
}

export default Arrow;
