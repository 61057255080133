//actions
import {
  EDIT_PROJECT,
  GET_LIST_APIS,
  INIT_SET_PROJECT,
  SET_PROPERTY_HANDLER,
  SET_PROPERTY_HANDLER_SELECT,
  SET_PROPERTY_EPSG,
  SET_PROPERTY_ADDRESS
} from "../actions/createAndEditProject";

const initializeCreateOrEditProjectState = {
  editProject: false,
  editProjectTarget: {},

  //Organization
  users: ["A", "B", "C", "D", "E", "F", "G", "H"],

  //text input
  projectName: null,
  address: null,
  description: null,
  proj4text: null,
  latlng: null,
  srid: "",

  //list select
  countries: [],
  systemUnits: [],
  constructionType: [],
  timezones: [],

  //selected object Key
  selectedCountries: null,
  selectedSystemUnits: null,
  selectedConstructionType: null,
  selectedTimezone: null,

  //selected object index
  // selectedCountriesIndex: null,
  // selectedSystemUnitsIndex: null,
  // selectedConstructionTypeIndex: null,
  // selectedTimezoneIndex: null,

  project_group_sq_t_project_group: null,
  //selected Epsg object
  selectedEpsg: {}
};

export default function CreateOrEditProjectReducer(
  state = initializeCreateOrEditProjectState,
  action
) {
  switch (action.type) {
    case INIT_SET_PROJECT:
      return initializeCreateOrEditProjectState;
    case SET_PROPERTY_ADDRESS:
      return { ...state, latlng: action.addressObj.latlng, address: action.addressObj.address };
    case SET_PROPERTY_EPSG:
      return {
        ...state,
        selectedEpsg: action.selectedEpsg,
        proj4text: action.selectedEpsg.proj4text
      };
    case SET_PROPERTY_HANDLER:
      return { ...state, [action.key]: action.text };
    case SET_PROPERTY_HANDLER_SELECT:
      return { ...state, [action.key]: action.sqKey };
    case EDIT_PROJECT:
      return editProject(state, action.editProject);
    case GET_LIST_APIS:
      return setListItems(state, action.apis);
    default:
      return state;
  }
}

function setListItems(state, items) {
  return { ...state, ...items };
}

function editProject(state, editProject) {
  return {
    ...state,
    editProject: true,
    editProjectTarget: editProject,
    projectName: editProject.f_proj_nm,
    address: editProject.f_address,
    description: editProject.f_description,
    proj4text: editProject.f_coord_system,
    srid: editProject.f_epsg || "",
    project_group_sq_t_project_group: editProject.project_group_sq_t_project_group,
    latlng: editProject?.f_latlng
  };
}
