import React from "react";
import { useTranslation } from "react-i18next";
import IconDelete from "../../../assets/icons/delete.svg";
import { Modal } from "antd";
import Axios from "axios";
import { initialize } from "../util/initialize";
import { connect } from "react-redux";
import { setAnnotations } from "../../../store/actions/viewDataSet";

const DeleteAnnotation = (props) => {
  const { t } = useTranslation();
  const onRemove = () => {
    Modal.confirm({
      content: t("message$Do_you_want_to_delete?"),
      onOk: () => {
        const { annotationGroup, annotation_mng_sq } = props.measure;
        Axios.post(`/measure/${annotation_mng_sq}/remove`).then((data) => {
          const { annotations } = props.ViewDataSetReducer.projectViewDataSetModel;

          props.setAnnotations(
            [].concat(
              annotations[annotationGroup].filter((x) => x.annotation_mng_sq !== annotation_mng_sq)
            ),
            annotationGroup
          );

          //call by reference
          props.measure.annotation_mng_sq = false;

          initialize();
        });
      },
    });
  };
  return <img className="i-delete" src={IconDelete} alt="delete icon" onClick={onRemove} />;
};

export default connect((state) => state, {
  setAnnotations,
})(DeleteAnnotation);
