import React, { useCallback, useEffect } from "react";
import { Layout, Form, Button, Input, message } from "antd";
import LoginImg from "./Login_Artwork.jpg";

import "./auth.less";

import Logo from "../../../assets/logo/Logo190x34px-01.png";
import { useState } from "react";
import { setLoginTime, setUser } from "../../../store/actions/app";
import { setToken } from "../../../store/actions/token"; // 21.3.3 token 추가
import { useDispatch } from "react-redux";
import Axios from "axios";
import { consoleError } from "../../../middleware";
import { Trans, useTranslation } from "react-i18next";
const { Content } = Layout;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const tailLayout = {
//   wrapperCol: { offset: 8, span: 16 },
// };

const cssStyle = {
  LayoutUi: {
    minHeight: "100vh",
  },
  LayoutBack: {
    backgroundImage: `url(${LoginImg})`,
  },

  formContent: {
    div: {
      width: "60%",
      margin: "0 auto",
    },
    h3: {
      fontSize: "28px",
      fontWeight: "bold",
    },
  },

  Button: {
    backgroundColor: "#346cee",
    border: "unset",
  },
};

function AuthUI(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const setUsernameHandler = useCallback((e) => setUsername(e.target.value), []);
  const setPasswordHandler = useCallback((e) => setPassword(e.target.value), []);

  const login = async () => {
    if (username.length > 0 && password.length > 0) {
      try {
        const { data } = await Axios.post("/authenticate", {
          username,
          password,
        });

        if (["USR101", "USR102", "USR103"].includes(data.c_user_type)) {
          window.location.href = data.redirectUrl;
          return;
        }

        dispatch(setUser(data));
        dispatch(setToken(data.token)); // token 정보 redux 저장
        dispatch(setLoginTime(new Date()));
        sessionStorage.setItem("userInfo", JSON.stringify(data));

        // window.addEventListener("beforeunload", function(event) {
        //   Axios.get("/authenticate");
        // });
      } catch (err) {
        consoleError("error", err);
      }
    } else {
      if (username.length === 0 && password.length === 0) {
        message.error(t("message$Check_Login_ID_PW"));
      } else if (username.length === 0) {
        message.error(t("message$Check_Login_ID"));
      } else if (password.length === 0) {
        message.error(t("message$Check_Login_PW"));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", onKeyPress);
    return () => {
      window.removeEventListener("keypress", onKeyPress);
    };
  });

  const onKeyPress = (event) => {
    if (event.keyCode === 13) {
      return login();
    }
  };

  return (
    <Layout style={cssStyle.LayoutUi}>
      <Content className="auth-content-wrap">
        <div className="auth-left-content" style={cssStyle.LayoutBack} alt="random sign in content">
          <section>
            <h2>
              <Trans i18nKey="html$Main 로그인 화면 문구">
                Insight Beyond the Site,
                <br /> XiteCloud
              </Trans>
            </h2>
            <p>{t("title$All-in-One_Cloud_Platform_for_Earthwork")}</p>
          </section>
        </div>
        <div className="auth-right-content">
          <div className="form-content" style={cssStyle.formContent.div}>
            <h3 style={cssStyle.formContent.h3}>
              <img src={Logo} alt="logo" />
            </h3>
            <div>
              <Form {...layout} name="basic">
                <h4>{t("title$Log_in_to_your_account")}</h4>
                <Form.Item
                  name="username"
                  onChange={setUsernameHandler}
                  rules={[
                    { required: true, message: `${t("message$Please_input_your_username")}!` },
                  ]}
                >
                  <Input placeholder={t("placeholder$Email_address")} defaultValue={username} />
                </Form.Item>

                <Form.Item
                  name="password"
                  onChange={setPasswordHandler}
                  rules={[
                    { required: true, message: `${t("message$Please_input_your_password")}!` },
                  ]}
                >
                  <Input.Password
                    placeholder={t("placeholder$Password", { count: 1 })}
                    defaultValue={password}
                  />
                </Form.Item>
              </Form>

              <Button type="primary" className="form-btn" onClick={login} style={cssStyle.Button}>
                {t("button$Login")}
              </Button>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default React.memo(AuthUI);
