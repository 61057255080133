import React from "react";
import emptyData from "../../../assets/img/xc-empty-4-x.png";
import "./index.less";
import { Trans } from "react-i18next";

const EmptyBox = (props) => {
  const reportOrProject = props.report ? "Report" : "project";
  return (
    <div className="empty-wrap">
      <img width="100%" src={emptyData} alt="empty data" />
      <p className="empty-msg">
        <Trans
          i18nKey="html$Document emptyBox 메세지"
          values={{ reportOrProject: reportOrProject }}
        >
          {"There is no %{reportOrProject}."} <br /> {"Please create a %{reportOrProject} "}
        </Trans>
      </p>
    </div>
  );
};

export default EmptyBox;
