import { TextSprite } from "./TextSprite.js";
import { Utils } from "./utils.js";
import { T } from "antd/lib/upload/utils";
import { consoleLog } from "../middleware/index.js";
import { ROUND_OFF_THE_NUMBERS_AREA, ROUND_OFF_THE_NUMBERS_COORDINATE } from "config/measure.js";

const THREE = window.THREE;

/* For Xitecloud Measurment From potree
   Jongsu Jeon
   2020.01.07
*/

function createHeightLine() {
  let lineGeometry = new THREE.LineGeometry();

  // lineGeometry.setPositions([
  // 	0, 0, 0,
  // 	0, 0, 0,
  // ]);

  // let lineMaterial = new THREE.LineMaterial({
  // 	color: 0x00ff00,
  // 	dashSize: 5,
  // 	gapSize: 2,
  // 	linewidth: 2,
  // 	resolution:  new THREE.Vector2(1000, 1000),
  // });

  // lineMaterial.depthTest = false;
  // const heightEdge = new THREE.Line2(lineGeometry, lineMaterial);
  // heightEdge.visible = false;

  //this.add(this.heightEdge);

  // return heightEdge;
  return null;
}

function createHeightLabel() {
  // const heightLabel = new TextSprite('');

  // heightLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
  // heightLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
  // heightLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
  // heightLabel.fontsize = 16;
  // heightLabel.material.depthTest = false;
  // heightLabel.material.opacity = 1;
  // heightLabel.visible = false;

  // return heightLabel;
  return null;
}

function createAreaLabel() {
  const areaLabel = new TextSprite("");

  areaLabel.setTextColor({ r: 140, g: 250, b: 140, a: 1.0 });
  areaLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
  areaLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
  areaLabel.fontsize = 16;
  areaLabel.material.depthTest = false;
  areaLabel.material.opacity = 1;
  areaLabel.visible = false;

  return areaLabel;
}

function createCircleRadiusLabel() {
  // const circleRadiusLabel = new TextSprite("");

  // circleRadiusLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
  // circleRadiusLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
  // circleRadiusLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
  // circleRadiusLabel.fontsize = 16;
  // circleRadiusLabel.material.depthTest = false;
  // circleRadiusLabel.material.opacity = 1;
  // circleRadiusLabel.visible = false;

  // return circleRadiusLabel;
  return null;
}

function createTitleLabel() {
  const titleLabel = new TextSprite("Title Any");

  titleLabel.setTextColor({ r: 140, g: 250, b: 140, a: 1.0 });
  titleLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
  titleLabel.setBackgroundColor({ r: 1, g: 1, b: 1, a: 1.0 });

  titleLabel.fontsize = 16;
  titleLabel.material.depthTest = false;
  titleLabel.material.opacity = 1;
  titleLabel.visible = true;

  return titleLabel;
}

function createAreaFace() {
  const areaFaceGeom = new THREE.Geometry();

  //let material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );

  let material = new THREE.MeshBasicMaterial({
    transparent: true,
    color: 0xb9f1ea,
    opacity: 0.5,
    depthTest: false,
    side: THREE.DoubleSide,
  });

  let mesh = new THREE.Mesh(areaFaceGeom, material);
  mesh.visible = false;

  return mesh;
}

function createCircleRadiusLine() {
  // const lineGeometry = new THREE.LineGeometry();

  // lineGeometry.setPositions([
  // 	0, 0, 0,
  // 	0, 0, 0,
  // ]);

  // const lineMaterial = new THREE.LineMaterial({
  // 	color: 0xff0000,
  // 	linewidth: 2,
  // 	resolution:  new THREE.Vector2(1000, 1000),
  // 	gapSize: 1,
  // 	dashed: true,
  // });

  // lineMaterial.depthTest = false;

  // const circleRadiusLine = new THREE.Line2(lineGeometry, lineMaterial);
  // circleRadiusLine.visible = false;

  // return circleRadiusLine;
  return null;
}

function createCircleLine() {
  // const coordinates = [];

  // let n = 128;
  // for(let i = 0; i <= n; i++){
  // 	let u0 = 2 * Math.PI * (i / n);
  // 	let u1 = 2 * Math.PI * (i + 1) / n;

  // 	let p0 = new THREE.Vector3(
  // 		Math.cos(u0),
  // 		Math.sin(u0),
  // 		0
  // 	);

  // 	let p1 = new THREE.Vector3(
  // 		Math.cos(u1),
  // 		Math.sin(u1),
  // 		0
  // 	);

  // 	coordinates.push(
  // 		...p0.toArray(),
  // 		...p1.toArray(),
  // 	);
  // }

  // const geometry = new THREE.LineGeometry();
  // geometry.setPositions(coordinates);

  // const material = new THREE.LineMaterial({
  // 	color: 0xff0000,
  // 	dashSize: 5,
  // 	gapSize: 2,
  // 	linewidth: 3,
  // 	resolution:  new THREE.Vector2(1000, 1000),
  // });

  // material.depthTest = false;

  // const circleLine = new THREE.Line2(geometry, material);
  // circleLine.visible = false;
  // circleLine.computeLineDistances();

  // return circleLine;
  return null;
}

function createCircleCenter() {
  // const sg = new THREE.SphereGeometry(1, 32, 32);
  // const sm = new THREE.MeshNormalMaterial();

  // const circleCenter = new THREE.Mesh(sg, sm);
  // circleCenter.visible = false;

  // return circleCenter;
  return null;
}

function createLine() {
  const geometry = new THREE.LineGeometry();

  geometry.setPositions([0, 0, 0, 0, 0, 0]);

  const material = new THREE.LineMaterial({
    color: 0xff0000,
    linewidth: 2,
    resolution: new THREE.Vector2(1000, 1000),
    gapSize: 1,
    dashed: true,
  });

  material.depthTest = false;

  const line = new THREE.Line2(geometry, material);

  return line;
}

function createCircle() {
  // const coordinates = [];

  // let n = 128;
  // for(let i = 0; i <= n; i++){
  // 	let u0 = 2 * Math.PI * (i / n);
  // 	let u1 = 2 * Math.PI * (i + 1) / n;

  // 	let p0 = new THREE.Vector3(
  // 		Math.cos(u0),
  // 		Math.sin(u0),
  // 		0
  // 	);

  // 	let p1 = new THREE.Vector3(
  // 		Math.cos(u1),
  // 		Math.sin(u1),
  // 		0
  // 	);

  // 	coordinates.push(
  // 		...p0.toArray(),
  // 		...p1.toArray(),
  // 	);
  // }

  // const geometry = new THREE.LineGeometry();
  // geometry.setPositions(coordinates);

  // const material = new THREE.LineMaterial({
  // 	color: 0xff0000,
  // 	dashSize: 5,
  // 	gapSize: 2,
  // 	linewidth: 2,
  // 	resolution:  new THREE.Vector2(1000, 1000),
  // });

  // material.depthTest = false;

  // const line = new THREE.Line2(geometry, material);
  // line.computeLineDistances();

  // return line;

  return null;
}

function createAzimuth() {
  // const azimuth = {
  // 	label: null,
  // 	center: null,
  // 	target: null,
  // 	north: null,
  // 	centerToNorth: null,
  // 	centerToTarget: null,
  // 	centerToTargetground: null,
  // 	targetgroundToTarget: null,
  // 	circle: null,

  // 	node: null,
  // };

  // const sg = new THREE.SphereGeometry(1, 32, 32);
  // const sm = new THREE.MeshNormalMaterial();

  // {
  // 	const label = new TextSprite("");

  // 	label.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
  // 	label.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
  // 	label.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
  // 	label.fontsize = 16;
  // 	label.material.depthTest = false;
  // 	label.material.opacity = 1;

  // 	azimuth.label = label;
  // }

  // azimuth.center = new THREE.Mesh(sg, sm);
  // azimuth.target = new THREE.Mesh(sg, sm);
  // azimuth.north = new THREE.Mesh(sg, sm);
  // azimuth.centerToNorth = createLine();
  // azimuth.centerToTarget = createLine();
  // azimuth.centerToTargetground = createLine();
  // azimuth.targetgroundToTarget = createLine();
  // azimuth.circle = createCircle();

  // azimuth.node = new THREE.Object3D();
  // azimuth.node.add(
  // 	azimuth.label,
  // 	azimuth.center,
  // 	azimuth.target,
  // 	azimuth.north,
  // 	azimuth.centerToNorth,
  // 	azimuth.centerToTarget,
  // 	azimuth.centerToTargetground,
  // 	azimuth.targetgroundToTarget,
  // 	azimuth.circle,
  // );

  // return azimuth;

  return null;
}

function createCrossPoint() {
  const crossPoint = {
    targetMeaure: null,
    label: null,
    center: null,
    target: null,
    guideline: null,
    crossPoint: null,

    node: null,
  };

  {
    const label = new TextSprite("");

    label.setTextColor({ r: 140, g: 250, b: 140, a: 1.0 });
    label.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
    label.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
    label.fontsize = 16;
    label.material.depthTest = false;
    label.material.opacity = 1;

    crossPoint.label = label;
  }

  crossPoint.guideline = createLine();

  crossPoint.node = new THREE.Object3D();

  crossPoint.node.add(
    crossPoint.label,
    // ,crossPoint.crossPoint
    crossPoint.guideline
  );

  return crossPoint;
}

export class Measure extends THREE.Object3D {
  constructor() {
    super();

    this.constructor.counter =
      this.constructor.counter === undefined ? 0 : this.constructor.counter + 1;

    this.name = "Measure_" + this.constructor.counter;
    this.title = "Measure Title";
    this._showTitle = false;

    this.points = [];
    this._showDistances = true;
    this._showCoordinates = false;
    this._showArea = false;
    this._closed = true;
    this._showAngles = false;
    this._showCircle = false;
    this._showHeight = false;
    this._showEdges = true;
    this._showAzimuth = false;

    this._lineType = 1; //1 : solid 2:dashed
    this._lineThickness = 2;
    this._lineDashSize = 5;

    this.lineDashedMaterial = new THREE.LineDashedMaterial({
      // color: this.color,
      dashSize: this._lineDashSize,
      gapSize: 1,
      linewidth: this._lineThickness,
      // , resolution: new THREE.Vector2(1000, 1000)
    });

    // this.lineDashedMaterial.resolution = new THREE.Vector2(1000, 1000);

    this.maxMarkers = Number.MAX_SAFE_INTEGER;

    this.sphereGeometry = new THREE.SphereGeometry(0.4, 10, 10);

    this._opacity = 0;
    this._color = new THREE.Color(0x346cee);
    this._lineColor = new THREE.Color(0x346cee);
    this._pointColor = new THREE.Color(0x346cee);
    this._faceColor = new THREE.Color(0x346cee);

    this.spheres = [];
    this.edges = [];
    this.sphereLabels = [];
    this.edgeLabels = [];
    this.coordinateLabels = [];

    // this.titleLabel = createTitleLabel();
    this.areaLabel = createAreaLabel();
    this.areaFace = createAreaFace();

    this.crossPoint = createCrossPoint();

    // this.add(this.titleLabel);

    this.add(this.areaLabel);
    this.add(this.areaFace);

    this.add(this.crossPoint.node);

    //전종수 2020.01.07
    this._callback = function () {};
    this.pickAreatarget = ""; //20200506
    this.type = "";
    this.dragable = true;
    this.firstTime = true;

    this.pickCrosspointMeasure = null;

    this.intersectsObj = {};
  }

  createSphereMaterial() {
    // let sphereMaterial = new THREE.MeshLambertMaterial({
    // 디자인에 따른 material 변경
    let sphereMaterial = new THREE.MeshBasicMaterial({
      //shading: THREE.SmoothShading,
      color: this._pointColor,
      depthTest: false,
      depthWrite: false,
    });

    return sphereMaterial;
  }

  addMarker(point) {
    if (point instanceof THREE.Vector3) {
      point = { position: point };
    } else if (point instanceof Array) {
      point = { position: new THREE.Vector3(...point) };
    }

    this.points.push(point);

    // sphere
    let sphere = new THREE.Mesh(this.sphereGeometry, this.createSphereMaterial());

    this.add(sphere);
    this.spheres.push(sphere);

    {
      // edges
      let lineGeometry = new THREE.LineGeometry();
      lineGeometry.setPositions([0, 0, 0, 0, 0, 0]);

      let lineMaterial = new THREE.LineMaterial({
        color: 0xff0000,
        linewidth: this._lineThickness,
        resolution: new THREE.Vector2(1000, 1000),
        dashSize: 5,
        gapSize: 2,
        dashed: true,
      });

      lineMaterial.depthTest = false;

      //let edge = new THREE.Line( lineGeometry, lineMaterial);		GL LINE
      let edge = new THREE.Line2(lineGeometry, lineMaterial); //LINEGEOMETRY

      edge.computeLineDistances();
      edge.visible = true;

      this.add(edge);
      this.edges.push(edge);
    }

    {
      // edge labels
      let edgeLabel = new TextSprite();
      edgeLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
      edgeLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
      edgeLabel.material.depthTest = false;
      edgeLabel.visible = false;
      edgeLabel.fontsize = 16;
      this.edgeLabels.push(edgeLabel);
      this.add(edgeLabel);
    }

    {
      // coordinate labels
      let coordinateLabel = new TextSprite();
      coordinateLabel.setBorderColor({ r: 0, g: 0, b: 0, a: 1.0 });
      coordinateLabel.setBackgroundColor({ r: 0, g: 0, b: 0, a: 1.0 });
      coordinateLabel.fontsize = 16;
      coordinateLabel.material.depthTest = false;
      coordinateLabel.material.opacity = 1;
      coordinateLabel.visible = false;
      this.coordinateLabels.push(coordinateLabel);
      this.add(coordinateLabel);
    }

    {
      // Event Listeners
      let drag = (e) => {
        if (this.firstTime || this.dragable) {
          let I = Utils.getMousePointCloudIntersection(
            e.drag.end,
            e.viewer.scene.getActiveCamera(),
            e.viewer,
            e.viewer.scene.pointclouds,
            { pickClipped: true, pickAreatarget: this.pickAreatarget }
          );

          if (I) {
            let i = this.spheres.indexOf(e.drag.object);
            if (i !== -1) {
              let point = this.points[i];

              for (let key of Object.keys(I.point).filter((e) => e !== "position")) {
                point[key] = I.point[key];
              }

              this.setPosition(i, I.location);

              //전종수
              this.intersectsObj = I;

              //this.update();

              try {
                if (this.pickCrosspointMeasure) {
                  this.crossPoint.targetMeaure = this.pickCrosspointMeasure;

                  let lastIndex = this.crossPoint.targetMeaure.points.length - 1;
                  let closetPoint = []; //new THREE.Vector3();;

                  //loop edges
                  for (let i = 0; i <= lastIndex; i++) {
                    let index = i;
                    let nextIndex = i + 1 > lastIndex ? 0 : i + 1;

                    let point = this.crossPoint.targetMeaure.points[index];
                    let nextPoint = this.crossPoint.targetMeaure.points[nextIndex];

                    //Math get closet point cod..
                    let _crossLine = new THREE.Line3(point.position, nextPoint.position);

                    //.closestPointToPoint ( point : Vector3, clampToLine : Boolean, target : Vector3 ) : Vector3

                    let _closetPoint = new THREE.Vector3();
                    _crossLine.closestPointToPoint(I.location, true, _closetPoint);

                    //add edge closet point
                    closetPoint.push(_closetPoint);
                  }

                  let sorted = closetPoint
                    .slice()
                    .sort((a, b) => I.location.distanceTo(a) - I.location.distanceTo(b));

                  let closetPointVC = sorted[0].clone();

                  this.crossPoint.guideline.geometry.setPositions([
                    ...I.location.toArray(),
                    ...closetPointVC.toArray(),
                  ]);

                  this.crossPoint.guideline.geometry.verticesNeedUpdate = true;
                  this.crossPoint.guideline.geometry.computeBoundingSphere();
                  this.crossPoint.guideline.computeLineDistances();

                  this.crossPoint.crossPoint = closetPointVC.clone();
                }
              } catch (e) {
                console.log(e);
              }
              this.crossPoint.node.visible = true;
            }
          }
        }
      };

      let drop = (e) => {
        let i = this.spheres.indexOf(e.drag.object);
        if (i !== -1) {
          this.dispatchEvent({
            type: "marker_dropped",
            measurement: this,
            index: i,
          });
        }

        this.update();
      };

      //MeshBasicMaterial에서 setHex 오류에 따른 효과 제거.
      // let mouseover = (e) => e.object.material.emissive.setHex(0x888888);
      // let mouseleave = (e) => e.object.material.emissive.setHex(0x000000);

      sphere.addEventListener("drag", drag);
      sphere.addEventListener("drop", drop);
      // sphere.addEventListener('mouseover', mouseover);
      // sphere.addEventListener('mouseleave', mouseleave);
    }

    let event = {
      type: "marker_added",
      measurement: this,
      sphere: sphere,
    };

    this.dispatchEvent(event);

    this.setMarker(this.points.length - 1, point);
  }

  removeMarker(index) {
    this.points.splice(index, 1);

    this.remove(this.spheres[index]);

    let edgeIndex = index === 0 ? 0 : index - 1;
    this.remove(this.edges[edgeIndex]);
    this.edges.splice(edgeIndex, 1);

    this.remove(this.edgeLabels[edgeIndex]);
    this.edgeLabels.splice(edgeIndex, 1);

    this.coordinateLabels[index].visible = false;
    this.coordinateLabels.splice(index, 1);

    this.spheres.splice(index, 1);

    this.update();

    this.dispatchEvent({ type: "marker_removed", measurement: this });
  }

  setMarker(index, point) {
    this.points[index] = point;

    let event = {
      type: "marker_moved",
      measure: this,
      index: index,
      position: point.position.clone(),
    };
    this.dispatchEvent(event);

    this.update();
  }

  setPosition(index, position) {
    let point = this.points[index];
    point.position.copy(position);

    let event = {
      type: "marker_moved",
      measure: this,
      index: index,
      position: position.clone(),
    };
    this.dispatchEvent(event);

    this.update();
  }

  getArea() {
    let area = 0;
    let j = this.points.length - 1;

    for (let i = 0; i < this.points.length; i++) {
      let p1 = this.points[i].position;
      let p2 = this.points[j].position;
      area += (p2.x + p1.x) * (p1.y - p2.y);
      j = i;
    }

    return Math.abs(area / 2);
  }

  getTotalDistance() {
    if (this.points.length === 0) {
      return 0;
    }

    let distance = 0;

    for (let i = 1; i < this.points.length; i++) {
      let prev = this.points[i - 1].position;
      let curr = this.points[i].position;
      let d = prev.distanceTo(curr);

      distance += d;
    }

    if (this.closed && this.points.length > 1) {
      let first = this.points[0].position;
      let last = this.points[this.points.length - 1].position;
      let d = last.distanceTo(first);

      distance += d;
    }

    return distance;
  }

  getAngleBetweenLines(cornerPoint, point1, point2) {
    let v1 = new THREE.Vector3().subVectors(point1.position, cornerPoint.position);
    let v2 = new THREE.Vector3().subVectors(point2.position, cornerPoint.position);

    // avoid the error printed by threejs if denominator is 0
    const denominator = Math.sqrt(v1.lengthSq() * v2.lengthSq());
    if (denominator === 0) {
      return 0;
    } else {
      return v1.angleTo(v2);
    }
  }

  getAngle(index) {
    if (this.points.length < 3 || index >= this.points.length) {
      return 0;
    }

    let previous = index === 0 ? this.points[this.points.length - 1] : this.points[index - 1];
    let point = this.points[index];
    let next = this.points[(index + 1) % this.points.length];

    return this.getAngleBetweenLines(point, previous, next);
  }

  update() {
    if (this.points.length === 0) return;

    if (this.points.length === 1 || this.type === "point") {
      if (this.coordinateLabels.length > 0) {
        for (let i = 0; i < this.coordinateLabels.length; i++) {
          let point = this.points[i];

          if (!point?.position) continue;

          let position = point.position;
          this.spheres[i].position.copy(position);
          this.spheres[i].material.color = this._pointColor;
          this.spheres[i].material.opacity = this._opacity;

          const coordinateLabel = this.coordinateLabels[i];

          let msg = position
            .toArray()
            .map((p) => {
              try {
                return Utils.addCommas(p.toFixed(ROUND_OFF_THE_NUMBERS_COORDINATE));
              } catch (e) {
                return p;
              }
            })
            .join(" / ");
          coordinateLabel.setText(msg);

          coordinateLabel.visible = this.showCoordinates;
        }
      }
    }

    let lastIndex = this.points.length - 1;

    let centroid = new THREE.Vector3();
    for (let i = 0; i <= lastIndex; i++) {
      let point = this.points[i];
      centroid.add(point.position);
    }
    centroid.divideScalar(this.points.length);

    for (let i = 0; i <= lastIndex; i++) {
      let index = i;
      let nextIndex = i + 1 > lastIndex ? 0 : i + 1;
      let previousIndex = i === 0 ? lastIndex : i - 1;

      let point = this.points[index];
      let nextPoint = this.points[nextIndex];
      let previousPoint = this.points[previousIndex];

      let sphere = this.spheres[index];

      // spheres
      sphere.position.copy(point.position);
      sphere.material.color = this._pointColor;
      sphere.material.opacity = this._opacity;

      if (this.type !== "point") {
        // edges
        let edge = this.edges[index];

        edge.material.color = this._lineColor;
        edge.material.linewidth = this._lineThickness;
        edge.material.opacity = this._opacity;

        edge.position.copy(point.position);

        edge.geometry.setPositions([
          0,
          0,
          0,
          ...nextPoint.position.clone().sub(point.position).toArray(),
        ]);

        //threejs example webgl_lines
        //1:solid, 2:dashed
        if (this._lineType === 1) {
          edge.material.dashed = false;

          // dashed is implemented as a defines -- not as a uniform. this could be changed.
          // ... or THREE.LineDashedMaterial could be implemented as a separate material
          // temporary hack - renderer should do this eventually
          delete edge.material.defines.USE_DASH;
          edge.material.needsUpdate = true;

          //dashed
        } else {
          edge.material.dashed = true;
          edge.material.defines.USE_DASH = "";
          edge.material.dashScale = 0.5;

          edge.material.dashSize = this._lineDashSize;
          edge.material.gapSize = 1;
          edge.material.needsUpdate = true;
        }

        edge.geometry.verticesNeedUpdate = true;
        edge.geometry.computeBoundingSphere();

        edge.computeLineDistances();

        //let _object = new THREE.Line2( geometrySpline, _lineDashedMaterial );
        //_object.computeLineDistances();
        edge.visible = index < lastIndex || this.closed;

        if (!this.showEdges) {
          edge.visible = false;
        }
      }
      if (this.type !== "geofence") {
        // edge labels
        let edgeLabel = this.edgeLabels[i];

        let center = new THREE.Vector3().add(point.position);
        center.add(nextPoint.position);
        center = center.multiplyScalar(0.5);
        let distance = point.position.distanceTo(nextPoint.position);

        edgeLabel.position.copy(center);

        let suffix = "";
        if (this.lengthUnit != null && this.lengthUnitDisplay != null) {
          distance =
            (distance / this.lengthUnit.unitspermeter) * this.lengthUnitDisplay.unitspermeter; //convert to meters then to the display unit
          suffix = this.lengthUnitDisplay.code;
        }

        let txtLength = Utils.addCommas(distance.toFixed(2));
        edgeLabel.setText(`${txtLength} ${suffix}`);
        edgeLabel.visible =
          this.showDistances &&
          (index < lastIndex || this.closed) &&
          this.points.length >= 2 &&
          distance > 0;
      }
    }

    if (this.type !== "geofence") {
      // update area label
      this.areaLabel.position.copy(centroid);
      this.areaLabel.visible = this.showArea && this.points.length >= 3;

      let area = this.getArea();

      let suffix = "";
      if (this.lengthUnit != null && this.lengthUnitDisplay != null) {
        area =
          (area / Math.pow(this.lengthUnit.unitspermeter, 2)) *
          Math.pow(this.lengthUnitDisplay.unitspermeter, 2); //convert to square meters then to the square display unit
        suffix = this.lengthUnitDisplay.code;
      }

      let txtArea = Utils.addCommas(area.toFixed(ROUND_OFF_THE_NUMBERS_AREA));
      let msg = `${txtArea} ${suffix}\u00B2`;
      this.areaLabel.setText(msg);
    }

    // update area coloring
    this.areaFace.visible = this.closed;
    this.areaFace.name = this.name + "_areaFace";

    if (this.closed && this.points.length > 2) {
      let vertices = this.points.map((v) => v.position);

      const shape = new THREE.Shape();
      for (let idx = 0; idx < vertices.length; idx++) {
        const element = vertices[idx];
        idx === 0 ? shape.moveTo(element.x, element.y) : shape.lineTo(element.x, element.y);
      }
      shape.lineTo(vertices[0].x, vertices[0].y);

      this.areaFace.geometry.dispose();
      this.areaFace.geometry = new THREE.ShapeGeometry(shape);

      this.areaFace.material.color = this._faceColor;
      this.areaFace.material.opacity = this._opacity;
    }

    if (this._calback) {
      this._callback(this);
    }

    // this.updateAzimuth();
  }

  raycast(raycaster, intersects) {
    for (let i = 0; i < this.points.length; i++) {
      let sphere = this.spheres[i];

      sphere.raycast(raycaster, intersects);
    }

    // recalculate distances because they are not necessarely correct
    // for scaled objects.
    // see https://github.com/mrdoob/three.js/issues/5827
    // TODO: remove this once the bug has been fixed
    for (let i = 0; i < intersects.length; i++) {
      let I = intersects[i];
      I.distance = raycaster.ray.origin.distanceTo(I.point);
    }
    intersects.sort(function (a, b) {
      return a.distance - b.distance;
    });
  }

  get showCoordinates() {
    return this._showCoordinates;
  }

  set showCoordinates(value) {
    this._showCoordinates = value;
    this.update();
  }

  get showAngles() {
    return this._showAngles;
  }

  set showAngles(value) {
    this._showAngles = value;
    this.update();
  }

  get showCircle() {
    return this._showCircle;
  }

  set showCircle(value) {
    this._showCircle = value;
    this.update();
  }

  get showAzimuth() {
    return this._showAzimuth;
  }

  set showAzimuth(value) {
    this._showAzimuth = value;
    this.update();
  }

  get showEdges() {
    return this._showEdges;
  }

  set showEdges(value) {
    this._showEdges = value;
    this.update();
  }

  get showHeight() {
    return this._showHeight;
  }

  set showHeight(value) {
    this._showHeight = value;
    this.update();
  }

  get showArea() {
    return this._showArea;
  }

  set showArea(value) {
    this._showArea = value;
    this.update();
  }

  get closed() {
    return this._closed;
  }

  set closed(value) {
    this._closed = value;
    this.update();
  }

  get showDistances() {
    return this._showDistances;
  }

  set showDistances(value) {
    this._showDistances = value;
    this.update();
  }

  get lineType() {
    return this._lineType;
  }

  set lineType(type) {
    this._lineType = type;
    this.update();
  }

  get lineThickness() {
    return this._lineThickness;
  }

  set lineThickness(thickness) {
    this._lineThickness = thickness;
    this.update();
  }

  get lineDashSize() {
    return this._lineDashSize;
  }

  set lineDashSize(dashSize) {
    this._lineDashSize = dashSize;
    this.update();
  }

  get showTitle() {
    return this._showTitle;
  }

  set showTitle(value) {
    this._showTitle = value;
    this.update();
  }

  get color() {
    return this._color;
  }

  // set color (value) {
  // 	this._color = value;

  // 	this.pointColor = value;
  // 	this.faceColor = value;
  // 	this.lineColor = value;

  // 	this.update();
  // }

  get pointColor() {
    return this._pointColor;
  }

  set pointColor(value) {
    this._pointColor = value;
    this.update();
  }

  get faceColor() {
    return this._faceColor;
  }

  set faceColor(value) {
    this._faceColor = value;
    this.update();
  }

  get lineColor() {
    return this._lineColor;
  }

  set lineColor(value) {
    this._lineColor = value;
    this.update();
  }

  get opacity() {
    return this._opacity;
  }

  set opacity(value) {
    this._opacity = value;
    this.update();
  }

  get callback() {
    return this._calback;
  }

  set callback(value) {
    this._calback = value;
  }
}
