//types
export const SUB_SURFACE = 'MODEL/SUB_SURFACE';
export const ALL_SUB_SURFACE = 'MODEL/ALL_SUB_SURFACE';
export const SUB_SURFACE_FILES = 'MODEL/SUB_SURFACE_FILES';
export const SELECT_BORE_HOLE = 'MODEL/SELECT_BORE_HOLE';
export const INCREMENT = 'MODEL/INCREMENT';
export const ROCK_TYPES = 'MODEL/ROCK_TYPES';

export const TARGET_TITLE = 'MODEL/TARGET/TITLE';

export const TOPOGRAPHIC_FILE_NAME = 'MODEL/TOPOGRAPHIC/FILE_NAME';
export const TOPOGRAPHIC_TITLE = 'MODEL/TOPOGRAPHIC/TITLE';

//setter
export const setSubSurface = data => ({ type: SUB_SURFACE, data});
export const setAllSubSurface = (selected, data) => ({ type: SUB_SURFACE, selected, data});
export const setRockTypes = (data) => ({ type: SUB_SURFACE, data});
export const onIncrement = data => ({ type: INCREMENT});
export const setFileNames = (name, key) => ({ type: SUB_SURFACE_FILES, name, key });
export const selectBoreHole = (object) => ({ type: SELECT_BORE_HOLE, object });

export const setTargetTitle = (name) => ({ type: TARGET_TITLE, name });

export const setTopographicFile = (name, key) => ({ type: TOPOGRAPHIC_FILE_NAME, name, key });
export const setTopographicTitle = (titleName, key) => ({ type: TOPOGRAPHIC_TITLE, titleName, key });