import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Viewer } from "cesium-react";
import { ScreenSpaceEventHandler, ScreenSpaceEventType, ImagerySplitDirection } from "cesium";
import { useTranslation } from "react-i18next";
import Model from "../model";
import _ from "lodash";
import {
  flyToWms,
  getImageryLayer,
  loadOpenStreetMap,
  loadTileMap,
  removeLayer,
  visibleTileMapLayer,
} from "utils/CesiumUtils";
import { capitalizeFirstLetter } from "utils/StringHelper";

export const SplitComponent = (props) => {
  const { t } = useTranslation();
  const cesiumViewer = useRef();

  const [viewer, setViewer] = useState();
  const [leftWmsLayer, setLeftWmsLayer] = useState(null);
  const [rightWmsLayer, setRightWmsLayer] = useState(null);

  const { projectKey, layer } = useSelector(
    (state) => ({
      projectKey: state.ViewDataSetReducer.projectKey,
      layer: state.ViewDataSetReducer.projectViewDataSetModel.layer,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!viewer) {
      if (cesiumViewer.current.cesiumElement) {
        setViewer(cesiumViewer.current.cesiumElement);
      }
      return;
    }

    initMap();

    const leftLayer = loadWmsLayerLeft();
    loadWmsLayerRight();

    flyToWms(viewer, leftLayer);

    drawOverlay(viewer, layer.overlay);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer]);

  useEffect(() => {
    if (!viewer) return;

    loadWmsLayerLeft();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.leftData]);

  useEffect(() => {
    if (!viewer) return;

    loadWmsLayerRight();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rightData]);

  // 맵 초기화
  const initMap = useCallback(() => {
    if (viewer.imageryLayers && viewer.imageryLayers.length > 0) viewer.imageryLayers.removeAll();

    if (viewer.entities && viewer.entities.length > 0) viewer.entities.removeAll();

    if (viewer.dataSource && viewer.dataSource.length > 0) viewer.dataSource.removeAll();

    // baseMap
    loadOpenStreetMap(viewer, `//a.tile.openstreetmap.org/`);

    // event
    const slider = document.getElementById("slider");

    const handler = new ScreenSpaceEventHandler(slider);

    let moveActive = false;

    viewer.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;

    function move(movement) {
      if (!moveActive) {
        return;
      }

      var relativeOffset = movement.endPosition.x;
      var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
      slider.style.left = 100.0 * splitPosition + "%";
      viewer.scene.imagerySplitPosition = splitPosition;
    }

    handler.setInputAction(function() {
      moveActive = true;
    }, ScreenSpaceEventType.LEFT_DOWN);
    handler.setInputAction(function() {
      moveActive = true;
    }, ScreenSpaceEventType.PINCH_START);

    handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
    handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);

    handler.setInputAction(function() {
      moveActive = false;
    }, ScreenSpaceEventType.LEFT_UP);
    handler.setInputAction(function() {
      moveActive = false;
    }, ScreenSpaceEventType.PINCH_END);
  }, [viewer]);

  const loadWmsLayerLeft = () => {
    removeLayer(viewer, leftWmsLayer);
    const layer = loadTileMap(viewer, `/uploads/data_pcd/${projectKey}/${props.leftData}/wms`, 1);
    layer.splitDirection = ImagerySplitDirection.LEFT;
    setLeftWmsLayer(layer);
    return layer;
  };
  const loadWmsLayerRight = () => {
    removeLayer(viewer, rightWmsLayer);
    const layer = loadTileMap(viewer, `/uploads/data_pcd/${projectKey}/${props.rightData}/wms`, 2);
    layer.splitDirection = ImagerySplitDirection.RIGHT;
    setRightWmsLayer(layer);
    return layer;
  };

  const model = {
    type: t("title$Visualization"),
    key: "1",
    models: [
      {
        title: `${t("title$Overlays_Information")}`,
        items: layer.overlay.filter((x) => ["tif", "dxfwms"].includes(x.dataType)),
        key: "overlay",
      },
    ],
  };

  const handleOverlayChange = () => {
    drawOverlay(viewer, layer.overlay);
  };
  return (
    <>
      <Viewer
        full
        ref={cesiumViewer}
        sceneModePicker={false}
        homeButton={false}
        animation={false}
        geocoder={false}
        baseLayerPicker={false}
        navigationHelpButton={false}
        fullscreenButton={false}
        timeline={false}
        shouldAnimate={false}
      />
      <div id="slider"></div>
      <div id="split-overlays">
        <Model
          models={model.models}
          name={model.type}
          controlsView={false}
          listHeight={450}
          onChange={handleOverlayChange}
        />
      </div>
    </>
  );
};

export const drawOverlay = (viewer, overlay) => {
  if (!viewer || !overlay) return;

  _.map(overlay, (item) => {
    const url = `/${item.dbObj.f_file_path}`;
    if (["tif", "wms", "dxfwms"].includes(item.dataType)) {
      // object map에 추가
      if (_.isEmpty(item._3DObject) === false) {
        const tmLayer = getImageryLayer(viewer, url);
        if (tmLayer === null) {
          // layer 추가
          const wmsLayer = loadTileMap(viewer, url);
          wmsLayer.show = item.visible;
          return;
        }
      } else {
        if (item.visible) {
          const wmsLayer = loadTileMap(viewer, url);
          wmsLayer.show = item.visible;
          item._3DObject = wmsLayer;
          return;
        }
      }

      if (_.isEmpty(item._3DObject) === false) visibleTileMapLayer(viewer, url, item.visible);
    }
  });
};
