import React from "react";
import ReactDOM from "react-dom";
import "./index.less";
import { Provider } from "react-redux";

import App from "./AppUI.jsx";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";

import "./utils/Axios.config";
import "./react-i18next/i18next";
// import { createStore } from 'redux';
// import appReducers from './store/reducers';
// const store = createStore(appReducers)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
