import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { addCommaWithRound } from "utils/StringHelper";
import { ROUND_OFF_THE_NUMBERS_VOLUME } from "config/measure";

const PolygonVolumeOverall = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.overalls.map((overall) => (
        <React.Fragment key={overall.idx}>
          {overall.layerName}
          <div className="overall-wrap" key={overall.idx}>
            <label>
              <span>{t("title$Fill")}</span>
              <Input value={addCommaWithRound(overall.fill, ROUND_OFF_THE_NUMBERS_VOLUME)} />
            </label>
            <label>
              <span>{t("title$Net")}</span>
              <Input value={addCommaWithRound(overall.net, ROUND_OFF_THE_NUMBERS_VOLUME)} />
            </label>
            <label>
              <span>{t("title$Cut")}</span>
              <Input value={addCommaWithRound(overall.cut, ROUND_OFF_THE_NUMBERS_VOLUME)} />
            </label>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default PolygonVolumeOverall;
