//libs
// import moment from 'moment';

//actions
import { MODEL_LAYER_INIT, MERGE, SELECT_DATE, TOPO_DATAS, TARGET_DATAS, SELECT_TOPO, SELECT_TARGET, SELECT_SURFACE, SURFACE_DATAS, LAYER_TITLE } from '../actions/modelLayer';


const modelInteface = Object.create({
    formData: [],
})

const initializeLayerState = {
    selectedModelDate: null,
    files: {
        topographic: {
            pointCloud: modelInteface,
            orthophoto: modelInteface
        },
        targetDesign: modelInteface,
        overlay: modelInteface
    },
    topoDates: [],
    surfaceDates: [],
    targetDates: [],
    selectedSurfaceDate: '',
    selectedTopoDate: '',
    selectedTargetDate: '',
    title: '',
}

export default function ModelLayerReducer (state = initializeLayerState, action) {
    switch (action.type) {
        case MODEL_LAYER_INIT: return initializeLayerState;
        case LAYER_TITLE : return { ...state, title: action.title };
        case MERGE : return { ...state, files: action.files };
        case SELECT_DATE : return { ...state, selectedModelDate: action.date };

        case TOPO_DATAS :return { ...state, topoDates: action.topoDates };
        case TARGET_DATAS :return { ...state, targetDates: action.targetDates };
        case SURFACE_DATAS :return { ...state, surfaceDates: action.surfaceDates };
        
        case SELECT_TOPO :return { ...state, selectedTopoDate: action.topo };
        case SELECT_TARGET :return { ...state, selectedTargetDate: action.target };
        case SELECT_SURFACE :return { ...state, selectedSurfaceDate: action.surface };
        default: return state;
    }
}