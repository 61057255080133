import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, message } from "antd";
import HideDashboard from "../../components/blocks/hideDashboard";
import { shallowEqual, useSelector } from "react-redux";
import ContentTitle from "../../components/elements/text/contentTitle";
import TextInput from "../../components/elements/input/text";
import Axios from "axios";
import DefaultButton from "../../components/elements/button/DefaultButton";
import BackButton from "../../components/elements/button/BackButton";
import _ from "lodash";
import { capitalizeFirstLetter } from "utils/StringHelper";
import { NewDocumentItemPanel } from "./NewDocumentItemPanel";
import { getProjectInformation } from "views/project/utils/project";
import { consoleError } from "middleware";
import "./newDocument.less";

const { Option } = Select;

const NewDocument = (props) => {
  const { t } = useTranslation();

  const { dataset, currentProject, selectedDate, dateSet, user_mng_sq } = useSelector(
    (state) => ({
      dataset: state.ReportReducer.dataset,
      currentProject: state.currentProjectReducer.currentProject,
      selectedDate: state.currentProjectReducer.selectedDate,
      dateSet: state.currentProjectReducer.dateSet,
      user_mng_sq: state.appState.user.user_mng_sq,
    }),
    shallowEqual
  );
  const { project_mng_sq, f_description, f_proj_nm } = currentProject;

  const [annotationData, setAnnotationData] = useState(initializeAnnotationList(t));
  const [reportTitle, setReportTitle] = useState("");
  // 리포트 선택 날짜, 프로젝트 날짜 구분
  const [reportDate, setReportDate] = useState();

  //selected date onchange event
  const handleDateChangeAsync = async (selectedDate) => {
    await initMapAsync(selectedDate, props.match.params.projectId);
    setReportDate(selectedDate);
  };

  /**
   * ⚡annotation data change
   */
  const handleChange = (data) => setAnnotationData(data);
  const handleTitleChange = (evt) => setReportTitle(evt.target.value);

  /**
   * ⚡report save confirm
   */
  const handleSaveClick = () => {
    if (reportTitle.length < 1) {
      document.querySelector(".ant-input").focus();
      return message.error(t("message$please_check_report_title"), 2);
    }

    const selectedReportItems = _.flattenDeep(
      annotationData
        .filter((x) => x.disabled !== true)
        .map((y) => y.list.filter((x) => x.selected === true))
    ).map((x) => x.value); //iterable element annotation key
    if (!selectedReportItems || selectedReportItems.length === 0)
      return message.error(t("message$Please check report items"), 2);

    const camera = window.viewer.scene.getActiveCamera();
    Axios.post("/report", {
      documentId: dataset?.selectedDocSq,
      position: camera.position,
      fov: camera.fov,
      reportTitle,
      selectedReportItems,
      projectId: project_mng_sq,
      selectedDate: reportDate ?? selectedDate,
      userId: user_mng_sq,
    })
      .then(({ data }) => {
        message.success(t("message$Successful_Save"), 3);
        pushHistoryBackSpace();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const pushHistoryBackSpace = () =>
    props.history.push(project_mng_sq ? `/projects/documents/${project_mng_sq}` : "/");

  const initMapAsync = async (date, projectId) => {
    try {
      await getProjectInformation(date, projectId);
    } catch (error) {
      consoleError(error);
    }
  };

  const initAnnotationCallback = useCallback(async () => {
    if (project_mng_sq === null || project_mng_sq === undefined) {
      const pathNameSplit = props.history.location.pathname.split("/");
      const projectId = pathNameSplit[pathNameSplit.length - 1];
      return props.history.push(`/projects/documents/${projectId}`);
    }

    try {
      const { data } = await Axios.get(`/listAnnotation`, {
        params: {
          project_mng_sq: project_mng_sq,
          data_date: reportDate ?? selectedDate,
        },
      });

      const obj = initializeAnnotationList(t);
      data.map((x) => {
        let selectedIndex = -1;
        if (dataset) {
          selectedIndex = _.findIndex(dataset.selectedReportItems, {
            annotation_mng_sq_t_annotation_mng: x.annotation_mng_sq,
          });
        }

        const item = {
          label: x.f_fence_nm,
          value: x.annotation_mng_sq,
          disabled: false,
          selected: selectedIndex !== -1,
        };

        if (x.f_fence_annotation_flag === "a2") {
          //Not completed
          item.disabled = x.f_status !== 2;
        }

        let itemPosition = 0;
        //geo-fence
        if (x.c_fence_type === "offsite") itemPosition = 3;

        //cross section
        if (x.f_fence_annotation_flag.indexOf("l2") > -1) itemPosition = 1;

        //annotation mark
        if (x.f_fence_annotation_flag.indexOf("p2") > -1) itemPosition = 4;

        //volume
        if (x.f_fence_annotation_flag.indexOf("a2") > -1) itemPosition = 2;

        return obj.find((x) => x.key === itemPosition).list.push(item);
      });

      setAnnotationData(obj);
      dataset && setReportTitle(dataset.reportName);
    } catch (error) {
      consoleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDate]);

  useEffect(() => {
    if (dataset) {
      setReportDate(dataset.selectedDate);

      dataset.selectedDate !== selectedDate &&
        initMapAsync(dataset.selectedDate, props.match.params.projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset]);

  useEffect(() => {
    initAnnotationCallback();
  }, [initAnnotationCallback]);

  return (
    <section className="new-document z-index-1">
      <article className="new-document-top">
        <BackButton
          className="new-document__back"
          style={{ background: "unset" }}
          onClick={pushHistoryBackSpace}
        />
        <h4
          style={{
            overflowWrap: "break-word",
          }}
        >
          {f_proj_nm ? f_proj_nm : " "}
        </h4>
        <HideDashboard />
        <h5>{f_description}</h5>
        <div className="new-document__description">
          <Select
            showSearch
            value={reportDate ?? selectedDate}
            placeholder={t("placeholder$Select_a_date")}
            optionFilterProp="children"
            onChange={handleDateChangeAsync}
          >
            {dateSet.map((date, index) => (
              <Option value={date.f_input_date} key={index}>
                {`${date.f_title} (${date.f_input_date})`}
              </Option>
            ))}
          </Select>
        </div>
      </article>

      <article className="new-document__content">
        <article className="new-document__content__part border-b">
          <div className="pdlr-30">
            <ContentTitle title={t("title$Report_Name")} style={{ color: "#182e63" }} />
            <TextInput
              changeValue={handleTitleChange}
              placeholder={t("placeholder$Report_Name")}
              size="large"
              textContent={reportTitle}
            />
          </div>
        </article>
        <NewDocumentItemPanel data={annotationData} onChange={handleChange} />
      </article>
      <article className="new-document__bottom">
        <DefaultButton block className="font-sofia" onClick={_.debounce(handleSaveClick, 300)}>
          {capitalizeFirstLetter(t("button$Publish_Report"))}
        </DefaultButton>
      </article>
    </section>
  );
};

// export default NewDocument;
export default React.memo(NewDocument);

const initializeAnnotationList = (t) => [
  {
    title: "Measurement_Item",
    c_fence_type: "onsite",
    disabled: false,
    list: [],
    key: 0,
  },
  {
    title: "Section_Analysis_Item",
    c_fence_type: "onsite",
    disabled: false,
    list: [],
    key: 1,
  },
  {
    title: "Volume_Estimation_Item",
    c_fence_type: "onsite",
    disabled: false,
    list: [],
    key: 2,
  },
  {
    title: "Geo-fencing",
    disabled: false,
    list: [],
    key: 3,
  },
  {
    title: "Annotation_Marks",
    disabled: false,
    list: [],
    key: 4,
  },
];
