import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CrossSectionChart from "../../../../components/modals/crossSectionChart/CrossSectionChart";
import ContentTitle from "../../../../components/elements/text/contentTitle";
import {
  Button,
  // Checkbox
} from "antd";
import AnnotationProperties from "../../children/AnnotationProperties";
// import { getCrossSectionInfomation } from "../utils/getInfomation";
import { fnCrossectionLoad } from "../../../../xitecloud3D/xiteCloud.dev";
import { initDraw3D } from "../../util/initialize";
import { connect } from "react-redux";
import { capitalizeFirstLetter } from "utils/StringHelper";
import { initLineAttr } from "views/Measurement/util/lineAttribute";

const SectionResult = (props) => {
  const { t } = useTranslation();
  const [visible, setvisible] = useState(false);
  // const [drawVisible, setDrawVisible] = useState(['Longitudinal', 'CrossSection']);

  const onClose = () => setvisible(false);
  const toggleDrawer = () => setvisible(!visible);

  const draw3DCrossSection = () => {
    const measure = props.projectChildrenComponents.currentMeasure;
    initDraw3D();
    measure.cross3DObject = fnCrossectionLoad(props.CrossSectionReducer.crossSectionDatasetArr);
  };

  // const options = [
  //   { label: "Longitudinal Line", value: "Longitudinal" },
  //   { label: "Cross Section Line", value: "CrossSection" },
  // ];

  // const onChange = (checkedValues) => {
  //   const measure = props.projectChildrenComponents.currentMeasure;
  //   if (measure.cross3DObject) {
  //     const { _3DObject_lat, annotation_lat, _3DObject_lon, annotation_lon  } = measure.cross3DObject;

  //     [_3DObject_lat, annotation_lat, _3DObject_lon, annotation_lon].map(item => item.visible = false);

  //     if (checkedValues.length === options.length) {
  //       [_3DObject_lat, annotation_lat, _3DObject_lon, annotation_lon].map(item => item.visible = true)
  //     } else {
  //       checkedValues.map(checked => {
  //         if (checked === 'CrossSection') [_3DObject_lat, annotation_lat].map(item => item.visible = true);
  //         if (checked === 'Longitudinal') [_3DObject_lon, annotation_lon].map(item => item.visible = true);
  //       })
  //     }
  //   }

  //   setDrawVisible(checkedValues)
  // }

  return (
    <>
      <CrossSectionChart onClose={onClose} visible={visible} />
      <ContentTitle title={t("title$Result", { count: 2 })} className="sub-title result-wrap">
        <Button size="small" onClick={toggleDrawer} className="dashboard-btn">
          <img src="s" alt="" />
          <span>{t("title$2D View")}</span>
        </Button>
        <Button
          size="small"
          style={{ marginRight: "10px" }}
          onClick={draw3DCrossSection}
          className="dashboard-btn"
        >
          <img src="s" alt="" />
          <span>{t("title$3D View")}</span>
        </Button>
      </ContentTitle>
      <div className="property-result-wrap">
        <AnnotationProperties properties={initLineAttr(t)} />

        {/* <Checkbox.Group
          options={options}
          defaultValue={drawVisible}
          onChange={onChange}
        /> */}
      </div>
    </>
  );
};

export default connect((state) => state, {
  // setData,
})(SectionResult);
