import Axios from "axios";
import { sortBy } from "lodash";
import { cumtomDxfWriter, convertDxfColorCode } from "./dxfWriterCustom";

/**
 * 기성 데이터(DB의 fn_xcad_new_to_old 함수 출력 결과)로서 DXF 문자열 생성
 * @param {*} sectionData DXF 기성 JSON 데이터
 * @returns
 */
export const makeSectionDxf = async (sectionData) => {
  // 글꼴 조회
  const fontName = await getDxfFontName();

  let data = sectionData; // 입력 데이터 초기 할당

  let _cntStation = data.insec_rock_line.length - 1; // 종단 (-1) 빼기
  let _transverse = data.t_summary.p_baselength;

  let _sumTransverse = 0; // 횡단 블록 간격

  // 종단면 각 높이 수치 배열 할당
  // 절토고
  const dataCutHeight = data.lon_insec_pt.map((_v) => {
    return [Number(_v.ex_dist).toFixed(2), Number(_v.cut_z).toFixed(2)];
  });

  // 성토고
  const dataFillHeight = data.lon_insec_pt.map((_v) => {
    return [Number(_v.ex_dist).toFixed(2), Number(_v.fill_z).toFixed(2)];
  });

  // 계획고
  const dataPlanHeight = data.lon_insec_pt.map((_v) => {
    return [Number(_v.ex_dist).toFixed(2), Number(_v.bottom_z).toFixed(2)];
  });

  // 지반고
  const dataTopoHeight = data.lon_insec_pt.map((_v) => {
    return [Number(_v.ex_dist).toFixed(2), Number(_v.top_z).toFixed(2)];
  });

  // 누가거리
  const dataStackDist = data.lon_insec_pt.map((_v) => {
    return [Number(_v.ex_dist).toFixed(2), Number(_v.ex_dist).toFixed(2)];
  });

  // 점간거리 (DB 함수에서는 산출하지 않으므로, 각 스테이션 순서별로 오름차순 정렬 후 다음 누가거리와 현 누가거리에서 차감한 값 설정)
  const dataPointTermDist = sortBy(data.lon_insec_pt, ["st_no", "asc"]).map((_v, idx, arr) => {
    return [
      Number(_v.ex_dist).toFixed(2),
      idx === arr.length - 1
        ? parseFloat(0).toFixed(2)
        : (Number(arr[idx + 1].ex_dist) - Number(_v.ex_dist)).toFixed(2),
    ];
  });

  // 측점
  const dataAddPointDist = data.lon_insec_pt.map((_v) => {
    return [Number(_v.ex_dist).toFixed(2), Number(_v.st_no).toFixed(2)];
  });

  let dataAnalArr = [];
  dataAnalArr.push(dataCutHeight);
  dataAnalArr.push(dataFillHeight);
  dataAnalArr.push(dataPlanHeight);
  dataAnalArr.push(dataTopoHeight);
  dataAnalArr.push(dataStackDist);
  dataAnalArr.push(dataPointTermDist);
  dataAnalArr.push(dataAddPointDist);

  let arrLabelLong = data.lon_insec_pt.map((item) => {
    return Number(item.ex_dist).toFixed(0);
  });

  // Line Point
  let dataMin_x = Math.min(...arrLabelLong.map((item) => Number(item))); // Number(arrLabelLong[0]);
  let dataMin_y = 0;
  let dataMax_x = Math.max(...arrLabelLong.map((item) => Number(item))); //Number(arrLabelLong[arrLabelLong.length - 1]);
  let dataMax_y = 0;

  let rockName = data.rock_info.map((v) => {
    return v.f_rock_type_nm;
  });

  dataMin_y = data.t_summary.zmin;
  dataMax_y = data.t_summary.zmax;

  /**
   * 사용자 정의 dxf-writer Drawing 클래스 (Handler의 seed 초기화를 위함)
   * 기본 레이어 0 할당 상태 (색상 및 글꼴(Font)은 이하 호출 시 설정)
   */
  let dxf = cumtomDxfWriter({ color: "white", font: fontName });

  /**
   * 레이어 설정 및 할당
   * 지표면(Topo): 백색 (White)
   * 계획선(Target): 적색 (Red)
   * 원지반(Natural): 녹색 (Green)
   * 암선(Rock): 하늘색 (Cyan)
   */
  dxf.addLayer("Topo", convertDxfColorCode("green"), "CONTINUOUS");
  dxf.addLayer("Target", convertDxfColorCode("red"), "CONTINUOUS");
  // eslint-disable-next-line array-callback-return
  data.rock_info.map((rock) => {
    // Type별 암층선 레이어 설정
    dxf.addLayer(rock.f_rock_type_nm, convertDxfColorCode("cyan"), "CONTINUOUS");
  });

  // 원지반 그래프 추가 시 전체 데이터 계산에서 누락 상태를 summary 값에 원지반 계산 추가
  if (data.org_topo && data.org_topo.length > 0) {
    let tmpZ = [];

    const _A = data.org_topo.map((item) => item.line_pt_arr[0].line_pt_arr.map((item) => item.z));
    _A.map((item) => (tmpZ = [...tmpZ, ...item]));

    const tmpY = Math.max(...tmpZ);

    dataMax_y = Math.max(dataMax_y, tmpY);
  }

  let dataTableOffset_x = dataMax_x;
  let dataTableOffset_y = 10;

  let arrDataLayer = data.insec_rock_line
    .find((v) => v.st_no === -1)
    .line_pt_arr.map((item) => {
      return {
        gbn: item.gbn,
        line_pt_arr: item.line_pt_arr.map((_item) => {
          return [Number(Number(_item.ex_dist).toFixed(2)), Number(Number(_item.z).toFixed(2))];
        }),
      };
    });

  /**
   * 기본 프레임 (축, 요약표)
   * 절토고 (타겟: Topo 절토부)
   * 성토고 (타겟: Topo 성토부)
   * 계획고 - 타겟,
   * 지반고 - TOPO,
   * 누가거리
   * 점간거리
   * 측점 (add point)
   * St 거리(10,20)
   */

  let infoObj = {
    x_offset: 10,
    x_min: dataMin_x, // Math.floor(dataMin_x / 10) * 10 - dataTableOffset_x,
    x_max: dataMax_x, // Math.floor(dataMax_x / 10) * 10 + dataTableOffset_x,
    y_min: Math.floor(dataMin_y / 10) * 10 - dataTableOffset_y,
    y_max: Math.floor(dataMax_y / 10) * 10 + dataTableOffset_y,
    y_axes_label_space: 10,
    t_cel_height: 20,
    t_cel_padding: 5,
    text_size_default: 10,

    text_size_table_th: 5, // 상단(종단면) 요약표 좌측 범례 문자 크기
    text_size_y_axis: 5, // 상단(종단면) 요약표 Y축 문자 크기
    text_size_dash: 2, // 상단(종단면) 요약표 내 수준선 문자 크기
    text_size_section_title: 2, // 하단(횡단면) 그래프 제목 문자 크기
    text_size_section_layer_text: 1, // 하단(횡단면) 그래프 암층명 문자 크기
    text_size_section_subtitle: 2, // 하단(횡단면) 그래프 아래 절토 및 성토 정보 문자 크기
    text_size_section_axis: 1, // 하단(횡단면) 그래프 X 및 Y축 문자 크기

    line_height_dash: 2,
    labelMaxLenth: 0,
  };

  /* --------------------------  상단(종단) 요약표 및 그래프 영역 -------------------------- */
  for (let i = 0; i < arrDataLayer.length; i++) {
    /**
     * data.rock_info[x].gbn 또는 data.insec_rock_line.find((v) => v.st_no === -1).line_pt_arr[x].gbn
     * gbn: -2 - 원지반, -1 - 계획, 0 - Topo, 1~ - 암층
     */
    const findRockName = (_gbn) =>
      _gbn === -1
        ? "Target"
        : _gbn === 0
          ? "Topo"
          : data.rock_info.find((v) => v.gbn === _gbn).f_rock_type_nm;
    const _rockName = findRockName(arrDataLayer[i].gbn);

    dxf.setActiveLayer(_rockName);
    dxf.drawPolyline(arrDataLayer[i].line_pt_arr);

    if (arrDataLayer[i].gbn > 0) {
      dxf.drawText(
        arrDataLayer[i].line_pt_arr[0][0],
        arrDataLayer[i].line_pt_arr[0][1],
        infoObj.text_size_section_subtitle,
        0,
        _rockName
      );
    }
  }

  // 기본 DXF 레이어(0) 설정
  dxf.setActiveLayer(0);

  // Default X, Y축
  dxf.drawLine(
    infoObj.x_min - infoObj.x_offset,
    infoObj.y_min,
    infoObj.x_max + infoObj.x_offset,
    infoObj.y_min
  ); // Y

  dxf.drawLine(
    infoObj.x_min - infoObj.x_offset,
    infoObj.y_min,
    infoObj.x_min - infoObj.x_offset,
    infoObj.y_max
  ); // X

  /**
   * 상단(종단면) 그래프 좌측(요약표) 범례 한영 명칭 대조 (다언어 대비)
   * 절토고: Cut
   * 성토고: Fill
   * 계획고: Plan
   * 지반고: Topo
   * 누가거리: Ext Dist
   * 점간거리: PT Dist1
   * 측점: ADD Pt
   */
  let textLabel = ["절토고", "성토고", "계획고", "지반고", "누가거리", "점간거리", "측점"];

  // 테이블 헤더 텍스트 최대 길이 구하기
  let labelMaxLenth = textLabel.reduce((accumulator, value) => {
    let len = getByteLengthOfUtf8String(value);
    if (accumulator < len) {
      accumulator = len;
    }
    return accumulator;
  }, []);

  infoObj.labelMaxLenth = labelMaxLenth;

  // 상단(종단면) 요약표 좌측
  for (let i = 0; i < textLabel.length; i++) {
    // 좌측 범례열 윤곽
    dxf.drawRect(
      infoObj.x_min -
        infoObj.x_offset -
        infoObj.labelMaxLenth * infoObj.text_size_table_th -
        infoObj.t_cel_padding,
      infoObj.y_min - i * infoObj.t_cel_height,
      infoObj.x_min - infoObj.x_offset,
      infoObj.y_min - (i * infoObj.t_cel_height + infoObj.t_cel_height)
    );

    // 상단(종단면) 요약표 좌측 범례 문자(textLabel)
    dxf.drawText(
      infoObj.x_min - infoObj.x_offset - infoObj.labelMaxLenth * infoObj.text_size_table_th,
      infoObj.y_min - (i * infoObj.t_cel_height + infoObj.t_cel_height / 2) - infoObj.t_cel_padding,
      infoObj.text_size_table_th,
      0,
      textLabel[i]
    );
  }

  // 상단(종단면) 요약표 윤곽
  dxf.drawRect(
    infoObj.x_min - infoObj.x_offset,
    infoObj.y_min,
    infoObj.x_max + infoObj.x_offset,
    infoObj.y_min - textLabel.length * infoObj.t_cel_height
  );

  // Y축 Label
  let lblYarr = [];
  lblYarr.push(infoObj.y_min); // 데이터 최소치 시작

  for (let i = infoObj.y_min + infoObj.y_axes_label_space; i < infoObj.y_max; i++) {
    if (Math.floor(i) % infoObj.y_axes_label_space === 0) lblYarr.push(Math.floor(i));
  }

  // 데이터 최대치 종단 추가
  if (lblYarr[lblYarr.length - 1] < infoObj.y_max) {
    lblYarr.push(infoObj.y_max);
  }

  // Y축 Label
  let maxYLabelCnt = lblYarr.length;
  for (let i = 0; i < maxYLabelCnt; i++) {
    let pt_y = lblYarr[i];

    dxf.drawText(
      infoObj.x_min -
        infoObj.x_offset -
        getByteLengthOfUtf8String(
          (maxYLabelCnt * infoObj.y_axes_label_space).toFixed(2).toString()
        ) *
          infoObj.text_size_y_axis,
      pt_y,
      infoObj.text_size_y_axis,
      0,
      lblYarr[i].toFixed(2).toString()
    );

    dxf.drawLine(
      infoObj.x_min - infoObj.x_offset,
      pt_y,
      infoObj.x_min -
        infoObj.x_offset -
        getByteLengthOfUtf8String(
          (maxYLabelCnt * infoObj.y_axes_label_space).toFixed(2).toString()
        ) *
          infoObj.text_size_y_axis,
      pt_y
    ); // Y Line
  }

  // 분석 정보선
  for (let i = 0; i < dataAnalArr.length; i++) {
    dxf.drawPolyline([
      [infoObj.x_min - infoObj.x_offset, infoObj.y_min - i * infoObj.t_cel_height],
      [infoObj.x_max + infoObj.x_offset, infoObj.y_min - i * infoObj.t_cel_height],
    ]);

    for (let j = 0; j < dataAnalArr[i].length; j++) {
      let pt_y = infoObj.y_min - i * infoObj.t_cel_height;

      // 그래프 가이드선
      if (i === 0) {
        let pt_y_g = dataAnalArr[0][j][1];

        // 계획고 보정
        pt_y_g = dataAnalArr[3][j][1];

        dxf.drawLine(dataAnalArr[i][j][0], infoObj.y_min, dataAnalArr[i][j][0], pt_y_g);
      }

      // 표 수준선
      dxf.drawLine(
        dataAnalArr[i][j][0],
        pt_y + infoObj.line_height_dash,
        dataAnalArr[i][j][0],
        pt_y - infoObj.line_height_dash
      );

      dxf.drawText(
        dataAnalArr[i][j][0],
        pt_y - getByteLengthOfUtf8String(dataAnalArr[i][j][1].toString()) * infoObj.text_size_dash,
        infoObj.text_size_dash,
        90,
        dataAnalArr[i][j][1].toString()
      );
    }
  }

  /* --------------------------  하단(횡단면) 그래프 영역 그리기 시작 -------------------------- */

  let _spacing = 0; // 스테이션 도표별 간격
  let _marginY = 30; // 스테이션 타이틀 높이보다 커야함

  // let stXposition = 0;
  let stXposition = infoObj.x_min - infoObj.x_offset;

  let distYMinMax = infoObj.y_max - infoObj.y_min;

  // 자료 ymin , 테이블 텍스트 갯수 * 텍스트 놓이, 종횡단 그래프 사이 마진
  // 스테이션 암층 그래프 기준점
  let stYPosition = infoObj.y_min - textLabel.length * infoObj.t_cel_height - _marginY;
  let elementYPosition = stYPosition;

  let arrChartDataLatAll = [];

  let elementYPositionMax = stYPosition;

  _transverse = _transverse < 100 ? 100 : _transverse;

  let baseLengthHalf = _transverse / 2;

  _sumTransverse += stXposition + _transverse + _spacing;

  // 스테이션별 도표
  for (let sti = 0; sti < _cntStation; sti++) {
    let stDataObj = data.t_base.find((v) => v.st_no === sti); //station index 는 +1

    // line parameter일 경우 _transverse = 0, baselength = 0으로 데이터 길이를 간격으로 사용함 [전종수 2020.09.23]
    if (data.t_summary.p_baselength === 0) {
      let rockDataObj = data.insec_rock_line.find((v) => v.st_no === sti);

      // 스테이션 없음
      if (!rockDataObj) continue;

      let _targetLine = rockDataObj.line_pt_arr.find((v) => v.gbn === -1); // XiteCAD에서 들어오는 데이터는 0 제외 드론선이 암선보다 짧을 때

      // Target 없음
      if (!_targetLine) continue;

      let lineptArr = _targetLine.line_pt_arr;

      _transverse = lineptArr[lineptArr.length - 1].ex_dist;

      baseLengthHalf = _transverse / 2.0;
    }

    // 최장 라인 적용
    if (data.org_topo && data.org_topo.length > 0) {
      let tmpX = [];

      const _B = data.org_topo[sti].line_pt_arr[0].line_pt_arr.map((item) => item.ex_dist);
      _B.map((item) => tmpX.push(item));

      const tmpX2 = Math.max(...tmpX);
      _transverse = Math.max(_transverse, tmpX2);
      baseLengthHalf = _transverse / 2;

      if (sti === 0) {
        _sumTransverse = stXposition + _transverse + _spacing;
      }
    }

    // 스테이션 제목
    elementYPosition = stYPosition + infoObj.text_size_section_title;

    let strTitle = stDataObj.c_station_name
      ? stDataObj.c_station_name
      : `ST.0+${Number(stDataObj.ex_dist).toFixed(0)}`;

    dxf.drawText(_sumTransverse, elementYPosition, infoObj.text_size_section_title, 0, strTitle);

    /* 암층선 */
    let arrChartDataLat = [];

    data.insec_rock_line
      .find((v) => v.st_no === sti)
      // eslint-disable-next-line no-loop-func, array-callback-return
      .line_pt_arr.map((item) => {
        let arrChartDataLatRock = item.line_pt_arr.map((_item) => {
          return [Number(_item.ex_dist).toFixed(2), Number(_item.z).toFixed(2)];
        });

        // (그래프 영점 이동)
        if (item.gbn === -1) {
          let _targetPtArr = data.target_line.filter((v) => v.st_no === sti);

          if (data.target_line_arr)
            _targetPtArr = data.target_line_arr.filter((v) => v.st_no === sti);

          if (_targetPtArr && _targetPtArr.length > 0) {
            let _tempTarget = _targetPtArr;

            // r_grp_index 로 끊어진 라인 그룹
            let _arrTarget = groupBy(_tempTarget[0].pt_arr, "r_grp_index");

            for (let grpItem in _arrTarget) {
              let arrLinePoints = [];
              _arrTarget[grpItem].map((item) => {
                arrLinePoints.push([item.st_x, item.st_z]);

                if (!data.target_line_arr) {
                  arrLinePoints.push([item.en_x, item.en_z]);
                }

                // eslint-disable-next-line array-callback-return
                return;
              });

              dxf.setActiveLayer("Target");
              dxf.drawPolyline(
                transXY(arrLinePoints, [
                  _sumTransverse,
                  -1 * (Math.abs(stYPosition) + infoObj.y_max),
                ])
              );
            }
          }
        } else if (item.gbn === 0) {
          dxf.setActiveLayer("Topo");
          dxf.drawPolyline(
            transXY(arrChartDataLatRock, [
              _sumTransverse,
              -1 * (Math.abs(stYPosition) + infoObj.y_max),
            ])
          );
        } else {
          // 스테이션 암층
          // 횡단 암층명
          let _rockName = data.rock_info.find((v) => v.gbn === item.gbn).f_rock_type_nm;
          // 각 암층별 DXF 레이어에 따라 설정

          dxf.setActiveLayer(_rockName);

          dxf.drawPolyline(
            transXY(arrChartDataLatRock, [
              _sumTransverse,
              -1 * (Math.abs(stYPosition) + infoObj.y_max),
            ])
          );

          let _trArr = transXY(arrChartDataLatRock, [
            _sumTransverse,
            -1 * (Math.abs(stYPosition) + infoObj.y_max),
          ]);

          dxf.drawText(
            _trArr[0][0],
            _trArr[0][1],
            infoObj.text_size_section_subtitle,
            0,
            _rockName
          );
        }

        arrChartDataLat.push(arrChartDataLatRock); //스테이션
      });

    arrChartDataLatAll.push(arrChartDataLat); //전체 스테이션

    // 최초 레이어 0으로 재설정
    dxf.setActiveLayer(0);

    // 기성 Lat 윤곽선
    dxf.drawRect(
      _sumTransverse,
      stYPosition,
      _sumTransverse + _transverse,
      stYPosition - distYMinMax
    );

    // Y Minimum
    elementYPosition = stYPosition - distYMinMax - infoObj.text_size_section_subtitle;

    // Label X Minimum
    dxf.drawText(
      _sumTransverse,
      elementYPosition,
      infoObj.text_size_section_axis,
      0,
      baseLengthHalf * -1
    );

    // Label X Maximum
    dxf.drawText(
      _sumTransverse + _transverse,
      elementYPosition,
      infoObj.text_size_section_axis,
      0,
      baseLengthHalf
    );

    // Label Y Minimum
    dxf.drawText(
      _sumTransverse - infoObj.text_size_section_axis * 5,
      elementYPosition + infoObj.text_size_section_axis,
      infoObj.text_size_section_axis,
      0,
      Number(infoObj.y_min).toFixed(2)
    );

    // Label Y Maximum
    dxf.drawText(
      _sumTransverse - infoObj.text_size_section_axis * 5,
      elementYPositionMax - infoObj.text_size_section_axis,
      infoObj.text_size_section_axis,
      0,
      Number(infoObj.y_max).toFixed(2)
    );

    // 기성 중심선을 위한 좌표 설정
    const centeredPoints = [
      [_sumTransverse + baseLengthHalf, stYPosition],
      [_sumTransverse + baseLengthHalf, stYPosition + -1 * (infoObj.y_max - infoObj.y_min)],
    ];

    for (let i = 0; i < centeredPoints.length - 1; i++) {
      // 기성 중심선
      dxf.drawLine(
        centeredPoints[i][0],
        centeredPoints[i][1],
        centeredPoints[i + 1][0],
        centeredPoints[i + 1][1]
      );
    }

    // 스테이션 부제목 (하단(횡단면) 스테이션별 그래프 아래 정보영역)
    elementYPosition =
      elementYPosition -
      infoObj.text_size_section_subtitle -
      infoObj.text_size_section_subtitle -
      infoObj.text_size_section_subtitle;

    let strSubTile = `F.H ${Number(data.lon_insec_pt.find((v) => v.st_no === sti)?.bottom_z).toFixed(2)}/ G.H:${Number(data.lon_insec_pt.find((v) => v.st_no === sti)?.top_z).toFixed(2)}`;

    dxf.drawText(
      _sumTransverse,
      elementYPosition,
      infoObj.text_size_section_subtitle,
      0,
      strSubTile
    );

    elementYPosition =
      elementYPosition - infoObj.text_size_section_subtitle - infoObj.text_size_section_subtitle;

    /* 횡단면 정보 시작 (횡단면 그래프 아래) */
    // 문자열 "절토"
    dxf.drawText(_sumTransverse, elementYPosition, infoObj.text_size_section_subtitle, 0, "절토");

    // 문자열 "성토"
    dxf.drawText(
      _sumTransverse + baseLengthHalf,
      elementYPosition,
      infoObj.text_size_section_subtitle,
      0,
      "성토"
    );

    elementYPosition =
      elementYPosition - infoObj.text_size_section_subtitle - infoObj.text_size_section_subtitle;

    let sumStArea = 0;
    // 절토
    for (let j = 0; j < rockName.length; j++) {
      // 암층명 ( 문자열 "절토"와 같은 X좌표가 되어야 함)
      dxf.drawText(
        _sumTransverse,
        elementYPosition -
          j * (infoObj.text_size_section_subtitle + infoObj.text_size_section_subtitle / 2),
        infoObj.text_size_section_subtitle,
        0,
        rockName[j]
      );
    }

    // Target 범위를 넘어가면 Bottom 정보가 없어 오류로 예외 처리
    try {
      let _tmp = data.c_info.find((v) => v.st_no === sti);
      if (_tmp) {
        // eslint-disable-next-line no-loop-func, array-callback-return
        _tmp.c_tin_geom.map((item) => {
          dxf.drawText(
            _sumTransverse + baseLengthHalf / 2,
            elementYPosition -
              item.gbn *
                (infoObj.text_size_section_subtitle + infoObj.text_size_section_subtitle / 2),
            infoObj.text_size_section_subtitle,
            0,
            Number(item.area).toFixed(2)
          );

          // 비다짐 Sum
          sumStArea += Number(item.area);

          /* ------------------------ */
          // 면적 폴리곤 그리기 추가 필요
          // if (item.pl_geom_c.type === "Polygon") {
          //   item.pl_geom_c.coordinates.map((item) => {});
          // } else if (item.pl_geom_c.type === "MultiPolygon") {
          //   item.pl_geom_c.coordinates.map((item) => {
          //     item.map((_item) => {});
          //   });
          // }
          /*--------------------------*/
        });
      }
    } catch (e) {}

    // 성토
    let fillName = ["노상", "노체", "비다짐"];
    let _fInfo = data.f_info ? data.f_info.find((v) => v.st_no === sti) : undefined;

    let _fillArea = 0;
    if (_fInfo) {
      _fillArea = _fInfo.f_tin_geom[0].area;
    }

    for (let j = 0; j < fillName.length; j++) {
      dxf.drawText(
        _sumTransverse + baseLengthHalf,
        elementYPosition -
          j * (infoObj.text_size_section_subtitle + infoObj.text_size_section_subtitle / 2),
        infoObj.text_size_section_subtitle,
        0,
        fillName[j]
      );

      if (j === 2) {
        dxf.drawText(
          _sumTransverse + baseLengthHalf + baseLengthHalf / 2,
          elementYPosition -
            j * (infoObj.text_size_section_subtitle + infoObj.text_size_section_subtitle / 2),
          infoObj.text_size_section_subtitle,
          0,
          Number(_fillArea).toFixed(2)
        );
      }
    }

    _sumTransverse += stXposition + _transverse * 2 + 10; //_spacing  ;
  }
  /* --------------------------  하단(횡단면) 그래프 영역 그리기 끝 -------------------------- */

  // To DXF String
  return dxf.toDxfString();
};

/**
 * 글꼴 조회
 */
const getDxfFontName = async () => {
  try {
    const { data } = await Axios.get("/getMiscellanies");

    const target = data.find((code) => code.code2 === "003");
    return target?.code_txt || "malgun.ttf";
  } catch (err) {
    console.error("DXF Utils - getDxfFontName Error : ", err);
  }
};

// Grouping
const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

// 좌표 역전
const transXY = (arrData, matrix) => {
  return arrData.map((_v, idx) => {
    return [Number(_v[0]) + matrix[0], Number(_v[1]) + matrix[1]];
  });
};

// 문자열 길이
const getByteLengthOfUtf8String = (_string) => {
  let b, i, c;

  if (_string !== undefined && _string !== "") {
    for (b = i = 0; (c = _string.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
    return b;
  } else {
    return 0;
  }
};
