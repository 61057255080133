import Axios from "axios";
import { store } from "../../../store/store";

export const initLineAttr = (t) => [
  {
    title: t("title$Horizontal_Length"),
    value: "00",
    key: 0,
  },
  {
    title: t("title$Surface_Length"),
    value: "00",
    key: 1,
  },
  {
    title: t("title$Elevation"),
    value: [t("format$Max"), t("format$Min")],
    key: 2,
  },
  {
    title: `${t(`title$Slope`)} (%)`,
    value: [t("format$Max"), t("format$Min")],
    key: 3,
  },
];

export function getAttribute() {
  return new Promise((resolve, reject) => {
    const props = store.getState();
    const measure = props.projectChildrenComponents.currentMeasure;
    const position = measure.measure.points.map((item, index) => {
      const { x, y, z } = item.position;
      return `${x} ${y} ${z}`;
    });

    const topo_data_sq = props.ViewDataSetReducer.projectViewDataSetModel.layer.topo.find(
      (x) => x.dataType && x.dataType === "pointcloud"
    ).dbObj.topo_data_sq;

    Axios.get("/api/PRJ020303_01", {
      params: {
        topo_data_sq,
        points: position.join(),
      },
    })
      .then(resolve)
      .catch(reject);
  });
}
