import Axios from "axios";
import plyWorker from "../../../workers/plyLoaderWorker";
import { setAddRequestList, setRemoveRequestList } from "../../../store/actions/app";
import { store } from "../../../store/store";

const THREE = window.THREE;
export const workerLoadPly = async (item, _color, workerObj) => {
  const filePath = `uploads/data_pcd/${item.project_mng_sq_t_project_mng}/${item.f_input_date}/${item.f_file_path}`;

  const checkFile = await Axios.get(`/fileCheck?filePath=${filePath}`);
  if (!checkFile.data) return checkFile;

  const requestId = `ply_loading_worker ${item.f_file_path}`;

  /**
   * add http list
   */
  store.dispatch(
    setAddRequestList({
      requestId: requestId,
    })
  );

  let _option = Object.assign({}, window.xitecloud.MeshStandardMaterial_options);
  _option.color = _color;
  const loader = new Worker(plyWorker);

  loader.addEventListener(
    "message",
    function(event) {
      var dataBuffer = event.data.buffer;
      var vertices = new Float32Array(dataBuffer);
      var buffer = new THREE.BufferAttribute(vertices, 3);

      var geometry = new THREE.BufferGeometry();
      geometry.addAttribute("position", buffer);
      geometry.computeVertexNormals();

      let material = new THREE.MeshStandardMaterial(_option);
      var child = new THREE.Mesh(geometry, material);
      child.castShadow = true;
      child.receiveShadow = true;
      child.geometry.verticesNeedUpdate = true;

      window.viewer.scene.scene.add(child);
      workerObj._3DObject = child;
      store.dispatch(
        setRemoveRequestList({
          requestId: requestId,
        })
      );

      loader.terminate();
    },
    false
  );

  loader.postMessage(`/${filePath}`);

  return workerObj;
};
