//actions
import {
  SUB_SURFACE,
  SUB_SURFACE_FILES,
  TARGET_TITLE,
  INCREMENT,
  SELECT_BORE_HOLE,
  ALL_SUB_SURFACE,
  ROCK_TYPES,
  TOPOGRAPHIC_FILE_NAME,
  TOPOGRAPHIC_TITLE
} from '../actions/uploadModel';

/*
dataInterface = {
  key: '1',
  boreholeName: 'borehole-1',
  x: '100',
  y: '100',
  z: '100',
  editable: false,
  soilTypes: [
        {
          key: '1',
          color: {
            colorName: '#333',
            key: '0'
          },
          rockTypes: {
            rockName: 'rock-1',
            key: '0'
          },
          verticalLength: '15',
          editable: false,
        }
  ]
}
*/

const initializeModelUploadState = {
  //sub surface data
  selectedRow: null,
  data: [],
  count: 2,
  fileNames: {
    topographic: '',
    targetDesign: '',
    overlay: ''
  },
  topographicFiles: {
    pointCloud: {
      titleName: '',
      fileName: '',
    },
    orthophoto: {
      titleName: '',
      fileName: '',
    }
  },
  targetTitle: ''
}

export default function initializeModelUploadReducer(state = initializeModelUploadState, action) {
  switch (action.type) {
    case ROCK_TYPES: return { ...state, selectedRow: action.data };
    case SUB_SURFACE: return { ...state, data: action.data };
    case ALL_SUB_SURFACE: return { ...state, selectedRow: action.selected, data: action.data };
    case SELECT_BORE_HOLE: return { ...state, selectedRow: action.object }
    case SUB_SURFACE_FILES:
      const { fileNames } = state;
      fileNames[action.key] = action.name;
      return { ...state, fileNames };

    case TOPOGRAPHIC_FILE_NAME:
      state.topographicFiles[action.key].fileName = action.name;
      return { ...state, topographicFiles: state.topographicFiles };

    case TOPOGRAPHIC_TITLE:
      state.topographicFiles[action.key].titleName = action.titleName;
      return { ...state, topographicFiles: state.topographicFiles };

    case TARGET_TITLE:
      return { ...state, targetTitle: action.name };

    case INCREMENT:
      return { ...state, count: state.count + 1 };

    default: return state;
  }
}