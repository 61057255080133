import Drawing from "dxf-writer";
import Handle from "dxf-writer/src/Handle";

/**
 * dxf-writer 재구성 및 초기화 (레이어 0, 색상, 글꼴 설정)
 * @param {*} {color: 색상, font: 글꼴} _style 기본 레이어(0번) 요소 색상, 글꼴 정보 JSON 객체
 * @returns Drawing 객체
 */
export const cumtomDxfWriter = (_style) => {
  // Handler Seed Value 강제 초기화 (초기화 하지 않은 채 연속 생성 시, AutoCAD에서 요소 복사 불가)
  Handle.seed = 0;

  /**
   * AutoCAD Color Index 재설정 (dxf-writer 미정의 색상 정의)
   * 20240729 회색(Gray, 8) 및 연회색(Light Gray, 9) 추가
   */
  Drawing.ACI = { ...Drawing.ACI, GRAY: 8, LIGHTGRAY: 9 };

  const styleInfo = getStyleInfo(_style);

  let dxfWriterObject = new Drawing();

  dxfWriterObject.header("ACADVER", [[1, "AC1032"]]); // AutoCAD 버전 (2018 이후)
  dxfWriterObject.header("LASTSAVEDBY", [[1, "XiteCloud"]]); // 작성자
  dxfWriterObject.setUnits("Meters").addLayer(0, styleInfo.color, "CONTINUOUS"); // 기본 단위 미터법, 기본 레이어 0에 선분 스타일 연속선

  dxfWriterObject.tables.STYLE.elements[0].fontFileName = styleInfo.font;

  return dxfWriterObject;
};

/**
 * 글꼴, 색상 지정
 * @param {*} _color 색상
 * @param {*} _font 글꼴
 * @returns {color: 색상, font: 글꼴} JSON 객체
 */
const getStyleInfo = (_style) => {
  if (!_style) _style = { color: "", font: "" };
  if (!_style.font) _style.font = "gulim";
  _style.color = convertDxfColorCode(_style.color);

  return {
    color: _style.color,
    font: _style.font,
  };
};

/**
 * AutoCAD 색상 코드 (AutoCAD Color Index) - 기본 황색(Yellow, 2)
 * (dxf-writer에 정의된 코드: Red: 1, Yellow: 2, Green: 3, Cyan: 4, Blue: 5, Magenta: 6, White: 7, Gray: 8, Light Gray: 9)
 * @param {*} color 문자열 또는 정수
 * @returns ACI 코드
 */
export const convertDxfColorCode = (color) => {
  switch (color) {
    case "red":
    case 1:
    case "1":
      color = Drawing.ACI.RED;
      break;
    case "yellow":
    case 2:
    case "2":
      color = Drawing.ACI.YELLOW;
      break;
    case "green":
    case 3:
    case "3":
      color = Drawing.ACI.GREEN;
      break;
    case "cyan":
    case 4:
    case "4":
      color = Drawing.ACI.CYAN;
      break;
    case "blue":
    case 5:
    case "5":
      color = Drawing.ACI.BLUE;
      break;
    case "magenta":
    case 6:
    case "6":
      color = Drawing.ACI.MAGENTA;
      break;
    case "white":
    case 7:
    case "7":
      color = Drawing.ACI.WHITE;
      break;
    case "gray":
    case 8:
    case "8":
      color = Drawing.ACI.GRAY;
      break;
    case "lightgray":
    case 9:
    case "9":
      color = Drawing.ACI.LIGHTGRAY;
      break;
    default:
      color = Drawing.ACI.YELLOW;
      break;
  }

  return color;
};
