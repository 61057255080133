import React, { useCallback, useEffect } from "react";
import { Button, Select, Tooltip } from "antd";
import Axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ProjectContent from "../children/ProjectContent";
import CurrentProjectChildren from "../children/CurrentProjectChildren";
import {
  setAddRequestList,
  setIsShow,
  setRemoveRequestList,
  setView,
} from "../../../store/actions/app";
import { consoleError } from "../../../middleware";
import {
  getLayerDateInfo,
  getProjectInformation,
  loadAnnotationAll,
  useInterval,
  getUpdateLayerDateInfo,
} from "../utils/project";
import { fnFlyRockLayer } from "../../../xitecloud3D/xiteCloud.dev";
import {
  setCurrentProject,
  setProjectDateSet,
  setProjectSelectedDateSet,
} from "../../../store/actions/currentProject";

import Chat from "../../../components/blocks/drawers/chat";
import EartthWorkProgress from "../../../components/modals/EartthWorkProgress/EartthWorkProgress";
import { setLayerTitle, setModelLayerDate } from "../../../store/actions/modelLayer";
import Tools from "../../../components/blocks/tools";
import IconShare from "../../../assets/icons/share.svg";
import BackButton from "../../../components/elements/button/BackButton";
import HideDashboard from "../../../components/blocks/hideDashboard";
import { calculateDestinationPoint3D } from "utils/CesiumUtils";
import { LAYER_DATE_INFO_INTERVAL_SECONDS } from "../../../config/timeIntervalInfo";

import "./project.less";

const { Option } = Select;

export const initMap = (coordSystem, latLng) => {
  if (!coordSystem || !latLng) return;

  const wgs84 = new window.proj4("WGS84");
  const local = new window.proj4(coordSystem);

  const [lat, lng] = latLng.split(",");
  const positionEntity = window.proj4(wgs84, local, [lng, lat, 0]);

  window.toMap = window.proj4(local, wgs84);

  const distance = 3000;
  const target = calculateDestinationPoint3D(
    positionEntity[0],
    positionEntity[1],
    0,
    -window.viewer.scene.view.direction.x,
    -window.viewer.scene.view.direction.y,
    -window.viewer.scene.view.direction.z,
    distance
  );
  const targetPosition = new window.THREE.Vector3(target.x, target.y, target.z);

  window.viewer.scene.view.position.copy(targetPosition);
  window.viewer.scene.view.radius = distance * 0.9;
  window.viewer.render();
};

/**
 * @component
 *    - ProjectUI
 * @description
 *    - React Hooks API
 *    - filtering Tools Objects template ['Measure', 'Pointcloud', 'Overlay']
 *    - selected date propertis ['layer', 'annotation']
 */
function ProjectUI(props) {
  const dispatch = useDispatch();

  const {
    projectTitle,
    projectDescription,
    projectSelectedDate,
    projectDateList,
    projectViewDataSetModel,
  } = useSelector(
    (state) => ({
      projectTitle: state.currentProjectReducer.currentProject.f_proj_nm,
      projectDescription: state.currentProjectReducer.currentProject.f_description,
      projectSelectedDate: state.currentProjectReducer.selectedDate,
      projectDateList: state.currentProjectReducer.dateSet,
      projectViewDataSetModel: state.ViewDataSetReducer.projectViewDataSetModel,
    }),
    shallowEqual
  );

  const { t } = useTranslation();

  const handleDateChange = (selectedDate) => {
    if (projectSelectedDate !== selectedDate) {
      const selectedDateObject = projectDateList.find((x) => x.f_input_date === selectedDate);

      if (!selectedDateObject) return;
      if (selectedDateObject.run_flag !== 3) return;

      dispatch(
        setAddRequestList({
          requestId: "getProjectAll",
        })
      );

      getProjectInformation(selectedDateObject.f_input_date, props.match.params.projectId)
        .then(() => {
          fnFlyRockLayer(true, projectViewDataSetModel, true);
          removeRequestListForLoading();
        })
        .catch((error) => consoleError(error));
    }
  };

  const removeRequestListForLoading = useCallback(() => {
    dispatch(
      setRemoveRequestList({
        requestId: "getProjectAll",
      })
    );

    dispatch(setIsShow(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // data upload 모달의 타이틀 날짜 바인딩 로직
  useEffect(() => {
    const selectedDateObject = projectDateList.find((x) => x.f_input_date === projectSelectedDate);
    if (!selectedDateObject) return;
    if (selectedDateObject.run_flag !== 3) return;

    dispatch(setLayerTitle(selectedDateObject.f_title));
    dispatch(setModelLayerDate(selectedDateObject.f_input_date));
  }, [projectSelectedDate]);

  useEffect(() => {
    (async () => {
      dispatch(setView("projects"));

      dispatch(
        setAddRequestList({
          requestId: "getProjectAll",
        })
      );
      if (projectSelectedDate === "") {
        /**
         * current project
         */
        const { data } = await Axios.get(`/projectList/${props.match.params.projectId}`);
        dispatch(setCurrentProject(data));

        /**
         * date list of project
         */
        const initProjectDateList = await getLayerDateInfo(props.match.params.projectId);

        if (initProjectDateList.length < 1) return removeRequestListForLoading();

        initMap(data.f_coord_system, data.f_latlng);
        dispatch(setProjectDateSet(initProjectDateList));
        if (initProjectDateList.length === 1 && initProjectDateList[0].run_flag !== 3) {
          dispatch(setProjectSelectedDateSet(initProjectDateList[0].f_input_date));
          return removeRequestListForLoading();
        }

        const inputDate =
          initProjectDateList.length > 1 && initProjectDateList[0].run_flag !== 3
            ? initProjectDateList[1].f_input_date
            : initProjectDateList[0].f_input_date;

        // console.log("ProjectUI 초기 렌더 실행", projectSelectedDate);
        return getProjectInformation(inputDate, props.match.params.projectId)
          .then(removeRequestListForLoading)
          .catch((error) => {
            consoleError(error);
            if (error.toString().indexOf("status code 403") !== -1) {
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            }
          });
      } else {
        window.viewer.scene.annotations.removeAllChildren();
        loadAnnotationAll();
        window.viewer.setEDLOpacity(1);
        return removeRequestListForLoading();
      }
    })().catch(consoleError);

    return () => {
      // console.log(`ProjectUI out`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    projectDateList.findIndex((x) => x.run_flag !== 3) !== -1 && updateLayerDateInfo(props);
  }, LAYER_DATE_INFO_INTERVAL_SECONDS * 1000);

  // 프로젝트 일자 데이터의 진행률 표시 업데이트
  const updateLayerDateInfo = async (props) => {
    const updatedProjectDateList = await getUpdateLayerDateInfo(props.match.params.projectId);
    if (updatedProjectDateList.length > 0) {
      dispatch(setProjectDateSet(updatedProjectDateList));
      return removeRequestListForLoading();
    }
  };

  /**
   *  프로젝트 일자 진행률 표시 문자열 구성
   *  셀렉트 박스: 레이어명(20221231) (Progress: XX%)
   *  툴팁: Preprocessing in progress (Progress: XX%)
   */
  const progressString = (description) => {
    let resultString = t("message$Preprocessing_in_progress"); // 전처리 진행중
    if (description) {
      if (!isNaN(parseFloat(description))) {
        // t_pg_notify_job_log의 description 컬럼에 숫자만 입력된 경우
        resultString += `(${description}%)`; // 진행률: XX%
      } else if (description.includes("PER_")) {
        // t_pg_notify_job_log의 description 컬럼에 특정 문자열("PER_") 포함하여 입력된 경우
        resultString += `(${description?.split("PER_")[1]}%)`; // 진행률: XX%
      }
    }
    return resultString;
  };

  const goRoot = () => props.history.push("/");
  return (
    <>
      <CurrentProjectChildren />
      <section className="project">
        <article className="project__top">
          <BackButton
            className="project__top__back"
            style={{ background: "unset" }}
            onClick={goRoot}
          />
          <Tooltip title={projectTitle}>
            <h4>{projectTitle}</h4>
          </Tooltip>

          <HideDashboard />
          <h5>{projectDescription}</h5>
          <div className="project__top__description acumin">
            <Select
              showSearch
              value={projectSelectedDate}
              placeholder={t("placeholder$Select_a_date")}
              optionFilterProp="children"
              onChange={handleDateChange}
            >
              {projectDateList.map((date, index) => (
                <Option value={date.f_input_date} key={index}>
                  <Tooltip title={date.run_flag !== 3 && `${progressString(date.f_description)}`}>
                    {date.f_title} ({date.f_input_date}){" "}
                    {date.run_flag !== 3 && t("message$Preprocessing_in_progress")}
                  </Tooltip>
                </Option>
              ))}
            </Select>
          </div>
        </article>
        <ProjectContent history={props.history} location={props.location} match={props.match} />
        {/* 2023-10-23 숨김처리 */}
        {/* <EartthWorkProgress /> */}
        <Tools />
        <Chat history={props.history} location={props.location} match={props.match} />
      </section>
    </>
  );
}

export default React.memo(ProjectUI);
