import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import { consoleError } from "middleware";

import DefaultButton from "../../components/elements/button/DefaultButton";
import { setView } from "../../store/actions/app";
import { configReportAndFolder } from "./utils/report";
import DocHeader from "./children/DocHeader";
import DocContents from "./children/DocContents";
import { isDevProjectLoad } from "../project/utils/isDevProjectLoad";
import {
  setCurrentProject,
  setProjectDateSet,
  setProjectSelectedDateSet,
} from "store/actions/currentProject";
import {
  getLayerDateInfo,
  getProjectInformation,
  loadAnnotationAll,
} from "views/project/utils/project";
import { initMap } from "views/project/template";
import { RemoveAllEntity, fnChangeTerrainMode } from "xitecloud3D/xiteCloud.dev";
import { setPublishInit } from "store/actions/report";
import "./document.less";

/**
 * @component
 *    - Documents
 * @description
 *    - React Hooks API
 *    - Drag and Drop
 *    - Project search
 */
const Documents = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectSq, projectSelectedDate } = useSelector((state) => ({
    projectSq: state.currentProjectReducer.currentProject.project_mng_sq,
    projectSelectedDate: state.currentProjectReducer.selectedDate,
  }));
  const { projectId } = useParams();

  const handleNewClick = () => {
    dispatch(setView("projects"));
    dispatch(setPublishInit());
  };

  const pid = projectSq || projectId;
  const initMapCallback = useCallback(async () => {
    if (!!!projectSq) {
      dispatch(setView("projects"));
      if (!projectSelectedDate) {
        try {
          const { data } = await Axios.get(`/projectList/${pid}`);
          dispatch(setCurrentProject(data));

          const initProjectDateList = await getLayerDateInfo(pid);

          initMap(data.f_coord_system, data.f_latlng);
          dispatch(setProjectDateSet(initProjectDateList));
          if (initProjectDateList.length === 1 && initProjectDateList[0].run_flag !== 3) {
            return dispatch(setProjectSelectedDateSet(initProjectDateList[0].f_input_date));
          }

          const inputDate =
            initProjectDateList.length > 1 && initProjectDateList[0].run_flag !== 3
              ? initProjectDateList[1].f_input_date
              : initProjectDateList[0].f_input_date;

          await getProjectInformation(inputDate, pid);
        } catch (error) {
          consoleError(error);

          if (error.toString().indexOf("status code 403") !== -1) {
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          }
        }
      } else {
        window.viewer.scene.annotations.removeAllChildren();
        loadAnnotationAll();
      }
    }
    configReportAndFolder();

    fnChangeTerrainMode(false); // 2D Terrain
    window.viewer.setEDLOpacity(1);

    isDevProjectLoad(pid, props.history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initMapCallback();

    return () => {
      setTimeout(() => {
        RemoveAllEntity();
        window.viewer.scene.removeAllMeasurements();
      }, 1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initMapCallback]);

  return (
    <section className="document">
      <DocHeader history={props.history} />
      <DocContents {...props} projectId={pid} />
      <article className="document__bottom">
        <Link to={`/projects/documents/create/${projectSq}`} onClick={handleNewClick}>
          <DefaultButton block className="font-sofia">
            {t("button$New_Report")}
          </DefaultButton>
        </Link>
      </article>
    </section>
  );
};

export default React.memo(Documents);
