import {
  fnLoadGeomAreaResult,
  fnLoadGeomVolumeResult,
  fnLoadGeomVolumeBGResult,
  makeSvrResource,
  loadContourGeoJSON,
} from "../../../../xitecloud3D/xiteCloud.dev";
import { store } from "../../../../store/store";
import Axios from "axios";
import { setCurrentModel } from "../../../../store/actions/currentModel";
import {
  visibleContour,
  visiblePointCloud,
  currentMeasure,
} from "../../../../store/actions/projectChildrenComponent";
import { loadOverlay } from "../../../../views/project/utils/loadOverlays";

/**
 * @param {:boolean} check
 * @param {:object} nodeitem -> datasetAnnotationObj
 * @description
 *    visible false to nodeitem inside for 3d objects
 * @type nodeitem has three type 3d object
 *      - 1. Measurement
 *      - 2. Three.Object
 *      - 3. Annotation
 */
export async function visibleHandleOnChange(visible, nodeItem) {
  const propCheck = visible;
  const objectVisibleHandle = (object) => {
    if (object) object.visible = propCheck;
  };

  if (nodeItem.gbn === "Layers") {
    if (nodeItem.isOverlay && nodeItem.visible === true && nodeItem.isLoaded === false) {
      const loadedOverlay = await loadOverlay(nodeItem.originalData, {});
      nodeItem._3DObject = loadedOverlay._3DObject;

      nodeItem.isLoaded = true;
    }

    if (nodeItem.isContour && nodeItem.visible === true && nodeItem.isLoaded === false) {
      const dataViewObj = await loadContourGeoJSON(
        nodeItem.dbObj,
        makeSvrResource("CONTOUR", nodeItem.dbObj)
      );
      nodeItem.planet = dataViewObj.planet;
      nodeItem._3DObject = dataViewObj._3DObject;
      nodeItem.indexTerm = dataViewObj.indexTerm;
      nodeItem.indexColor = dataViewObj.indexColor;
      nodeItem.indexType = dataViewObj.indexType;
      nodeItem.indexThickness = dataViewObj.indexThickness;
      nodeItem.interTerm = dataViewObj.interTerm;
      nodeItem.interColor = dataViewObj.interColor;
      nodeItem.interThickness = dataViewObj.interThickness;
      nodeItem.interType = dataViewObj.interType;
      nodeItem.changeTerm = dataViewObj.changeTerm;

      nodeItem.isLoaded = true;
    }
  }
  /**
   * Layers & Annotation analysis 3D object (2)
   */

  if (nodeItem.titleAnnotation) nodeItem.titleAnnotation.visible = propCheck; //전종수 [2021.02.25]
  if (nodeItem._3DObject && Object.keys(nodeItem._3DObject).length)
    objectVisibleHandle(nodeItem._3DObject);
  if (nodeItem.measure) objectVisibleHandle(nodeItem.measure);
  /**
   * Annotations
   *    - Measurement
   *    - Annotation
   */
  if (nodeItem.gbn === "Annotations") {
    objectVisibleHandle(nodeItem.measure);

    //volume calc result annotations
    if (nodeItem.potreeAnnotation && nodeItem.potreeAnnotation.length)
      nodeItem.potreeAnnotation.map(objectVisibleHandle);

    //has result annotation
    if (visible && !nodeItem.toolObj.modelDraw && nodeItem.resultStatus === 2) {
      const { data } = await Axios.get("/getAnnotationArea", {
        params: {
          annotation_mng_sq: nodeItem.annotation_mng_sq,
        },
      });

      const annotationTarget = store
        .getState()
        .ViewDataSetReducer.projectViewDataSetModel.annotations[nodeItem.annotationGroup].find(
          (x) => x.annotation_mng_sq === nodeItem.annotation_mng_sq
        );

      const result = data[0].f_result;
      if (result) {
        /**
         * 리턴되는 결과의 데이터 셋에 따른 조건문
         */
        if (nodeItem.toolObj.value === "l2") {
          annotationTarget.f_result = result;
          store.dispatch(currentMeasure(annotationTarget));
        } else {
          /**
           * volume(a2) -> data[0].f_result.data
           * polygon(a1) -> data[0].f_result
           */
          annotationTarget.f_result = result.data ? result.data : result;

          if (nodeItem.toolObj.value === "a1") fnLoadGeomAreaResult(data[0], nodeItem);
          if (nodeItem.toolObj.value === "a2") {
            if (nodeItem.f_param.type === "VOLUME_BG") {
              fnLoadGeomVolumeBGResult(data[0], nodeItem);
            } else {
              fnLoadGeomVolumeResult(data[0], nodeItem);
            }
          }
        }

        nodeItem.toolObj.modelDraw = true;
      }
    }
  }

  /**
   * etc
   */
  if (typeof nodeItem._3DObject instanceof Array) {
    nodeItem._3DObject.map(objectVisibleHandle(nodeItem._3DObject));
  }
}

export function initializeTopo() {
  store.dispatch(setCurrentModel(null));
  store.dispatch(visibleContour(false));
  store.dispatch(visiblePointCloud(false));
}
