import { FlatVolume } from "../../../xitecloud3D/Volume";
import DxfParser from "dxf-parser";
import { ConvertDXFToThree } from "../../../xitecloud3D/three/ConvertDXFToThree";
import Axios from "axios";
import { visibleHandle } from "../../overlays/util/_3dVisible";
import { store } from "../../../store/store";
import { setAddRequestList, setIsShow, setRemoveRequestList } from "../../../store/actions/app";
import { loadWMSbyPath, loadWMSbyGIS } from "../../../xitecloud3D/xiteCloud.dev";
const THREE = window.THREE;

export async function loadOverlay(overlayItem, returnObject) {
  const tifPath = `/uploads/data_pcd/${overlayItem.project_mng_sq_t_project_mng}/overlays/overay_wms_${overlayItem.overlay_data_sq}`;
  const checkFilePath = ["tif", "dxfwms", 'GIS'].includes(overlayItem.f_file_type)
    ? `${tifPath}/tilemapresource.xml`
    : `/fileCheck?filePath=${overlayItem.f_file_path}`;

  /**
   * @description
   *      - 파일이 있는지 확인을 위해 다른 response type으로 요청.
   *      - arraybuffer를 타입으로 지정 시 각 에러 코드를 arraybuffer로 변환하여 statusCode로 응답이 오기 때문에 실 파일과 구분 불가.
   *      - 단순 동기 식으로 요청 시 한 요청이 끝난 후 다음 요청을 하기에 ajax 병렬화
   */
  const checkFile = await Axios.get(checkFilePath);

  if (checkFile) {
    /**
     * @description
     *      - 기존 파일 받아와 array buffer로 변환 후 파일 넘기는 로직 전부 변경. # 2020.09.01
     *      - 각 파일 타입 별 개별 로딩.
     *      - 단순 동기 식으로 요청 시 한 요청이 끝난 후 다음 요청을 하기에 ajax 병렬화
     */

    /**
     * loading start
     */
    store.dispatch(
      setAddRequestList({
        requestId: "overlayObjectLoad",
      })
    );

    if (["tif", "dxfwms"].includes(overlayItem.f_file_type)) {
      returnObject._3DObject = await loadWMSbyPath(tifPath);
    } else if ( 'GIS' == overlayItem.f_file_type) {
      returnObject._3DObject = await loadWMSbyGIS(overlayItem.f_file_path)
    } else {
      returnObject._3DObject = await {
        image: fnLoadAjaxImgForOverlay,
        ply: fnLoadAjaxPLYforOveray,
        dxf: fnLoadAjaxDXF,
      }[overlayItem.f_file_type](overlayItem.f_file_path, overlayItem.f_overlay_xy);

      returnObject._3DObject.overayType = overlayItem.f_file_type;

      visibleHandle(returnObject._3DObject, false);

      returnObject._3DObject.addEventListener("select", (e) => {
        if (
          store.getState().OverlayReducer.workspace &&
          store.getState().OverlayReducer._3dObject &&
          store.getState().OverlayReducer._3dObject.uuid === e.target.uuid
        ) {
          return;
        }

        return window.viewer.inputHandler.deselect(e.target);
      });
    }

    returnObject.visible = true;
  }

  /**
   * loading end
   */
  store.dispatch(
    setRemoveRequestList({
      requestId: "overlayObjectLoad",
    })
  );
  store.dispatch(setIsShow(false));

  return returnObject;
}

export const fnLoadAjaxImgForOverlay = (filePath, option) => {
  return new Promise((resolve, reject) => {
    var loader = new THREE.ImageLoader();

    //load a text file and output the result to the console
    loader.load(`/${filePath}`, (image) => {
      if (!option) {
        let pTarget = window.viewer.scene.view.getPivot();
        let wp = window.viewer.scene.pointclouds[0]
          .getWorldPosition()
          .applyMatrix4(window.viewer.scene.getActiveCamera().matrixWorldInverse);
        let w = Math.abs(wp.z / 5);

        option.position = new THREE.Vector3(pTarget.x, pTarget.y, pTarget.z);
        option.rotation = new THREE.Vector3(0, 0, 0); //x:0,y:0,z:0
        option.scale = new THREE.Vector3(w, w, w);
      }

      // VOLUME visible
      let volume = new FlatVolume({ type: FlatVolume, image: image, showVolumeLabel: false });
      volume.name = option.name;
      volume.clip = false;

      if (option.rotation.x !== 0) volume.rotateX(option.rotation.x);
      if (option.rotation.y !== 0) volume.rotateY(option.rotation.y);
      if (option.rotation.z !== 0) volume.rotateZ(option.rotation.z);

      volume.scale.set(option.scale.x, option.scale.y, option.scale.z);
      volume.position.set(option.position.x, option.position.y, option.position.z);

      window.viewer.scene.addVolume(volume);
      return resolve(volume);
    });
  });
};

export const fnLoadAjaxPLYforOveray = function(filePath) {
  return new Promise((resolve, reject) => {
    let LoaddingManager = new THREE.LoadingManager();
    let loader = new THREE.PLYLoader(LoaddingManager);

    loader.load(
      `/${filePath}`,
      (geometry) => {
        geometry.computeVertexNormals();

        let _option = Object.assign({}, window.xitecloud.MeshStandardMaterial_options);
        _option.color = undefined;
        _option.vertexColors = THREE.VertexColors;

        var material = new THREE.MeshStandardMaterial(_option);
        let plyGeom = new THREE.Mesh(geometry, material);
        // plyGeom.name = name;

        window.viewer.scene.scene.add(plyGeom);
        return resolve(plyGeom);
      },
      //progress
      () => {},
      //error
      (err) => {
        console.error(err);
        return reject(err);
      }
    );
  });
};

export const fnLoadAjaxDXF = function(filePath) {
  return new Promise((resolve, reject) => {
    var loader = new THREE.FileLoader();

    //load a text file and output the result to the console
    loader.load(
      // resource URL
      `/${filePath}`,
      (dxfFile) => {
        let parser = new DxfParser();
        let data = parser.parseSync(dxfFile);
        let con = new ConvertDXFToThree(data);

        con.convert();
        window.viewer.scene.scene.add(con.rtnGeometry);
        return resolve(con.rtnGeometry);
      },
      //progress
      () => {},
      //error
      (err) => {
        console.error(err);
        return reject(err);
      }
    );
  });
};
