import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import IconPlus from "../../../assets/icons/plus.svg";
import ContentTitle from "../../../components/elements/text/contentTitle";
import Axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import AnnotationImages from "../../../components/modals/AnnotationImages/AnnotationImages";
import { createAnnotation } from "../util/fnCreateAnnotaion";
import { consoleError } from "../../../middleware";
import { setAnnotations } from "../../../store/actions/viewDataSet";
import WorkspaceTitle from "../children/WorkspaceTitle";
import AnnotationFooter from "../children/AnnotationFooter";
import {
  setPreviewerList,
  setPreviewerVisible,
  setPreviewerSelect,
} from "../../../store/actions/previewImage";
import MeasurementVisualization from "../children/MeasurementVisualization";
import { savedThenMergeAndInit, saveAnnotation } from "../util/save";
import {
  setHandleVisible,
  setHandleTargetName,
  setHandleInit,
} from "../../../store/actions/saveHandle";
import { titleAnnotationVisible, toolsChange } from "../util/initialize";
import { CoordinateForm } from "../children/CoordinateForm";
import "./index.less";

const MeasurementAnnotation = (props) => {
  const { t } = useTranslation();
  const measure = props.projectChildrenComponents.currentMeasure;

  useEffect(() => {
    (async () => {
      titleAnnotationVisible(false);

      if (measure.annotation_mng_sq) {
        const { data } = await Axios.get("/annotationFiles", {
          params: {
            annotation_mng_sq: measure.annotation_mng_sq,
          },
        });

        props.setPreviewerList(data);
      }
    })();

    return () => {
      titleAnnotationVisible(true);
      props.setPreviewerList([]);
      props.setHandleInit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure]);

  const [description, setDescription] = useState(measure.description);
  const onDescriptionChange = (e) => setDescription(e.target.value);

  const [uploadImages, setUploadImages] = useState([]);
  const fileUpload = ({ data }) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      uploadImages.forEach((file) => {
        formData.append("files[]", file.originFileObj);
      });

      formData.append("annotationKey", data[0].annotation_mng_sq);
      formData.append("projectId", props.currentProjectReducer.currentProject.project_mng_sq);
      formData.append("userId", props.appState.user.user_mng_sq);

      Axios.post("/createAnnotationFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(resolve)
        .catch(reject);
    });
  };

  const onSave = () => {
    measure.measure._cancel();

    const dbParam = createAnnotation();

    // dbParam.f_fence_nm = measure.title;
    dbParam.f_description = description;

    // dbParam.f_line_color = selectedColor;
    //insert or update here
    saveAnnotation(dbParam)
      .then(({ data }) => {
        measure.annotation_mng_sq = data[0].annotation_mng_sq;

        if (uploadImages.length === 0) {
          return savedThenMergeAndInit();
        }

        return fileUpload({ data }).then(({ data }) => savedThenMergeAndInit());
      })
      .catch((err) => consoleError(err));
  };

  return (
    <div className="measurement">
      <WorkspaceTitle wrapperName="measurement" />
      <section className="measurement__content">
        <article className="measurement__content__part">
          <ContentTitle title={t("title$Analysis")} style={{ color: "#182e63" }} />
          <CoordinateForm type={"point"} points={[measure.measure.points[0]]} />
          <div className="pt-30">
            <p className="measurement__content-title">{t("title$Description")}</p>
            <TextArea
              style={{
                resize: "none",
              }}
              className="annotation-description"
              onChange={onDescriptionChange}
              value={description}
              placeholder=""
              autoSize={{ minRows: 3, maxRows: 3 }}
            />
          </div>
        </article>
        <article className="measurement__content__part">
          <p
            style={{
              paddingBottom: "15px",
            }}
          >
            {t("title$Upload_Images (%{length})", {
              length: props.PreviewerReducer.fileList.length + uploadImages.length,
            })}
          </p>
          <div className="annotation-upload-image-wrap">
            <div className="annotation-uploaded-wrapper">
              {props.PreviewerReducer.fileList.map((image, index) => (
                <p
                  className="annotation-upload-img-item"
                  key={image.file_mng_sq}
                  style={{
                    backgroundImage: `url("/${image.f_file_path}")`,
                  }}
                >
                  <span className="layer-zoom" onClick={() => props.setPreviewerSelect(index)}>
                    {/* 돋보기 아이콘으로 변경 */}
                    <img src={IconPlus} alt="plus icon" />
                  </span>
                </p>
              ))}
            </div>
            <div className="annotation-upload-wrapper">
              <AnnotationImages fileList={uploadImages} setFileList={setUploadImages} />
            </div>
          </div>
        </article>
        <MeasurementVisualization type="Point" />
      </section>
      <AnnotationFooter onClick={onSave} className="measurement_footer" />
    </div>
  );
};

// export default Measurement;
export default connect((state) => state, {
  setAnnotations,
  setPreviewerList,
  setPreviewerVisible,
  setPreviewerSelect,
  setHandleVisible,
  setHandleTargetName,
  setHandleInit,
  // setData,
})(MeasurementAnnotation);
