import React from "react";
import { Input, Radio } from "antd";
// const attributes = [
//   {
//     placeholder: "Lowest Point",
//     key: 1,
//   },
//   {
//     placeholder: "Highest Point",
//     key: 2,
//   },
//   {
//     placeholder: "Linear Fit",
//     key: 3,
//   },
//   {
//     placeholder: "Custom Altitude",
//     key: 4,
//   },
// ];

const PolygonVolumeSetAttr = (props) => {
  const radioStyle = {
    display: "block",
    margin: "15px 0",
    fontSize: "12px",
    height: "25px",
    lineHeight: "25px",
  };

  const onChange = (e, key) => {
    const tmp = props.attributes.map(x => {
      if (x.key === key) x.value = e.target.value;
      return x;
    })
    props.setAttributes(tmp)
  }

  return (
    <div>
      <Radio.Group
        onChange={(e) => props.setattributeRadio(e.target.value)}
        value={props.attributeRadio}
      >
        {props.attributes.map((attr) => (
          <Radio
            style={radioStyle}
            value={attr.key}
            key={attr.key}
          >
            {attr.placeholder}
            <Input
              className="attr-input"
              size="small"
              value={attr.value}
              onChange={(e) => onChange(e, attr.key)}
              placeholder={attr.placeholder}
              disabled={props.attributeRadio !== attr.key ? true : false}
            />
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default PolygonVolumeSetAttr;
