import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import EmptyBox from "../../../components/blocks/empty";
import DocumentFolder from "../../../components/blocks/folder/DocumentFolder";
import HocDocuementFolder from "../../../components/modals/createFolder/HocDocuementFolder";
import DragAndDrop from "../../home/children/DragAndDrop";
import IconLocation from "../../../assets/icons/location.svg";
import Axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPublishDataSet, setReportVisible } from "../../../store/actions/report";

const DocContents = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { allReports, reports, folders, keyword } = useSelector(
    (state) => ({
      allReports: state.ReportReducer.allReports,
      reports: state.ReportReducer.reports,
      folders: state.ReportReducer.folders,
      keyword: state.ReportReducer.keyword,
    }),
    shallowEqual
  );

  const ref = useRef(null);

  const handleCardClick = async (document) => {
    try {
      const { data } = await Axios.get(`/report/${document.document_mng_sq}`);

      dispatch(
        setPublishDataSet({
          reportName: document.f_report_name,
          selectedDocSq: document.document_mng_sq,
          selectedReportItems: data,
          selectedDate: document.f_input_date,
        })
      );
      dispatch(setReportVisible(true));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <article className="document__content" ref={ref}>
      <h4 className="document__content__title">
        <img className="icon i-location" src={IconLocation} alt="location icon" />
        {t("title$Report", { count: allReports.length })} ({allReports.length})
        <HocDocuementFolder />
      </h4>
      {allReports.length > 0 ? (
        <>
          <ul className="document__content__project-wrapper">
            {_.chain(reports)
              .filter((x) => x.f_report_name.toLowerCase().includes(keyword.toLowerCase()))
              .orderBy("document_mng_sq", "desc")
              .map((report, i) => (
                <DragAndDrop
                  parentRef={ref}
                  history={props.history}
                  onClick={handleCardClick}
                  handle="li"
                  key={i}
                  isDocument={true}
                  itemKey={report.document_mng_sq}
                  item={report}
                />
              ))
              .value()}
          </ul>
          <ul className="document__content__project-wrapper"></ul>
          <ul className="document__content__folder-wrapper">
            <li className="document__content__folder">
              {_.map(folders, (folder, i) => (
                <DocumentFolder
                  folderIndex={i}
                  documentCardonClick={handleCardClick}
                  projects={folder.projects}
                  folder={folder}
                  {...props}
                  isDocument={true}
                  keyword={keyword}
                  key={"doc_folder_" + i}
                />
              ))}
            </li>
          </ul>
        </>
      ) : (
        <EmptyBox report />
      )}
    </article>
  );
};

export default React.memo(DocContents);
