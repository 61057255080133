//actions
import { SESSION_LIMIT_TIME } from "../../config/history";
import {
  USER,
  VIEW,
  IS_SHOW,
  Language,
  ADD_HTTP_LIST,
  REMOVE_HTTP_LIST,
  LOGIN_TIME,
} from "../actions/app";

const initializeAppState = {
  view: "home",
  user: null,
  isShow: false,
  lang: "en",
  httpReqeustList: [],
  loginTime: new Date(),
};

export default function AppReducer(state = initializeAppState, action) {
  switch (action.type) {
    case USER:
      return { ...state, user: action.user };
    case VIEW:
      return { ...state, view: action.view };
    case IS_SHOW:
      return { ...state, isShow: action.isShow };
    case Language:
      return { ...state, lang: action.lang };
    case ADD_HTTP_LIST:
      return {
        ...state,
        isShow: true,
        httpReqeustList: state.httpReqeustList.concat([action.requestConfig]),
      };

    case REMOVE_HTTP_LIST:
      return {
        ...state,
        httpReqeustList: state.httpReqeustList.filter(
          (x) => x.requestId !== action.requestConfig.requestId
        ),
      };
    case LOGIN_TIME:
      return { ...state, loginTime: action.loginTime };
    default:
      return state;
  }
}
