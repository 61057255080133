import React, { useEffect } from "react";
import { loadProjectLayerApi } from "../utils/loadLayerApi";
import { grepSelectedInfo } from "../../../components/modals/upload/utils/grepSelectedDate";
import EmptyModel from "./EmptyModel";
import HasModel from "./HasModel";
import { useSelector } from "react-redux";

/**
 * @component
 *    - ProjectContent
 * @description
 *    - Content Wrapper
 *    - initialize project first selected date and dataset
 */
const ProjectContent = (props) => {
  /**
   * @component
   *    - ProjectContent
   * @function modelsItemList
   * @description
   *    - projectViewDataSetModel merge and convert to array
   *    - has't model? return empty model template
   */

  const { layerCount, annotationCount } = useSelector((state) => ({
    layer: state.ViewDataSetReducer.projectViewDataSetModel.layer,
    layerCount: state.ViewDataSetReducer.layerCount,
    annotationCount: state.ViewDataSetReducer.annotationCount,
  }));

  const hasModel = layerCount + annotationCount > 0;

  //project sq key
  const project_mng_sq = useSelector(
    (state) => state.currentProjectReducer.currentProject.project_mng_sq
  );

  useEffect(() => {
    if (project_mng_sq === null || project_mng_sq === undefined) return;

    (async () => {
      //set upload date selected
      await grepSelectedInfo();

      // uploaded date item list
      await loadProjectLayerApi(project_mng_sq);
    })();
  }, [project_mng_sq]);

  return (
    <article className="project__content">
      {hasModel ? <HasModel {...props} /> : <EmptyModel match={props.match} />}
    </article>
  );
};

export default React.memo(ProjectContent);
