import React from "react";
import DefaultInput from "../../../components/elements/input/DefaultInput";

const OverlayProperty = (props) => {
  // { title, visible, location }
  const location = props.property.location;
  return (
    <>
      {props.children}
      <ul className="infomation-wrap">
        {Object.keys(location).map((item, index) => (
          <li key={index}>
            <span>
              {item} : {
              props.property.editable ? (
                <DefaultInput
                disabled={props.disabled}
                  style={{
                    width: "60%",
                    textAlign: "right",
                    marginLeft: '32%'
                  }}
                  onChange={(e) => props.property.onChange(item, e.target.value, props.property.title)}
                  value={location[item]}
                />
              ) : (
                location[item]
              )}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default OverlayProperty;
