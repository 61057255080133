import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { Menu, Icon } from "antd";
import { QuestionCircleFilled, LogoutOutlined } from "@ant-design/icons";
import SvgIcon from "../../components/elements/svg";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/actions/app";
import { setToken } from "../../store/actions/token";
import { capitalizeFirstLetter } from "utils/StringHelper";

const SETTING_STYLE = { position: "absolute", bottom: "10px", left: 0, right: 0 };

function HomeNavRoute(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const view = useSelector((state) => state.appState.view);

  const handleLogout = () => {
    Axios.get("/authenticate").then(({ status }) => {
      dispatch(setUser(null));
      dispatch(setToken(null));
      sessionStorage.removeItem("userInfo");
      window.location.href = "/";
    });
  };

  const handleMenuClick = () => {
    props.onMenuClick();
  };

  return (
    <Menu theme="light" selectedKeys={[view]} onClick={handleMenuClick}>
      <Menu.Item key="homePage">
        <a rel="noopener noreferrer" target="_blank" href="http://xitecloud.io">
          <Icon type="home" theme="filled" style={{ fontSize: "13px", color: "#182e63" }} />
          <span>{capitalizeFirstLetter(t("button$Home"))}</span>
        </a>
      </Menu.Item>
      <Menu.Item key="root">
        {/* <Link to={`/projects/show/${props.currentProject}`}> */}
        <Link to="/">
          <Icon type="appstore" theme="filled" style={{ fontSize: "13px", color: "#182e63" }} />
          <span>{capitalizeFirstLetter(t("button$project", { count: 2 }))}</span>
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="apps" disabled>
        <Link to={`/apps`}>
          <i className="anticon" style={{ fontSize: "13px" }}>
            <SvgIcon fill={"#182e63"} />
          </i>
          <span>Apps</span>
        </Link>
      </Menu.Item> */}
      <Menu.Item key="section">
        <Link to={`/section/commit`}>
          <i className="anticon" style={{ fontSize: "13px" }}>
            <SvgIcon fill={"#182e63"} />
          </i>
          <span>{capitalizeFirstLetter(t("button$Earthwork_Progress", { count: 2 }))}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="help" disabled>
        <Link to={`/help`}>
          <QuestionCircleFilled style={{ fontSize: "13px", color: "#182e63" }} />
          <span>{capitalizeFirstLetter(t("button$Help"))}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="Log Out" style={SETTING_STYLE} onClick={handleLogout}>
        <LogoutOutlined
          style={{
            color: "#182e63",
          }}
        />
        <span>{t("button$LogOut")}</span>
      </Menu.Item>
    </Menu>
  );
}

export default HomeNavRoute;
