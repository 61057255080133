/** ## 반올림 자리수 상수 */

/**
 * 좌표 (m 단위) 소수점 반올림 설정
 */
export const ROUND_OFF_THE_NUMBERS_COORDINATE = 3;

/**
 * 길이 (m 단위) 소수점 반올림 설정
 */
export const ROUND_OFF_THE_NUMBERS_LENGTH = 2;

/**
 * 면적 (㎡ 단위) 소수점 반올림 설정
 */
export const ROUND_OFF_THE_NUMBERS_AREA = 2;

/**
 * 볼륨 (㎥ 단위) 소수점 반올림 설정
 */
export const ROUND_OFF_THE_NUMBERS_VOLUME = 2;

/**
 * 각도 (도 또는 % 단위) 소수점 반올림 설정
 */
export const ROUND_OFF_THE_NUMBERS_DEGREE = 2;
