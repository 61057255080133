import io from "socket.io-client";

import Axios from "axios";

// export const devPath = `${window.location.protocol}//${window.location.hostname}:3001/`
export const hostname =
  process.env.NODE_ENV === "production" ? window.location.hostname : "58.233.207.36";

export const devPath = `${window.location.protocol}//${hostname}:3001/`;
export const CHAT_SERVER_PATH = process.env.NODE_ENV === "production" ? "/chat/" : devPath;

export const SOCKET_IO_PATH =
  process.env.NODE_ENV === "production" ? `/projects` : `${devPath}projects`;
export const SOCKET_IO_PATH_NOTI =
  process.env.NODE_ENV === "production" ? `/notifications` : `${devPath}notifications`;

export const SOCKET_IO_PATH_FLEET =
  process.env.NODE_ENV === "production" ? `/AssetStatus` : `${devPath}AssetStatus`;
// export const SOCKET_IO_PATH_FLEET = `sol.xitecloud.io/AssetStatus`;

export const axiosForChat = Axios.create({
  baseURL: CHAT_SERVER_PATH,
});

export const socket = (path) =>
  io(path, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 20,
    forceNew: false,
  });

// token 추가(21.03.03)
export const socket2 = (path, token) =>
  io(path, {
    auth: {
      token: token,
    },
    extraHeaders: {
      xctoken: token,
    },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 20,
    forceNew: false,
    query: {
      token: token,
    },
  });

// token 추가(21.03.03)
var ConnSocketNotify;
export const getSocketNotify = (path, token) => {
  if (!ConnSocketNotify) {
    ConnSocketNotify = io(path, {
      auth: {
        token: token,
      },
      extraHeaders: {
        xctoken: token,
      },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 20,
      forceNew: false,
      query: {
        token: token,
      },
    });
  }
  return ConnSocketNotify;
};

// token 추가(21.03.03)
var ConnSocketChatt;
export const getSocketConnectChatt = (path, token) => {
  if (!ConnSocketChatt) {
    ConnSocketChatt = io(path, {
      auth: {
        token: token,
      },
      extraHeaders: {
        xctoken: token,
      },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 20,
      forceNew: false,
      query: {
        token: token,
      },
      origins: "*",
      transports: ["websocket"],
    });
  }
  return ConnSocketChatt;
};

// token 추가(21.03.03)
export const getSocketConnect2 = (path, token) => {
  return io(path, {
    auth: {
      token: token,
    },
    extraHeaders: {
      xctoken: token,
    },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 20,
    forceNew: false,
    query: {
      token: token,
    },
    origins: "*",
    transports: ["websocket"],
  });
};

export const socketOn = (cmd, cb) => {
  ConnSocketChatt.on(cmd, cb);
};
export const socketEmit = (cmd, data) => {
  ConnSocketChatt.emit(cmd, data);
};
export const socketConnet = () => {
  ConnSocketChatt.connect();
};
export const socketDisConnet = () => {
  ConnSocketChatt.disconnect();
  ConnSocketChatt.close();
  ConnSocketChatt = undefined;
};
