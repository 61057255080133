//actions
import { CHAT_INIT, GET_MESSAGE_LIST, READ_MESSAGE,CHAT_COMPONENT_VISIBLE, CHAT_INPUT_FOCUS, CHAT_IS_LOADING, CHAT_WRITE_MESSAGE,CHAT_LIMIT } from '../actions/chatReducer';

const initializeChatState = {
    readMsg: false,
    items: [],
    msgLoading: false,
    writeMessage: '',
    componentVisible: false,
    isInputFoucs: false,
    limitPage: 2
}

export default function ChatReducer (state = initializeChatState, action) {

    switch (action.type) {
        case GET_MESSAGE_LIST : return { ...state, items: action.items };
        case READ_MESSAGE : return { ...state, readMsg: action.bool };
        case CHAT_INIT: return initializeChatState;
        case CHAT_COMPONENT_VISIBLE : return { ...state, componentVisible: action.bool};
        case CHAT_INPUT_FOCUS : return { ...state, isInputFoucs: action.bool };
        case CHAT_IS_LOADING : return { ...state, msgLoading: action.bool};
        case CHAT_LIMIT: return { ...state, limitPage: state.limitPage + 1 };
        case CHAT_WRITE_MESSAGE: return { ...state, writeMessage: action.value };
        default: return state;
    }
}

