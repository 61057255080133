import React, { useCallback, useEffect, useState } from "react";
import { Layout, Drawer } from "antd";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import "./base.less";
import Logo from "../../assets/logo/logo.svg";
import MapController from "../../components/blocks/mapController";
// import Section from '../../views/section';
import Xitecloud3d from "../../components/blocks/xitecloud3d";
import ViewRoutesModules from "../../route-modules/view-route-core";
import HomeNavRoutes from "../../route-modules/route-navgations/home-route";
import ProjectNavRoutes from "../../route-modules/route-navgations/project-route";
import { MenuOutlined } from "@ant-design/icons";
import GoogleMap from "../../components/blocks/googlemap/GoogleMap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Content, Sider } = Layout;

const cssStyle = {
  Layout: {
    minHeight: "100vh",
  },
  toggleSider: {
    right: "16px",
  },
  sider: {
    position: "fixed",
  },
  siderLogo: {
    borderBottom: "1px solid #bcc5ea",
  },
  siderLogoSymbol: {
    color: "#bcc5ea",
    fontSize: "20px",
  },
};

function BaseLayout() {
  const { i18n } = useTranslation();
  /**
   * Drawer handler .Sider
   */
  const [mainVisible, setMainVisible] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCloseMainDrawer = useCallback(() => setMainVisible(false), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onActiveMainDrawer = useCallback(() => setMainVisible(true), []);

  /**
   * current view state
   */
  const view = useSelector((state) => state.appState.view);
  const [isMap, setIsMap] = useState(true);
  useEffect(() => {
    setIsMap(view === "home" || view === "home/create" ? true : false);

    return () => setIsMap(false);
  }, [view]);

  /**
   * Drawer piece component
   */
  const titleExtra = useCallback(() => {
    return (
      <div className="sider__logo">
        <Link to="/">
          <img className="sider__logo" src={Logo} alt="logo" />
        </Link>
        <label onClick={onCloseMainDrawer}>
          <span className="toggle-sider"></span>
          <span className="toggle-sider" style={cssStyle.toggleSider}></span>
        </label>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout style={cssStyle.Layout}>
      <Router>
        <Route>
          <Drawer
            className="app__sider"
            title={titleExtra()}
            width={200}
            closable={false}
            placement={"left"}
            onClose={onCloseMainDrawer}
            visible={mainVisible}
            key={"left"}
          >
            <HomeNavRoutes onMenuClick={onCloseMainDrawer} />
          </Drawer>
        </Route>
        <Route path="*">
          <Sider
            className={`sider z-10`}
            collapsible
            collapsed={true}
            // onCollapse={this.onCollapse}
            style={cssStyle.sider}
            collapsedWidth={50}
            width={180}
            trigger={null}
          >
            <div className="sider__logo" onClick={onActiveMainDrawer} style={cssStyle.siderLogo}>
              <MenuOutlined className="sider__logo-symbol" style={cssStyle.siderLogoSymbol} />
            </div>
            <ProjectNavRoutes />
          </Sider>
        </Route>
        <Route>
          <Content className="content">
            <Xitecloud3d />
            {isMap || <MapController />}
            {isMap && <GoogleMap language={i18n.language} />}
            <ViewRoutesModules />
          </Content>
        </Route>
      </Router>
    </Layout>
  );
}

export default BaseLayout;
