import React, { useState, useEffect } from "react";

const useLoadAnnotation = (currentMeasure) => {
  /**
   * isTab 
   *    1.layer 2.annotation
   */
  const [tabKey, setTabKey] = useState("1");
  const onChangeTab = (key) => setTabKey(key);

  /**
   * @component
   *    - ProjectContent
   * @description
   *    - annotations dataset render to react
   */
  useEffect(() => {
      if (currentMeasure !== null) {
        setTabKey("2");
      }
    }, [currentMeasure]);

  return [tabKey, onChangeTab];
};

export default useLoadAnnotation;
