import React, { useState, useEffect, useCallback } from "react";
import { Drawer, Button, Icon, Input } from "antd";

import "./chat.less";
import MyUpload from "../../chatUpload";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import SvgIcon from "../../../elements/svg";

import SliderBar from "../../../elements/slider";

import {
  SOCKET_IO_PATH,
  getSocketConnectChatt,
  socketEmit,
  socketOn,
  socketConnet,
  socketDisConnet,
} from "./env";
import ChatListComponent from "./views/ChatListComponent";
import {
  setGetMsg,
  setPastMessages,
  setChatLoading,
  setCurrentMessage,
  setComponentVisible,
  setChatInputFocus,
  setChatInitialize,
} from "../../../../store/actions/chatReducer";
import { store } from "../../../../store/store";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";

const cssStyle = {
  fileUploadIcon: {
    fontSize: "18px",
    marginRight: "5px",
  },
  inputLabel: {
    width: "300px",
  },
  fontSize13: {
    fontSize: "13px",
  },
  sliderBarProps: {
    max: 1,
    min: 0.5,
    width: "40px",
    step: 0.1,
    default: 1,
  },
};

function Chat(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.userInfo);

  const [svg, setSvg] = useState({
    sendSvgProps: {
      src: `M306.7,11.9c-2.1-1.6-5-1.8-7.4-0.6L14.2,163.2c-2.2,1.2-3.6,3.5-3.7,6c0,2.5,1.3,4.9,3.4,6.1L89,219.1
      c2.5,1.5,5.7,1.2,8-0.7l122.2-104.3L134.4,237c-0.6,0.9-1,1.9-1.2,3l-8.5,61.6c-0.4,2.6,0.8,5.2,3,6.7c1.2,0.8,2.5,1.2,3.9,1.2
      c1.2,0,2.4-0.3,3.4-0.9l54.1-31l53,30.9c1.9,1.1,4.3,1.3,6.3,0.3c2-0.9,3.5-2.8,4-5l57-285C309.9,16.2,308.9,13.5,306.7,11.9z`,
      fill: "#bec5cc",
    },
    fileUploadSvgProps: {
      src: `M234.8,234.8v-59.8h-28.4v59.8c0,25.6-20.8,46.3-46.3,46.3s-46.3-20.8-46.3-46.3v-59.8H85.2v59.8
      c0,41.3,33.5,74.8,74.8,74.8S234.8,276,234.8,234.8z M145.1,100.2v119.6h29.9V100.2H145.1z M160,38.9c25.6,0,46.3,20.8,46.3,46.3
      v59.8h28.4V85.2c0-41.3-33.5-74.8-74.8-74.8S85.2,44,85.2,85.2v59.8h28.4V85.2C113.7,59.7,134.4,38.9,160,38.9z`,
      fill: "#bec5cc",
    },
  });

  //project sq is room number
  const projectSq = props.match.params.projectId;
  const { user, loading, componentVisible, writeMessage, isInputFoucs } = useSelector(
    (state) => ({
      user: state.appState.user,
      loading: state.ChatReducer.msgLoading,
      componentVisible: state.ChatReducer.componentVisible,
      writeMessage: state.ChatReducer.writeMessage,
      isInputFoucs: state.ChatReducer.isInputFoucs,
    }),
    shallowEqual
  );

  const userParams = {
    name: user.f_user_nm,
    email: user.user_email_id,
    sq: user.user_mng_sq,
    //token: user.token, // token 추가
  };

  useEffect(() => {
    getSocketConnectChatt(SOCKET_IO_PATH, userInfo.token);
    socketEmit("roomJoin", {
      user: userParams,
      room: projectSq,
    });

    socketOn("pastChats", (data) => {
      // const list = data.pastChats;
      // console.log(data);
      const localItems = store.getState().ChatReducer.items;

      dispatch(setPastMessages([...localItems, ...data.pastChats]));

      dispatch(setGetMsg(true));
    });

    socketOn("upload", (data) => {
      const localItems = store.getState().ChatReducer.items;
      dispatch(
        setPastMessages([
          ...localItems,
          {
            message: data.message,
            file: {
              mimeType: data.files[0].f_description,
              path: data.files[0].f_file_path,
              name: data.files[0].f_file_name,
              sq: data.files[0].chat_files_sq,
            },
            user: data.user,
          },
        ])
      );

      const scrollEl = document.querySelector(".ant-drawer .ant-drawer-body ul");

      if (scrollEl) {
        scrollEl.scrollTo(0, scrollEl.scrollHeight);
      }

      dispatch(setChatLoading(false));
    });

    socketOn("disconnect", (reason) => {
      if (reason !== "io server disconnect") {
        // console.log("socket disconnect reason ->", reason);
        socketConnet();
      }
    });

    socketOn("msg", (data) => {
      const localItems = store.getState().ChatReducer.items;
      const chatCard = {
        created: data.created,
        message: data.message,
        user: data.user,
      };

      if (data.notice) chatCard.notice = data.notice;

      dispatch(setPastMessages([...localItems, chatCard]));

      const scrollEl = document.querySelector(".ant-list-items");

      if (scrollEl) {
        scrollEl.scrollTo(0, scrollEl.scrollHeight);
      }
    });

    return () => {
      socketEmit("leave", {
        room: projectSq,
        user: userParams,
      });
      socketDisConnet();
      dispatch(setChatInitialize());
    };
  }, []);

  const onEnterKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const onSubmit = () => {
    socketEmit("msg", {
      message: writeMessage,
      room: projectSq,
      user: userParams,
    });

    dispatch(setCurrentMessage(""));
  };

  const onChange = (value) => {
    if (isNaN(value) || document.querySelector(".ant-drawer-content") === null) return;
    document.querySelector(".ant-drawer-content").style.opacity = value;
  };

  const chatTitle = useCallback(
    () => (
      <>
        {t("title$Communication", { count: 2 })}{" "}
        <SliderBar {...cssStyle.sliderBarProps} onChange={onChange} />
      </>
    ),
    []
  );
  const isLoading = (bool) => dispatch(setChatLoading(bool));

  const handleMouseHover = (active, svgKey) => {
    if (svgKey === "sendSvgProps" && isInputFoucs) return;
    svg[svgKey].fill = active === "on" ? "#346cee" : "#bec5cc";
    setSvg({ ...svg });
  };

  const onInputFocus = () => {
    dispatch(setChatInputFocus(!isInputFoucs));
    svg.sendSvgProps.fill = svg.sendSvgProps.fill === "#bec5cc" ? "#346cee" : "#bec5cc";
    setSvg({ ...svg });
  };

  const onChatChange = (e) => dispatch(setCurrentMessage(e.target.value));

  const setUploadFiles = (fileName) => {
    if (loading === true && fileName.response) {
      socketEmit("upload", {
        message: null,
        files: fileName.response,
        room: projectSq,
        user: {
          name: user.f_user_nm,
          email: user.user_email_id,
          sq: user.user_mng_sq,
        },
      });
    }
  };

  //component trigger
  const componentVisibleHandle = (bool) => dispatch(setComponentVisible(bool));

  return (
    <div className="chat">
      <div className="chat__btn-wrapper">
        <Button
          style={{
            width: "110px",
            padding: "0px",
            display: componentVisible ? "none" : "block",
          }}
          className="font-sofia"
          onClick={() => componentVisibleHandle(true)}
        >
          <Icon type="eye" theme="filled" />
          {t("title$Communication", { count: 2 })}
        </Button>
      </div>

      {/* {visible &&  */}
      <Drawer
        title={chatTitle()}
        className="comment-wrap"
        placement="right"
        onClose={() => componentVisibleHandle(false)}
        visible={componentVisible}
        width="350"
      >
        <ChatListComponent
          history={props.history}
          match={props.match}
          location={props.location}
          firstInputSpace={onChange}
        />
        <div className="chat-input-form">
          <label className="chat-component-label" htmlFor="chat-component-upload">
            <i
              className="hover-cursor"
              style={cssStyle.fileUploadIcon}
              onMouseEnter={() => handleMouseHover("on", "fileUploadSvgProps")}
              onMouseLeave={() => handleMouseHover("off", "fileUploadSvgProps")}
            >
              <SvgIcon {...svg.fileUploadSvgProps} />
            </i>
            <MyUpload setUploadFiles={setUploadFiles} isLoading={isLoading} projectSq={projectSq} />
          </label>
          <label className="chat-component-label" style={cssStyle.inputLabel}>
            <Input
              className="chat-input"
              onChange={onChatChange}
              onBlur={onInputFocus}
              onFocus={onInputFocus}
              value={writeMessage}
              onKeyDown={onEnterKeyDown}
            />
            <i
              className="chat-send hover-cursor anticon"
              style={cssStyle.fontSize13}
              onClick={onSubmit}
              onMouseEnter={() => handleMouseHover("on", "sendSvgProps")}
              onMouseLeave={() => handleMouseHover("off", "sendSvgProps")}
            >
              <SvgIcon {...svg.sendSvgProps} />
            </i>
          </label>
        </div>
      </Drawer>
      {/* } */}
    </div>
  );
}

export default React.memo(Chat);
