import React from "react";
import "./pointCloud.less";
import { Button, InputNumber, Slider, Switch } from "antd";

import ContentTitle from "../../components/elements/text/contentTitle";
import Sliders from "../../components/elements/slider";
import HideDashboard from "../../components/blocks/hideDashboard";
import { connect } from "react-redux";
import { visiblePointCloud } from "../../store/actions/projectChildrenComponent";
import { Trans, withTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import { capitalizeFirstLetter } from "utils/StringHelper";

class PointCloud extends React.Component {
  state = {
    pointSize: 1,
    pointOpacity: 1,
    // material: null,
    bWidth: 0,
    bMin: 0,
    bMax: 0,
    elevationMapVisible: false,
    elevationRange: [0, 0],
    elevationRangeInputMin: 0,
    elevationRangeInputMax: 0,
  };

  myRef = React.createRef();

  myKey = this.props.currentModelReducer.dbObj.topo_data_sq;

  setLocalStorage = () => {
    localStorage.setItem(
      `pointCloud_${this.myKey}`,
      JSON.stringify({
        ...this.state,
      })
    );
  };

  componentDidMount = () => {
    const _3Object = this.props.currentModelReducer._3DObject;
    let material = _3Object.material;

    /**
     * gradient effect :RAINBOW
     */
    material.gradient = window.Potree.Gradients.RAINBOW;

    if (!localStorage.getItem(`pointCloud_${this.myKey}`)) {
      /**
       * find target
       *  redux data type migrate to potree object
       */
      let box = [_3Object.pcoGeometry.tightBoundingBox, _3Object.getBoundingBoxWorld()].find(
        (v) => v !== undefined
      );
      box = window.Potree.Utils.computeTransformedBoundingBox(box, _3Object.matrixWorld);

      /**
       * get, set elevationRange
       */
      let bWidth = box.max.z - box.min.z;
      let bMin = box.min.z - 0.2 * bWidth;
      let bMax = box.max.z + 0.2 * bWidth;

      // _3Object.updateMatrixWorld()

      this.setState(
        {
          bWidth,
          bMin,
          bMax,
          elevationRange: material.elevationRange.map((x) => Math.round(x)).reverse(),
        },
        this.setLocalStorage
      );
    } else {
      /**
       * using localstorage visualization Option
       */
      const localData = JSON.parse(localStorage.getItem(`pointCloud_${this.myKey}`));
      this.setState({ ...localData });
      this.visibleElevation(localData.elevationMapVisible);
    }
  };

  debounceFn = debounce((value) => {
    this.elevationRangeChange(value);
  }, 1000);
  // this.elevationRangeChang(value);

  elevationRangeInputMinChange = (value) => {
    const range = [value, Number(this.state.elevationRange[1])];

    this.setState({
      elevationRangeInputMin: value,
    });

    this.debounceFn(range);
  };
  elevationRangeInputMaxChange = (value) => {
    const range = [Number(this.state.elevationRange[0]), Number(value)];

    this.setState({
      elevationRangeInputMax: value,
    });

    this.debounceFn(range);
  };

  elevationRangeChange = (value) => {
    // console.log("elevationRangeChange", value);

    this.setState(
      {
        elevationRange: value,
        elevationRangeInputMin: value[0],
        elevationRangeInputMax: value[1],
      },
      () => {
        this.props.currentModelReducer._3DObject.material.elevationRange = this.state.elevationRange;
        this.setLocalStorage();
      }
    );
  };

  visibleElevation = (bool) => {
    // let mode = bool ? 'elevation' : 'RGBA';
    let mode = bool ? "elevation" : "rgba";
    this.props.currentModelReducer._3DObject.material.activeAttributeName = mode;
    this.props.currentModelReducer._3DObject.updateMatrixWorld();
    this.setState({ elevationMapVisible: bool }, this.setLocalStorage);
  };

  onChange = (pointSize) => {
    if (isNaN(pointSize)) return;
    this.setState({ pointSize }, this.setLocalStorage);
    let material = this.props.currentModelReducer._3DObject.material;
    material.size = pointSize;
  };

  onChangeOpacity = (pointOpacity) => {
    if (isNaN(pointOpacity)) return;
    this.setState({ pointOpacity }, this.setLocalStorage);
    window.viewer.setEDLOpacity(pointOpacity);
  };

  visibleFalse = () => {
    // window.viewer.setEDLOpacity(1)
    // this.props.currentModelReducer._3DObject.material.size = 1;
    // this.props.currentModelReducer._3DObject.updateMatrixWorld()
    // this.props.currentModelReducer._3DObject.material.activeAttributeName = 'RGBA';
    this.props.visiblePointCloud(false);
  };

  render() {
    const { t } = this.props;

    const pointSizeSlider = {
      label: t("title$Point_Size"),
      width: "144px",
      min: 0,
      max: 3,
      step: 0.01,
    };

    const opacitySlider = {
      label: capitalizeFirstLetter(t("title$Opacity")),
      width: "144px",
      min: 0,
      max: 1,
      step: 0.01,
    };
    return (
      <div className="point-cloud">
        <section className="point-cloud__top">
          <Button className="point-cloud__top__back" icon="left" onClick={this.visibleFalse} />
          <h4>{t("title$Point Cloud Option")}</h4>
          <HideDashboard />
          <div className="point-cloud__top__actions"></div>
          <div className="point-cloud__top__description"></div>
        </section>
        <section className="point-cloud__content">
          <article className="point-cloud__content__part">
            <ContentTitle title={t("title$Visualization_Option")} />
            <Sliders {...pointSizeSlider} default={this.state.pointSize} onChange={this.onChange} />
            <Sliders
              {...opacitySlider}
              default={this.state.pointOpacity}
              onChange={this.onChangeOpacity}
            />
          </article>

          <article className="point-cloud__content__part">
            <ContentTitle title={t("title$Elevation_range")}>
              <Switch
                className="toggle-elevation-map"
                checked={this.state.elevationMapVisible}
                size="small"
                onChange={this.visibleElevation}
              />
            </ContentTitle>

            <div className="input-range">
              <Trans i18nKey="html$pointCloud range button">
                <InputNumber
                  min={this.state.bMin}
                  max={this.state.bMax}
                  value={this.state.elevationRangeInputMin}
                  onChange={this.elevationRangeInputMinChange}
                />
                {" ~ "}
                <InputNumber
                  min={this.state.bMin}
                  max={this.state.bMax}
                  value={this.state.elevationRangeInputMax}
                  onChange={this.elevationRangeInputMaxChange}
                />
              </Trans>
            </div>

            <Slider
              className="custom-slider"
              ref={this.myRef}
              range
              tooltipVisible={false}
              value={this.state.elevationRange}
              step={0.001}
              min={this.state.bMin}
              max={this.state.bMax}
              onChange={this.elevationRangeChange}
            />

            <p className="slider-range">
              <span className="start">{this.state.bMax}</span>
              <span className="end">{this.state.bMin}</span>
            </p>
          </article>
        </section>
      </div>
    );
  }
}
export default withTranslation()(
  connect((state) => state, {
    visiblePointCloud,
  })(PointCloud)
);

// class PointCloud extends React.Component {
//   state = {
//     pointSize: 1,
//     pointOpacity: 1,
//     material: null,
//     bWidth: 0,
//     bMin: 0,
//     bMax: 0,
//     elevationRange: [0, 0]
//   }

//   componentDidMount = () => {
//     const _3Object = this.props.currentModelReducer._3DObject;

//     let box = [_3Object.pcoGeometry.tightBoundingBox, _3Object.getBoundingBoxWorld()]
//       .find(v => v !== undefined);

//     box = window.Potree.Utils.computeTransformedBoundingBox(box, _3Object.matrixWorld);

//     let bWidth = box.max.z - box.min.z;
//     let bMin = box.min.z - 0.2 * bWidth;
//     let bMax = box.max.z + 0.2 * bWidth;

//     this.setState({
//       bWidth,
//       bMin,
//       bMax
//     })

//     let material = _3Object.material;
//     // material.activeAttributeName = 'elevation';

//     // material.elevationRange = [0, 1]
//     // 0. "SPECTRAL"
//     // 1. "PLASMA"
//     // 2. "YELLOW_GREEN"
//     // 3. "VIRIDIS"
//     // 4. "INFERNO"
//     // 5. "GRAYSCALE"
//     // 6. "TURBO"
//     // 7. "RAINBOW"
//     // 8. "CONTOUR"

//     material.gradient = window.Potree.Gradients.RAINBOW;
//     // console.log(window.Potree.Gradients.RAINBOW)
//     // material.gradient = [
//     //   [0.0, new THREE.Color(1, 0, 0)],
//     //   [0.1, new THREE.Color(1, 0, 0)],
//     //   [0.16666666666666666, new THREE.Color(1, 0.64, 0)],
//     //   [0.3333333333333333, new THREE.Color(1, 1, 0)],
//     //   [0.6666666666666666, new THREE.Color(0, 1, 1)],
//     //   [0.8333333333333334, new THREE.Color(0, 0, 1)],
//     //   [1, new THREE.Color(0.278, 0, 0.714)],
//     // ];
//     this.setState({
//       elevationRange: material.elevationRange.map(x => Math.round(x)).reverse()
//     })

//     console.log(this.state.elevationRange)
//     // material.elevationRange = [-14.01, 32.63];

//     this.props.currentModelReducer._3DObject.updateMatrixWorld()
//   }

//   elevationRangeChange = (value) => {
//     console.log(value)
//     this.setState(({
//       elevationRange: value
//     }), () => {
//       this.props.currentModelReducer._3DObject.material.elevationRange = this.state.elevationRange
//     })
//   }

//   visibleElevation = (bool) => {
//     let mode = bool ? 'elevation' : 'RGBA';
//     this.props.currentModelReducer._3DObject.material.activeAttributeName = mode;
//     this.props.currentModelReducer._3DObject.updateMatrixWorld();
//   }

//   onChange = (pointSize) => {
//     if (isNaN(pointSize)) return;
//     this.setState({ pointSize })
//     let material = this.props.currentModelReducer._3DObject.material;
//     material.size = pointSize;
//   }

//   onChangeOpacity = (pointOpacity) => {
//     if (isNaN(pointOpacity)) return;
//     this.setState({ pointOpacity })
//     window.viewer.setEDLOpacity(pointOpacity)
//   }

//   visibleFalse = () => {
//     window.viewer.setEDLOpacity(1)
//     this.props.currentModelReducer._3DObject.material.size = 1;
//     this.props.currentModelReducer._3DObject.material.activeAttributeName = 'RGBA';
//     this.props.currentModelReducer._3DObject.updateMatrixWorld()
//     this.props.visiblePointCloud(false);
//   };

//   render() {
//     return (
//       <div className="point-cloud">
//         <section className="point-cloud__top">
//           <Button className="point-cloud__top__back" icon="left" onClick={this.visibleFalse} />
//           <h4>Point Cloud</h4>
//           <HideDashboard />
//           <div className="point-cloud__top__actions">
//             {/* <img className="i-edit" src={IconEdit} alt="edit icon" />
//             <img className="i-delete" src={IconDelete} alt="delete icon" /> */}
//           </div>
//           <div className="point-cloud__top__description">
//             {/* <CalendarSelector />
//             <Button>Search Layers<Icon type="caret-down" /></Button> */}
//           </div>
//         </section>
//         <section className="point-cloud__content">
//           <article className="point-cloud__content__part">
//             <ContentTitle title="Visualization Option" />
//             <Sliders {...pointSizeSlider} default={this.state.pointSize} onChange={this.onChange} />
//             <Sliders {...opacitySlider} default={this.state.pointOpacity} onChange={this.onChangeOpacity} />
//           </article>

//           {/* <SelectBox label="Attribute" width="165px" items={['RGB and Elevation']} default="RGB and Elevation"/> */}
//           <article className="point-cloud__content__part">

//             {/* <Checkbox className="point-cloud__content__checkbox">RGB</Checkbox> */}
//             {/* <Slider label="Gamma" width="144px" disabled/>
//             <Slider label="Bright" width="144px" disabled/>
//             <Slider label="Contrast" width="144px" disabled/> */}

//             <ContentTitle title={`Elevation Map`}><Switch className="toggle-elevation-map" size="small" onChange={this.visibleElevation} /></ContentTitle>
//             {/* <span>  length(bWidth) : {this.state.bWidth}</span>
//             <span>  length(bMin) : {this.state.bMin}</span>
//             <span>  length(bMax) : {this.state.bMax}</span> */}

//             <span>Elevation range: {this.state.elevationRange[0]} to {this.state.elevationRange[1]}</span>

//             <Slider
//               className="custom-slider"
//               range
//               tooltipVisible={false}
//               value={this.state.elevationRange}
//               step={0.001}
//               min={this.state.bMin}
//               max={this.state.bMax}
//               onChange={this.elevationRangeChange}
//             />
//             <p className="slider-range">

//               <span className="start">{this.state.bMax}</span>
//               <span className="end">{this.state.bMin}</span>
//             </p>
//             {/* background-image: linear-gradient(to left, #0138a7, #41febf, #faf452,#fe4141 103%); height: 49px; */}
//             {/* <Checkbox className="point-cloud__content__checkbox">Elevation</Checkbox> */}
//             {/* <ColorPicker /> */}
//             {/* <Sliders label="Opacity" min={0} max={100} visibleMinMax /> */}
//           </article>

//           {/* <article className="point-cloud__content__part">
//             <ContentTitle title="Segmentation"/>
//             <SelectBox items={['Polygonal Selection']} default="Polygonal Selection"/>
//           </article>
//           <article className="point-cloud__content__part">
//             <ContentTitle title="CSF (Cloth Simulation Filtering)"/>
//             <Tabs className="csf-tabs" defaultActiveKey="1">
//               <TabPane tab="General Parameter" key="1">
//                 <div className="csf-tabs__tab1 group">
//                   <p>Scene</p>
//                   <RadioGroup items={['Sleep slope', 'Relief', 'Flat']}/>
//                   <Checkbox>Slope Processing</Checkbox>
//                 </div>
//               </TabPane>
//               <TabPane tab="Advanced Parameter" key="2">
//                 <SelectBox label="Cloth resolution" width="70px" items={['2.0']} default="2.0"/>
//                 <SelectBox label="Max iterations" width="70px" items={['500']} default="500"/>
//                 <SelectBox label="Classification threshold" width="70px" items={['0.5']} default="0.5"/>
//                 <Checkbox className="point-cloud__content__checkbox">Export cloth mesh</Checkbox>
//                 <Button className="advanced">Advanced Parameter Instruction</Button>
//               </TabPane>
//             </Tabs>
//           </article>
//           <article className="point-cloud__content__part">
//             <ContentTitle title="Random Points Sampling (Down Sampling)"/>
//             <Slider min={0} max={100} visibleMinMax/>
//             <SelectBox label="Remaing Points" width="75px" items={['100%', '95%']} default="100%"/>
//           </article>
//          */}
//         </section>
//         {/* <section className="point-cloud__bottom">
//           <Comment />
//         </section> */}
//       </div>
//     );
//   }
// }

// export default connect((state) => state, {
//   visiblePointCloud
// })(PointCloud);
