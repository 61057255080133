//actions
import {
    PROJECT_ALL_LIST,
    PROJECT_ALL_SET,
    PROJECT_LIST,
    FOLDER_LIST,
    PROJECT_SEARCH_KEYWORD,
    PROJECT_WORKSPACE_VISIBLE
} from '../actions/projectList';

const initializeProjectList = {
    workspaceVisible: true,

    //not included projects on "folders"
    projects: [],
    
    //every project
    allProjects: [],

    //folder list
    folders: [],

    keyword: ''
}

export default function projectReducer (state = initializeProjectList, action) {
    switch (action.type) {
        //projects
        case PROJECT_WORKSPACE_VISIBLE : return {...state, workspaceVisible: !state.workspaceVisible }
        case PROJECT_ALL_LIST : return { ...state, allProjects: action.projectAllList };
        case PROJECT_ALL_SET : return {
            ...state,
            allProjects: action.projectAllList,
            projects: action.projects,
            folders: action.folders
        };
        case PROJECT_LIST : return { ...state, projects: action.projects };
        case FOLDER_LIST : return { ...state, folders: action.folders };

        //keyword
        case PROJECT_SEARCH_KEYWORD : return { ...state, keyword: action.keyword };

        default: return state;
    }
}