import React from "react";
import IconNoData from "../../../assets/icons-2/No Data.svg";
import UploadModal from "../../../components/modals/upload";
import { useTranslation } from "react-i18next";

/**
 * @component
 *    - EmptyModel
 * @description
 *    - empty model template
 */
const EmptyModel = (props) => {
  const { t } = useTranslation();
  return (
    <div className="model-empty__wrapper">
      <img src={IconNoData} alt="no data" />
      <p>{t("title$No_Data_Yet")}</p>
      <UploadModal match={props.match} />
    </div>
  );
};

export default React.memo(EmptyModel);
