import React from "react";
import { Modal } from "antd";
import ModalTypeTable from "../../tables/modalType";
import { useTranslation } from "react-i18next";
const TypeModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("title$Soil Type Setting", { count: 1 })}
      className="text-right"
      visible={props.typesVisible}
      onOk={props.showType}
      onCancel={props.showType}
      footer={[<React.Fragment key="type-table"></React.Fragment>]}
    >
      <ModalTypeTable />
    </Modal>
  );
};

export default TypeModal;
