import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";

/* eslint-disable jsx-a11y/anchor-is-valid */
const TableAction = (props) => {
  const { t } = useTranslation();
  return props.record.editable ? (
    <>
      <p>
        <a onClick={() => props.activeEdit(props.record, false)}>
          {capitalizeFirstLetter(t("button$Save"))}
        </a>
        <a onClick={() => props.onCancel(props.record.key)}>
          {"  "}
          {capitalizeFirstLetter(t("button$Cancel"))}
        </a>
      </p>
    </>
  ) : (
    <>
      <p className="edit-wrap">
        <a onClick={() => props.activeEdit(props.record, true)}>
          {capitalizeFirstLetter(t("button$Edit"))}
        </a>
        <a onClick={() => props.handleDelete(props.record.key)}>
          {"  "}
          {capitalizeFirstLetter(t("button$Remove"))}
        </a>
      </p>
    </>
  );
};

export default TableAction;
