//actions
import { PREVIEWER_IMAGE_LIST, PREVIEWER_IMAGE_VISIBLE, PREVIEWER_IMAGE_INIT, PREVIEWER_IMAGE_SELECT } from '../actions/previewImage';

const initializePreviewerState = {
    visible: false,
    fileList: [],
    selectedImage: null,
}

export default function PreviewerReducer (state = initializePreviewerState, action) {
    switch (action.type) {
        case PREVIEWER_IMAGE_INIT : return initializePreviewerState;
        case PREVIEWER_IMAGE_SELECT : return { ...state, selectedImage: action.selectedImage, visible: true};
        case PREVIEWER_IMAGE_LIST : return { ...state, fileList: action.fileList };
        case PREVIEWER_IMAGE_VISIBLE : return { ...state, visible: action.visible };
        default: return state;
    }
}