import React, { createRef } from 'react';

import { CHAT_SERVER_PATH,
  //  axiosForChat
   } from '../env';

class StreamVideo extends React.Component {
    ref = createRef()
  
    componentDidMount = () => {
        // axiosForChat.get(`/files/${this.props.item.file._id}`)
        // .then(({ data }) => {
        //   console.log(data)
        //   // return this.ref.current.src = `${CHAT_SERVER_PATH}uploads/chats/${data._id}.${data.mimeType.replace('video/', '')}`
        // })
    }
  
    render() {
      return (
        <video width="320" height="240" controls ref={this.ref}>
          <source type={this.props.item.file.mimeType} src={`${CHAT_SERVER_PATH}${this.props.item.file.path}`} />
        </video>
      )
    }
  }

export default StreamVideo;