import React from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../../../components/elements/button/DefaultButton";
import { capitalizeFirstLetter } from "utils/StringHelper";

const AnnotationFooter = (props) => {
  const { t } = useTranslation();

  return (
    <section className={props.className}>
      <DefaultButton block onClick={props.onClick}>
        {capitalizeFirstLetter(t("button$Save"))}
      </DefaultButton>
    </section>
  );
};

export default AnnotationFooter;
