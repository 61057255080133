import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en_US from "./locales/en-US/translation.json";
import ko_KR from "./locales/ko-KR/translation.json";

// 국가 코드와 json 파일 매핑
const resources = {
  "ko-KR": { translation: ko_KR },
  "en-US": { translation: en_US },
};

/**
 * @lick https://codetain.com/blog/how-to-translate-react-application-with-react-i18next-part-2
 * detecting option 관련 언어 설정, 캐싱 참조
 */
const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

const INTERPOLATION_OPTIONS = {
  // template literal 방식
  // ex: ('안녕 %{하세요}')
  prefix: "%{",
  suffix: "}",
  escapeValue: false, // react already safes from xss
  alwaysFormat: true,
  format(value, format, lng) {
    if (format === "uppercase") return value.toUpperCase();
    // interpolation 되는 값이 number로 들어올 때, 언어 설정에 맞는 locale string으로 변환해서 반환
    if (typeof value === "number") return value.toLocaleString(lng);
    return value;
  },
};

const REACT_OPTIONS = {
  defaultTransParent: "div",
  transEmptyNodeValue: "",
  transSupportBasicHtmlNodes: true,
  // <Trans> 컴포넌트 내부에 들어가는 html 태그들
  transKeepBasicHtmlNodesFor: ["br", "strong", "i", "button", "a", "span", "div", "input", "Row"],
  transWrapTextNodes: "",
};

/**
 * @link https://react.i18next.com/latest/i18next-instance
 */
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: resources,
    // lng: "ko-KR", //기본 언어. LanguageDetector 브라우저 언어 사용시 삭제
    fallbackLng: "en-US", // 지정 되지 않은 언어 감지시 en-US로 설정
    saveMissing: true,
    returnNull: false,
    returnEmptyString: false,
    keySeparator: false,
    nsSeparator: false,
    interpolation: INTERPOLATION_OPTIONS,
    react: REACT_OPTIONS,
    detection: DETECTION_OPTIONS,

    parseMissingKeyHandler,
  });

function parseMissingKeyHandler(key, defaultValue) {
  console.warn("Missing i18n key:", key, defaultValue);
  const keySeparator = "~~";
  const value = key.includes(keySeparator) ? key.split(keySeparator)[1] : key;

  return value;
}

export function changeLanguage(lng) {
  return i18next.changeLanguage(lng);
}

export const i18n = i18next;
