import React from "react";
import { useTranslation } from "react-i18next";
import { consoleError } from "../../../middleware";

import IconLocation from "../../../assets/icons/location.svg";
import { ReactComponent as IconEdit } from "../../../assets/icons-2/Icon_Edit.svg";
import { ReactComponent as IconDelete } from "../../../assets/icons-2/Icon_Delete.svg";

import Axios from "axios";
import moment from "moment";
import DropDown from "../../elements/dropdown";
import { capitalizeFirstLetter } from "utils/StringHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPublishDataSet } from "store/actions/report";
import { Modal, message } from "antd";
import { configReportAndFolder } from "views/documents/utils/report";
import "./projectCard.less";

const DocumentCard = (props) => {
  const { t } = useTranslation();
  const { item, itemKey } = props;

  const dispatch = useDispatch();
  const projectId = useSelector(
    (state) => state.currentProjectReducer.currentProject.project_mng_sq,
    shallowEqual
  );
  // const [state, setState] = useState({
  //   weatherDictionary: {
  //     rainy: IconWeatherRain,
  //     sunny: IconWeatherSunny,
  //   },
  //   latlon: item.f_latlng.split(","),
  //   city: "",
  //   weather: "01d",
  //   temperature: "",
  //   country: "",
  // });

  /**
   * ⚡리포트 수정
   */
  const handleEditAsync = async () => {
    props.history.push(`/projects/documents/create/${projectId}?docId=${itemKey}`);
    try {
      const { data } = await Axios.get(`/report/${item.document_mng_sq}`);

      dispatch(
        setPublishDataSet({
          reportName: item.f_report_name,
          selectedDocSq: item.document_mng_sq,
          selectedDate: item.f_input_date,
          selectedReportItems: data,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * ⚡리포트 삭제
   */
  const handleDelete = () => {
    const handleOkAsync = async () => {
      if (!itemKey) return new Error("no document id");

      try {
        await Axios.post(`/report/${itemKey}/remove`);
        await configReportAndFolder();

        message.success(t("message$Successful_delete"), 3);
      } catch (err) {
        consoleError(err);
      }
    };

    Modal.confirm({
      content: t("message$Do_you_want_to_delete?"),
      onOk: handleOkAsync,
    });
  };

  // 2023.09.22 기 코드가 하드코딩 되어있고 리포트별 날씨 데이터가 의미 없어 보임.
  // 리포트별 getWeatherLocation api 요청으로 인한 성능 문제로 임시 삭제

  // const initWeatherCallback = useCallback(async () => {
  //   try {
  //     const { data } = await Axios.get("/getWeatherLocation", {
  //       params: {
  //         lat: state.latlon[0],
  //         lon: state.latlon[1],
  //       },
  //     });

  //     setState({
  //       ...state,
  //       city: data.name,
  //       country: data.sys.country,
  //     });
  //   } catch (err) {
  //     return consoleError(err);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   initWeatherCallback();
  // }, [initWeatherCallback]);

  return (
    <div className="project-card" style={props.style}>
      <h4 className="project-card__title no-drag" onClick={() => props.onClick(item)}>
        <img className="icon i-location" src={IconLocation} alt="location icon" />
        {item.f_report_name}
      </h4>
      <p className="project-card__caption caption">{item.f_title}</p>
      <p className="project-card__description acumin">
        <img
          className="icon i-weather-rain"
          src={`https://openweathermap.org/img/w/01d.png`}
          alt="weather rain icon"
        />
        {moment(item.f_report_create_dt).format("YYYY-MM-DD")}
        {/* | {state.city}, {state.country} */}
      </p>
      <DropDown
        items={[
          {
            name: capitalizeFirstLetter(t("button$Edit")),
            className: "i-edit",
            icon: <IconEdit width={10} height={10} className="i-edit" />,
            onClick: handleEditAsync,
          },
          {
            name: capitalizeFirstLetter(t("button$Delete")),
            className: "i-delete",
            icon: <IconDelete width={10} height={10} className="i-delete" />,
            onClick: handleDelete,
          },
        ]}
      />
    </div>
  );
};

export default React.memo(DocumentCard);
