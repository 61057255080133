import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, Input, InputNumber, Popconfirm, Table } from "antd";
import { combineClassName } from "utils/StringHelper";
import IconDelete from "../../../assets/icons/delete.svg";
import IconEdit from "../../../assets/icons/edit.svg";
import IconCheck from "../../../assets/icons-2/Icon_Check.svg";
import IconCancel from "../../../assets/icons-2/Icon_Cancel.svg";
import { useTranslation } from "react-i18next";
import "./XcEditableTable.less";

/**
 * @typedef {object} XcEditableTableProps
 * @property {boolean} bordered
 * @property {(import("antd/lib/table").ColumnProps & {editable: boolean, inputType: string})[]} columns
 * @property {any} tableRef
 * @property {boolean} useEditMode
 * @property {boolean} useLineNo
 * @property {JSX.Element} headerMenu
 * @property {React.ReactNode} headerTitle
 * @property {string} format
 * @property {string} wrapperClassName
 * @property {{
 *  edit: React.ImgHTMLAttributes,
 *  delete: React.ImgHTMLAttributes,
 *  confirm: React.ImgHTMLAttributes,
 *  cancel: React.ImgHTMLAttributes,
 *  deleteConfirm: import("antd/lib/popconfirm").PopconfirmProps,
 *  column: import("antd/lib/table").ColumnProps
 * }} operationColumnProps
 * @property {(evt: React.MouseEvent<HTMLImageElement, MouseEvent>, value:{[field: string]: any}) => void} onConfirm
 * @property {(evt: React.MouseEvent<HTMLElement, MouseEvent>, record:any) => void} onDelete
 * @property {(evt: React.MouseEvent<HTMLImageElement, MouseEvent>, record:any) => void} onEdit
 * @property {(evt: React.MouseEvent<HTMLImageElement, MouseEvent>, record:any) => void} onCancel
 */

const XcEditableTableForm = forwardRef(
  (
    /**
     * @type {({form: import("antd/lib/form/Form").WrappedFormUtils} & import("antd/lib/table").TableProps & XcEditableTableProps )}
     */
    { form, ...props },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      form,
    }));
    const {
      tableRef,
      dataSource,
      columns,
      headerMenu = <></>,
      headerTitle = <></>,
      bordered = false,
      useEditMode = true,
      useLineNo = true,
      format,
      wrapperClassName,
      className,
      operationColumnProps,
      onConfirm,
      onDelete,
      onEdit,
      onCancel,
      ...restProps
    } = props;

    const { t } = useTranslation();

    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record) => record.key === editingKey;

    /**
     * @type {import("antd/lib/table").ColumnProps}
     */
    const lineColumn = {
      title: "No.",
      dataIndex: "lineNo",
      key: "lineNo",
      width: "15px",
      onCell: () => ({
        style: {
          fontSize: "11px",
          padding: "0 0 0 2px",
          letterSpacing: "0.01px",
        },
      }),
      render: (_value, _record, i) => i + 1,
    };

    /**
     * @type {import("antd/lib/table").ColumnProps}
     */
    const operationColumn = {
      title: "operation",
      dataIndex: "operation",
      key: "operation",
      width: "40px",
      render: (_value, record, i) => {
        const editable = isEditing(record);
        return (
          <div className={`editable-operation-wrapper`}>
            {editable ? (
              <>
                <img
                  src={IconCheck}
                  onClick={(evt) => {
                    onConfirm && onConfirm(evt, record, form.getFieldsValue());
                    setEditingKey("");
                  }}
                  alt="check icon"
                  title={t("button$Save")}
                  {...operationColumnProps?.confirm}
                />
                <img
                  src={IconCancel}
                  onClick={(evt) => {
                    onCancel && onCancel(evt, record);
                    setEditingKey("");
                  }}
                  alt="cancel icon"
                  title={t("button$Cancel")}
                  {...operationColumnProps?.cancel}
                />
              </>
            ) : (
              <>
                <img
                  src={IconEdit}
                  onClick={(evt) => {
                    onEdit && onEdit(evt, record);
                    form.resetFields();
                    setEditingKey(record.key);
                  }}
                  alt="edit icon"
                  title={t("button$Edit")}
                  {...operationColumnProps?.edit}
                />
                <Popconfirm
                  overlayClassName={"editable-popconfirm"}
                  title={t("message$Do_you_want_to_delete?")}
                  placement="right"
                  okText={t("button$OK")}
                  cancelText={t("button$Cancel")}
                  onConfirm={(evt) => {
                    onDelete && onDelete(evt, record);
                    setEditingKey("");
                  }}
                  {...operationColumnProps?.deleteConfirm}
                >
                  <img
                    src={IconDelete}
                    alt="delete icon"
                    title={t("button$Delete")}
                    {...operationColumnProps?.delete}
                  />
                </Popconfirm>
              </>
            )}
          </div>
        );
      },
      ...operationColumnProps?.column,
    };

    const getMergedColumns = () => {
      if (!columns) return [];

      const mergedLineColumns = useLineNo ? [lineColumn, ...columns] : columns;
      const mergedColumns = useEditMode ? [...mergedLineColumns, operationColumn] : columns;
      return mergedColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.inputType,
            dataIndex: col.dataIndex,
            title: col.title,
            form: form,
            editing: isEditing(record),
            useEditMode: useEditMode,
            format: format,
          }),
        };
      });
    };

    return (
      <Form ref={ref} className={combineClassName("editable-table-form-wrapper", wrapperClassName)}>
        <div className={"editable-table-form-title"}>
          {headerTitle}
          <span>{headerMenu}</span>
        </div>
        <Table
          ref={tableRef}
          bordered={bordered}
          columns={getMergedColumns()}
          dataSource={dataSource}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          className={combineClassName(`xc-editable-table`, className, bordered ? "" : "no-border")}
          {...restProps}
        />
      </Form>
    );
  }
);

export const EnhancedXcEditableTableForm = Form.create()(XcEditableTableForm);

const EditableCell = (props) => {
  const { form, editing, dataIndex, inputType, record, useEditMode, children, ...rest } = props;

  const inputNode =
    inputType === "number" ? (
      <InputNumber className={"editable-input"} />
    ) : (
      <Input className={"editable-input"} />
    );

  const renderItem = () => {
    if (!dataIndex || !form || !editing) {
      return children;
    }
    if (!useEditMode) {
      return (
        <td {...rest}>
          <Input className={"editable-input"} disabled value={record[dataIndex]} />
        </td>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }} key={dataIndex}>
        {form.getFieldDecorator(dataIndex, {
          initialValue: record[dataIndex],
        })(inputNode)}
      </Form.Item>
    );
  };

  return <td {...rest}>{renderItem()}</td>;
};
