import React from "react";
import Arrow from "../../elements/Arrow/Arrow";

import "./index.less";

import { 
  // connect,
  useDispatch } from "react-redux";
import { setWorkspaceVisible } from "../../../store/actions/projectList";

/**
 * @component
 *    - HideDashboard
 * @description 
 *    - Dashboard hide || show
 */
const HideDashboard = (props) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(setWorkspaceVisible());

  return (
    <span className="hide-dashboard" onClick={onClick}>
      <Arrow
        style={{
          padding: "10px 10px",
          width: "100%",
          height: "100%",
          opacity: 0.4,
        }}
      />
    </span>
  );
};

// export default connect((state) => state, { setWorkspaceVisible })(HideDashboard);
export default HideDashboard;
