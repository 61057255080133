import { makeParamPoints } from "../../../xitecloud3D/xiteCloud.dev";
import { store } from "../../../store/store";

/**
 * annotation paramerter options
 */
export function createAnnotation() {
  const state = store.getState();
  const { annotation_mng_sq, toolObj, type, title, measure } = state.projectChildrenComponents.currentMeasure;
  const { selectedDate, currentProject } = state.currentProjectReducer;

  /**
   * colors
   */
  const isPoint = (type === 'point' || type === 'annotation');
  const getHex = isPoint ? `#${measure.pointColor.getHexString()}` : `#${measure.lineColor.getHexString()}`

  return {
    annotation_mng_sq: annotation_mng_sq ? annotation_mng_sq : null,
    project_mng_sq_t_project_mng: currentProject.project_mng_sq,
    f_create_date: selectedDate,
    f_fence_nm: title,
    f_fence_annotation_flag: toolObj.value,
    c_fence_type: toolObj.annotationGroup,
    c_annotation_type: toolObj.measureType,
    f_line_color: getHex,
    f_line_dash: 0,
    f_line_width: measure.lineThickness,
    f_fill_color: '',
    f_fill_transparency: 100,
    f_location: makeParamPoints(measure),
    f_description: '',
    //f_fence_annotation_flag: objParam.toolObj.value,
  }


  // const topo_0 = xitecloudViewDataSet.layer.topo.find((_item) => (_item.dataType === 'pointcloud')).dbObj
  // if ( objParam.toolObj.selectedOptions != undefined ) {

  //   //a2|TOPO
  //   _toolObjValue = objParam.toolObj.selectedOptions.reduce(function (pre, cur) {
  //     return pre.value + '|' + cur.value;
  //   });
  // }  else {
  // }

  // const dbParam = {
  //   project_mng_sq_t_project_mng: currentProject.project_mng_sq,
  //   f_create_date: topo_0.f_input_date,
  //   f_fence_nm: objParam.title,
  //   //f_fence_annotation_flag: objParam.toolObj.value,
  //   f_fence_annotation_flag: _toolObjValue,
  //   c_fence_type: objParam.toolObj.annotationGroup,
  //   c_annotation_type: objParam.toolObj.measureType,
  //   f_line_color: '',
  //   f_line_dash: 0,
  //   f_line_width: 0,
  //   f_fill_color: '',
  //   f_fill_transparency: 100,
  //   f_location: makeParamPoints(objParam.measure),
  //   f_description: ''
  // }


  // axios.post(`/createAnnotation`, {
  //   params: dbParam
  // })
  //   .then(_callback)
  //   .catch((error) => consoleError(error));

  //   _callback();
}

// export function saveAnnotation (dbParam) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`/createAnnotation`, { params: dbParam })
//       .then((data) => resolve(data))
//       .catch((error) => reject(error));
//   });
// }