import Axios from "axios";

const { store } = require("../../../../store/store");

/**
   * @return
   *  { srid, dist, srctable, targettable, baselength, add_points }
   */
export const getCrossSectionInfomation = () => {
  const props = store.getState();
  const measure = props.projectChildrenComponents.currentMeasure
  const layers = props.ViewDataSetReducer.projectViewDataSetModel.layer;
  const crossSectionPoints = props.CrossSectionReducer.addPoints;

  const addPoints = (crossSectionPoints.length !== 0 ) ? crossSectionPoints.map(
    (addedMeasure) =>
      `${addedMeasure.points.map(
        (item) => `${item.position.x} ${item.position.y} ${item.position.z}`
      )}`
  ) : '';

  //workspace Transverse Width 숫자입력 필드에서 엔터 치면 파라미터 오류 발생 [전종수 20200826]
  let _p_addpoint = (addPoints.length > 0 && addPoints.join() !== ',') ? addPoints.join() : '';
  if (_p_addpoint.indexOf(',') === 0) 
      _p_addpoint = _p_addpoint.substring(1);

  return {
    // srid,
    dist: props.CrossSectionReducer.spacing,
    // srctable: layers.topo.find((x) => x.dataType === "pointcloud").dbObj.f_ply_geom_tbl_nm,
    srctable: `layer_${props.currentProjectReducer.currentProject.project_mng_sq}_${props.currentProjectReducer.selectedDate}_original`,
    targettable: layers.target[0].dbObj.f_geometry_tbl_nm,
    points: measure.measure.points
      .map((item) => `${item.position.x} ${item.position.y} ${item.position.z}`)
      .join(),
    baselength: props.CrossSectionReducer.width,
    add_points: _p_addpoint,
  };
};

export const ajaxGetCrossSection = async () => {
  const { data } = await Axios.get("/api/PRJ02030401_01", {
    params: getCrossSectionInfomation(),
  })

  const hasData = (data.length && data[0].data.analysis.length) ? data : [];
  return hasData;
}

export const ajaxGetCrossSectionV2 = async () => {
  const { data } = await Axios.get("/api/PRJ02030401_02", {
    params: getCrossSectionInfomation(),
  })
  return data;
}
