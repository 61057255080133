import {
  PUBLISH_DATASET,
  PUBLISH_INIT,
  REPORT_LIST,
  REPORT_FOLDER,
  REPORT_UN_GROUP,
  PUBLISH_VISIBLE,
  PUBLISH_KEYWORD,
} from "../actions/report";

const initializeReportState = {
  visible: false,
  dataset: null,
  allReports: [],
  folders: [],
  reports: [],
  keyword: "",
};

export default function ReportReducer(state = initializeReportState, action) {
  switch (action.type) {
    case PUBLISH_DATASET:
      return { ...state, dataset: action.dataset };
    case PUBLISH_VISIBLE:
      return { ...state, visible: action.visible };
    case PUBLISH_INIT:
      return { ...state, dataset: null, visible: false, reports: [] };
    case REPORT_LIST:
      return { ...state, allReports: action.list };
    case REPORT_FOLDER:
      return { ...state, folders: action.folders };
    case REPORT_UN_GROUP:
      return { ...state, reports: action.reports };
    case PUBLISH_KEYWORD:
      return { ...state, keyword: action.keyword };
    default:
      return state;
  }
}
