import React from "react";
import { Cascader } from "antd";
import IconPoint from "../../../../assets/icons/point.svg";
import IconLine from "../../../../assets/icons/line.svg";

import IconPolygan from "../../../../assets/icons-2/Icon_Polygan.svg";
import IconViewPointTop from "../../../../assets/icons-2/Icon_View_Point_Top.svg";

import IconIconMapHandle from "../../../../assets/icons-2/Icon_Floating.svg";

import "./tools.less";
import { useTranslation } from "react-i18next";

// export const options = {
//   point: [
//     {
//       value: "",
//       label: t("button$Point"),
//       disabled: true,
//     },
//     {
//       value: "p0",
//       label: "Measurement",
//       measureType: "point",
//       pickAreatarget: "model",
//       annotationGroup: "onsite",
//     },
//     {
//       value: "p2",
//       label: "Annotation Mark",
//       measureType: "annotation",
//       pickAreatarget: "model",
//       annotationGroup: "analytics",
//     },
//   ],
//   line: [
//     {
//       value: "",
//       label: "Line",
//       disabled: true,
//     },
//     {
//       value: "l0",
//       label: "Measurement",
//       measureType: "line",
//       pickAreatarget: "model",
//       annotationGroup: "onsite",
//     },
//     {
//       value: "l2",
//       label: "Section Analysis",
//       measureType: "line",
//       pickAreatarget: "model",
//       annotationGroup: "onsite",
//       crossection: true,
//     },
//     {
//       value: "l1",
//       label: "Geo-fencing",
//       measureType: "line",
//       pickAreatarget: "geo",
//       annotationGroup: "offsite",
//     },
//   ],
//   area: [
//     {
//       value: "",
//       label: "Area",
//       disabled: true,
//     },
//     {
//       value: "a1",
//       label: "Measurement",
//       measureType: "area",
//       pickAreatarget: "model",
//       annotationGroup: "onsite",
//       crossection: false,
//       volume: false,
//       childValue: "TOPO",
//       childLabel: "Curent Topographic Model PCD",
//     },
//     {
//       value: "a2",
//       label: "Volume Estimation",
//       measureType: "area",
//       pickAreatarget: "model",
//       annotationGroup: "onsite",
//       crossection: false,
//       volume: true,
//       childValue: "TOPO",
//       childLabel: "Target Design",
//     },
//   ],
//   view: [
//     {
//       value: "",
//       label: "View Location",
//       disabled: true,
//     },
//     {
//       value: "L",
//       label: "Left Side View",
//     },
//     {
//       value: "R",
//       label: "Right Side View",
//     },
//     {
//       value: "F",
//       label: "Front Side View",
//     },
//     {
//       value: "B",
//       label: "Back Side View",
//     },
//     {
//       value: "T",
//       label: "Top Side View",
//     },
//     {
//       value: "D",
//       label: "Bottom Side View",
//     },
//   ],
//   imagery: [
//     {
//       value: "",
//       label: "GIS map Imagery Control",
//       disabled: true,
//     },
//     {
//       value: "flyVisible",
//       label: "Fly RockLayer",
//     },
//     // {
//     //   value: true,
//     //   label: "3D Terrain",
//     // },
//     // {
//     //   value: false,
//     //   label: "2D Terrain",
//     // },
//     // {
//     //   value: 0,
//     //   label: "Bing Maps Aerial with Labels",
//     // },
//     // {
//     //   value: 1,
//     //   label: "OpenStreet-Map",
//     // },
//     // {
//     //   value: 2,
//     //   label: "ESRI World Street Map",
//     // }
//   ],
// };

const cascadeOptionsTemp = ({ options, childrenImg, onChange }) => {
  return {
    value: [],
    className: "tools__cascader",
    options,
    onChange,
    childrenImg,
  };
};

function ToolsUI(props) {
  const { t } = useTranslation();
  const options = {
    point: [
      {
        value: "",
        label: t("tools$Point"),
        disabled: true,
      },
      {
        value: "p0",
        label: t("tools$Measurement"),
        measureType: "point",
        pickAreatarget: "model",
        annotationGroup: "onsite",
      },
      {
        value: "p2",
        label: t("tools$Annotation_Mark"),
        measureType: "annotation",
        pickAreatarget: "model",
        annotationGroup: "analytics",
      },
    ],
    line: [
      {
        value: "",
        label: t("tools$Line"),
        disabled: true,
      },
      {
        value: "l0",
        label: t("tools$Measurement"),
        measureType: "line",
        pickAreatarget: "model",
        annotationGroup: "onsite",
      },
      {
        value: "l2",
        label: t("tools$Section_Analysis"),
        measureType: "line",
        pickAreatarget: "model",
        annotationGroup: "onsite",
        crossection: true,
      },
      {
        value: "l1",
        label: t("tools$Geo-fencing"),
        measureType: "line",
        pickAreatarget: "geo",
        annotationGroup: "offsite",
      },
    ],
    area: [
      {
        value: "",
        label: t("tools$Area"),
        disabled: true,
      },
      {
        value: "a1",
        label: t("tools$Measurement"),
        measureType: "area",
        pickAreatarget: "model",
        annotationGroup: "onsite",
        crossection: false,
        volume: false,
        childValue: "TOPO",
        childLabel: "Curent Topographic Model PCD",
      },
      {
        value: "a2",
        label: t("tools$3D Volume Estimation"),
        measureType: "area",
        pickAreatarget: "model",
        annotationGroup: "onsite",
        crossection: false,
        volume: true,
        childValue: "TOPO",
        childLabel: "Target Design",
      },
    ],
    view: [
      {
        value: "",
        label: t("tools$View_Location"),
        disabled: true,
      },
      {
        value: "L",
        label: t("tools$Left_Side_View"),
      },
      {
        value: "R",
        label: t("tools$Right_Side_View"),
      },
      {
        value: "F",
        label: t("tools$Front_Side_View"),
      },
      {
        value: "B",
        label: t("tools$Back_Side_View"),
      },
      {
        value: "T",
        label: t("tools$Top_Side_View"),
      },
      {
        value: "D",
        label: t("tools$Bottom_Side_View"),
      },
    ],
    imagery: [
      {
        value: "",
        label: "GIS map Imagery Control",
        disabled: true,
      },
      {
        value: "flyVisible",
        label: "Fly RockLayer",
      },
      // {
      //   value: true,
      //   label: "3D Terrain",
      // },
      // {
      //   value: false,
      //   label: "2D Terrain",
      // },
      // {
      //   value: 0,
      //   label: "Bing Maps Aerial with Labels",
      // },
      // {
      //   value: 1,
      //   label: "OpenStreet-Map",
      // },
      // {
      //   value: 2,
      //   label: "ESRI World Street Map",
      // }
    ],
  };

  const cascadeOptions = [
    {
      options: options.point,
      onChange: props.onChangeTools,
      childrenImg: {
        src: IconPoint,
        alt: "point icon",
      },
    },
    {
      options: options.line,
      onChange: props.onChangeTools,
      childrenImg: {
        src: IconLine,
        alt: "line icon",
      },
    },
    {
      options: options.area,
      onChange: props.onChangeTools,
      childrenImg: {
        src: IconPolygan,
        alt: "area icon",
        style: {
          width: "20px",
          height: "20px",
        },
      },
    },
    {
      options: options.view,
      onChange: props.onChangeView,
      childrenImg: {
        src: IconViewPointTop,
        alt: "view icon",
        style: {
          width: "14px",
          height: "14px",
        },
      },
    },
  ].map((item) => cascadeOptionsTemp(item));

  return (
    <div className="tools">
      {cascadeOptions.map((cascade, i) => (
        <Cascader
          key={i}
          value={cascade.value}
          className={cascade.className}
          options={cascade.options}
          onChange={cascade.onChange}
        >
          <span>
            <img
              src={cascade.childrenImg.src}
              alt={cascade.childrenImg.alt}
              style={cascade.childrenImg.style}
            />
          </span>
        </Cascader>
      ))}

      <span className="tools__cascader" onClick={props.onChangeImagery}>
        <img
          src={IconIconMapHandle}
          alt="imagery icon"
          style={{
            width: "14px",
            height: "14px",
          }}
        />
      </span>
      {/* <img className="tools__compare" src={IconCompare} alt="compare icon" /> */}

      {/* 기존위치 -> 메뉴 단으로 이동 */}
      {/* <ModalAddUsers className="tools__add-users" /> */}
    </div>
  );
}

export default React.memo(ToolsUI);
