//actions
import { ROCK_CHART_DATA, ROCK_CHART_DATA_COLOR, CHART_ROCK_CHART_DATA  } from '../actions/rockChartDataSet';

const initializeRockDataState = {
    rockDataSet: {},
    rockChartDataSet: [],
    colors: [],
}

export default function RockDataSetReducer(state = initializeRockDataState, action) {
    switch (action.type) {
        case ROCK_CHART_DATA: return { ...state, rockDataSet: action.obj };
        case ROCK_CHART_DATA_COLOR: return { ...state, colors: action.colors };
        case CHART_ROCK_CHART_DATA: return { ...state, rockChartDataSet: action.chartDataSet };
        default: return state;
    }
}