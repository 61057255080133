//types
export const MODAL_VISIBLE = 'MODAL/VISIBLE';
export const MODAL_SUCCESS = 'MODAL/SUCCESS';
export const MODAL_WARNING = 'MODAL/WARNING';

export const MODAL_INIT = 'MODAL/INIT';

export const setModalInit = () => ({ type: MODAL_INIT });
export const setModalVisible = visible => ({ type: MODAL_VISIBLE, visible});
export const setModalSuccess = ({ success, title, message }) => ({ type: MODAL_SUCCESS, success, title, message });
export const setModalWarning = ({ warning, title, message }) => ({ type: MODAL_WARNING, warning, title, message });
