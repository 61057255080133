import React from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button } from "antd";
import IconPathFind from "../../../assets/icons/pathfind.svg";
import IconArrowLineUpDown from "../../../assets/icons/arrow-line-up-down.svg";
import { capitalizeFirstLetter } from "utils/StringHelper";
import "./houlingRouteNavigator.less";

class HoulingRouteNavigatorModal extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="houling-route-navigator">
        <img
          className="i-pathfind"
          src={IconPathFind}
          onClick={this.showModal}
          alt="pathfind icon"
        />
        <Modal
          title="Houling Route Navigator"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="houling-route-navigator__modal"
          width={500}
          centered
          footer={[
            <Button
              className="houling-route-navigator__modal__confirm"
              key="submit"
              onClick={this.handleOk}
            >
              {capitalizeFirstLetter(t("button$Save"))}
            </Button>,
          ]}
        >
          <ul className="group">
            <li className="left">
              <Button>{t("button$Choose_starting_point_or_click_on_the_map")}</Button>
              <Button>{t("button$Choose_destination_or_click_on_the_map")}</Button>
              <img src={IconArrowLineUpDown} alt={"arrow line up down icon"} />
            </li>
            <li className="right">
              <Button className="right__find">{capitalizeFirstLetter(t("button$Find"))}</Button>
              <Button className="right__distance">
                {`${capitalizeFirstLetter(t("button$Distance"))}(m)`}
              </Button>
            </li>
          </ul>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(HoulingRouteNavigatorModal);
