import React from 'react';
import { CHAT_SERVER_PATH } from '../env';
import IconDown from '../../../../../assets/icons-2/Icon_Download.svg';
const ImageChatMessage = (props) => {

  // window.open(
  //   CHAT_SERVER_PATH + `fileDownload?fileId=${props.item.file.sq}`
  // )

    return (
    //download
      // <span className="msg-img-wrapper" onClick={() => window.open(`/images.html?images=${props.item.file._id}`, '_blank')}></span>
      <span className="msg-img-wrapper" onClick={() => window.open(CHAT_SERVER_PATH + `fileDownload?fileId=${props.item.file.sq}`)}>
      {/* <img className="msg-wrap-img" src={`${LOCATION_PATH}uploads/chats/${props.item.file.name}`} */}
      {/* <img className="msg-wrap-img" src={`${CHAT_SERVER_PATH}uploads/chats/${props.item.file.name}`} */}
      <img className="msg-wrap-img" src={`${CHAT_SERVER_PATH}${props.item.file.path}`}
        alt="uploaded messages"
      />
      <span className="img-layer">
        <div>
          <img className="icon-download" src={IconDown} />
        </div>
      </span>
    </span>
    );
}

export default ImageChatMessage;
