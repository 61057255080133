//project
export const CURRENT_PROJECT = "PROJECT/CURRENT_PROJECT";
export const setCurrentProject = (project) => ({ type: CURRENT_PROJECT, project });

//project
export const CURRENT_PROJECT_ALL = "PROJECT/CURRENT_PROJECT/All_SET";
export const setCurrentProjectAllProps = (project, dataList) => ({
  type: CURRENT_PROJECT,
  project,
  dataList,
});

export const CURRENT_PROJECT_INIT = "PROJECT/CURRENT_PROJECT_INIT";
export const setCurrentProjectInit = () => ({ type: CURRENT_PROJECT_INIT });

export const CURRENT_PROJECT_OPTION = "PROJECT/CURRENT_PROJECT_OPTION";
export const setCurrentProjectOption = (option) => ({ type: CURRENT_PROJECT_OPTION, option });

//date
export const PROJECT_DATE_SET = "PROJECT/DATE_SET";
export const setProjectDateSet = (date) => ({ type: PROJECT_DATE_SET, date });

//selected date
export const PROJECT_SELECTED_DATE_SET = "PROJECT/SELECTED_DATE_SET";
export const setProjectSelectedDateSet = (date) => ({ type: PROJECT_SELECTED_DATE_SET, date });
