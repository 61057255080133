import { sortBy, uniq, flatten } from "lodash";

/**
 * 
 * @param {:object} rock_info
 *      - borehole rock infomation [ -1: target, 0: topo , else: ...customRockInfo ]
 * @param {:object} x 
 *      - pivot
 * @param {boolean} bool 
 *      - section rockinfo - next_gbn -> rock_name
 *      - is not section - gbn -> rock_name
 */
export function rockName(rock_info, x, bool) {
    if (!rock_info) return "target"
    if (bool) return rock_info.find((y) => y.gbn === x.nxt_gbn).f_rock_type_nm

    return x.gbn === -1 ? "target" : rock_info.find((y) => y.gbn === x.gbn) ? rock_info.find((y) => y.gbn === x.gbn).f_rock_type_nm : "topo";
};

/**
 * 
 * @param {:object} rock_info 
 *      - borehole rock infomation [ -1: target, 0: topo , else: ...customRockInfo ]
 *      - target color -> "#B6B6B6"
 *      - topo color -> "#00001a"
 *      - else -> user config color
 * @param {:object} x 
 *      - pivot
 */
export function rockColors(rock_info, x) {
    if (!rock_info) return "#B6B6B6";
    return x.gbn === -1 ? "#B6B6B6" : rock_info.find((y) => y.gbn === x.gbn) ? rock_info.find((y) => y.gbn === x.gbn).f_rock_type_color : "#00001a"
};

/**
 * 
 * @param {:object} dataset  { 
 *      rock_info: user config rock info,
 *      insec_rock_line: chart dataset,
 *      c_info: cut info,
 *      f_info: fill info,
 *      lon_insec_pt: longitudinal info,
 *      t_base: station info,
 *      t_summary: request parmeter options + z(min, max) 
 *  }
 * @param {*} reactSetAnalysis
 *      - react useState set callback
 */
export function setChartAnalysis(dataset, reactSetAnalysis) {
    const lon_insec_pt = dataset.lon_insec_pt;

    reactSetAnalysis({
        analysis: sortBy(
            lon_insec_pt.map((x) => ({
                bottom_z: x.bottom_z,
                cut_z: x.cut_z,
                ex_dist: x.ex_dist,
                fill_z: x.fill_z,
                pt_dist: x.pt_dist,
                st_idx: x.st_no,
                top_z: x.top_z,
                pt_idx: x.st_no,
            })),
            ["pt_idx"]
        ),
    });
}

/**
 * 
 * @param {:object} dataset  { 
 *      rock_info: user config rock info,
 *      insec_rock_line: chart dataset,
 *      c_info: cut info,
 *      f_info: fill info,
 *      lon_insec_pt: longitudinal info,
 *      t_base: station info,
 *      t_summary: request parmeter options + z(min, max) 
 *  }
 * @param {*} reactSetAnalysis 
 *      - react useState set callback
 * 
 * @param {*} reactSetLongData 
 *      - react useState set callback
 * 
 * @description
 *      - using report component
 */
export function setReportChart(dataset, reactSetAnalysis, reactSetLongData) {
    const { rock_info, insec_rock_line, target_line, org_topo } = dataset
    
    let originalTopoArr = [];
    const Longitud = insec_rock_line.find(x => x.st_no === -1);
    const LongitudData = Longitud.line_pt_arr.map(x => {

        const data = [];

        if (rockName(rock_info, x) === 'target' && target_line) {
            const longTarget = target_line.find(x => x.st_no === -1)
            return (
                configTargetChartData(longTarget)
                .map((target, index) => ({
                    data: target,
                    rockInfo: rockName(rock_info, x),
                    colors: rockColors(rock_info, x)
                }))
            )
        } else {
            x.line_pt_arr.map(y => data.push({
                x: y.ex_dist,
                y: y.z
            }))
        }

        return ({
            data,
            rockInfo: rockName(rock_info, x),
            colors: rockColors(rock_info, x)
        })

    })
    
    if (org_topo) {
        const org_topo_1 = org_topo.find(x => x.st_no === -1);
        if (org_topo_1) {
            originalTopoArr = org_topo_1.line_pt_arr.map(x => {
                const data = [];

                x.line_pt_arr.map(y => data.push({
                    x: y.ex_dist,
                    y: y.z
                }));

                return ({
                    data,
                    rockInfo: '원지반',
                    colors: '#00ff00'
                })
            })
            
        }

    }

    if (originalTopoArr.length > 0) LongitudData.push(originalTopoArr)

    reactSetLongData(flatten(LongitudData).map(x => {
        return ({
            label: x.rockInfo,
            data: x.data,
            // backgroundColor: defaultColorArr[i],
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: x.colors,
            showLine: true,
            borderWidth: 1,
            fill: true,
        })
    }))

    if (reactSetAnalysis) {
        setChartAnalysis(dataset, reactSetAnalysis)
    }
}

//target_line 데이터 parsing 용
export function configTargetChartData(longOrSection, xaxis = 0) {
    const targetLineArr = uniq(longOrSection.pt_arr.map(y => y.r_grp_index));

    const targets = []
    for (let i = 0; i < targetLineArr.length; i++) {
        const temp = [];
        const lineNum = targetLineArr[i];
        const targetData = longOrSection.pt_arr.filter(y => y.r_grp_index === lineNum);
        
        targetData.map(y => {
            temp.push({ x: y.st_x - xaxis, y: y.st_z })
            temp.push({ x: y.en_x - xaxis, y: y.en_z })
            return;
        })

        targets.push(temp);
    }

    return targets;
}

//target_line_arr 데이터 parsing 용 [2021.09.28 전종수]
export function configTargetChartData2(longOrSection, xaxis = 0) {
    const targetLineArr = uniq(longOrSection.pt_arr.map(y => y.r_grp_index));

    const targets = []
    for (let i = 0; i < targetLineArr.length; i++) {
        const temp = [];
        const lineNum = targetLineArr[i];
        const targetData = longOrSection.pt_arr.filter(y => y.r_grp_index === lineNum);
        
        targetData.map(y => {
            temp.push({ x: y.st_x - xaxis, y: y.st_z })
            // temp.push({ x: y.en_x - xaxis, y: y.en_z })
            return;
        })

        targets.push(temp);
    }

    return targets;
}