//actions
import { CURRENT_MODEL } from '../actions/currentModel';

//state
const currentModel = {

}

export default function CurrentModelReducer (state = currentModel, action) {
    switch (action.type) {
        case CURRENT_MODEL : return { ...action.model };
        default: return state;
    }
}