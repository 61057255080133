import React from "react";
import { i18n } from "react-i18next/i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
const { confirm } = Modal;

export const removeItem = (props) => {
  confirm({
    title: props.title ? props.title : i18n.t("message$Do_you_want_to_delete?"),
    icon: props.icon ? props.icon : <ExclamationCircleOutlined />,
    content: props.content ? props.content : " ",
    onOk: props.onOk,
    onCancel: props.onCancel,
  });
};
