import React from "react";
import { connect } from "react-redux";
import { setTopographicTitle } from "../../../../store/actions/uploadModel";
import FileUpload from "../fileUpload";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const UploadTopoWrap = (props) => {
  const { t } = useTranslation();
  return (
    <div className="topo-upload-wrap">
      <label>
        - {t("title$Point_Cloud_Data")}
        <Input
          disabled={props.disabled}
          placeholder={t("placeholder$Enter_name")}
          onChange={(e) => props.setTopographicTitle(e.target.value, "pointCloud")}
        />
        {/* <span style={spanStyle}>{this.props.modelUploadReducer.fileNames.topographic ? this.props.modelUploadReducer.fileNames.topographic : 'Accept Type "xyz"'}</span> */}
        <FileUpload disabled={props.disabled} fileKey="topographic" detailKey="pointCloud" />
      </label>
      <label>
        - {t("title$OrthoPhoto_Data")}
        <Input
          disabled={props.disabled}
          placeholder={t("placeholder$Enter_name")}
          onChange={(e) => props.setTopographicTitle(e.target.value, "orthophoto")}
        />
        {/* <span style={spanStyle}>{this.props.modelUploadReducer.fileNames.topographic ? this.props.modelUploadReducer.fileNames.topographic : 'Accept Type "xyz"'}</span> */}
        <FileUpload disabled={props.disabled} fileKey="topographic" detailKey="orthophoto" />
      </label>
    </div>
  );
};
// export default UploadTopoWrap;
export default connect((state) => state, { setTopographicTitle })(UploadTopoWrap);
