//types
export const SET_MODEL_OVERLAY_FILE = "MODEL/FILE/OVERLAY";
export const INIT_MODEL_OVERLAY = "MODEL/INIT/OVERLAY";
export const SET_OVERLAY_WORKSPACE = "MODEL/VISIBLE/OVERLAY";
export const SET_OVERLAY_3D_OBJECT = "MODEL/3D_OBJECT/OVERLAY";
export const SET_OVERLAY_TITLE = "MODEL/TITLE/OVERLAY";
export const SET_OVERLAY_TITLE_AND_TYPE = "MODEL/TYPE_AND_TITLE/OVERLAY";

export const setOverlayTitles = (title) => ({ type: SET_OVERLAY_TITLE, title });
export const setOverlayTypeAndTitle = ({ fileType, title, overlayKey }) => ({
  type: SET_OVERLAY_TITLE_AND_TYPE,
  fileType,
  title,
  overlayKey,
});
export const setOverlayFile = (file) => ({ type: SET_MODEL_OVERLAY_FILE, file });
export const setOverlayObject = (obj, workspace = true) => ({
  type: SET_OVERLAY_3D_OBJECT,
  obj,
  workspace,
});
export const setOverlayWorkspace = (visible) => ({ type: SET_OVERLAY_WORKSPACE, visible });
export const setOverlayInit = () => ({ type: INIT_MODEL_OVERLAY });
