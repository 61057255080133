import {
  currentBackupMeasure,
  currentMeasure,
} from "../../../store/actions/projectChildrenComponent";
import { store } from "../../../store/store";
import { toolsChange } from "./initialize";

export const setMarker = (point, index) => {
  if (!point) return;

  const currMeasure = store.getState().projectChildrenComponents.currentMeasure;
  const { measure } = currMeasure;
  const { x = 0, y = 0, z = 0 } = point;

  measure.points[index].position = new window.THREE.Vector3(x, y, z);
  measure.update();
  store.dispatch(currentMeasure(currMeasure));
};

export const setMarkerPosition = (value, index, key) => {
  if (isNaN(+value)) return;

  const currMeasure = store.getState().projectChildrenComponents.currentMeasure;
  const { measure } = currMeasure;

  measure.points[index].position[key] = +value;
  measure.update();
};

export const addMarker = (point) => {
  if (!point) return;

  const currMeasure = store.getState().projectChildrenComponents.currentMeasure;
  const { measure } = currMeasure;
  const { x = 0, y = 0, z = 0 } = point;

  measure.addMarker(new window.THREE.Vector3(x, y, z));
  measure.update();
  store.dispatch(currentMeasure(currMeasure));
};

export const removeMarker = (...idx) => {
  const currMeasure = store.getState().projectChildrenComponents.currentMeasure;
  const { measure } = currMeasure;

  if (idx.length > 0) {
    idx.sort((a, b) => b - a).forEach((i) => measure.removeMarker(i));
  } else {
    measure.removeMarker(idx);
  }

  measure.update();
  store.dispatch(currentMeasure(currMeasure));
};

export const resetMarker = () => {
  const currMeasure = store.getState().projectChildrenComponents.currentMeasure;
  const { toolObj, measure } = currMeasure;
  store.dispatch(currentBackupMeasure(measure));

  toolsChange([toolObj], true);
};
