import React, { useEffect, useState } from "react";
import { Tabs, Checkbox } from "antd";

import Model from "../../../components/blocks/model";
import UploadModal from "../../../components/modals/upload";
import useLoadAnnotation from "../hooks/useLoadAnnotation";
// import { layerAndAnnotationModel } from "./modelDataset";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LayerSplit from "../../../components/blocks/split";
import { VisibleAnnotation } from "../../../xitecloud3D/xiteCloud.dev";
import { capitalizeFirstLetter } from "utils/StringHelper";

// 20210429 최대한 LayerSplit 기능 추가
const { TabPane } = Tabs;

const HasModel = (props) => {
  const { t, i18n } = useTranslation();
  /**
   * custom hook
   *      - render to tab
   */
  const [tabKey, onChangeTab] = useLoadAnnotation(
    useSelector((state) => state.projectChildrenComponents.currentMeasure)
  );

  /**
   * data schema
   */
  const { layer, annotations } = useSelector(
    (state) => ({
      layer: state.ViewDataSetReducer.projectViewDataSetModel.layer,
      annotations: state.ViewDataSetReducer.projectViewDataSetModel.annotations,
    }),
    shallowEqual
  );

  // annotation title visible on/off
  const [check, setcheck] = useState(false);

  const onCheck = () => {
    setcheck(!check);

    VisibleAnnotation(annotations.onsite, !check);
    VisibleAnnotation(annotations.offsite, !check);
    VisibleAnnotation(annotations.analytics, !check);
  };
  // console.log(layerModel, annotationModel)
  /**
   * models schema
   */
  const [ModelsList, setModelsList] = useState([]);

  useEffect(() => {
    let list = [
      {
        type: t("title$Visualization"),
        key: "1",
        models: [
          {
            title: t("title$Topographic_Model"),
            items: layer.topo,
            key: "topo",
          },
          {
            title: t("title$Sub-surface_Model"),
            items: layer.subsurface,
            key: "surface",
          },
          {
            title: t("title$Target_Design_Model"),
            items: layer.target,
            key: "target",
          },
          {
            title: `${t("title$Overlays_Information")}`,
            items: layer.overlay,
            key: "overlay",
          },
        ],
        children: (
          <div className="layers__top">
            <UploadModal match={props.match} />
          </div>
        ),
      },
      {
        type: t("title$Analysis"),
        key: "2",
        models: [
          {
            title: capitalizeFirstLetter(t("title$Geo-fences")),
            items: annotations.offsite,
            key: "geo",
          },
          {
            title: capitalizeFirstLetter(t("title$Analytic", { count: 2 })),
            items: annotations.onsite,
            key: "anlytics",
          },
          {
            title: t("title$Annotation_Mark"),
            items: annotations.analytics,
            key: "annotationMark",
          },
        ],
        children: <div className="annotations__top" />,
      },
    ];
    setModelsList(list);
  }, [layer, annotations, i18n.language]);

  return (
    <>
      <Tabs className="project__content__tab" type="card" activeKey={tabKey} onChange={onChangeTab}>
        {ModelsList.map((model) => (
          <TabPane className={model.type} tab={model.type} key={model.key}>
            {model.children}
            <Model models={model.models} name={model.type} history={props.history} />
          </TabPane>
        ))}
      </Tabs>
      <LayerSplit />
      <div style={{ padding: "0px 10px" }}>
        <Checkbox checked={check} onClick={onCheck} defaultChecked={false}>
          {t("button$Annotation_Visible")}
        </Checkbox>
      </div>
    </>
  );
};

export default React.memo(HasModel);
