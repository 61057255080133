//actions
import { MODAL_VISIBLE, MODAL_SUCCESS, MODAL_WARNING, MODAL_INIT } from '../actions/Modal';

const initializeModalState = {
    visible: false,
    success: false,
    warning: false,
    message: '',
    title: '',
}

export default function ModalReducer(state = initializeModalState, action) {
    switch (action.type) {
        case MODAL_INIT: return {
            visible: false,
            success: false,
            warning: false,
            message: '',
            title: '',
        }
        case MODAL_VISIBLE: return { ...state, visible: action.visible };
        case MODAL_SUCCESS: return {
            visible: true,
            success: action.success,
            warning: false,
            message: action.message,
            title: action.title
        };

        case MODAL_WARNING: return {
            visible: true,
            success: false,
            warning: action.warning,
            message: action.message,
            title: action.title
        };

        default: return state;
    }
}