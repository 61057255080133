//actions
import { COLOR_CURRENT_COLOR, COLOR_INIT, COLOR_VISIBLE } from '../actions/MeasurementColor';

const initializeColorState = {
    visible: false,
    selectedColor: ''
}

export default function MeasureColorReducer(state = initializeColorState, action) {

    switch (action.type) {
        case COLOR_CURRENT_COLOR: return { ...state, selectedColor: action.hex };
        case COLOR_INIT: return initializeColorState;
        case COLOR_VISIBLE: return { ...state, visible: action.visible };

        default: return state;
    }
}