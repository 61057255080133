import { notification } from "antd";
import Axios from "axios";
import {
  setAddRequestList,
  setIsShow,
  setLoginTime,
  setRemoveRequestList,
} from "../store/actions/app";
import { store } from "../store/store";
import { requestIdMaker } from "./Hibrary";
import errorOperator from "./errorHandler";
import { i18n } from "react-i18next/i18next";

/**
 *  blockUrl: 로딩 애니메이션(LoadingBar) 띄우지 않을 호출 URL 저장 변수
 *  20230712: Section > 기성일 조회(/section/commitList), 설계차수 조회(/section/designSeq), 기성 기준일 조회(/section/topoList) 추가
 *  20230721: XiteCloud Analyst > Layers > 일자(/layerDateInfo/check), Topographic Model/Overlay Information(/loadProjectInfoAll/check) 추가
 */
const blockUrl = [
  "/heartBeat",
  "/authenticate",
  "/section/commitList",
  "/section/designSeq",
  "/section/topoList",
  "/layerDateInfo/check",
  "/loadProjectInfoAll/check",
  "/fleet_history/history",
];

/**
 *  passInterceptoreUrl: 별도 오류 처리할 URL 저장 변수
 * 20240703: 최초 생성
 */
const passInterceptoreUrl = ["/authenticate"];

let requestId = requestIdMaker();

/**
 * @AxiosConfig
 *      -Request
 */
Axios.interceptors.request.use((config) => {
  config.requestId = requestId.next().value;
  /**
   * add http list
   * ⚡url이 아닌 요청별 로딩 애니메이션 숨기기, config에 hideLoadingBar 옵션 추가
   */
  if (!(blockUrl.includes(config.url) || config.hideLoadingBar)) {
    store.dispatch(setAddRequestList(config));

    /** loadding bar visible true */
    store.dispatch(setIsShow(true));
  }

  store.dispatch(setLoginTime(new Date()));

  /**
   * production route
   */
  if (config.url.indexOf("http") === -1 && process.env.NODE_ENV === "production") {
    config.url = `/ngin${config.url}`;
  }

  // console.log(config)
  return config;
}, errorHandler);

/**
 * @AxiosConfig
 *      -Response
 */
Axios.interceptors.response.use((response) => {
  store.dispatch(setLoginTime(new Date()));

  // heartBeat 세션 유지 호출
  if (!blockUrl.includes(response.config.url)) {
    store.dispatch(setRemoveRequestList(response.config));
    store.dispatch(setIsShow(false));
  }

  return response;
}, errorHandler);

function errorHandler(error) {
  const isPassInterceptor =
    error.config.url.includes(passInterceptoreUrl) && error.response.status === 422;
  const isPermission = sessionStorage.userInfo && error.response.status === 403;

  if (!isPassInterceptor && !isPermission) {
    if (error.response) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      console.log(error.request);
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      console.log("Error", error.message);
    }

    console.log(error.config);
    console.log(error);
    if (error.code) {
      notification.error({
        message: error.name,
        description: error.message,
      });
    }

    store.dispatch(setRemoveRequestList(error.config));

    if ("stack" in error && "message" in error) {
      notification.error(errorOperator(error.message, i18n));
    }

    store.dispatch(setIsShow(false));
    return Promise.reject(error);
  } else if (isPassInterceptor) {
    // 별도 오류 메시지 처리 - 로그인 화면(URL)
    notification.error({
      message: i18n.t("title$Login_Fail"),
      description: i18n.t("message$Check_Login_ID_PW"),
    });
  } else if (isPermission) {
    sessionStorage.removeItem("userInfo");
    window.location.href = "/";

    store.dispatch(setIsShow(false));
    return Promise.reject(error);
  }
}
