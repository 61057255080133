
// const interface = {
//     pointSize: 1,
//     pointOpacity: 1,
//     // material: null,
//     bWidth: 0,
//     bMin: 0,
//     bMax: 0,
//     elevationMapVisible: false,
//     elevationRange: [0, 0]
//   }

export function visualizationOption(currentModelReducer) {
  const localStoragePonintOption = JSON.parse(localStorage.getItem(`pointCloud_${currentModelReducer.dbObj.topo_data_sq}`));

  if (localStoragePonintOption) {
    const _3Object = currentModelReducer._3DObject;
    let material = _3Object.material;

    if (localStoragePonintOption.elevationMapVisible) {
      /**
      * gradient effect :RAINBOW
      */
      _3Object.material.activeAttributeName = 'elevation';
      material.gradient = window.Potree.Gradients.RAINBOW;
      _3Object.material.elevationRange = localStoragePonintOption.elevationRange

      // currentModelReducer._3DObject.updateMatrixWorld();
    }

    if (!isNaN(localStoragePonintOption.pointSize)) {
      material.size = localStoragePonintOption.pointSize;
    }

    if (isNaN(localStoragePonintOption.pointOpacity)) {
      window.viewer.setEDLOpacity(localStoragePonintOption.pointOpacity)
    }

  }
}