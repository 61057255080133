import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Modal } from "antd";
import Axios from "axios";
import { SESSION_ALERT_TIME, SESSION_LIMIT_TIME } from "../../../config/history";
import { setLoginTime, setUser } from "../../../store/actions/app";
import { setToken } from "../../../store/actions/token";

const LoginExtDialog = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startTime = useSelector((state) => state.appState.loginTime);
  const [limitTime, setLimitTime] = useState(SESSION_LIMIT_TIME);

  let timer;

  useEffect(() => {
    setLimitTime(SESSION_LIMIT_TIME);
    return () => {};
  }, [startTime]);

  useEffect(() => {
    if (limitTime <= 0) {
      if (sessionStorage.userInfo) {
        sessionStorage.removeItem("userInfo");
      }
    } else {
      timer = setInterval(fnSetTime, 500);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [limitTime]);

  const fnSetTime = () => {
    let curTime = new Date();

    let timeTerm = parseInt((curTime.getTime() - startTime.getTime()) / 1000);

    let time = SESSION_LIMIT_TIME - timeTerm;

    setLimitTime(time);
  };

  const handleLoginExt = useCallback(() => {
    Axios.get("/heartBeat");
  }, []);

  const handleLogout = () => {
    if (timer) clearInterval(timer);
    Axios.get("/authenticate").then(({ status }) => {
      dispatch(setUser(null));
      dispatch(setToken(null));
      if (sessionStorage.userInfo) sessionStorage.removeItem("userInfo");
      window.location.href = "/";
    });
  };

  return (
    <>
      <Modal
        width={350}
        centered={true}
        visible={limitTime < SESSION_ALERT_TIME && limitTime > 0}
        maskClosable={false}
        title={t(`button$Login_Extension`)}
        footer={
          <>
            <Button type="primary" onClick={handleLoginExt}>
              {t(`button$Login_Extension`)}
            </Button>
            <Button type="ghost" onClick={handleLogout}>
              {t(`button$LogOut`)}
            </Button>
          </>
        }
      >
        <p>{t(`message$자동_로그아웃까지_%{count}초_남았습니다.`, { count: limitTime })}</p>
      </Modal>
      {limitTime <= 0 && <LoginModal />}
    </>
  );
};

const LoginModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.addEventListener("keypress", onKeyPress);
    return () => {
      window.removeEventListener("keypress", onKeyPress);
    };
  });

  const handleLogin = async () => {
    if (!username || !password) {
      return;
    }

    try {
      const { data } = await Axios.post("/authenticate", {
        username,
        password,
      });

      sessionStorage.setItem("userInfo", JSON.stringify(data));
      dispatch(setLoginTime(new Date()));
      dispatch(setUser(data));
      dispatch(setToken(data.token)); // token 정보 redux 저장
    } catch (err) {}
  };

  const handleCancel = () => {
    handleLogout();
  };

  const handleLogout = () => {
    Axios.get("/authenticate").then(({ status }) => {
      dispatch(setUser(null));
      dispatch(setToken(null));
      if (sessionStorage.userInfo) sessionStorage.removeItem("userInfo");
      window.location.href = "/";
    });
  };

  const onKeyPress = (event) => {
    if (event.keyCode === 13) {
      return handleLogin();
    }
  };

  return (
    <>
      <Modal
        width={350}
        centered={true}
        visible={true}
        maskClosable={false}
        onCancel={handleCancel}
        title={t(`title$로그인`)}
        footer={
          <>
            <Button type="primary" onClick={handleLogin}>
              {t(`button$Login`)}
            </Button>
            <Button type="ghost" onClick={handleCancel}>
              {t(`button$Cancel`)}
            </Button>
          </>
        }
      >
        <Form name="basic">
          <Form.Item
            name="username"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            value={username}
            rules={[{ required: true, message: t("message$Please_input_your_username") }]}
          >
            <Input placeholder={t("placeholder$E-mail_address")} />
          </Form.Item>

          <Form.Item
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            rules={[{ required: true, message: t("message$Please_input_your_password") }]}
          >
            <Input.Password placeholder={t("placeholder$Password")} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(LoginExtDialog);
