//actions
import { MEASUREMENT, CURRENT_MEASUREMENT, POINTCLOUD, CURRENT_BACK_MEASUREMENT, INIT_MEASURE, CONTOUR, FLEET_GEOFENCE } from '../actions/projectChildrenComponent';

const initializeProjectComponentVisibleState = {
    measurementComponent: false,
    currentMeasure: null,
    backupMeasure: null,
    pointCloudComponent: false,
    contourMapComponent: false,
    fleetGeoComponent: false,
    // editingPointCloud: null,
    // currentPointCloud: null
}

export default function ProjectComponentVisibleReducer (state = initializeProjectComponentVisibleState, action) {
    switch (action.type) {
        case MEASUREMENT : return { ...state, measurementComponent: action.visible };
        case CURRENT_MEASUREMENT : return { ...state, currentMeasure: action.measure };
        case CURRENT_BACK_MEASUREMENT : return { ...state, backupMeasure: action.measure }
        case POINTCLOUD : return { ...state, pointCloudComponent: action.visible };
        case CONTOUR : return { ...state, contourMapComponent: action.visible };
        case FLEET_GEOFENCE : return { ...state, fleetGeoComponent: action.visible };
        case INIT_MEASURE : return initializeProjectComponentVisibleState;
        // case CURRENT_POINTCLOUD : return {
        //     ...state, 
        //     editingPointCloud: action.pointCloud,
        //     currentPointCloud: action.pointCloud,
        // };

        default: return state;
    }
}