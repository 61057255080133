import { CaretDownOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Empty, Row } from "antd";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import ContentTitle from "components/elements/text/contentTitle";
import "./newDocument.less";

export const NewDocumentItemPanel = (props) => {
  const { t } = useTranslation();
  const { data, onChange } = props;

  /**
   * ⚡annotation item check
   */
  const handleItemChange = (evt, item, pIndex, cIndex) => {
    const clonedData = _.cloneDeep(data);
    clonedData[pIndex].list[cIndex] = {
      ...item,
      selected: evt.target.checked,
    };

    onChange && onChange(clonedData);
  };

  /**
   * ⚡annotation items title check
   */
  const handleTitleChange = (evt, items, index) => {
    const clonedData = _.cloneDeep(data);
    clonedData[index].list = _.map(items, (item) => ({
      ...item,
      selected: evt.target.checked,
    }));

    onChange && onChange(clonedData);
  };

  /**
   * ⚡annotation all check
   */
  const handleAllChange = (evt) => {
    const selectedAllData = _.map(data, (annotation) => ({
      ...annotation,
      list: _.map(annotation.list, (item) => ({ ...item, selected: evt.target.checked })),
    }));

    onChange && onChange(selectedAllData);
  };

  // 전체 체크박스 상태값
  const checkedAll = _.every(
    _.map(data, (annotation) => _.every(annotation.list, ["selected", true]))
  );
  const indeterminateAll = _.some(
    _.map(data, (annotation) => _.some(annotation.list, ["selected", true]))
  );

  // Collapse default active key
  const defaultActiveKey = _.map(data, (_x, i) => i);
  // 항목 없음
  const isEmpty = _.every(data, (x) => !x.list || x.list.length === 0);
  return (
    <article className="new-document__content__part border-b">
      {isEmpty ? (
        <Empty />
      ) : (
        <>
          <div className="pdlr-30 content-title">
            <ContentTitle title={t("title$Select_Report_Items")} style={{ color: "#182e63" }} />
            <Row>
              <Checkbox
                checked={checkedAll}
                indeterminate={!checkedAll && indeterminateAll}
                onChange={handleAllChange}
              >
                {t("button$All")}
              </Checkbox>
            </Row>
          </div>
          <article className="new-document__content__part border-b inner-content">
            <Collapse
              className={"new-document-items-collapse"}
              bordered={false}
              defaultActiveKey={defaultActiveKey}
              expandIconPosition="right"
              destroyInactivePanel={true}
              expandIcon={(iconProps) => (
                <CaretDownOutlined rotate={iconProps.isActive ? false : -90} />
              )}
            >
              {data.map((annotation, parentIndex) => {
                const checkedTitle = _.every(annotation.list, ["selected", true]);
                return (
                  annotation.list.length > 0 && (
                    <Collapse.Panel
                      className={"new-document-item-panel model"}
                      key={parentIndex}
                      header={
                        <Row>
                          <Checkbox
                            indeterminate={
                              !checkedTitle && _.some(annotation.list, ["selected", true])
                            }
                            checked={checkedTitle}
                            onClick={(evt) => {
                              evt.stopPropagation();
                            }}
                            onChange={(evt) => {
                              handleTitleChange(evt, annotation.list, parentIndex);
                            }}
                          >
                            {t("title$" + annotation.title)}
                          </Checkbox>
                        </Row>
                      }
                    >
                      {annotation.list.map((annotationItem, childIndex) => (
                        <Row key={childIndex}>
                          <Checkbox
                            disabled={annotation.disabled}
                            checked={annotationItem.selected}
                            onChange={(evt) =>
                              handleItemChange(evt, annotationItem, parentIndex, childIndex)
                            }
                          >
                            {annotationItem.label}
                          </Checkbox>
                        </Row>
                      ))}
                    </Collapse.Panel>
                  )
                );
              })}
            </Collapse>
          </article>
        </>
      )}
    </article>
  );
};
