//actions
import { BOREHOLE_LIST, BOREHOLE_ROCK_LIST, BOREHOLE_ROCK_COLOR_LIST } from '../actions/borehole';

const initializeBoreholeState = {
    selectedBoreholeKey: null,
    boreholeRows: [],
    rockRows: [],
    rockTypeColorRows: []
}

export default function BoreHoleReducer(state = initializeBoreholeState, action) {
    switch (action.type) {
        
        case BOREHOLE_LIST: return { ...state, boreholeRows: action.boreholeList };
        case BOREHOLE_ROCK_COLOR_LIST: return { ...state, rockTypeColorRows: action.rockColorList };
        case BOREHOLE_ROCK_LIST: return { ...state, rockRows: action.rockList, selectedBoreholeKey: action.boreholeKey };

        default: return state;
    }
}