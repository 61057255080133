import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { connect } from "react-redux";
import { setFileNames, onIncrement, selectBoreHole } from "../../../store/actions/uploadModel";
import SubTable from "./rocktypesTable";
import Axios from "axios";
import { sortBy } from "lodash";
import iconPlus from "../../../assets/icons-2/Icon_Plus-Rectangle.svg";
import "./index.less";
import { removeItem } from "./utils";
import TableAction from "./childrens/TableAction";
import { consoleError } from "../../../middleware";
import {
  setBoreholeList,
  setRockTypeList,
  setRockTypeColorList,
} from "../../../store/actions/borehole";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";
const { ColumnGroup, Column } = Table;

function Tables(props) {
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      {
        const { data } = await Axios.get(
          `/borehole/${props.currentProjectReducer.currentProject.project_mng_sq}`
        );
        props.setBoreholeList(
          data.map((x, index) => ({
            key: x.borehole_sq,
            borehole_sq: x.borehole_sq,
            boreholeName: x.f_bh_nm,
            x: x.f_x,
            y: x.f_y,
            z: x.f_z,
            editable: false,
          }))
        );
      }

      {
        const { data } = await Axios.get(
          `/boreholeType/${props.currentProjectReducer.currentProject.project_mng_sq}`
        );
        props.setRockTypeColorList(
          data.map((x, index) => ({
            key: x.bh_rock_type_sq,
            sqKey: x.bh_rock_type_sq,
            rockTypeName: x.f_rock_type_nm,
            colors: x.f_rock_type_color,
            editable: false,
          }))
        );
      }
    })().catch(consoleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (key) => {
    removeItem({
      onOk: () => {
        (async () => {
          /**
           * remove rows data
           */
          // eslint-disable-next-line react-hooks/exhaustive-deps
          await Axios.delete(`/borehole/${key}`);

          props.setBoreholeList(
            props.BoreHoleReducer.boreholeRows.filter((item) => item.key !== key)
          );
        })();
      },
      onCancel() {},
    });
  };

  const onEdit = (e, record, propertyKeyName) => {
    props.BoreHoleReducer.boreholeRows.find((item) => item.key === record.key)[propertyKeyName] =
      e.target.value;
    props.setBoreholeList([].concat(props.BoreHoleReducer.boreholeRows));
  };

  const activeEdit = (record, bool) => {
    (async () => {
      let temp = [];
      if (bool === false) {
        const reqPath = record.borehole_sq ? `/borehole/${record.borehole_sq}` : "/borehole";

        const { data } = await Axios.post(reqPath, {
          ...record,
          projectId: props.currentProjectReducer.currentProject.project_mng_sq,
        });

        if (!record.borehole_sq) {
          temp = data.map((x) => ({
            key: x.borehole_sq,
            borehole_sq: x.borehole_sq,
            boreholeName: x.f_bh_nm,
            x: x.f_x,
            y: x.f_y,
            z: x.f_z,
            editable: false,
          }));

          props.BoreHoleReducer.boreholeRows = props.BoreHoleReducer.boreholeRows.filter(
            (x) => x.key !== record.key
          );
        }
      }

      if (props.BoreHoleReducer.boreholeRows.find((x) => x.key === record.key)) {
        props.BoreHoleReducer.boreholeRows.find((x) => x.key === record.key).editable = bool;
      }

      props.setBoreholeList(temp.concat(props.BoreHoleReducer.boreholeRows));
    })().catch(consoleError);
  };

  const [createCount, setCreateCount] = useState(0);
  const addRow = () => {
    props.setBoreholeList(
      [
        {
          key: `create_${createCount}`,
          boreholeName: "",
          x: "",
          y: "",
          z: "",
          editable: true,
        },
      ].concat(props.BoreHoleReducer.boreholeRows)
    );

    setCreateCount(createCount + 1);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys[0] === "create_0") {
        props.setRockTypeList(
          props.BoreHoleReducer.rockTypeColorRows.map((x, index) => ({
            key: `create_${index}`,
            color: {
              colorName: x.colors,
              key: x.sqKey,
            },
            rockTypes: {
              rockName: x.rockTypeName,
              key: x.sqKey,
            },
            bh_rock_type_sq: x.sqKey,
            verticalLength: "",
            sortNum: index + 1,
            editable: true,
          })),
          selectedRowKeys[0]
        );
      } else {
        (async () => {
          const { data } = await Axios.get(`/boreholeType`, {
            params: {
              borehole_sq: selectedRowKeys[0],
            },
          });

          props.setRockTypeList(
            sortBy(
              data.map((x) => ({
                bh_rock_sq: x.bh_rock_sq,
                bh_rock_type_sq: x.bh_rock_type_sq,
                key: x.bh_rock_sq,
                color: {
                  colorName: x.f_rock_type_color,
                  key: x.bh_rock_type_sq,
                },
                rockTypes: {
                  rockName: x.f_rock_type_nm,
                  key: x.bh_rock_type_sq,
                },
                sortNum: x.f_rock_num,
                verticalLength: x.f_height,
                editable: false,
              })),
              "sortNum"
            ),
            selectedRowKeys[0]
          );
        })().catch(consoleError);
      }
    },
  };

  const onCancel = (key) => {
    if (`${key}`.indexOf("create_") > -1) {
      props.setBoreholeList(props.BoreHoleReducer.boreholeRows.filter((x) => x.key !== key));
    } else {
      props.BoreHoleReducer.boreholeRows.find((x) => x.key === key).editable = false;
      props.setBoreholeList([].concat(props.BoreHoleReducer.boreholeRows));
    }
  };

  return (
    <>
      <div className="table-inline-block">
        <img
          className="add-row"
          onClick={addRow}
          src={iconPlus}
          style={{ width: "13px", height: "13px" }}
          alt="add icons"
        />
        <br />
        <Table
          size="small"
          dataSource={props.BoreHoleReducer.boreholeRows}
          bordered={true}
          pagination={false}
          scroll={{ y: 280 }}
          // className="table-test"
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
        >
          <Column
            title={t("column$Borehole_Name")}
            width={80}
            dataIndex="boreholeName"
            key="boreholeName"
            render={(text, record) =>
              record.editable ? (
                <Input
                  size="small"
                  onChange={(e) => onEdit(e, record, "boreholeName")}
                  value={text}
                />
              ) : (
                text
              )
            }
          />
          <ColumnGroup title={capitalizeFirstLetter(t("column$Coordinate"))}>
            {["x", "y", "z"].map((key) => (
              <Column
                title={key.toUpperCase()}
                dataIndex={key}
                key={key}
                render={(text, record) =>
                  record.editable ? (
                    <Input size="small" onChange={(e) => onEdit(e, record, key)} value={text} />
                  ) : (
                    text
                  )
                }
              />
            ))}
          </ColumnGroup>
          <Column
            title={capitalizeFirstLetter(t("column$Edit"))}
            key="action"
            render={(text, record) => (
              <TableAction
                record={record}
                activeEdit={activeEdit}
                onCancel={onCancel}
                handleDelete={handleDelete}
              />
            )}
          />
        </Table>
      </div>
      <div className="table-inline-block">
        {props.BoreHoleReducer.selectedBoreholeKey && <SubTable />}
      </div>
    </>
  );
}

export default connect((state) => state, {
  setFileNames,
  onIncrement,
  selectBoreHole,
  setBoreholeList,
  setRockTypeList,
  setRockTypeColorList,
})(Tables);
