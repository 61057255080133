import { configCurrentProject } from "../../documents/utils/dev";

export async function isDevProjectLoad(projectIdx, history) {
    try {
        /**
         * @DEVELOPMENT_MODE
         */
        if (process.env.NODE_ENV === "development" && !projectIdx) {
            const pathNameSplit = history.location.pathname.split("/");
            let localProjectId = pathNameSplit[pathNameSplit.length - 1];
            await configCurrentProject(localProjectId);
            return true;
        }

        /**
         * @PRODUCTION_MODE
         */
        if (process.env.NODE_ENV !== "development" && !projectIdx) {
            const pathNameSplit = history.location.pathname.split("/");
            return history.push(
                `/projects/show/${pathNameSplit[pathNameSplit.length - 1]}`
            );
        }
    } catch (err) {
        if (process.env.NODE_ENV !== "development") return history.push('/');
        console.error(err);
    }
}