//types
// export const VIEWDATASET = 'SET/3D/VIEWDATASET';
// export const setData = (viewDataSet) => ({
//     type: VIEWDATASET, obj: {
//         "layers": [
//             {
//                 title: "Topographic Model",
//                 items: viewDataSet.layer.topo
//             },
//             {
//                 title: "Sub-surface Model",
//                 items: viewDataSet.layer.subsurface
//             },
//             {
//                 title: "Target Design Model",
//                 items: viewDataSet.layer.target
//             },
//             {
//                 title: "Overlays Information",
//                 items: viewDataSet.layer.overlay
//             }
//         ],
//         annotations: [
//             {
//                 title: "Geo-fences",
//                 items: viewDataSet.annotations.offsite
//             }, {
//                 title: "Analytics",
//                 items: viewDataSet.annotations.onsite
//             },
//             {
//                 title: "Annotation Mark",
//                 items: viewDataSet.annotations.analytics
//             }
//         ]
//     }
// });

export const VIEWDATASET_INIT = 'SET/3D/VIEWDATASET/INIT';
export const setDataSetInit = () => ({ type: VIEWDATASET_INIT })

export const VIEWDATASET_PROJECT_KEY = 'SET/3D/VIEWDATASET/PROJECT_KEY';
export const setViewDataKey = (projectKey) => ({ type: VIEWDATASET_PROJECT_KEY, projectKey });

//layers
export const VIEWDATASET_LAYERS = 'SET/3D/VIEWDATASET/LAYER';
export const setLayers = (obj, key) => ({ type: VIEWDATASET_LAYERS, obj, key })

export const VIEWDATASET_MANY_LAYERS = 'SET/3D/VIEWDATASET/LAYER/MANY';
export const setLayersMany = (layers, totalCount) => ({ type: VIEWDATASET_MANY_LAYERS, layers, totalCount })


//annotations
export const VIEWDATASET_ANNOTATION = 'SET/3D/VIEWDATASET/ANNOTATION';

export const setAnnotations = (obj, key) => ({ type: VIEWDATASET_ANNOTATION, obj, key })


export const VIEWDATASET_MANY_ANNOTATION = 'SET/3D/VIEWDATASET/ANNOTATION/MANY';
export const setAnnotationMany = (annotations, totalCount) => ({ type: VIEWDATASET_MANY_ANNOTATION, annotations, totalCount })


//기존 데이터 구조
// models: {
      //   layers: [
      //     {
      //       title: "Topographic Model",
      //       items: []
      //       //items: ['Point Cloud', 'Triangle Mesh', 'Contour Map', 'Orthophoto']
      //     },
      //     {
      //       title: "Sub-surface Model",
      //       items: []
      //       //items: ['3D Borehole Model', 'Top Soil Layer', 'Soft Rock Layer']
      //     },
      //     {
      //       title: "Target Design Model",
      //       items: []
      //       //items: ['Basement PAD', 'Parking Lot']
      //     },
      //     {
      //       title: "Overlays Information",
      //       items: []
      //       //items: ['Building Design_PDF', 'Alignment _PDF']
      //     }
      //   ],
      //   annotations: [
      //     {
      //       title: "Geo-fences (On-site)",
      //       items: this.stateOnSiteArr
      //       //items: ['Open Storage Area', 'Entrance (East)', 'Temporary Road', 'Design Area (Target)', 'Work Cell (Mgmt. Level)']
      //     },
      //     {
      //       title: "Geo-fences (Off-site)",
      //       items: this.stateOffSiteArr
      //       //items: ['Borrow Pit', 'Disposal Area (Marble Stone)', 'Disposal Area (Rock)']
      //     },
      //     {
      //       title: "Analytics & Annotations",
      //       items: this.stateAnalyticsArr
      //       //items: ['Volume for Target Design', 'Volume for Open Storage Area ']
      //     }
      //   ]
      // },


      // export const VIEWDATASET_SUBSURFACE = 'SET/3D/VIEWDATASET/SUBSURFACE';
// export const VIEWDATASET_TARGET = 'SET/3D/VIEWDATASET/TARGET';
// export const VIEWDATASET_OVERLAY = 'SET/3D/VIEWDATASET/OVERLAY';
// export const setSubsurfaces = (subsurfaces) => ({ type: VIEWDATASET_SUBSURFACE, subsurfaces })
// export const setTargets = (targets) => ({ type: VIEWDATASET_TARGET, targets })
// export const setOverlays = (overlays) => ({ type: VIEWDATASET_OVERLAY, overlays })

// export const VIEWDATASET_OFFSITE = 'SET/3D/VIEWDATASET/OFFSITE';
// export const VIEWDATASET_ANALYTICS = 'SET/3D/VIEWDATASET/ANALYTICS';

// export const setOnsite = (onsite) => ({ type: VIEWDATASET_ONSITE, onsite })
// export const setOffsite = (offsite) => ({ type: VIEWDATASET_OFFSITE, offsite })