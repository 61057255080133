import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import {
  setSubSurface,
  setFileNames,
  onIncrement,
  selectBoreHole,
  setAllSubSurface,
} from "../../../store/actions/uploadModel";
import { connect } from "react-redux";
import { Select } from "antd";
import Axios from "axios";
import iconPlus from "../../../assets/icons-2/Icon_Plus-Rectangle.svg";
import { removeItem } from "./utils";
import TableAction from "./childrens/TableAction";
import { consoleError } from "../../../middleware";
import { setRockTypeList } from "../../../store/actions/borehole";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";
const { Option } = Select;
const { Column } = Table;

function SubTable(props) {
  const { t } = useTranslation();
  const onEdit = (e, record, propertyKeyName) => {
    props.BoreHoleReducer.rockRows.find((item) => item.key === record.key)[propertyKeyName] =
      e.target.value;
    props.setRockTypeList(
      [].concat(props.BoreHoleReducer.rockRows),
      props.BoreHoleReducer.selectedBoreholeKey
    );
  };

  const handleChange = (value, selectType, key) => {
    const selectedObj = props.BoreHoleReducer.rockTypeColorRows.find((x) => x.sqKey === value);

    props.BoreHoleReducer.rockRows.find((x) => x.key === key).bh_rock_type_sq = value;

    const options = {
      color: {
        colorName: selectedObj.colors,
        key: selectedObj.sqKey,
      },
      rockTypes: {
        rockName: selectedObj.rockTypeName,
        key: selectedObj.sqKey,
      },
    };

    props.BoreHoleReducer.rockRows.find((x) => x.key === key).color = options.color;
    props.BoreHoleReducer.rockRows.find((x) => x.key === key).rockTypes = options.rockTypes;

    props.setRockTypeList(
      [].concat(props.BoreHoleReducer.rockRows),
      props.BoreHoleReducer.selectedBoreholeKey
    );
  };

  const [createCount, setCreateCount] = useState(0);

  useEffect(() => {
    if (props.BoreHoleReducer.rockRows.length === 0) {
      props.setRockTypeList(
        props.BoreHoleReducer.rockTypeColorRows
          .map((x, index) => ({
            key: `create_${index}`,
            color: {
              colorName: x.colors,
              key: x.sqKey,
            },
            rockTypes: {
              rockName: x.rockTypeName,
              key: x.sqKey,
            },
            bh_rock_type_sq: x.sqKey,
            verticalLength: "",
            sortNum: index + 1,
            editable: true,
          }))
          .concat(props.BoreHoleReducer.rockRows),
        props.BoreHoleReducer.selectedBoreholeKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.BoreHoleReducer.selectedBoreholeKey]);

  const addRow = () => {
    props.setRockTypeList(
      [
        {
          key: `create_${createCount}`,
          color: {
            colorName: "",
            key: "temp",
          },
          rockTypes: {
            rockName: "",
            key: "temp",
          },
          bh_rock_type_sq: null,
          verticalLength: "",
          sortNum: props.BoreHoleReducer.rockRows.length + 1,
          editable: true,
        },
      ].concat(props.BoreHoleReducer.rockRows),
      props.BoreHoleReducer.selectedBoreholeKey
    );
    setCreateCount(createCount + 1);
  };

  const activeEdit = (record, bool) => {
    (async () => {
      let temp = [];
      if (bool === false) {
        if (!record.bh_rock_type_sq && record.verticalLength === "") {
          return alert(t("message$Select_focus"));
        }

        const reqPath = record.bh_rock_sq ? `/boreholeRock/${record.bh_rock_sq}` : "/boreholeRock";

        const { data } = await Axios.post(reqPath, {
          sortNum: record.sortNum,
          typeSq: record.bh_rock_type_sq,
          verticalLength: record.verticalLength,
          projectId: props.currentProjectReducer.currentProject.project_mng_sq,
          borehole_sq_t_borehole: props.BoreHoleReducer.selectedBoreholeKey,
        });

        if (!record.bh_rock_sq) {
          temp = data.map((x) => ({
            bh_rock_sq: x.bh_rock_sq,
            bh_rock_type_sq: record.bh_rock_type_sq,
            key: x.bh_rock_sq,
            color: record.color,
            rockTypes: record.rockTypes,
            sortNum: x.f_rock_num,
            verticalLength: x.f_height,
            editable: false,
          }));

          props.BoreHoleReducer.rockRows = props.BoreHoleReducer.rockRows.filter(
            (x) => x.key !== record.key
          );
        }
      }

      if (props.BoreHoleReducer.rockRows.find((x) => x.key === record.key)) {
        props.BoreHoleReducer.rockRows.find((x) => x.key === record.key).editable = bool;
      }

      props.setRockTypeList(
        _.sortBy(temp.concat(props.BoreHoleReducer.rockRows), "sortNum"),
        props.BoreHoleReducer.selectedBoreholeKey
      );
    })().catch(consoleError);
  };

  const handleDelete = (key) => {
    removeItem({
      onOk: () => {
        (async () => {
          await Axios.delete(`/boreholeRock/${key}`);

          props.setRockTypeList(
            props.BoreHoleReducer.rockRows.filter((item) => item.key !== key),
            props.BoreHoleReducer.selectedBoreholeKey
          );
        })();
      },
      onCancel() {},
    });
  };

  const onCancel = (key) => {
    if (`${key}`.indexOf("create_") > -1) {
      props.setRockTypeList(
        props.BoreHoleReducer.rockRows.filter((x) => x.key !== key),
        props.BoreHoleReducer.selectedBoreholeKey
      );
    } else {
      props.BoreHoleReducer.rockRows.find((x) => x.key === key).editable = false;
      props.setRockTypeList(
        [].concat(props.BoreHoleReducer.rockRows),
        props.BoreHoleReducer.selectedBoreholeKey
      );
    }
  };
  return (
    <>
      <img
        className="add-row"
        onClick={addRow}
        src={iconPlus}
        style={{ width: "13px", height: "13px" }}
        alt={"add-rows icon"}
      />
      <br />
      <Table
        size="small"
        dataSource={props.BoreHoleReducer.rockRows}
        bordered={true}
        pagination={false}
        scroll={{ y: 290 }}
      >
        <Column
          title=" "
          width={50}
          align="center"
          dataIndex="sortNum"
          key="sortNum"
          render={(text, record, index) => {
            return <>{index + 1}</>;
          }}
        />
        <Column
          title={t("column$Soil_Type", { count: 2 })}
          dataIndex="rockTypes"
          key="rockTypes"
          render={(text, record) => (
            <>
              <Select
                style={{ width: "100%" }}
                size="small"
                value={record.bh_rock_type_sq}
                disabled={!record.editable}
                onChange={(e) => handleChange(e, "rockTypes", record.key)}
              >
                {props.BoreHoleReducer.rockTypeColorRows.map((rock, index) => (
                  <Option value={rock.sqKey} key={index}>
                    {rock.rockTypeName}
                  </Option>
                ))}
              </Select>
            </>
          )}
        />
        <Column
          title={capitalizeFirstLetter(t("column$Color"))}
          dataIndex="color"
          key="color"
          render={(text, record) => (
            <Select
              style={{ width: "100%" }}
              size="small"
              value={record.bh_rock_type_sq}
              disabled={true}
              onChange={(e) => handleChange(e, "color", record.key)}
            >
              {props.BoreHoleReducer.rockTypeColorRows.map((color, index) => (
                <Option value={color.sqKey} key={index}>
                  {color.colors}
                </Option>
              ))}
            </Select>
          )}
        />
        <Column
          title={capitalizeFirstLetter(t("column$Thickness"))}
          dataIndex="verticalLength"
          key="verticalLength"
          render={(text, record) =>
            record.editable ? (
              <Input
                size="small"
                onChange={(e) => onEdit(e, record, "verticalLength")}
                value={text}
              />
            ) : (
              text
            )
          }
        />
        <Column
          title={capitalizeFirstLetter(t("column$Action"))}
          key="action"
          render={(text, record) => (
            <TableAction
              record={record}
              activeEdit={activeEdit}
              onCancel={onCancel}
              handleDelete={handleDelete}
            />
          )}
        />
      </Table>
    </>
  );
}

export default connect((state) => state, {
  setSubSurface,
  setFileNames,
  onIncrement,
  selectBoreHole,
  setAllSubSurface,
  setRockTypeList,
})(SubTable);
