//types
export const MERGE = 'Layer/MERGE';
export const SELECT_DATE = 'Layer/SELECT_DATE';

export const TOPO_DATAS = 'Layer/TOPO_DATE';
export const SURFACE_DATAS = 'Layer/SURFACE_DATE';
export const TARGET_DATAS = 'Layer/TARGET_DATE';

export const SELECT_TOPO = 'Layer/SELECT_TOPO';
export const SELECT_TARGET = 'Layer/SELECT_TARGET';
export const SELECT_SURFACE = 'Layer/SELECT_SURFACE';

export const MODEL_LAYER_INIT = 'Layer/MODEL_LAYER_INIT';


export const LAYER_TITLE = 'Layer/TITLE';

//setter
export const setLayerTitle = title => ({ type: LAYER_TITLE, title });

export const setModelLayerInit = () => ({ type: MODEL_LAYER_INIT });

export const setLayer = files => ({ type: MERGE, files});
export const setModelLayerDate = date => ({ type: SELECT_DATE, date});
export const setAPITopoDate = topoDates => ({ type: TOPO_DATAS, topoDates});
export const setAPITargetDate = targetDates => ({ type: TARGET_DATAS, targetDates});

export const setSelectTopoDate = topo => ({ type: SELECT_TOPO, topo});
export const setSelectTargetDate = target => ({ type: SELECT_TARGET, target});

export const setAPISurfaceDate = surfaceDates => ({ type: SURFACE_DATAS, surfaceDates})
export const setSelectSurfaceDate = surface => ({ type: SELECT_SURFACE, surface})