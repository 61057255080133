//actions
import { SAVE_HANDLE_INIT, SAVE_HANDLE_TARGET_NAME, SAVE_HANDLE_VISIBLE } from '../actions/saveHandle';

const initializeHandle = {
    canSave: true,
    targetWorkspace: '',
}

export default function SaveHandleReducer(state = initializeHandle, action) {
    switch (action.type) {
        case SAVE_HANDLE_INIT: return { ...initializeHandle };
        case SAVE_HANDLE_VISIBLE: return {...state, canSave: action.visible };
        case SAVE_HANDLE_TARGET_NAME: return {...state, targetWorkspace: action.name };

        default: return state;
    }
}