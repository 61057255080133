import React from 'react';
import StreamVideo from './StreamVideo';
import FileDownload from './FileDownload';

const IsVideoOrAnotherFile = (props) => {
    if (props.item.file.mimeType && props.item.file.mimeType.toString().indexOf('video') > -1) {
        return <StreamVideo item={props.item} />
    } else {
        return <FileDownload item={props.item} />
    }
}

export default IsVideoOrAnotherFile;
