import React, { createRef, useEffect, useState } from "react";
import _ from "lodash";
import IconDelete from "../../../assets/icons/delete.svg";
import IconPlus from "../../../assets/icons/plus.svg";
import IconReset from "../../../assets/icons/Icon_reset.svg";
import IconData from "../../../assets/icons-2/Icon_Site_data.svg";
import { Checkbox, Form, InputNumber, Popconfirm, message } from "antd";
import numeral from "numeral";
import {
  addMarker,
  removeMarker,
  resetMarker,
  setMarker,
  setMarkerPosition,
} from "../util/coordinate";
import { useTranslation } from "react-i18next";
import { clipboardCopy } from "utils/domHelper";
import ParseCoordinate from "./ParseCoordinate";
import { EnhancedXcEditableTableForm } from "../../../components/elements/XcEditableTable/XcEditableTable";
import "./index.less";

/**
 * @typedef {object} CoordinateFormProps
 * @property {any[]} points Vector3
 * @property {React.ReactNode} headerTitle
 * @property {boolean} isPoint
 * @property {boolean} useEditMode
 * @property {boolean} usePointString
 * format - numeral http://numeraljs.com
 * @property {string} format
 * @property {JSX.Element} headerMenu
 * @property {{copy: JSX.Element, add: JSX.Element, delete: JSX.Element, text: JSX.Element, reset: JSX.Element}} headerNodeItems
 * @property {(record: any) => void} onSetCallback
 */

/**
 * @param {CoordinateFormProps & import("../../../components/elements/XcEditableTable/XcEditableTable").XcEditableTableProps} props
 */
export const CoordinateForm = (props) => {
  const { t } = useTranslation();
  const {
    headerTitle,
    points = [],
    useEditMode = true,
    usePointString = true,
    /** http://numeraljs.com */
    format = "0,0.[000]",
    headerMenu,
    headerNodeItems,
    type = "line",
    onSetCallback,
    ...rest
  } = props;

  const isPoint = type === "point";
  const convertedPoints = _.map(points, (x, i) => ({
    key: i,
    x: x.position.x,
    y: x.position.y,
    z: x.position.z,
  }));

  const formRef = createRef();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  /** 체크박스 */
  const handleSelectChange = (newSelectedRowKeys, newSelectedRow) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRow);
  };

  /**@param {import("antd/lib/checkbox/Checkbox").CheckboxChangeEvent} evt */
  const handleAllSelectChange = (evt) => {
    if (!convertedPoints || convertedPoints.length === 0) return;

    const { checked } = evt.target;
    if (checked) {
      setSelectedRowKeys(convertedPoints.map((x, i) => i));
      setSelectedRows(convertedPoints);
    } else {
      resetSelectedRows();
    }
  };

  /**
   * 복사
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} _evt
   */
  const handleCopyClickAsync = async (_evt) => {
    // 선택시 선택항목 복사
    const selectedPoints = selectedRows && selectedRows.length > 0 ? selectedRows : convertedPoints;
    if (!selectedPoints || selectedPoints.length === 0) return;

    const text = _(selectedPoints)
      .map((point) => {
        const omittedKey = _.omit(point, "key");
        return _.map(omittedKey, (x) => numeral(x).format(format)).join(" ");
      })
      .join(" \n");

    try {
      await clipboardCopy(text);
      message.success(t("message$Coordinate copied."), 2);
    } catch (error) {
      message.error(t("message$Coordinate copy failed."), 2);
    }
  };

  /**
   * 추가
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} _evt
   */
  const handleAddClick = (_evt) => {
    const point =
      convertedPoints.length > 0
        ? convertedPoints[convertedPoints.length - 1]
        : { x: 0, y: 0, z: 0 };
    addMarker(point);
  };

  /**
   * 삭제
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} _evt
   */
  const handleDeleteClick = (_evt) => {
    if (!selectedRowKeys || selectedRowKeys.length === 0 || isPoint) return;

    removeMarker(...selectedRowKeys);
    onSetCallback && onSetCallback();
    resetSelectedRows();
  };

  /**
   * 다시 그리기
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} _evt
   */
  const handleResetClick = (_evt) => {
    resetMarker();
  };

  /**
   * row 수정 확인
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} _evt
   */
  const handleEditConfirm = (_evt, record, values) => {
    setMarker(values, record.key);
    onSetCallback && onSetCallback(values);
  };

  /**
   * row 삭제
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} _evt
   */
  const handleEditDelete = (_evt, record) => {
    if (isPoint) return;

    removeMarker(record.key);
    onSetCallback && onSetCallback(record);
  };

  /**
   * row 수정 취소
   * @param {React.MouseEvent<HTMLImageElement, MouseEvent>} evt
   */
  const handleEditCancel = (evt, record) => {
    evt.preventDefault();
    setMarker(record, record.key);
  };

  const getColumns = () => {
    if (!convertedPoints || convertedPoints.length === 0) return [];

    const omittedKey = _.omit(convertedPoints[0], "key");
    return _.keys(omittedKey).map((key) => ({
      title: key,
      dataIndex: key,
      key: key,
      editable: true,
      className: isPoint ? "point-col" : "",
    }));
  };

  const resetSelectedRows = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const getDeleteMessage = (counts) => {
    const minimumCounts = { point: 1, line: 2, polygon: 3 };
    if (minimumCounts[type] > counts) {
      return t(`message$좌표가 %{count}개이상 필요합니다. 삭제 하시겠습니까?`, {
        count: minimumCounts[type],
      });
    }
    return t("message$Do_you_want_to_delete?");
  };

  // scroll useEffect
  useEffect(() => {
    const tableNode = document.querySelector(".coordinate-editable-table .ant-table-body");
    if (tableNode) {
      tableNode.scrollTo({
        top: tableNode.scrollHeight,
        behavior: "smooth",
      });
    }
    resetSelectedRows();
  }, [points.length]);

  const defaultHeaderNodes = {
    copy: (
      <img
        alt="copy icon"
        src={IconData}
        title={t("button$Copy")}
        onClick={_.debounce(handleCopyClickAsync, 300)}
      />
    ),
    add: (
      <img
        alt="plus icon"
        src={IconPlus}
        title={t("button$Add")}
        onClick={_.debounce(handleAddClick, 300)}
      />
    ),
    delete: (
      <Popconfirm
        overlayClassName={"editable-popconfirm"}
        title={getDeleteMessage(convertedPoints.length - selectedRowKeys.length)}
        placement="right"
        okText={t("button$OK")}
        cancelText={t("button$Cancel")}
        disabled={!selectedRowKeys || selectedRowKeys.length === 0}
        onConfirm={handleDeleteClick}
      >
        <img alt="delete icon" src={IconDelete} title={t("button$Delete")} />
      </Popconfirm>
    ),
    text: <ParseCoordinate type={type} />,
    reset: (
      <img alt="reset icon" src={IconReset} title={t("button$Redraw")} onClick={handleResetClick} />
    ),
  };

  const defaultHeaderMenu = (
    <>
      {points.length > 0 && (headerNodeItems?.copy || defaultHeaderNodes.copy)}
      {!isPoint && points.length > 0 && (
        <>
          {headerNodeItems?.add || defaultHeaderNodes.add}
          {headerNodeItems?.delete || defaultHeaderNodes.delete}
        </>
      )}
      {usePointString && (headerNodeItems?.text || defaultHeaderNodes.text)}
      {headerNodeItems?.reset || defaultHeaderNodes.reset}
    </>
  );

  const allChecked = convertedPoints.length !== 0 && convertedPoints.length === selectedRows.length;
  const defaultHeaderTitle = (
    <div className="coordinate-form-title">
      {!isPoint && (
        <Checkbox
          checked={allChecked}
          indeterminate={!allChecked && selectedRows.length > 0}
          disabled={!convertedPoints || convertedPoints.length === 0}
          onChange={handleAllSelectChange}
        />
      )}
      {`${t("title$Coordinate")} (X, Y, Z)`}
    </div>
  );

  return (
    <EnhancedXcEditableTableForm
      wrapperClassName={"coordinate-form-wrapper"}
      className={"coordinate-editable-table"}
      wrappedComponentRef={formRef}
      headerMenu={headerMenu || defaultHeaderMenu}
      headerTitle={defaultHeaderTitle}
      dataSource={convertedPoints}
      columns={getColumns()}
      bordered={false}
      showHeader={false}
      useEditMode={useEditMode}
      useLineNo={!isPoint}
      format={format}
      scroll={{
        y: 110,
      }}
      components={{
        body: {
          cell: CustomEditableCell,
        },
      }}
      rowSelection={
        !isPoint
          ? {
              selectedRowKeys: selectedRowKeys,
              columnWidth: 20,
              onChange: handleSelectChange,
            }
          : undefined
      }
      operationColumnProps={{
        deleteConfirm: {
          title: getDeleteMessage(convertedPoints.length - 1),
          disabled: isPoint,
        },
        delete: {
          className: isPoint ? "disabled" : "",
        },
      }}
      onConfirm={handleEditConfirm}
      onDelete={handleEditDelete}
      onCancel={handleEditCancel}
      {...rest}
    />
  );
};

/**
 * @param {{form: import("antd/lib/form/Form").WrappedFormUtils}} props
 */
const CustomEditableCell = (props) => {
  const {
    form,
    editing,
    dataIndex,
    record,
    useEditMode,
    format,
    inputType,
    children,
    ...rest
  } = props;

  const handleInputConfirm = (evt, index, key) => {
    const { value } = evt.target;
    setMarkerPosition(value, index, key);
  };

  const renderItem = () => {
    if (!dataIndex || !form) {
      return children;
    }

    if (!useEditMode || !editing) {
      return (
        <Form.Item style={{ margin: 0 }}>
          <InputNumber
            value={record[dataIndex]}
            disabled
            style={{ color: "rgba(0, 0, 0, 0.75)" }}
            formatter={(value) => numeral(value).format(format)}
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item style={{ margin: 0 }} key={dataIndex}>
        {form.getFieldDecorator(dataIndex, {
          initialValue: record[dataIndex],
        })(
          <InputNumber
            maxLength={20}
            className={"editable-input"}
            onBlur={(evt) => handleInputConfirm(evt, record.key, dataIndex)}
            onPressEnter={(evt) => handleInputConfirm(evt, record.key, dataIndex)}
          />
        )}
      </Form.Item>
    );
  };

  return <td {...rest}>{renderItem()}</td>;
};
