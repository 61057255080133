import React, { useEffect, useState } from "react";
import { Route, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Menu, Icon } from "antd";
import Notification from "../../views/notification";
import Organization from "../../components/modals/organization/Organization";
import SitePlanningIcon from "../../assets/icons-2/Icon_Site_Planner-22.svg";
import SiteFleetIcon from "../../assets/icons-2/Icon_Site_Fleet.svg";
import SiteDocumentsIcon from "../../assets/icons-2/Icon_Site_Document.svg";
import SiteAnalystIcon from "../../assets/icons-2/Icon_Site_Analyst.svg";
import { changeLanguage } from "react-i18next/i18next";
import { ReactComponent as IconKo } from "../../assets/icons-2/Icon_Ko.svg";
import { ReactComponent as IconEn } from "../../assets/icons-2/Icon_En.svg";

import "./index.less";

function ProjectNavRoute(props) {
  const { t, i18n } = useTranslation();

  const view = useSelector((state) => state.appState.view);
  const project_mng_sq = useSelector(
    (state) => state.currentProjectReducer.currentProject.project_mng_sq
  );
  const location = useLocation();

  const [selectedKeys, setselectedKeys] = useState([]);

  const handleLinkItemClick = (keyItem) => {
    if (selectedKeys.indexOf(keyItem) !== -1) return;
    setselectedKeys([keyItem]);
  };

  // 임시 언어변환 함수
  const handleLanguageClick = () => {
    changeLanguage(i18n.language === "ko-KR" ? "en-US" : "ko-KR");
  };

  // useEffect(() => {
  //     view === 'home' ? setselectedKeys([]) : onActvie('site-analyst');
  //     console.log('2', selectedKeys)
  // }, [view]);

  useEffect(() => {
    if (location.pathname.indexOf("fleet") !== -1) {
      setselectedKeys(["site-fleet"]);
    } else if (location.pathname.indexOf("documents") !== -1) {
      setselectedKeys(["documents"]);
    } else if (location.pathname.indexOf("show") !== -1) {
      // 기존 else만 있어서
      console.log("@@");
      setselectedKeys(["site-analyst"]);
    }
  }, [location.pathname]);

  return (
    <Route path="*">
      <div className="nav-route-wrapper">
        <Menu theme="dark" selectedKeys={selectedKeys} style={{ height: "calc(100vh - 56px)" }}>
          {/* <Menu.Item key="home">
                        <Link to="/">
                            <Icon type="home" theme="filled" style={FONT_SIZE_13} />
                            <span>Home</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="projects/show/">
                        <Link to={`/projects/show/${props.currentProject}`}>
                            <Icon type="appstore" theme="filled" style={FONT_SIZE_13} />
                            <span>Projects</span>
                        </Link>
                    </Menu.Item> */}

          <Menu.Item
            key="site-analyst"
            disabled={project_mng_sq === undefined || project_mng_sq === null}
          >
            <Link
              to={`/projects/show/${project_mng_sq}`}
              onClick={() => handleLinkItemClick("site-analyst")}
            >
              <i className="anticon" style={{ fontSize: "15px" }}>
                <img src={SiteAnalystIcon} alt="site Analyst icon" />
              </i>
              <span>{t("button$Xite_Analyst")}</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="site-planning" disabled>
            <i className="anticon" style={{ fontSize: "15px" }}>
              <img src={SitePlanningIcon} alt="site planning icon" />
            </i>
            <span>{t("button$Xite_Planning")}</span>
          </Menu.Item>

          <Menu.Item key="site-fleet" disabled={view !== "projects"}>
            <Link to={`/fleet/${project_mng_sq}`} onClick={() => handleLinkItemClick("site-fleet")}>
              <i className="anticon" style={{ fontSize: "15px" }}>
                <img src={SiteFleetIcon} alt="site fleet icon" />
              </i>
              <span>{t("button$Xite_Fleet")}</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="documents" disabled={view !== "projects"}>
            <Link
              to={`/projects/documents/${project_mng_sq}`}
              onClick={() => handleLinkItemClick("documents")}
            >
              <i className="anticon" style={{ fontSize: "15px" }}>
                <img src={SiteDocumentsIcon} alt="documents icon" />
              </i>
              {/* <Icon type="file-text" theme="filled" style={FONT_SIZE_11} /> */}
              <span>{t("button$Documents")}</span>
            </Link>
          </Menu.Item>

          {/* nav footer */}
          {/* 21.8.30 최대한 주석 : 사용안해서 삭제 요청 */}
          {/* <Menu.Item key="test" style={test_STYLE} >
                        <TestData /> */}
          {/* <Organization className="tools__add-users" /> */}
          {/* <span>TEXT</span> */}
          {/* </Menu.Item> */}

          <Menu.Item key="language" style={LANGUAGE_STYLE} onClick={handleLanguageClick}>
            <Icon
              className="language-icon"
              component={i18n.language === "ko-KR" ? IconKo : IconEn}
              style={{ margin: "0px 1px 1px 0px" }}
            />
            <span>{t("button$Language")}</span>
          </Menu.Item>

          <Menu.Item key="organization" style={ADD_USER_STYLE} disabled>
            <Organization className="tools__add-users" disabled />
            <span>{t("button$Organization")}</span>
          </Menu.Item>

          <Menu.Item key="notification" style={NOTIFICATION_STYLE} disabled>
            <Notification disabled />
          </Menu.Item>

          <Menu.Item key="setting" style={SETTING_STYLE} disabled>
            <Icon type="setting" theme="filled" style={FONT_SIZE_11} />
            <span>{t("button$Setting")}</span>
          </Menu.Item>
        </Menu>
      </div>
    </Route>
  );
}

export default React.memo(ProjectNavRoute);
// export default connect((state) => state, { })(ProjectNavRoute);

const FONT_SIZE_11 = { fontSize: "11px", lineHeight: "34px" };
const COMMON_STYLE = {
  position: "absolute",
  left: 0,
  right: 0,
  width: "64%",
  margin: "0 auto",
};
const NOTIFICATION_STYLE = {
  ...COMMON_STYLE,
  bottom: "80px",
};
const ADD_USER_STYLE = {
  ...COMMON_STYLE,
  bottom: "128px",
};
const SETTING_STYLE = {
  ...COMMON_STYLE,
  bottom: "27px",
  lineHeight: "33px",
};
const LANGUAGE_STYLE = {
  ...COMMON_STYLE,
  bottom: "175px",
};
