import React, { useEffect } from "react";
import { xiteCloud } from "../../../xitecloud3D/xiteCloud.dev";
import "./xitecloud3d.less";

function Xitecloud3d(props) {
  /**
   * Initilize 3D
   */
  useEffect(() => {
    xiteCloud();

    const potreeElement = document.getElementById("potree_render_area");
    const canvasList = potreeElement.getElementsByTagName("canvas");

    if (canvasList.length < 1) return;

    const potree_canvas = canvasList[1];
    const cesium_canvas = canvasList[0];

    const potree_gl = potree_canvas.getContext("webgl");
    const cesium_gl = cesium_canvas.getContext("webgl");

    if (!potree_gl || !cesium_gl) {
      return;
    }

    const listener = (event) => {
      event.preventDefault();
      window.location.reload(true);
    };

    potree_canvas.addEventListener("webglcontextlost", listener, false);

    return () => {
      potree_canvas.removeEventListener("webglcontextlost", listener, false);
    };
  }, []);

  return (
    <article className="xitecloud3d">
      <div className="xitecloud3d__potree_container">
        <div id="potree_render_area">
          <div id="cesiumContainer" className="xitecloud3d__cesiumContainer"></div>
        </div>
        <div id="potree_sidebar_container"> </div>
      </div>
    </article>
  );
}

// export default React.memo(Xitecloud3d);
export default Xitecloud3d;
