import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setRockDataSet, setChartDataSet, setColor } from "../../store/actions/rockChartDataSet";
import LongitudinalChart from "../../components/modals/crossSectionChart/LongitudinalChart/LongitudinalChart";
import ChartTable from "../../components/modals/crossSectionChart/LongitudinalChart/ChartTable";
import CrossSectionDashBoard from "../../components/modals/crossSectionChart/CrossSectionDashboard/CrossSectionDashBoard";

import { setReportChart } from "../Measurement/CroSSection/utils/setChartData";
import { Table } from "antd";
import { sortBy } from "lodash";
import { capitalizeFirstLetter } from "utils/StringHelper";

const ReportChart = (props) => {
  const { t } = useTranslation();
  const [chartLabel, setChartLabel] = useState([0, 1, 2, 3, 4, 5]);

  const [analysis, setAnalysis] = useState({
    analysis: [],
  });

  const [longData, setLongData] = useState([]);

  const [datasource, setDatasource] = useState([]);
  useEffect(() => {
    if (props.data && Object.keys(props.data).length) {
      console.log(props.data);
      setReportChart(props.data, setAnalysis, setLongData);

      setDatasource(
        sortBy(
          props.data.lon_insec_pt.map((x) => {
            const temp = {};
            Object.keys(x).map((key) => (temp[key] = x[key] ? +x[key].toFixed(3) : 0));
            return temp;
          }),
          ["st_no"]
        )
      );
    }
  }, [props.data]);

  const columns = [
    {
      title: t("column$측점_(ST)"),
      key: "st_no",
      dataIndex: "st_no",
    },
    {
      title: t("column$절토고"),
      key: "cut_z",
      dataIndex: "cut_z",
    },
    {
      title: t("column$성토고"),
      key: "fill_z",
      dataIndex: "fill_z",
    },
    {
      title: t("column$계획고"),
      key: "bottom_z",
      dataIndex: "bottom_z",
    },
    {
      title: t("column$지반고"),
      key: "top_z",
      dataIndex: "top_z",
    },
    {
      title: t("column$누가거리"),
      key: "ex_dist",
      dataIndex: "ex_dist",
    },
    {
      title: t("column$점간거리"),
      key: "pt_dist",
      dataIndex: "pt_dist",
    },
  ];

  return (
    <>
      {props.data && (
        <>
          <LongitudinalChart
            data={longData}
            label={chartLabel}
            isLong={true}
            gradient
            dataSet={props.data}
          />
          {/* <ChartTable data={analysis} /> */}
          <Table
            className="report-longitud"
            columns={columns}
            dataSource={datasource}
            pagination={false}
            bordered
          />
          <CrossSectionDashBoard report={true} data={props.data} />
        </>
      )}
    </>
  );
};

// export default ReportChart;

export default connect((state) => state, {
  setRockDataSet,
  setChartDataSet,
  setColor,
})(ReportChart);

// export default ReportPublish;
