import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Tooltip, Input, Modal } from "antd";
import { visibleHandle } from "../util/_3dVisible";
import {
  setOverlayTitles,
  setOverlayWorkspace,
  setOverlayTypeAndTitle,
} from "../../../store/actions/overlay";
import Axios from "axios";
// import { setModalSuccess } from "../../../store/actions/Modal";
import { setLayers } from "../../../store/actions/viewDataSet";
import IconEdit from "../../../assets/icons/edit.svg";
import IconDelete from "../../../assets/icons/delete.svg";
import HideDashboard from "../../../components/blocks/hideDashboard";

const OverlayTitle = (props) => {
  const { t } = useTranslation();
  const [titleVisible, setTitleVisible] = useState(false);
  const onTitleChange = (e) => props.setOverlayTitles(e.target.value);
  const onTitleEdit = () => setTitleVisible(!titleVisible);
  const backSpace = () => {
    if (props.position !== undefined) {
      if (!props.OverlayReducer.overlayKey) {
        props.OverlayReducer._3dObject.parent.remove(props.OverlayReducer._3dObject);
      }

      Object.keys(props.backstate).map((key) =>
        Object.keys(props.backstate[key]).map(
          (xyz) => (props.OverlayReducer._3dObject[key][xyz] = props.backstate[key][xyz])
        )
      );

      visibleHandle(props.OverlayReducer._3dObject, false);
    }
    props.setOverlayWorkspace(false);
  };

  const removeOverlay = () => {
    if (props.OverlayReducer.overlayKey) {
      Axios.post(`/overlay/${props.OverlayReducer.overlayKey}/remove`)
        .then(() => {
          Modal.success({
            content: t("message$Successful_deleted_overlay_item"),
            onOk: () => {
              //삭제된 대상을 제외한 배열
              props.setLayers(
                props.ViewDataSetReducer.projectViewDataSetModel.layer.overlay.filter(
                  (x) => x.dbObj.overlay_data_sq !== props.OverlayReducer.overlayKey
                ),
                "overlay"
              );
              props.OverlayReducer.overlayKey = null;
              backSpace();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          Modal.error({
            content: t("message$Failed_delete_overlay_item"),
            onOk: () => {},
          });
        });
    }
  };

  return (
    <section className={props.className}>
      <Button className="overlay__top__back" icon="left" onClick={backSpace} />
      {titleVisible ? (
        <Input
          onChange={onTitleChange}
          value={props.OverlayReducer.layerName}
          style={{
            width: 190,
            border: "unset",
            borderBottom: "2px solid #eee",
            marginLeft: "14px",
            marginTop: "-6px",
          }}
        />
      ) : (
        <Tooltip title={props.OverlayReducer.layerName}>
          <h4>{t("title$%{layer} for_overlay", { layer: props.OverlayReducer.layerName })}</h4>
        </Tooltip>
      )}
      <HideDashboard />
      <div className="overlay__top__actions">
        <img className="i-edit" onClick={onTitleEdit} src={IconEdit} alt="edit icon" />
        <img className="i-delete" onClick={removeOverlay} src={IconDelete} alt="delete icon" />
      </div>
    </section>
  );
};

export default connect((state) => state, {
  setOverlayTitles,
  setOverlayWorkspace,
  setLayers,
  setOverlayTypeAndTitle,
})(OverlayTitle);
