import React, { useState, useEffect } from "react";
import { Radio, Icon, Input } from "antd";
import SelectBox from "../../elements/select";
import FileUpload from "./fileUpload";

//store
import {
  setSubSurface,
  setFileNames,
  setTopographicTitle,
  setTargetTitle,
} from "../../../store/actions/uploadModel";

import "./modelUpload.less";
import { connect } from "react-redux";
import UploadTopoWrap from "./children/UploadTopoWrap";
import BoreholeModal from "./children/BoreholeModal";
import Axios from "axios";
import {
  setSelectTopoDate,
  setAPITopoDate,
  setAPITargetDate,
  setSelectTargetDate,
  setSelectSurfaceDate,
} from "../../../store/actions/modelLayer";
import { useTranslation } from "react-i18next";

function ModelUpload(props) {
  const { t } = useTranslation();

  const [radioSelected, setRadioSelected] = useState(1);
  const onRadioSelect = (e) => setRadioSelected(e.target.value);
  const [boreholeList, setBoreholeList] = useState([]);

  /**
   * @param {import("react-i18next").UseTranslationResponse} i18next
   * @param {string} name
   */
  const getKey = (name) => {
    let key = "";
    if (name.indexOf(t("title$Overlay")) > -1) {
      key = "overlay";
    }

    if (name.indexOf(t("title$Topographic")) > -1) {
      key = "topo";
    }

    if (name.indexOf(t("title$Sub-surface")) > -1) {
      key = "surface";
    }

    if (name.indexOf(t("title$Target_Design")) > -1) {
      key = "target";
    }

    return key;
  };

  const handlers = {
    topo: {
      value: props.modelLayerReducer.selectedTopoDate,
      list: props.modelLayerReducer.topoDates.sort().reverse(),
      handler: (e) => props.setSelectTopoDate(e),
    },
    surface: {
      value: props.modelLayerReducer.selectedSurfaceDate,
      list: boreholeList.sort().reverse(),
      handler: (e) => props.setSelectSurfaceDate(e),
    },
    target: {
      value: props.modelLayerReducer.selectedTargetDate,
      list: props.modelLayerReducer.targetDates.sort().reverse(),
      handler: (e) => props.setSelectTargetDate(e),
    },
  };

  useEffect(() => {
    (async () => {
      const { data } = await Axios.get("/api/loadBoreGeomList", {
        params: {
          project_mng_sq: props.currentProjectReducer.currentProject.project_mng_sq,
        },
      });

      setBoreholeList(data.map((x) => x.f_input_date));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * radio
   */
  const componentKey = getKey(props.name);
  useEffect(() => {
    if (!handlers[componentKey]) return;

    /**
     * first upload
     */
    if (radioSelected === 1 && !handlers[componentKey].list.length) {
      return setRadioSelected(2);
    }

    /**
     * use dataset from db
     */
    if (radioSelected === 1) {
      if (handlers[componentKey].value.length < 1) {
        handlers[componentKey].handler(handlers[componentKey].list[0]);
      }
    } else {
      /**
       * upload
       */
      handlers[componentKey].handler("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioSelected]);

  return (
    <>
      {props.name.indexOf(t("title$Overlay")) === -1 && (
        <section className="model-upload">
          <article className="model-upload__top caption">
            <p>{props.name}</p>
            <Icon type="reload" />
          </article>
          <article className="model-upload__content">
            <ul>
              <Radio.Group value={radioSelected} onChange={onRadioSelect}>
                <li className="group acumin">
                  <Radio value={1}>{t("button$Previous Data")}</Radio>
                  <SelectBox
                    disabled={radioSelected !== 1 ? true : false}
                    width="309px"
                    items={handlers[componentKey] && handlers[componentKey].list}
                    default={handlers[componentKey] && handlers[componentKey].value}
                    select={handlers[componentKey] && handlers[componentKey].handler}
                  />
                </li>
                <li className="group acumin">
                  <Radio value={2}>{t("button$New_Upload")}</Radio>

                  <div
                    className={`model-upload__content__inputs group ${
                      props.name.indexOf(t("title$Sub-surface")) > -1 ? "surface-wrap" : ""
                    }`}
                  >
                    {props.name.indexOf(t("title$Topographic")) > -1 && (
                      <UploadTopoWrap disabled={radioSelected !== 2 ? true : false} />
                    )}
                    {props.name.indexOf(t("title$Sub-surface")) > -1 && (
                      <BoreholeModal disabled={radioSelected !== 2 ? true : false} />
                    )}
                    {props.name.indexOf(t("title$Target_Design")) > -1 && (
                      <div className="target-upload-wrap">
                        <Input
                          disabled={radioSelected !== 2 ? true : false}
                          placeholder={t("placeholder$Enter_name")}
                          onChange={(e) => props.setTargetTitle(e.target.value)}
                        />
                        <FileUpload
                          disabled={radioSelected !== 2 ? true : false}
                          fileKey="targetDesign"
                        />
                      </div>
                    )}
                  </div>
                </li>
              </Radio.Group>
            </ul>
          </article>
        </section>
      )}
    </>
  );
}

export default connect((state) => state, {
  setSubSurface,
  setFileNames,
  setTopographicTitle,
  setTargetTitle,
  setAPITopoDate,
  setSelectTopoDate,
  setAPITargetDate,
  setSelectTargetDate,
  setSelectSurfaceDate,
})(ModelUpload);
