import * as Cesium from "cesium";
import { hex2Rgba } from "./Color";
import _ from "lodash";

const normalizeVector = (x, y, z) => {
  var magnitude = window.Math.sqrt(x * x + y * y + z * z);
  return { x: x / magnitude, y: y / magnitude, z: z / magnitude };
};
export const calculateDestinationPoint3D = (
  startX,
  startY,
  startZ,
  directionX,
  directionY,
  directionZ,
  distance
) => {
  var normalizedDirection = normalizeVector(directionX, directionY, directionZ);
  var endX = startX + distance * normalizedDirection.x;
  var endY = startY + distance * normalizedDirection.y;
  var endZ = startZ + distance * normalizedDirection.z;
  return { x: endX, y: endY, z: endZ };
};

export const getLLA = (position) => {
  const ellipsoid = Cesium.Ellipsoid.WGS84;
  const cartographic = ellipsoid.cartesianToCartographic(position);
  const lon = Cesium.Math.toDegrees(cartographic.longitude);
  const lat = Cesium.Math.toDegrees(cartographic.latitude);
  const alt = cartographic.height;
  return [lon, lat, alt];
};

export const getCenterCoordinates = (viewer) => {
  const cartographic = new Cesium.Rectangle(0, 0, 0, 0);
  viewer.camera.computeViewRectangle(Cesium.Ellipsoid.WGS84, cartographic);
  const west = Number(Cesium.Math.toDegrees(cartographic.west));
  const south = Number(Cesium.Math.toDegrees(cartographic.south));
  const east = Number(Cesium.Math.toDegrees(cartographic.east));
  const north = Number(Cesium.Math.toDegrees(cartographic.north));

  const x = (east - west) / 2 + west;
  const y = (north - south) / 2 + south;

  return [x, y, cartographic.height];
};

export const getUrlPcdWms = (projectId, date) => {
  return `/uploads/data_pcd/${projectId}/${date}/wms`;
};

// ########################################################################################################

export const flyToWms = async (viewer, layer, duration = 0) => {
  const rectangle = await layer.getViewableRectangle();

  return flyTo(viewer, {
    destination: rectangle,
    duration: duration,
  });
};

// ########################################################################################################

// ## Imagery ##

export const removeLayer = (viewer, layer) => {
  if (!viewer) return;
  if (!layer) return;

  viewer.imageryLayers.remove(layer);
};

export const getImageryLayer = (viewer, imageryProviderOrUrl) => {
  const index = getImageryLayerIndex(viewer, imageryProviderOrUrl);
  return index < 0 ? null : viewer.imageryLayers.get(index);
};

export const getImageryLayerIndex = (viewer, imageryProviderOrUrl) => {
  if (!viewer.imageryLayers) return -1;

  const url =
    typeof imageryProviderOrUrl === "string"
      ? imageryProviderOrUrl
      : imageryProviderOrUrl?._options?.url;
  const index = viewer.imageryLayers._layers.findIndex(
    (x) => x.imageryProvider._options?.url === url
  );
  return index;
};

export const addImageryProvider = (viewer, provider, index = undefined) => {
  if (!viewer || !provider) return;

  const idx = index && Number(index);

  return viewer.imageryLayers.addImageryProvider(provider, idx);
};

export const visibleTileMapLayer = (viewer, imageryProviderOrUrl, visible) => {
  const index = getImageryLayerIndex(viewer, imageryProviderOrUrl);

  if (index > -1) {
    viewer.imageryLayers._layers[index].show = visible;
  }
};

// GIS
export const visibleWebMapLayer = (viewer, imageryProviderOrUrl, visible) => {
  let index = -1;
  if (viewer.imageryLayers) {
    const { _layers } = imageryProviderOrUrl;
    index = viewer.imageryLayers._layers.findIndex((x) => x.imageryProvider?._layers === _layers);
  }

  if (index > -1) {
    viewer.imageryLayers._layers[index].show = visible;
  }
};

/** ImageryProvider
 *  https://cesium.com/learn/cesiumjs/ref-doc/ImageryProvider.html?classFilter=imageryProvider
 */

/** ArcGisMapServerImageryProvider */
/** BingMapsImageryProvider */
/** GoogleEarthEnterpriseImageryProvider */
/** GridImageryProvider */
/** IonImageryProvider */
/** MapboxImageryProvider */
/** MapboxStyleImageryProvider */
/** OpenStreetMapImageryProvider */
export const loadOpenStreetMap = (viewer, url) => {
  if (!url) return;
  const layer = new Cesium.OpenStreetMapImageryProvider({ url });
  return addImageryProvider(viewer, layer);
};

/** SingleTileImageryProvider */
/** TileCoordinatesImageryProvider */

/** TileMapServiceImageryProvider
 *  https://cesium.com/learn/cesiumjs/ref-doc/TileMapServiceImageryProvider.html?classFilter=imageryPro
 */
export const loadTileMap = (viewer, url, index = undefined) => {
  if (!url) return;
  const provider = new Cesium.TileMapServiceImageryProvider({ url });
  return addImageryProvider(viewer, provider, index);
};

/** UrlTemplateImageryProvider */
/** WebMapServiceImageryProvider
 *  https://cesium.com/learn/cesiumjs/ref-doc/WebMapServiceImageryProvider.html?classFilter=imageryProvider
 */

/** WebMapTileServiceImageryProvider
 *  https://cesium.com/learn/cesiumjs/ref-doc/WebMapTileServiceImageryProvider.html?classFilter=imageryProvider
 */

/** OpenStreetMapImageryProvider
 *  https://cesium.com/learn/cesiumjs/ref-doc/ArcGisMapServerImageryProvider.html
 */

/** TileMapServiceImageryProvider
 *  https://cesium.com/learn/cesiumjs/ref-doc/TileMapServiceImageryProvider.html
 */

// ########################################################################################################

export const getColor = (r, g, b, a) => {
  return new Cesium.Color(r, g, b, a);
};
export const getColorByArray = (arr) => {
  const arr2 = arr.map((x) => x / 255);
  return new Cesium.Color(arr2[0], arr2[1], arr2[2], arr2[3]);
};

export const getCenter = (positions) => {
  return Cesium.BoundingSphere.fromPoints(positions).center;
};

// ## Entity ##
export const initSelectedEntity = () => {
  return { feature: undefined, originalColor: new Cesium.Color() };
};
export const loadEntities = (viewer, dataSource) => {};

export const zoomTo = (viewer, object) => {
  viewer.zoomTo(object);
};

export const getEntityById = (entityCollection, id) => {
  return entityCollection.getById(id);
};
export const showEntityById = (entityCollection, id) => {
  const ett = entityCollection.getById(id);
  if (ett) ett.show = true;
};
export const hideEntityById = (entityCollection, id) => {
  const ett = entityCollection.getById(id);
  if (ett) ett.show = false;
};
export const toggleEntityById = (entityCollection, id) => {
  const ett = entityCollection.getById(id);
  if (ett) ett.show = !ett.show;
};

export const showEntities = (entityCollection, ids) => {
  ids.map((id) => {
    setTimeout(() => {
      showEntityById(entityCollection, id);
    }, 0);
  });
};
export const hideEntities = (entityCollection, ids) => {
  ids.map((id) => {
    setTimeout(() => {
      hideEntityById(entityCollection, id);
    }, 0);
  });
};
export const toggleEntities = (entityCollection, ids) => {
  ids.map((id) => {
    setTimeout(() => {
      toggleEntityById(entityCollection, id);
    }, 0);
  });
};

export const addEntities = (entityCollection, groupId, entities) => {
  entityCollection.add({
    id: groupId,
    entityCollection: entities,
  });
};
export const updateEntities = (entityCollection, entitiesOptions) => {
  entitiesOptions.map((entityOptions) => {
    // updateEntity(entityCollection, entityOptions);
    setTimeout(() => {
      updateEntity(entityCollection, entityOptions);
    }, 1);
  });
};

export const updateEntitiesBatch = (entityCollection, entitiesOptions, batchSize = 100) => {
  let index = 0;
  const updateBatch = () => {
    const endIndex = Math.min(index + batchSize, entitiesOptions.length);

    for (let i = index; i < endIndex; i++) {
      const entityOptions = entitiesOptions[i];
      updateEntity(entityCollection, entityOptions);
    }

    index = endIndex;
    if (endIndex < entitiesOptions.length) {
      setTimeout(updateBatch, 1000 / 60); // 60프레임
    }
  };

  updateBatch();
};

export const addEntity = (entityCollection, entityOptions) => {
  return entityCollection.add(entityOptions);
};
export const updateEntity = (entityCollection, entityOptions) => {
  if (!entityOptions) return;
  if (!entityOptions.id) return;

  const entity = entityCollection.getById(entityOptions.id);
  if (entity) {
    removeEntityById(entityCollection, entityOptions.id);
    addEntity(entityCollection, entityOptions);
  } else {
    addEntity(entityCollection, entityOptions);
  }
};

export const addPolygon = (entityCollection, polygonOptions) => {
  const ett = { ...polygonOptions };
  entityCollection.add(ett);
};
export const addPoint = (viewer, pointOptions) => {};
export const addPolyline = (viewer, polylineOptions) => {};

export const removeAllEntities = (entityCollection) => {
  entityCollection.removeAll();
};

export const removeEntityById = (entityCollection, id) => {
  if (id) entityCollection.removeById(id);
};
export const removeEntitiesById = (entityCollection, ids) => {
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    setTimeout(() => entityCollection.removeById(id), 1);
  }
};

export const convertPolygonToArray = (str) => {
  if (!str) {
    return null;
  } else {
    return str
      .replaceAll("POLYGON", "")
      .replaceAll("Z", "")
      .replaceAll("((", "")
      .replaceAll("))", "")
      .trim()
      .replaceAll(" ", ",")
      .split(",")
      .map((x) => parseFloat(x));
  }
};

export const convertPointsToArray = (str) => {
  if (!str) {
    return null;
  } else {
    return str
      .replaceAll("POINT", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .trim()
      .replaceAll(" ", ",")
      .split(",")
      .map((x) => parseFloat(x));
  }
};

const convertRoutePoint = (str) => {
  if (!str) return null;

  return str
    .replaceAll("LINESTRING(", "")
    .replaceAll(",", ",0,")
    .replaceAll(")", ",0")
    .replaceAll(" ", ",")
    .split(",")
    .map((x) => parseFloat(x));
};

const convertPoint = (str) => {
  if (!str) return null;

  return str
    .replaceAll("POINT(", "")
    .replaceAll(")", ",0")
    .replaceAll(" ", ",")
    .split(",")
    .map((x) => parseFloat(x));
};

/**  동서남북 좌표
 *  (Polygon의 변환 배열에서 좌표 분리)
 */
export const getRectangleCoordinates = (coordinatesArray) => {
  const evenIndexArray = coordinatesArray.filter((v, i) => {
    return !(i % 2);
  });
  const oddIndexArray = coordinatesArray.filter((v, i) => {
    return i % 2;
  });

  const minY = Math.min.apply(Math, evenIndexArray);
  const maxY = Math.max.apply(Math, evenIndexArray);
  const minX = Math.min.apply(Math, oddIndexArray);
  const maxX = Math.max.apply(Math, oddIndexArray);

  return {
    minX: minX,
    maxX: maxX,
    minY: minY,
    maxY: maxY,
  };
};

export const highlightEntityPoint = (entity) => {
  if (!entity) return;

  entity.point.outlineColor = Cesium.Color.WHITE;
  entity.point.outlineWidth = 1.5;

  entity.label.showBackground = true;
  entity.label.backgroundColor = entity.point.color;
  entity.label.fillColor = Cesium.Color.WHITE;
  entity.label.verticalOrigin = Cesium.VerticalOrigin.TOP;
  entity.label.horizontalOrigin = Cesium.HorizontalOrigin.CENTER;

  entity.label.pixelOffset = new Cesium.Cartesian2(0, -30);
};

export const highlightOffEntityPoint = (
  entity,
  pointSize,
  color,
  labelFillColor = "#000000",
  labelPixelOffset = [4, 0]
) => {
  if (!entity) return;

  entity.point.color = Cesium.Color.fromCssColorString(color);
  entity.point.pixelSize = pointSize;

  entity.point.outlineWidth = 0;

  entity.label.showBackground = false;
  entity.label.fillColor = Cesium.Color.fromCssColorString(labelFillColor);

  entity.label.pixelOffset = new Cesium.Cartesian2(
    labelPixelOffset[0] + parseInt(pointSize / 2),
    labelPixelOffset[1]
  );
  entity.label.verticalOrigin = Cesium.VerticalOrigin.CENTER;
  entity.label.horizontalOrigin = Cesium.HorizontalOrigin.LEFT;
};

export const highlightEntityPolyline = (entity, color) => {
  if (!entity) return;

  entity.polyline.width = 7;
  // entity.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
  //   color: Cesium.Color.ORANGE,
  //   outlineWidth: 3,
  //   outlineColor: Cesium.Color.WHITE,
  // });
  entity.polyline.material = new Cesium.PolylineGlowMaterialProperty({
    color: getColorByArray(hex2Rgba(color)),
    glowPower: 0.5,
  });
  entity.polyline.zIndex = 9999;
  // entity.polyline.material = new Cesium.PolylineArrowMaterialProperty(Cesium.Color.ORANGE);
};

export const highlightOffEntityPolyline = (entity, style, color, thickness) => {
  if (!entity) return;

  entity.polyline.zIndex = 0;
  entity.polyline.width = thickness;
  entity.polyline.material = getMaterial(style, color);
};

export const updateEntityPoint = (entity, pointSize, pointColor) => {
  entity.point.pixelSize = pointSize;
  entity.point.color = getColorByArray(hex2Rgba(pointColor));
  // entity.label.backgroundColor = getColorByArray(hex2Rgba(pointColor));
};

export const updateEntityPolyline = (
  entity,
  style,
  color,
  thickness = entity.polyline.width || 1
) => {
  const material = getMaterial(style, color);
  entity.polyline.material = material;
  entity.polyline.width = thickness;
};

export const updateEntityPolygonMaterial = (entity, color, alpha = 50) => {
  const material = Cesium.Color.fromAlpha(Cesium.Color.fromCssColorString(color), alpha / 100);
  entity.polygon.material = material;
};

export const getHistoryPointOptions = (id, lat, lng, color, pointSize = 5) => {
  try {
    const positions = fromDegrees(lng, lat, 0);
    return {
      id: id,
      position: positions,
      point: {
        pixelSize: pointSize,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        color: color,
      },
    };
  } catch (e) {
    return null;
  }
};

export const getHaulingPointOptions = (
  id,
  name,
  show,
  position,
  pointSize,
  pointColor,
  labelText,
  labelFillColor = "#000000",
  labelOutlineColor = "#000000",
  labelBackgroundColor = "#FFFFFF80",
  labelPixelOffset = [4, 0],
  labelOutlineWidth = 0.5
) => {
  try {
    const pointArr = convertPoint(position);
    const positions = fromDegrees(pointArr[0], pointArr[1], pointArr[2]);
    return {
      id: id,
      name: name,
      position: positions,
      point: {
        pixelSize: pointSize,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        color: Cesium.Color.fromCssColorString(pointColor),
      },
      label: {
        text: labelText,
        font: "bold 9pt Segoe UI Semibold",
        // fillColor: Color.fromCssColorString(pointColor),
        fillColor: Cesium.Color.fromCssColorString(labelFillColor),
        // outlineColor: Color.fromCssColorString(labelOutlineColor),
        // outlineWidth: labelOutlineWidth,
        showBackground: false,
        backgroundColor: Cesium.Color.fromCssColorString(labelBackgroundColor),
        pixelOffset: new Cesium.Cartesian2(
          labelPixelOffset[0] + parseInt(pointSize / 2),
          labelPixelOffset[1]
        ),
        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        style: Cesium.LabelStyle.FILL,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      },
      show: show,
    };
  } catch (e) {
    return null;
  }
};
export const getMaterial = (style, color) => {
  let material;
  switch (style) {
    case "0":
      material = getColorByArray(hex2Rgba(color));
      break;
    case "1":
      material = new Cesium.PolylineDashMaterialProperty({
        color: getColorByArray(hex2Rgba(color)),
        // dashPattern: parseInt("11011011", 2),
        dashLength: 32,
      });
      break;
    case "2":
      material = new Cesium.PolylineDashMaterialProperty({
        color: getColorByArray(hex2Rgba(color)),
        // dashPattern: parseInt("10101010", 2),
        dashLength: 4,
      });
      break;
    default:
      material = getColorByArray(hex2Rgba("#FFFFFF"));
      break;
  }
  return material;
};

/** hauling options */
export const getHaulingRouteOptions = (id, name, show, pointStr, style, color, thickness) => {
  const material = getMaterial(style, color);
  const tempArr = convertRoutePoint(pointStr);
  const pointArr = _.chunk(tempArr, 3).map((x) => {
    return fromDegrees(x[0], x[1], x[2]);
  });

  return getPolylineOptions(id, name, pointArr, thickness, material, show);
};

/** geofence options */
export const getGeofencePositions = (positionString) => {
  const coordArr = positionString
    .replaceAll(", ", ",")
    .replaceAll(" ", ",")
    .split(",")
    .map((x) => parseFloat(x));

  const pointArr = _.chunk(coordArr, 3).map((x) => {
    return fromDegrees(x[0], x[1], x[2]);
  });

  return pointArr;
};

export const getGeofenceEntityOptions = (data, cesiumInstance = Cesium) => {
  const {
    id,
    title,
    fence_type,
    f_fence_xyz_latlng,
    f_fill_color,
    f_fill_transparency,
    f_line_color,
    f_line_thickness,
    checked,
    labelMaximumDistance,
  } = data;

  switch (fence_type?.toUpperCase()) {
    case "POLYGON":
      return getGeofencePolygonOptions(
        {
          id: id,
          name: title,
          positions: f_fence_xyz_latlng,
          fillColor: f_fill_color,
          fillAlpha: f_fill_transparency,
          outlineColor: f_line_color,
          outlineWidth: f_line_thickness,
          visible: checked,
          labelText: title,
          labelFillColor: f_fill_color,
          labelMaximumDistance: labelMaximumDistance,
        },
        cesiumInstance
      );
    case "LINE":
      return getGeofencePolylineOptions(
        {
          id: id,
          name: title,
          positions: f_fence_xyz_latlng,
          width: f_line_thickness,
          color: f_line_color,
          visible: checked,
          labelText: title,
          labelFillColor: f_line_color,
          labelMaximumDistance: labelMaximumDistance,
        },
        cesiumInstance
      );
    case "POINT":
      return;

    default:
      return;
  }
};

export const getHaulingGeofenceEntityOptions = (data, cesiumInstance = Cesium) => {
  const {
    id,
    title,
    fence_type,
    f_fence_xyz_latlng,
    f_fill_color,
    f_line_thickness,
    checked = true,
  } = data;

  switch (fence_type.toUpperCase()) {
    case "POLYGON":
      return getGeofencePolygonOptions(
        {
          id: id,
          name: title,
          positions: f_fence_xyz_latlng,
          fillColor: f_fill_color,
          fillAlpha: 80,
          outlineColor: f_fill_color,
          outlineWidth: 0,
          visible: checked,
        },
        cesiumInstance
      );
    case "LINE":
      return getGeofencePolylineOptions(
        {
          id: id,
          name: title,
          positions: f_fence_xyz_latlng,
          width: f_line_thickness,
          color: f_fill_color,
          visible: checked,
        },
        cesiumInstance
      );
    case "POINT":
      return;
    default:
      return;
  }
};

export const getGeofencePolylineOptions = (
  {
    id,
    name,
    positions,
    width,
    color,
    visible,
    labelText,
    labelFillColor = "#000000",
    labelOutlineColor = "#000000",
    labelBackgroundColor = "#FFFFFF",
    labelPixelOffset = [0, -10],
    labelOutlineWidth = 0,
    labelMaximumDistance = 2000,
  },
  cesiumInstance
) => {
  const Cesium = cesiumInstance;

  const pointArr = getGeofencePositions(positions);
  const material = Cesium.Color.fromCssColorString(color);
  const center = pointArr[parseInt(pointArr.length / 2)];
  return {
    id: id,
    name: name,
    show: visible,
    polyline: {
      positions: pointArr,
      material: material,
      width: width,
      clampToGround: true,
    },
    position: center,
    label: {
      text: labelText,
      font: "20pt",
      fillColor: getColorByArray(hex2Rgba(labelFillColor)),
      outlineColor: getColorByArray(hex2Rgba(labelOutlineColor)),
      backgroundColor: getColorByArray(hex2Rgba(labelBackgroundColor, 0.5)),
      pixelOffset: new Cesium.Cartesian2(labelPixelOffset[0], labelPixelOffset[1]),
      outlineWidth: labelOutlineWidth,
      heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      showBackground: false,
      show: true,
      distanceDisplayCondition: {
        near: 0,
        far: labelMaximumDistance,
      },
    },
  };
};
export const getGeofencePolygonOptions = (
  {
    id,
    name,
    positions,
    fillColor,
    fillAlpha,
    outlineColor,
    outlineWidth,
    visible,
    labelText,
    labelFillColor = "#000000",
    labelOutlineColor = "#000000",
    labelBackgroundColor = "#FFFFFF",
    labelPixelOffset = [0, 0],
    labelOutlineWidth = 0.5,
    labelMaximumDistance = 2000,
  },
  cesiumInstance
) => {
  const Cesium = cesiumInstance;
  const pointArr = getGeofencePositions(positions);
  const material = Cesium.Color.fromAlpha(
    Cesium.Color.fromCssColorString(fillColor),
    (fillAlpha ?? 50) / 100
  );
  const outlineMaterial = Cesium.Color.fromCssColorString(outlineColor);
  const center = getCenter(pointArr);
  return {
    id: id,
    name: name,
    show: visible,
    polyline: {
      positions: [...pointArr, pointArr[0]],
      material: outlineMaterial,
      width: outlineWidth,
      clampToGround: true,
    },
    polygon: {
      hierarchy: pointArr,
      material: material,
      // height: 0,
      // outline: true,
      // outlineColor: outlineMaterial,
      // outlineWidth: outlineWidth,
    },
    position: center,
    label: {
      text: labelText,
      font: "bold 9pt Segoe UI Semibold",
      fillColor: getColorByArray(hex2Rgba(labelFillColor)),
      outlineColor: getColorByArray(hex2Rgba(labelOutlineColor)),
      backgroundColor: getColorByArray(hex2Rgba(labelBackgroundColor, 0.5)),
      pixelOffset: new Cesium.Cartesian2(labelPixelOffset[0], labelPixelOffset[1]),
      outlineWidth: labelOutlineWidth,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      showBackground: false,
      show: true,
      distanceDisplayCondition: {
        near: 0,
        far: labelMaximumDistance,
      },
    },
  };
};

export const getPointOptions = (id, name, position, size, color) => {};
export const getPointAndLabelOptions = (
  id,
  name,
  position,
  pointSize,
  pointColor,
  labelText,
  labelFont,
  labelFillColor,
  labelOutlineColor,
  labelBackgroundColor,
  labelPixelOffsetX,
  labelPixelOffsetY,
  labelOutlineWidth
) => {
  return {
    id: id,
    name: name,
    position: position,
    point: {
      pixelSize: pointSize,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      color: getColorByArray(hex2Rgba(pointColor)),
    },
    label: {
      text: labelText,
      font: labelFont,
      fillColor: getColorByArray(hex2Rgba(labelFillColor ?? "#000000")),
      outlineColor: getColorByArray(hex2Rgba(labelOutlineColor ?? "#000000")),
      backgroundColor: getColorByArray(hex2Rgba(labelBackgroundColor ?? "#FFFFFF")),
      pixelOffset: new Cesium.Cartesian2(labelPixelOffsetX, labelPixelOffsetY),
      outlineWidth: labelOutlineWidth,
      heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      showBackground: true,
      show: true,
    },
  };
};

export const getPolylineOptions = (id, name, positions, width, material, show = true) => {
  return {
    id: id,
    name: name,
    polyline: {
      positions: positions,
      material: material,
      width: width,
      clampToGround: true,
    },
    show: show,
  };
};

export const getPolylineOptions2 = (id, name, positions, width, color, alpha) => {
  const material = getColorByArray(hex2Rgba(color, alpha));

  return getPolylineOptions(id, name, positions, width, material);
};

export const getPolylineAndLabelOptions = () => {};

export const getPolygonOptions = (id, name, hierarchy, material) => {
  return {
    id: id,
    name: name,
    polygon: {
      hierarchy: hierarchy,
      material: material,
    },
  };
};

export const getPolygonOptions2 = (id, name, hierarchy, color, alpha) => {
  const material = getColorByArray(hex2Rgba(color, alpha));
  return getPolygonOptions(id, name, hierarchy, material);
};

// ## Camera ##
export const flyTo = (viewer, object) => {
  viewer.scene.camera.flyTo(object);
};

// Camera: setView
export const setView = (viewer, destination) => {
  viewer.camera.setView({
    destination: destination,
  });
};

export const getHeightReference = (value) => {
  return Cesium.HeightReference[value];
};
export const getLabelStyle = (value) => {
  return Cesium.LabelStyle[value];
};

/**
 * Rectangle Converter
 *
 * North: X좌표 최대
 * South: X좌표 최소
 * West: Y좌표 최소
 * East: Y좌표 최대
 * */
export const getRectangleFromDegrees = (minX, maxX, minY, maxY) => {
  const south = minX;
  const north = maxX;
  const west = minY;
  const east = maxY;

  return Cesium.Rectangle.fromDegrees(west, south, east, north);
};

/** Cartesian2 */
export const getCartesian2 = (x, y) => {
  return new Cesium.Cartesian2(x, y);
};

/** Cartesian3: 3차원 좌표 */
export const fromDegrees = (x, y, z) => {
  return Cesium.Cartesian3.fromDegrees(x, y, z);
};

/** Cartesian3: 배열 */
export const fromDegreesArray = (array) => {
  return Cesium.Cartesian3.fromDegreesArray(array);
};

export const getEntityFormatFleetHistoryTrackingPoint = (lat, lng, text) => {
  const position = fromDegrees(lat, lng, 0);

  return {
    position: position,
    label: {
      text: text,
      font: "22pt",
    },
    show: true,
  };
};

export const htmlCredit = (html) => {
  return new Cesium.Credit(html);
};
