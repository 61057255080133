export function visibleHandle(_3DObject, bool) {
  // return true;
  if (_3DObject.children.length) {
    if (bool) {
      if (_3DObject.overayType !== 'dxf') {
        _3DObject.children.map(obj => obj.visible = true)
        _3DObject.children[0].visible = false;
      } else {
        _3DObject.children.map(obj => obj.visible = true)
        _3DObject.children[0].visible = true;
      }
      return bool;
    }

    if (_3DObject.overayType !== 'dxf') {
      _3DObject.children.map(obj => obj.visible = false);
      _3DObject.children[_3DObject.children.length - 1].visible = true;
    } else {
      _3DObject.children.map(obj => obj.visible = true);
      _3DObject.children[_3DObject.children.length - 1].visible = true;
    }
  }

  return bool;
}