import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currentMeasure } from "../../../store/actions/projectChildrenComponent";
import ContentTitle from "../../../components/elements/text/contentTitle";
import AnnotationProperties from "../children/AnnotationProperties";
import { createAnnotation } from "../util/fnCreateAnnotaion";
import WorkspaceTitle from "../children/WorkspaceTitle";
import { initLineAttr, getAttribute } from "../util/lineAttribute";
import AnnotationFooter from "../children/AnnotationFooter";
import MeasurementVisualization from "../children/MeasurementVisualization";
import { configAndSave } from "../util/save";
import { setHandleVisible, setHandleTargetName } from "../../../store/actions/saveHandle";
import { titleAnnotationVisible } from "../util/initialize";
import _ from "lodash";
import { addCommaWithRound } from "utils/StringHelper";
import { ROUND_OFF_THE_NUMBERS_DEGREE, ROUND_OFF_THE_NUMBERS_LENGTH } from "config/measure";
import { CoordinateForm } from "../children/CoordinateForm";
import "./index.less";

// const { Option } = Select;
const MeasurementLine = (props) => {
  const { t, i18n } = useTranslation();
  const measure = props.projectChildrenComponents.currentMeasure;

  const [attributes, setAttributes] = useState(initLineAttr(t));

  const getAttributeAPI = async () => {
    const { data } = await getAttribute(props);
    if (data) {
      // let defaultAttributes = initLineAttr(t);
      if (data.status && data.status === 500) return setAttributes(initLineAttr(t));

      const attrArr = [];

      attrArr.push({
        title: t("title$Horizontal_Length"),
        value: data[0].h_length
          ? addCommaWithRound(data[0].h_length, ROUND_OFF_THE_NUMBERS_LENGTH)
          : "00",
        key: 0,
      });

      attrArr.push({
        title: t("title$Surface_Length"),
        value: data[0].s_length
          ? addCommaWithRound(data[0].s_length, ROUND_OFF_THE_NUMBERS_LENGTH)
          : "00",
        key: 1,
      });

      // geofence 일 경우 표시 안함
      if (measure.toolObj.value !== "l1") {
        attrArr.push({
          title: t("title$Slope"),
          value: "0",
          visibility: true,
          key: 2,
        });

        attrArr.push({
          title: t("title$ - Degree"),
          value: _.isNil(data[0].ang_degree_max)
            ? [t("format$Max"), t("format$Min")]
            : [
                data[0].ang_degree_max.toFixed(ROUND_OFF_THE_NUMBERS_DEGREE),
                data[0].ang_degree_min.toFixed(ROUND_OFF_THE_NUMBERS_DEGREE),
              ],
          key: 3,
        });

        attrArr.push({
          title: t("title$ - Rate"),
          value: _.isNil(data[0].ang_rate_max)
            ? [t("format$Max"), t("format$Min")]
            : [
                data[0].ang_rate_max.toFixed(ROUND_OFF_THE_NUMBERS_DEGREE),
                data[0].ang_rate_min.toFixed(ROUND_OFF_THE_NUMBERS_DEGREE),
              ],
          key: 4,
        });

        data.map((item, i) => {
          if (i !== 0) {
            attrArr.push({
              title: t(`title$Segment. %{count}`, { count: i }),
              value: _.isNil(item.ang_rate_max)
                ? [t("format$Max"), t("format$Min")]
                : [
                    item.ang_degree_max.toFixed(ROUND_OFF_THE_NUMBERS_DEGREE),
                    item.ang_rate_max.toFixed(ROUND_OFF_THE_NUMBERS_DEGREE),
                  ],
              isLine: true,
              key: 5 + i,
            });
          }
          return false;
        });
      }

      return setAttributes(attrArr);
    }
  };

  const handleSave = () => configAndSave(false, createAnnotation());

  useEffect(() => {
    getAttributeAPI();
    titleAnnotationVisible(false);
    return () => {
      titleAnnotationVisible(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure, i18n.language]);

  return (
    <div className="measurement">
      <WorkspaceTitle wrapperName="measurement" />
      <section className="measurement__content">
        <article className="measurement__content__part">
          <ContentTitle title={t("title$Result", { count: 2 })} style={{ color: "#182e63" }} />
          <CoordinateForm points={measure.measure.points} onSetCallback={getAttributeAPI} />
          <AnnotationProperties properties={attributes} />
        </article>
        <MeasurementVisualization type="Line" />
      </section>
      <AnnotationFooter onClick={handleSave} className="measurement_footer" />
    </div>
  );
};

// export default Measurement;
export default connect((state) => state, {
  currentMeasure,
  setHandleVisible,
  setHandleTargetName,
  // setData,
})(MeasurementLine);
