//types
export const MEASUREMENT = 'PROJECT/MEASUREMENT';
export const CURRENT_MEASUREMENT = 'PROJECT/CURRENT/MEASUREMENT';
export const CURRENT_BACK_MEASUREMENT = 'PROJECT/BACKUP/MEASUREMENT';


// export const CURRENT_POINTCLOUD = 'PROJECT/CURRENT/POINTCLOUD';

//setter
export const visibleMeasurement = (visible) => ({ type: MEASUREMENT, visible });
export const currentMeasure = measure => ({ type: CURRENT_MEASUREMENT, measure });
export const currentBackupMeasure = measure => ({ type: CURRENT_BACK_MEASUREMENT, measure });

export const INIT_MEASURE = 'PROJECT/MEASURE';
export const initMeasure = () => ({ type: INIT_MEASURE });

export const POINTCLOUD = 'PROJECT/POINTCLOUD';
export const visiblePointCloud = visible => ({ type: POINTCLOUD, visible });

export const CONTOUR = 'PROJECT/CONTOUR';
export const visibleContour = visible => ({ type: CONTOUR, visible });

export const FLEET_GEOFENCE = 'PROJECT/FLEET_GEOFENCE';
export const visibleFleetGeo = visible => ({ type: FLEET_GEOFENCE, visible });


// export const currentPointCloud = pointCloud => ({ type: CURRENT_POINTCLOUD, pointCloud });