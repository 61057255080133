import React from 'react';

const defaultStyle = {
    display: 'inline-block',
    width: '8px',
    height: '8px',
    borderRadius: '8px'
}

const PickColorUI = (props) => {

    return (
        <>
            <span style={{ ...defaultStyle, backgroundColor: props.text}}></span>
            {' '}
            {props.text}
        </>
    );
}

export default PickColorUI;
