import { combineReducers } from 'redux';

import AppReducer from './app';
import ProjectReducer from './projectList';
import ModelUploadReducer from './uploadModel';
import RockTypeReducer from './rockTypes';
import CurrentProjectReducer from './currentProject';
import ModelLayerReducer from './modelLayer';
import CurrentModelReducer from './currentModel';
import ProjectComponentVisibleReducer from './projectChildrenComponent';
import RockDataSetReducer from './rockChartDataSet';
import ViewDataSetReducer from './viewDataSet';
import ReportReducer from './report';
import ModalReducer from './Modal';
import OverlayReducer from './overlay';
import BoreHoleReducer from './borehole';
import PreviewerReducer from './previewImage';
import MeasureColorReducer from './MeasurementColor';
import CreateOrEditProjectReducer from './createAndEditProject';
import CrossSectionReducer from './crossSection';
import SaveHandleReducer from './saveHandle';
import ChatReducer from './chatReducer';
import FleetReducer from './fleet.asset';
import TokenReducer from './token';

export default combineReducers({
    appState: AppReducer,
    projectReducer: ProjectReducer,
    modelUploadReducer: ModelUploadReducer,
    rockTypeReducer: RockTypeReducer,
    currentProjectReducer: CurrentProjectReducer,
    modelLayerReducer: ModelLayerReducer,
    currentModelReducer: CurrentModelReducer,
    projectChildrenComponents: ProjectComponentVisibleReducer,
    ViewDataSetReducer,
    RockDataSetReducer,
    ReportReducer,
    ModalReducer,
    OverlayReducer,
    BoreHoleReducer,
    PreviewerReducer,
    MeasureColorReducer,
    CreateOrEditProjectReducer,
    CrossSectionReducer,
    SaveHandleReducer,
    ChatReducer,
    FleetReducer,
    TokenReducer
})