import React from 'react';

const appSVGSrc = `M280.5,9.5H160H39.5c-16.6,0-30,13.4-30,30V160v120.5c0,16.6,13.4,30,30,30H160h120.5c16.6,0,30-13.4,30-30V160V39.5
C310.5,22.9,297.1,9.5,280.5,9.5z M93.3,240C86,240,80,234,80,226.7s6-13.3,13.3-13.3c7.4,0,13.3,6,13.3,13.3S100.7,240,93.3,240z
M93.3,173.3c-7.4,0-13.3-6-13.3-13.3c0-7.4,6-13.3,13.3-13.3c7.4,0,13.3,6,13.3,13.3C106.7,167.4,100.7,173.3,93.3,173.3z
M93.3,106.7c-7.4,0-13.3-6-13.3-13.3S86,80,93.3,80c7.4,0,13.3,6,13.3,13.3S100.7,106.7,93.3,106.7z M160,240
c-7.4,0-13.3-6-13.3-13.3s6-13.3,13.3-13.3s13.3,6,13.3,13.3S167.4,240,160,240z M160,173.3c-7.4,0-13.3-6-13.3-13.3
c0-7.4,6-13.3,13.3-13.3s13.3,6,13.3,13.3C173.3,167.4,167.4,173.3,160,173.3z M160,106.7c-7.4,0-13.3-6-13.3-13.3S152.6,80,160,80
s13.3,6,13.3,13.3S167.4,106.7,160,106.7z M226.7,240c-7.4,0-13.3-6-13.3-13.3s6-13.3,13.3-13.3c7.4,0,13.3,6,13.3,13.3
S234,240,226.7,240z M226.7,173.3c-7.4,0-13.3-6-13.3-13.3c0-7.4,6-13.3,13.3-13.3c7.4,0,13.3,6,13.3,13.3
C240,167.4,234,173.3,226.7,173.3z M226.7,106.7c-7.4,0-13.3-6-13.3-13.3s6-13.3,13.3-13.3c7.4,0,13.3,6,13.3,13.3
S234,106.7,226.7,106.7z`

const SvgIcon = (props) => {
    return (
        <svg
        x={props.x ? props.x : '0px'}
        y={props.y ? props.y : '0px'}
        width={props.width ? props.width : "1em"}
        height={props.height ? props.height : "1em"}
        viewBox={props.viewBox ? props.viewBox : "0 0 320 320"}
        >
        <path
            fill={props.fill ? props.fill : `#edeff2`}
            d={ props.src ? props.src : appSVGSrc }
        />
    </svg>
    );
}

export default SvgIcon;
