import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

//print 아이콘 요청 후 전환하기
// import exportIcon from "../../../assets/icons-2/Icon_Export.svg";
// import ChartTable from "./LongitudinalChart/ChartTable";
import downLoadIcon from "../../../assets/icons-2/Icon_Download.svg";
import LongitudinalChart from "./LongitudinalChart/LongitudinalChart";
import CrossSectionDashBoard from "./CrossSectionDashboard/CrossSectionDashBoard";

// import { Button } from "antd";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { setRockDataSet, setChartDataSet, setColor } from "../../../store/actions/rockChartDataSet";
import { openCrossectionPopup, fnDownloadSectionDXF } from "../../../xitecloud3D/xiteCloud.dev";
import HocChartTable from "./LongitudinalChart/HocChartTable";
import { setReportChart } from "../../../views/Measurement/CroSSection/utils/setChartData";
import TempTables from "./TempTables/TempTables";

/**
 * @component
 *    - CrossSectionChart
 * @description
 *    - React Hooks API
 *    - Analysis Section Dashboard
 *    - Project search
 */
const CrossSectionChart = (props) => {
  const { t } = useTranslation();
  /**
   * @component
   *    - CrossSectionChart
   * @description
   *    - Default Chart Options [ Label, Data ]
   */
  const [chartLabel, setChartLabel] = useState([0, 1, 2, 3, 4, 5]);

  /**
   * @component
   *    - CrossSectionChart
   * @description
   *    - initialize Selected Tab
   */
  const [selectedTab, setSelectedTab] = useState("longitudinal");
  const onSelectTab = (e) => setSelectedTab(e);
  const [longData, setLongData] = useState([]);
  useEffect(() => {
    if (Object.keys(props.CrossSectionReducer.crossSectionDatasetArr).length) {
      setReportChart(props.CrossSectionReducer.crossSectionDatasetArr, null, setLongData);
    }
    console.log(props.CrossSectionReducer.crossSectionDatasetArr, longData);
  }, [props.CrossSectionReducer.crossSectionDatasetArr]);

  // useEffect(() => {
  //   if (props.CrossSectionReducer.crossSectionLoaded.length) {
  //     const propsDatas = props.CrossSectionReducer.crossSectionLoaded;
  //     /*
  //        //토포(rock0) + 암층 별  스테이션 DATA{line_idx 0 종단, line_idx > 0 스테이션별 라인 포인트 )}
  //        props.data
  //     */
  //     const PropsDataSet = {};
  //     for (let i = 0; i < propsDatas.length; i++) {
  //       const currentLineData = propsDatas[i].data;
  //       const linePivot = currentLineData.line[currentLineData.line.length - 1].line_idx;
  //       const lineFilter = {};

  //       for (let y = 0; y <= linePivot; y++) {
  //         lineFilter["line_id_" + y] = currentLineData.line.filter(
  //           (x) => x.line_idx === y
  //         );
  //       }

  //       PropsDataSet["rock_" + currentLineData.idx] = {
  //         line: lineFilter,
  //         analysis: currentLineData.analysis,
  //         _3d: currentLineData._3d,
  //         station_spacing: currentLineData.station_spacing,
  //         transverse: currentLineData.transverse,
  //       };
  //     }

  //     const chartDataSets = [];
  //     // const colors = [];
  //     // let dataArr = [];
  //     Object.keys(PropsDataSet).map((datasetKey, i) => {
  //       // colors.push(
  //       //   i === 0
  //       //     ? window.xitecloud.defaultColor.faceColor
  //       //     : window.xitecloud.ArrColor[i - 1]
  //       // );
  //       // let dataArr = PropsDataSet[datasetKey].line.line_id_0.map((lineObject) => lineObject.top_z);
  //       // dataArr = [];
  //       // PropsDataSet[datasetKey].line.line_id_0.map((lineObject) => lineObject.top_z);
  //       chartDataSets.push({
  //         label: datasetKey,
  //         data: PropsDataSet[datasetKey].line.line_id_0.map((lineObject) => lineObject.top_z),
  //         // backgroundColor: defaultColorArr[i],
  //         // borderColor: '#000',
  //         borderWidth: 1,
  //         fill: true,
  //       });
  //     });

  //     const labelArr = [];

  //     for (
  //       let i = 0;
  //       i < PropsDataSet["rock_0"].line.line_id_0.length - 1;
  //       i++
  //     ) {
  //       labelArr.push(i);
  //     }

  //     const labelTabelLabel = [];
  //     if (PropsDataSet["rock_0"].analysis.length) {
  //       PropsDataSet["rock_0"].analysis.map((x) =>
  //         labelTabelLabel.push(x.st_idx)
  //       );
  //     }

  //     // props.data.analysis.map((x) => labelTabelLabel.push(x.st_idx));
  //     //redux
  //     props.setRockDataSet(PropsDataSet);
  //     props.setChartDataSet(chartDataSets);
  //     // props.setColor(colors);

  //     // setTableLabel(labelTabelLabel);
  //     setChartLabel(labelArr);

  //     //state
  //     setChartData(chartDataSets);
  //     // setChartData(dataSets);
  //   }
  // }, [props.CrossSectionReducer.crossSectionLoaded]);

  const open3DCrossSectionChart = () =>
    openCrossectionPopup(
      props.projectChildrenComponents.currentMeasure,
      props.ViewDataSetReducer.projectViewDataSetModel,
      props.currentProjectReducer.currentProject
    );

  const downloadDXF = () => {
    fnDownloadSectionDXF(props.CrossSectionReducer.crossSectionDatasetArr);
  };

  return (
    <div
      className="drawer-section-analysis"
      style={{
        bottom: props.visible ? 0 : "-100vh",
      }}
    >
      <section className="drawer-title">
        <h2>{t("title$Section_Analysis_Results")}</h2>
        <p>
          {/* Longitudinal Section Dashboard{" "} */}

          {/* <Switch
            defaultChecked

            //  onChange={onChange}
          />{" "} */}
          <span className="head-right-wrap">
            {/* <Button className="">2D</Button> */}
            {/* viewData, xitecloudViewDataSet, currentProject */}
            {/* <Button onClick={open3DCrossSectionChart}>3D</Button> */}
            {/* <Button className="" block>print</Button> */}
            {/* <span className="down-icon">
              <img src={exportIcon} alt="print icon" />
            </span> */}
            <TempTables value={props.currentProjectReducer.selectedDate} />{" "}
            <span className="down-icon" onClick={downloadDXF}>
              <img src={downLoadIcon} alt="download icon" />
            </span>
            <CloseOutlined
              onClick={props.onClose}
              style={{
                display: "inline-block",
                height: "30px",
                lineHeight: "30px",
                marginLeft: "12px",
                fontSize: "18px",
              }}
            />
            {/* 토적표 팝업 컴포넌트 */}
            {/*  */}
            {/* <button id="temp-btn" onClick={onDataViewer}>토적표</button> */}
          </span>
          <span className="tab-wrap">
            <span
              value="longitudinal"
              style={{
                color: selectedTab === "longitudinal" ? "#000000" : "#828b99",
              }}
              onClick={() => onSelectTab("longitudinal")}
            >
              {t("button$Longitudinal_Section")}
            </span>
            <span> | </span>
            <span
              style={{
                color: selectedTab !== "longitudinal" ? "#000000" : "#828b99",
              }}
              value="crossSection"
              onClick={() => onSelectTab("crossSection")}
            >
              {t("button$Cross_Section")}
            </span>
          </span>
        </p>
      </section>
      <section className="drawer-content">
        {selectedTab === "crossSection" ? (
          <CrossSectionDashBoard
            label={chartLabel}
            data={props.CrossSectionReducer.crossSectionDatasetArr}
            gradient
          />
        ) : (
          <>
            <LongitudinalChart
              data={longData}
              label={chartLabel}
              isLong={true}
              gradient
              dataSet={props.CrossSectionReducer.crossSectionDatasetArr}
            />

            <HocChartTable />
          </>
        )}
      </section>
    </div>
  );
};

// export default CrossSectionChart;
export default connect((state) => state, {
  setRockDataSet,
  setChartDataSet,
  setColor,
})(CrossSectionChart);
