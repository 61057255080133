import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import Axios from "axios";

import IconLocation from "../../../assets/icons/location.svg";
import IconWeatherRain from "../../../assets/icons/weather-rain.svg";
import IconWeatherSunny from "../../../assets/icons/weather-sunny.svg";
import { ReactComponent as IconEdit } from "../../../assets/icons-2/Icon_Edit.svg";
import { ReactComponent as IconDelete } from "../../../assets/icons-2/Icon_Delete.svg";
import DropDown from "../../elements/dropdown";
import { capitalizeFirstLetter } from "utils/StringHelper";
import { useTranslation } from "react-i18next";
import { consoleError } from "../../../middleware";
import { Modal, Tooltip, message } from "antd";
import { getProjectListAll } from "views/home/utils/projectList";
import "./projectCard.less";

function ProjectCard(props) {
  const { t } = useTranslation();

  const { item, itemKey } = props;

  const [state, setstate] = useState({
    weatherDictionary: {
      rainy: IconWeatherRain,
      sunny: IconWeatherSunny,
    },
    latlon: item.dbData.f_latlng.split(","),
    weather: "01d",
    temperature: "",
    city: "",
    country: "",
  });

  /**
   * ⚡프로젝트 수정
   */
  const handleEdit = () => {
    props.history.push(`/projects/edit/${itemKey}`);
  };

  /**
   * ⚡프로젝트 삭제
   */
  const handleDelete = () => {
    const handleOkAsync = async () => {
      if (!itemKey) return new Error("no project id");

      try {
        await Axios.post(`/project/${itemKey}/remove`);
        await getProjectListAll();

        message.success(t("message$Successful_delete"), 3);
      } catch (err) {
        consoleError(err);
      }
    };

    Modal.confirm({
      content: t("message$Do_you_want_to_delete?"),
      onOk: handleOkAsync,
    });
  };

  const handleTitleClick = (evt) => {
    evt.preventDefault();
    props.history.push(`/projects/show/${item.id}`);
  };

  useEffect(() => {
    try {
      (async () => {
        const { data } = await Axios.get("/getWeatherLocation", {
          params: {
            lat: state.latlon[0],
            lon: state.latlon[1],
          },
        });
        if (data === "error") return;
        setstate({
          ...state,
          weather: data.weather[0].icon,
          temperature: data.main.temp,
          city: data.name,
          country: data.sys.country,
        });
      })();
    } catch (err) {
      return consoleError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.dbData.f_latlng]);

  const weatherIconSrc = `https://openweathermap.org/img/w/${state.weather}.png`;
  const temperature = Number(state.temperature - 273.15).toFixed(1) + "℃";
  return (
    <div className="project-card" style={props.style}>
      <Tooltip placement="topLeft" title={item.title}>
        <h4
          className="project-card__title no-drag"
          onClick={handleTitleClick}
          onTouch={handleTitleClick}
        >
          <img className="icon i-location" src={IconLocation} alt="location icon" />
          {item.title}
        </h4>
      </Tooltip>
      <p className="project-card__caption caption">{item.caption}</p>
      <p className="project-card__description acumin">
        {props.weather}
        <img className="icon i-weather-rain" src={weatherIconSrc} alt="weather rain icon" />
        {temperature} | {Moment(item.date).format("YYYY-MM-DD")} | {state.city}, {state.country}
      </p>
      <DropDown
        items={[
          {
            name: capitalizeFirstLetter(t("button$Edit")),
            className: "i-edit",
            icon: <IconEdit width={10} height={10} className="i-edit" />,
            onClick: handleEdit,
          },
          {
            name: capitalizeFirstLetter(t("button$Delete")),
            className: "i-delete",
            icon: <IconDelete width={10} height={10} className="i-delete" />,
            onClick: handleDelete,
          },
        ]}
      />
    </div>
  );
}

export default React.memo(ProjectCard);
