//types
export const HANDLE_ASSET = "FLEET/HADLE/ASSET";
export const ORIGINAL_ASSET = "FLEET/ORIGINAL/ASSET";
export const DETAIL_ASSET = "FLEET/DETAIL/ASSET";
export const UPDATE_DETAIL_ASSET = "FLEET/UPDATE/DETAIL/ASSET";

export const CREATE_GEO_FENCE = "FLEET/CREATE/GEO_FENCE";
export const HANDLE_GEO_FENCE = "FLEET/HADLE/GEO_FENCE";

export const CESIUM_ASSET_ID = "CESIUM_ASSET_ID"; // 엔티티 클릭시 장비id 가져오는 용도
export const CHANGE_GEO_FENCE = "CHANGE/GEO/FENCE";
export const VIEW_FLEET_TASK = "FLEET/VIEW/TASK"; //
export const MEASUREDATA = "TEMP/DATA";
export const FLEET_MODAL_RENDER = "";
export const COUNT_METHOD = "COUNT_METHOD";
export const MARK_PRESET = "MARK_PRESET";
export const SHOW_ASSET = "SHOW_ASSET";
export const UPDATE_TIME = "UPDATE_TIME";
export const ASSET_SPEC = "FLEET/ASSET/SPEC";

export const setGeofenceItems = (geofences) => ({ type: HANDLE_GEO_FENCE, geofences });
export const setCreateGeoVisible = (bool, geoId) => ({
  type: CREATE_GEO_FENCE,
  bool,
  geoId: geoId || "",
});
export const setDetailAssets = (assetId) => ({ type: DETAIL_ASSET, assetId });
export const setUpdateDetailAssets = (detailItem) => ({ type: UPDATE_DETAIL_ASSET, detailItem });
export const setFleetAssets = (assets) => ({ type: HANDLE_ASSET, assets });
export const setOriginalFleetAssets = (assets) => ({ type: ORIGINAL_ASSET, assets });
export const setCesiumId = (assets) => ({ type: CESIUM_ASSET_ID, assets });
export const setChangeGeofence = (assets) => ({ type: CHANGE_GEO_FENCE, assets });
export const setTaskVisible = (bool) => ({ type: VIEW_FLEET_TASK, bool });
export const setTempdata = (measuredata) => ({ type: MEASUREDATA, measuredata: measuredata || "" });
export const setFleetModalRender = (assets) => ({ type: FLEET_MODAL_RENDER, assets });
export const setCountMethod = (countMethod) => ({ type: COUNT_METHOD, countMethod });
export const setMarkPreset = (markPreset) => ({ type: MARK_PRESET, markPreset });
export const setShowAsset = (showAsset) => ({ type: SHOW_ASSET, showAsset });
export const setUpdateTime = (updateTime) => ({
  type: UPDATE_TIME,
  updateTime,
});
export const setAssetSpecifications = (assetSpecs) => ({ type: ASSET_SPEC, assetSpecs });
