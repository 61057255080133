import React from 'react';

import ProjectRoutes from './route-views/projects-route';

function RouteModules (props) {
    return (
        <div className="route-wrap">
            <ProjectRoutes />
        </div>
    );
}

export default RouteModules;