//actions
import {
  // CROSS_SECTION_LIST,
  CROSS_SECTION_SPACING,
  CROSS_SECTION_TRANSVERSE_WIDTH,
  CROSS_SECTION_INIT,
  CROSS_SECTION_ADD_POINT,
  CROSS_SECTION_ATTR_LIST,
  CROSS_SECTION_LONGITUDITUDINAL_WIDTH,
  CROSS_SECTION_LIST_V2,
  SET_LONG_CHART,
} from "../actions/crossSection";

const initializeCrossState = {
  width: 40.0,
  spacing: 20,
  LongitudinalChartWidth: 1290,
  addPoints: [],
  chartjsObject: {
    width: 0,
    ticks: [],
    gridItem: [],
  },
  //new version
  crossSectionDatasetArr: {},
  lineAttribute: [
    {
      title: "Horizontal Length",
      value: "000",
      key: 0,
    },
    {
      title: "Surface Length",
      value: "000",
      key: 1,
    },
    {
      title: "Elevation",
      value: ["Max", "Min"],
      key: 2,
    },
    {
      title: `Slope (%)`,
      value: ["Max", "Min"],
      key: 3,
    },
  ],
};

export default function CrossSectionReducer(state = initializeCrossState, action) {
  switch (action.type) {
    case CROSS_SECTION_INIT:
      return initializeCrossState;
    case CROSS_SECTION_LONGITUDITUDINAL_WIDTH:
      return { ...state, LongitudinalChartWidth: action.width };
    case CROSS_SECTION_TRANSVERSE_WIDTH:
      return { ...state, width: action.width };
    case CROSS_SECTION_SPACING:
      return { ...state, spacing: action.spacing };
    case CROSS_SECTION_ADD_POINT:
      return { ...state, addPoints: action.addPoint };
    case CROSS_SECTION_LIST_V2:
      return { ...state, crossSectionDatasetArr: action.list };
    case CROSS_SECTION_ATTR_LIST:
      return { ...state, lineAttribute: action.attr };

    case SET_LONG_CHART:
      return { ...state, chartjsObject: action.chartjsObject };
    default:
      return state;
  }
}
