import { message } from "antd";
import Axios from "axios";

/**
 * make requestId
 */
export function* requestIdMaker() {
    let i = 0;
    while(true) yield i++;
}

/**
 * @param   
 *  `data:image/jpg;base64, ${Buffer.from(response.data, 'binary').toString('base64')}`, filename
 * @description
 *  - "base64" convet to "File API"
 */
export function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}


// const { data } = await axios.get('/uploads/test/Login_Artwork.jpg', {
//     resArrayBufferInterface
// });
export const resArrayBufferInterface = {
    responseType: 'arraybuffer'
}


export function sliceSector(sliceTarget) {
    const temp = []

    const y = 10;
    for (let i = 0; i < Math.ceil(sliceTarget.length / y); i++) {
        temp.push(sliceTarget.slice(y * i, (i + 1) * y));
    }

    return temp;
}

export const nowProcessing = () => {
    if (window.xitecloud.isProcessing) {
        message.warning('Sorry now Processing...', 3)
        return true;
    };
    return false;
}

/**
 * 대용량 업로드로 전환 할 시 업로드 사용 
 *      기존 폼 방식에서 스트림 방식으로 전환하여 업로드.
 *      올리는 방식에 따른 데이터 사용.
 * @return base64 result
 */
export const readFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = (e) => resolve({
            result: reader.result,
            name: reader.name
        });

        reader.onerror = (e) => reject(e)
    })
}

export async function sliceFileUpload (file, uploadPath) {
    const { reuslt } = file;

    const data = reuslt.split(',')[1];

    const FILE_NAME = file.name;
    const CHUNK_SIZE = 1024;
    const FILE_SIZE = data.length;

    let fileSlicePosition = 0;

    const uploadAjax = async () => {
        await Axios.post(uploadPath, {
            data: {
                filename: FILE_NAME,
                filelength: FILE_SIZE,
                filepos: fileSlicePosition,
                data: data.substring(fileSlicePosition, fileSlicePosition + CHUNK_SIZE)
            }
        })

        fileSlicePosition = fileSlicePosition + CHUNK_SIZE;

        //is finished
        if (fileSlicePosition > FILE_SIZE) {
            return fileSlicePosition = FILE_SIZE;
        }

        return uploadAjax();
    }

    //first call
    uploadAjax();
}

export async function useExample (file) {
    const { result } = await readFile(file)
    const data = result.split(',')[1];
    
    const FILE_NAME = file.name;
    const CHUNK_SIZE = 1024;
    const FILE_SIZE = data.length;

    let fileSlicePosition = 0;

    /**
     * upload 형태가 axios 일 경우,
     * 
     * socket 형식일 경우는 추가해야함
     */
    const uploadAjax = async () => {
        await Axios.post('/XITECLOUD_UPLOAD_PATH', {
            data: {
                filename: FILE_NAME,
                filelength: FILE_SIZE,
                filepos: fileSlicePosition,
                data: data.substring(fileSlicePosition, fileSlicePosition + CHUNK_SIZE)
            }
        })

        fileSlicePosition = fileSlicePosition + CHUNK_SIZE;

        //is finished
        if (fileSlicePosition > FILE_SIZE) {
            return fileSlicePosition = FILE_SIZE;
        }

        return uploadAjax();
    }

    //first call
    uploadAjax();
}
