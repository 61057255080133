/**
 *  initialize
 */
export const INIT_SET_PROJECT = 'PROJECT/INIT/TARGET_PROJECT';
export const setInitProjectTarget = () => ({ type: INIT_SET_PROJECT});

/**
 *  set target project
 */
export const EDIT_PROJECT = 'PROJECT/EDIT/TARGET_PROJECT';
export const setEditProjectTarget = (editProject) => ({ type: EDIT_PROJECT, editProject });

/**
 * list items
 *  [ countries, systemUnits, constructionType, timezones]
 */
export const GET_LIST_APIS = 'PROJECT/GET/ITEMS';
export const setListItems = (apis) => ({ type: GET_LIST_APIS, apis });

/**
 * handler for text input
 */
export const SET_PROPERTY_HANDLER = 'PROJECT/SET/ITEMS/TEXT';
export const setListItemsText = (text, key) => ({ type: SET_PROPERTY_HANDLER, text, key });

/**
 * handler for select
 */
export const SET_PROPERTY_HANDLER_SELECT = 'PROJECT/SET/ITEMS/SELECT';
export const setListItemsSelect = (sqKey, key) => ({ type: SET_PROPERTY_HANDLER_SELECT, sqKey, key });

/**
 * handler for epsg loader
 */
export const SET_PROPERTY_EPSG = 'PROJECT/SET/EPSG';
export const setEPSG = (selectedEpsg) => ({ type: SET_PROPERTY_EPSG, selectedEpsg });

/**
 * handler for address 
 */
export const SET_PROPERTY_ADDRESS = 'PROJECT/SET/ADDRESS';
export const setADDRESS = (addressObj) => ({ type: SET_PROPERTY_ADDRESS, addressObj });