import React from "react";
import { withTranslation } from "react-i18next";
import { Input } from "antd";
import "./text.less";
import { capitalizeFirstLetter } from "utils/StringHelper";

class TextInput extends React.Component {
  state = {
    inputStyle: {
      width: this.props.width || "100%",
    },
  };
  render() {
    const { t } = this.props;
    return (
      <div className="text-input group">
        {this.props.label ? <label>{this.props.label}</label> : null}
        <Input
          className={`${this.props.className} acumin`}
          ref={this.props.ref}
          size={this.props.size}
          onClick={this.props.onClick}
          width={this.props.width}
          prefix={this.props.prefix}
          placeholder={this.props.placeholder || capitalizeFirstLetter(t("placeholder$Text"))}
          suffix={this.props.suffix}
          style={this.state.inputStyle}
          value={this.props.textContent ? this.props.textContent : null}
          onChange={this.props.changeValue ? this.props.changeValue : null}
        />
      </div>
    );
  }
}

export default withTranslation()(TextInput);
