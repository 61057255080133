import React from "react";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { setAddRequestList, setRemoveRequestList } from "store/actions/app";
import { store } from "store/store";

const antIcon = <LoadingOutlined size="large" style={{ fontSize: 36 }} spin />;

const LoadingBar = (props) => {
  const httpRequestList = useSelector((state) => state.appState.httpReqeustList);
  const isShow = useSelector((state) => state.appState.isShow);

  return (
    <div
      className={`model-loading-bar ${(process.env.NODE_ENV === "development" ||
        (httpRequestList.length === 0 && isShow === false)) &&
        "display-none"}`}
    >
      <Spin
        indicator={antIcon}
        size="large"
        tip={`Waiting for ${httpRequestList.length} response`}
      />
    </div>
  );
};

export default React.memo(LoadingBar);

export const setGlobalLoading = (
  loading = true,
  requestConfig = { requestId: "global-loading" }
) => {
  const setRequest = loading ? setAddRequestList : setRemoveRequestList;
  store.dispatch(setRequest(requestConfig));
};
