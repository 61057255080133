import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import TextInput from "../../../elements/input/text";
import "./createFolder.less";
import IconAddFile from "../../../../assets/icons/add-file.svg";
import DefaultButton from "../../../elements/button/DefaultButton";
import { capitalizeFirstLetter } from "utils/StringHelper";

function ConfirmModal(props) {
  const { t } = useTranslation();

  const Footer = () => (
    <DefaultButton
      className="create-folder__modal__confirm acumin"
      nodeKey="submit"
      onClick={props.handleOk}
    >
      {capitalizeFirstLetter(t("button$Confirm"))}
    </DefaultButton>
  );

  return (
    <div className="create-folder">
      <img
        className="icon i-add-file"
        src={IconAddFile}
        onClick={props.showModal}
        alt="add file icon"
      />
      <Modal
        title={t("title$Create_Folder")}
        visible={props.visible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        className="create-folder__modal"
        width={350}
        centered
        footer={<Footer />}
      >
        <p className="create-folder__modal__title caption">{t("title$Folder_Name")}</p>
        <TextInput
          className="create-folder__modal__text"
          placeholder={t("placeholder$Folder_Name")}
          {...props}
        />
      </Modal>
    </div>
  );
}

export default ConfirmModal;
