import React, { useEffect, useRef, useState } from "react";
import { Color, Ion } from "cesium";
import { Viewer } from "cesium-react";
import {
  loadOpenStreetMap,
  removeAllEntities,
  convertPolygonToArray,
  getPolygonOptions,
  fromDegreesArray,
  addEntity,
  getRectangleFromDegrees,
  getRectangleCoordinates,
  setView,
} from "utils/CesiumUtils";
import "./overlay.less";

/**
 * 오버레이 범위 표시
 * @param {*} label       Entity Label
 * @param {*} coordinates 업로드 DXF 파일의 영역 좌표 (WGS84/4326)
 *
 * @return 지도에 표시할 다각형(Polygon) Entity
 */
const renderUploadedOverlayRange = (viewer, label, coordinateArray) => {
  const polygonLabel = `${label}_polygon`;

  const polygonObj = getPolygonOptions(
    `id_${polygonLabel}`,
    `name_${polygonLabel}`,
    fromDegreesArray(coordinateArray),
    Color.RED.withAlpha(0.5)
  );

  return addEntity(viewer.entities, polygonObj);
};

export const OverlayRangeViewer = (props) => {
  Ion.defaultAccessToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1OWFiMTkzMC05NGIxLTQxZDctODM5Zi1iYzAzMDBhY2YxNWIiLCJpZCI6Mjk3NjUsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1OTI1NzYwMTB9.fXEcnp1-eSNgueZF0zKAw68Gdcom9z_MrqKJBVCWY3M";

  const cesiumViewer = useRef();

  /** Cesium Viewer */
  const [viewer, setViewer] = useState();

  /** 초기화 */
  const handleInitMap = () => {
    if (viewer.imageryLayers && viewer.imageryLayers.length > 0) {
      viewer.imageryLayers.removeAll();
    }

    if (viewer.entities.values && viewer.entities.values.length > 0) {
      removeAllEntities(viewer.entities);
    }

    if (viewer.dataSource && viewer.dataSource.length > 0) {
      viewer.dataSource.removeAll();
    }

    // 최초 지도 초기화 (Open Street Map)
    const loadMap = loadOpenStreetMap(viewer, `//a.tile.openstreetmap.org/`);

    // Polygon 문자열의 배열 변환
    const coordArr = convertPolygonToArray(props.overlayData.coordinates);

    // 업로드 오버레이 표시 영역의 Entity 추가
    const entity = renderUploadedOverlayRange(viewer, props.overlayData.renderLabel, coordArr);

    if (loadMap && entity) {
      const recCoord = getRectangleCoordinates(coordArr);
      setView(
        viewer,
        getRectangleFromDegrees(recCoord.minX, recCoord.maxX, recCoord.minY, recCoord.maxY)
      );
    }
  };

  useEffect(() => {
    if (!viewer) {
      if (cesiumViewer.current.cesiumElement) {
        setViewer(cesiumViewer.current.cesiumElement);
      }
      return;
    }

    handleInitMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, props.overlayData]);

  return (
    <div className="view-area">
      <div className="view-inner">
        <Viewer
          ref={cesiumViewer}
          full
          animation={false}
          baseLayerPicker={false}
          fullscreenButton={false}
          vrButton={false}
          geocoder={false}
          homeButton={false}
          infoBox={false}
          sceneModePicker={false}
          selectionIndicator={false}
          timeline={false}
          navigationHelpButton={false}
        />
      </div>
    </div>
  );
};
