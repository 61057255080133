import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";
import { Table } from "antd";
import LongitudinalChart from "../LongitudinalChart/LongitudinalChart";
import { connect } from "react-redux";
import { rockName, rockColors } from "../../../../views/Measurement/CroSSection/utils/setChartData";
import {
  flatten,
  // uniq
} from "lodash";
import {
  configTargetChartData,
  configTargetChartData2,
} from "../../../../views/Measurement/CroSSection/utils/setChartData";
import { capitalizeFirstLetter } from "utils/StringHelper";

const renderContent = (value, row, index) => {
  return {
    children: value,
    props: {},
  };
};

// dummy data rows
// const data = [
//   {
//     key: "1",
//     fillName: "1.Rock",
//     fillValue: 10,
//     cutName: "노상",
//     cutValue: 10,
//   },
//   {
//     key: "2",
//     fillName: "2.Rock",
//     fillValue: 20,
//     cutName: "노체",
//     cutValue: 20,
//   },
//   {
//     key: "3",
//     fillName: "3.Rock",
//     fillValue: 30,
//     cutName: "비다짐",
//     cutValue: 30,
//   },
//   {
//     key: "4",
//     fillName: "4.Rock",
//     fillValue: 40,
//     cutName: "",
//     cutValue: "",
//   },
// ];

const CrossSectionDashBoard = (props) => {
  const { t } = useTranslation();
  const [dataSet, setDataSet] = useState(props.CrossSectionReducer.crossSectionDatasetArr);
  const [resultRows, setResultRows] = useState([]);
  const crossRef = useRef(null);

  const columns = [
    {
      title: capitalizeFirstLetter(t("column$절토")),
      colSpan: 2,
      dataIndex: "fillName",
    },
    {
      // title: "FILL",
      colSpan: 0,
      dataIndex: "fillValue",
      render: renderContent,
    },
    {
      title: capitalizeFirstLetter(t("column$성토")),
      colSpan: 2,
      dataIndex: "cutName",
    },
    {
      // title: "Phone",
      colSpan: 0,
      dataIndex: "cutValue",
      render: renderContent,
    },
  ];

  const configLogic = (dataSet) => {
    const {
      insec_rock_line,
      rock_info,
      lon_insec_pt,
      t_base,
      c_info,
      f_info,
      t_summary,
      target_line,
      target_line_arr,
      org_topo,
    } = dataSet;

    const sections = insec_rock_line.filter((x) => x.st_no !== -1);

    /**
     * Xaxis label [ (width / 2) * -1 ]
     * width - transverse
     */
    const xaxis = t_summary.p_baselength / 2;
    const labelArr = [];
    for (let i = -1 * xaxis; i <= xaxis; i++) {
      labelArr.push(i);
    }

    /**
     * only sections
     */
    const dataSets = sections
      .map((section) => {
        let originalTopoArr = [];
        const sectionDataSet = section.line_pt_arr.map((x) => {
          const data = [];

          if (rockName(rock_info, x) === "target" && target_line) {
            let targets = target_line.filter((x) => x.st_no !== -1);
            let targetLine = targets.find((x) => x.st_no === section.st_no);

            let rtn = configTargetChartData(targetLine, xaxis).map((target, index) => ({
              data: target,
              rockInfo: rockName(rock_info, x),
              colors: rockColors(rock_info, x),
            }));

            //CAD 도면 target line
            if (target_line_arr && target_line_arr.length > 0) {
              targets = target_line_arr.filter((x) => x.st_no !== -1);
              targetLine = target_line_arr.find((x) => x.st_no === section.st_no);

              if (targetLine !== undefined) {
                rtn = configTargetChartData2(targetLine, xaxis).map((target, index) => ({
                  data: target,
                  rockInfo: rockName(rock_info, x),
                  colors: rockColors(rock_info, x),
                }));
              }
            }
            return rtn;
          } else {
            x.line_pt_arr.map((y) =>
              data.push({
                x: y.ex_dist - xaxis,
                y: y.z,
              })
            );
          }

          return {
            data,
            rockInfo: rockName(rock_info, x),
            colors: rockColors(rock_info, x),
          };
        });

        if (org_topo) {
          if (org_topo.find((x) => x.st_no === section.st_no)) {
            originalTopoArr = org_topo
              .find((x) => x.st_no === section.st_no)
              .line_pt_arr.map((x) => {
                const data = [];

                x.line_pt_arr.map((y) =>
                  data.push({
                    x: y.ex_dist,
                    y: y.z,
                  })
                );

                return {
                  data,
                  rockInfo: "원지반",
                  colors: "#00ff00",
                };
              });
          }
        }

        if (originalTopoArr.length > 0) sectionDataSet.push(originalTopoArr);
        return flatten(sectionDataSet).map((x) => ({
          label: x.rockInfo,
          data: x.data,
          // backgroundColor: defaultColorArr[i],
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderColor: x.colors,
          showLine: true,
          borderWidth: 1,
          fill: true,
          idx: section.st_no,
        }));
      })
      .map((x) => {
        /**
         * infomation objects
         *  1.rock 2.base
         */
        const infoRockObj = lon_insec_pt.find((y) => y.st_no === x[0].idx);
        const infoBaseObj = t_base.find((y) => y.st_no === x[0].idx);

        /**
         * config data table options
         * c_info : 절토
         * f_info : 성토
         *
         * @param (c_info [...f_info])
         */
        const tblData = [];
        if (c_info && c_info.find((y) => y.st_no === x[0].idx)) {
          /**
           * c_info -> c_tin_geom
           */
          c_info
            .find((y) => y.st_no === x[0].idx)
            .c_tin_geom.map((y, i) => {
              return tblData.push({
                key: `cut_${i}`,
                fillName: rockName(rock_info, y, true),
                fillValue: y.area.toFixed(3),
                cutName: ` - `, //수정필요
                cutValue: ` - `, //수정필요(암층)
              });
            });
        }

        /**
         * rock layer가 있을경우에만 실행
         */
        // 성토가 없더라도 절토 데이터가 있을 때 보여주는 로직
        if (tblData.length || (f_info && f_info.find((y) => y.st_no === x[0].idx))) {
          if (tblData.length === 1) {
            tblData[0].fillName = "Topo(No Rock Layer)";
          }

          /**
           * 3 -> 비다짐 로우까지
           * tblData의 데이터 길이가 3보다 작을 경우 임시 데이터 로우 추가로 푸쉬
           */
          if (tblData.length < 3) {
            for (let y = tblData.length; y < 3; ++y) {
              tblData.push({
                key: `temp_${y}`,
                fillName: "-",
                fillValue: "-",
                cutName: "-",
                cutValue: "-",
              });
            }
          }

          tblData[0].cutName = "노상";
          tblData[1].cutName = "노체";
          tblData[2].cutName = "비다짐";
          tblData[2].cutValue = "-";

          if (f_info && f_info.find((y) => y.st_no === x[0].idx)) {
            /**
             * f_info -> f_tin_geom
             */
            f_info
              .find((y) => y.st_no === x[0].idx)
              .f_tin_geom.map((y, i) => (tblData[2].cutValue = y.area.toFixed(3)));
          }
        } else {
          tblData.push({
            key: "no_data",
            fillName: "-",
            fillValue: "-",
            cutName: "-",
            cutValue: "-",
          });
        }

        console.log("infoRockObj", infoRockObj);

        let bottom_z = "";
        let top_z = "";
        if (infoRockObj) {
          bottom_z = infoRockObj.bottom_z === null ? "" : Number(infoRockObj.bottom_z).toFixed(2);
          top_z = infoRockObj.top_z === null ? "" : Number(infoRockObj.top_z).toFixed(2);
        }

        return {
          chartTitle: infoBaseObj.c_station_name
            ? infoBaseObj.c_station_name
            : `ST.0+${Number(infoBaseObj.ex_dist).toFixed(0)}.00`,
          data: x,
          // setChart: props.setChart,
          label: labelArr,
          // color: props.RockDataSetReducer.colors,
          tableTitle: `F.H: ${bottom_z} / G.H : ${top_z}`,
          tableData: tblData,
        };
      });

    setResultRows(dataSets);
  };

  const crossSectionDataConfig = () => {
    if (props.data) {
      setDataSet(props.data);
      console.log(props.data);
      return configLogic(props.data);
    }
  };

  useEffect(() => {
    crossSectionDataConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.RockDataSetReducer]);

  useEffect(() => {
    Array.from(crossRef.current.children).map((x, index) => {
      if (index % 2 === 0) {
        const created = document.createElement("div");
        created.className = "cross-section-print-wrap";
        created.appendChild(x);
        return crossRef.current.appendChild(created);
      } else {
        return document.querySelector(".cross-section-dashboard > div:last-child").appendChild(x);
      }
    });
  }, [resultRows]);

  // setResultRows(resultTableRows);

  //rock2부터 rock ...type
  // line의 line2부터 종단 횡단 데이터
  return (
    <article className="cross-section-dashboard" ref={crossRef}>
      {resultRows.map((row, index) => (
        <ResultSection key={index} dataSet={dataSet} columns={columns} {...row} />
      ))}
    </article>
  );
};

function ResultSection(props) {
  return (
    <section>
      <span className="title-gray-block">{props.chartTitle}</span>
      <div className="chart-wrap">
        {props.chartComponent ? (
          <props.chartComponent />
        ) : (
          // <></>
          <LongitudinalChart
            label={props.label}
            data={props.data}
            dataSet={props.dataSet}
            gradient={false}
            // color={props.color}
          />
        )}
      </div>
      <span className="title-gray-block">{props.tableTitle}</span>
      <Table columns={props.columns} dataSource={props.tableData} pagination={false} bordered />
    </section>
  );
}

// export default CrossSectionDashBoard;
export default connect((state) => state, {})(CrossSectionDashBoard);
/**
 * 횡단 구 버전 데이터 셋
 */
// const uiUpdate = () => {
//   let labelArr = [];
//   if (Object.keys(props.RockDataSetReducer.rockDataSet).length) {
//     let baseLengthHalf =
//       props.RockDataSetReducer.rockDataSet["rock_0"].transverse / 2;

//     //for (let i = 0; i < props.RockDataSetReducer.rockDataSet['rock_0'].line.line_id_0.length - 1; i++) {
//     for (let i = -1 * baseLengthHalf; i <= baseLengthHalf; i++) {
//       labelArr.push(i);
//     }

//     //스테이션 LOOP
//     const resultTableRows = [];
//     for (
//       let i_st = 0;
//       i_st < props.RockDataSetReducer.rockDataSet["rock_0"].analysis.length;
//       i_st++
//     ) {
//       let _stChartData = [];
//       const tblData = [];

//       //rock layer
//       for (
//         let i_rock = 0;
//         i_rock < props.RockDataSetReducer.rockChartDataSet.length;
//         i_rock++
//       ) {
//         //스테이션 암층 그래프
//         _stChartData.push({
//           backgroundColor:
//             props.RockDataSetReducer.rockChartDataSet[i_rock].backgroundColor,
//           borderWidth:
//             props.RockDataSetReducer.rockChartDataSet[i_rock].borderWidth,
//           //,data: props.RockDataSetReducer.rockDataSet['rock_'+i_rock].line['line_id_'+(i_st+1)]
//           data: props.RockDataSetReducer.rockDataSet["rock_" + i_rock].line[
//             "line_id_" + (i_st + 1)
//           ].map((obj) => {
//             return obj.top_z;
//           }),
//           fill: true,
//           label: props.RockDataSetReducer.rockChartDataSet[i_rock].label,
//         });
//       }

//       //비다짐
//       let sum = 0;
//       for (
//         let i_rock = 1;
//         i_rock < props.RockDataSetReducer.rockChartDataSet.length;
//         i_rock++
//       ) {
//         let _val = 0;
//         let _rock_name = "No Name";
//         try {
//           _val =
//             props.RockDataSetReducer.rockDataSet["rock_" + i_rock]._3d.geom[
//               i_st + 1
//             ].layer_area;

//           _rock_name =
//             props.RockDataSetReducer.rockDataSet["rock_" + i_rock]._3d.geom[
//               i_st + 1
//             ].f_rock_type_nm;
//         } catch (e) {}

//         tblData.push({
//           key: i_rock,
//           fillName: _rock_name,
//           fillValue: _val.toFixed(3),
//           cutName: ` - `, //수정필요
//           cutValue: ` - `, //수정필요(암층)
//         });

//         sum += _val;
//       }

//       /**
//        * rock layer가 있을경우에만 실행
//        */
//       if (tblData.length) {
//         tblData[0].cutName = "노상";
//         tblData[1].cutName = "노체";
//         tblData[2].cutName = "비다짐";
//         tblData[2].cutValue = sum.toFixed(3);
//       }

//       // 스테이션 데이터 조립
//       resultTableRows.push({
//         chartTitle: `ST.0+${Number(
//           props.RockDataSetReducer.rockDataSet["rock_0"].analysis[i_st]
//             .ex_dist
//         ).toFixed(0)}.00`,
//         data: _stChartData,
//         // setChart: props.setChart,
//         label: labelArr,
//         color: props.RockDataSetReducer.colors,
//         tableTitle:
//           "F.H: " +
//           Number(
//             props.RockDataSetReducer.rockDataSet["rock_0"].analysis[i_st]
//               .bottom_z
//           ).toFixed(2) +
//           " / G.H :" +
//           Number(
//             props.RockDataSetReducer.rockDataSet["rock_0"].analysis[i_st]
//               .top_z
//           ).toFixed(2),
//         tableData: {
//           columns,
//           data: tblData,
//         },
//         //아이프레임 안쓸경우 제거
//         // ,chartComponent: () => (
//         //   <iframe
//         //     src="http://localhost:3000/"
//         //     frameBorder="0"
//         //     width="100%"
//         //     height="100%"
//         //   />
//         //  )
//       });
//     }
//     console.log(resultTableRows);
//     setResultRows(resultTableRows);
//   }
// };
