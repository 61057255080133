import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import HideDashboard from "../../../components/blocks/hideDashboard";
import BackButton from "../../../components/elements/button/BackButton";
import TextInput from "../../../components/elements/input/text";
import { setReportKeyword } from "../../../store/actions/report";

const cssStyle = {
  BackButton: {
    background: "unset",
  },
  h4: {
    overflowWrap: "break-word",
  },
};

const DocHeader = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectTitle, projectDescription, keyword } = useSelector(
    (state) => ({
      projectTitle: state.currentProjectReducer.currentProject.f_proj_nm,
      projectDescription: state.currentProjectReducer.currentProject.f_description,
      keyword: state.ReportReducer.keyword,
    }),
    shallowEqual
  );

  const handleKeywordChange = (evt) => dispatch(setReportKeyword(evt.target.value));

  return (
    <article className="document_top">
      <BackButton
        className="document__back"
        style={cssStyle.BackButton}
        onClick={() => props.history.goBack()}
      />
      <h4 style={cssStyle.h4}>{projectTitle}</h4>
      <HideDashboard />
      <h5>{projectDescription}</h5>
      <div className="document__description">
        <TextInput
          textContent={keyword}
          placeholder={t("placeholder$Report_Name")}
          changeValue={handleKeywordChange}
        />
      </div>
    </article>
  );
};

export default DocHeader;
