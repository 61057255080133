import React from "react"; //, { useState, useEffect }
import { useTranslation } from "react-i18next";
import {
  //  Checkbox,
  InputNumber,
  Button,
} from "antd";
import CrossSectionCoordinate from "./CrossSectionCoordinate";
import { connect } from "react-redux";
import { startInsertion } from "../../../xitecloud3D/xiteCloud.dev";
import {
  setCrossAddPoint,
  setCrossWidth,
  setCrossSpacing,
} from "../../../store/actions/crossSection";
import ParseCoordinate from "./ParseCoordinate";

const CrossSectionProperties = (props) => {
  const { t } = useTranslation();
  // setStationSpacing
  // setTransverseWidth
  const onAddPointStartInsertion = (e) => {
    const measure = props.projectChildrenComponents.currentMeasure;
    startInsertion(
      {
        value: "p0",
        label: t("title$Measurement"),
        measureType: "crossSectionPoint",
        pickAreatarget: "model",
        pickCrosspointMeasure: measure.measure,
        annotationGroup: "onsite",
      },
      (options, measurement) => {
        props.setCrossAddPoint(props.CrossSectionReducer.addPoints.concat(measurement));

        if (!measure.crossSectionChildren) {
          measure.crossSectionChildren = [].concat(props.CrossSectionReducer.addPoints);
        }

        // console.log(props.CrossSectionReducer.addPoints)
        measure.crossSectionChildren.push(measurement);
        // setCrossSectionPoints(crossSectionPoints.concat(measurement));
        // getAttributeAPI();
      }
    );
  };

  const properties = [
    {
      title: t("title$Station_Spacing"),
      value: props.CrossSectionReducer.spacing,
      handler: (e) => props.setCrossSpacing(e),
      key: 0,
    },
    {
      title: t("title$Transverse_Width"),
      value: props.CrossSectionReducer.width,
      handler: (e) => props.setCrossWidth(e),
      key: 1,
    },
  ];

  return (
    <>
      {/* <Checkbox
        checked={crossSectionVisible}
        onChange={onVisibleCrossSection}
        className="d-inline"
      >
        Cross Section
      </Checkbox> */}
      <div className="cross-section-property-wrap">
        {properties.map((property) => (
          <div className="cross-section-property" key={property.key}>
            {property.title}
            <span>
              <InputNumber
                size="small"
                min={0}
                max={1000}
                className="properties-wrap"
                value={property.value}
                onChange={property.handler}
              />
              {/* <span className="properties-wrap">{property.value}</span> */}
            </span>
          </div>
        ))}
        <div>
          <div className="cross-section-property">
            {t("title$Add_Station")}
            <ParseCoordinate isAddPoints />
            <span>
              <Button
                // disabled={!crossSectionVisible}
                className="cross-section-add-point"
                size="small"
                onClick={onAddPointStartInsertion}
              >
                +
              </Button>
            </span>
          </div>
          <div className="scroll-wrap">
            {props.CrossSectionReducer.addPoints.map((crossSectionPoint, index) => (
              <CrossSectionCoordinate key={index} measure={crossSectionPoint} />
            ))}
          </div>
        </div>
        {/* <div
          className="disabled-layer"
          style={{
            display: crossSectionVisible ? "none" : "inline-block",
          }}
        /> */}
      </div>
    </>
  );
};
export default connect((state) => state, {
  setCrossAddPoint,
  setCrossSpacing,
  setCrossWidth,
})(CrossSectionProperties);
