import React from "react";
import IconTopography from "../../../../assets/icons-2/Icon_Topography.svg";
import IconSubsurface from "../../../../assets/icons-2/Icon_Subsurface.svg";
import IconTarget from "../../../../assets/icons-2/Icon_Target_Model.svg";
import IconOverlay from "../../../../assets/icons-2/Icon_Overlay.svg";
import IconGeoFences from "../../../../assets/icons-2/Icon_Geo-fences.svg";
import IconAnnotation from "../../../../assets/icons-2/Icon_Annotation.svg";
import IconAnalytics from "../../../../assets/icons-2/Icon_Analytics.svg";

const ICON_SCHEMA = {
  topo: {
    src: IconTopography,
    alt: "icon topography",
  },
  surface: {
    src: IconSubsurface,
    alt: "icon subsurface",
  },
  target: {
    src: IconTarget,
    alt: "icon target",
  },
  overlay: {
    src: IconOverlay,
    alt: "icon overlay",
  },
  geo: {
    src: IconGeoFences,
    alt: "icon GeoFences",
    style: { width: "17px" },
  },
  anlytics: {
    src: IconAnalytics,
    alt: "icon Analytics",
  },
  annotationMark: {
    src: IconAnnotation,
    alt: "icon Annotation",
  },
};

/**
 * @parent Model
 * @component ModelFilterIcon
 * @param {:str} modelKey
 * @description
 *      Model Filter Icons
 */
const ModelFilterIcon = (props) => {
  const item = ICON_SCHEMA[props.modelKey];

  return <img className="model__header-icon" style={item.style} src={item.src} alt={item.alt} />;
};

export default ModelFilterIcon;
