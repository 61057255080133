import React from "react";
import { Modal, Button, DatePicker } from "antd";
import {
  setModelLayerDate,
  setAPITopoDate,
  setAPITargetDate,
  setSelectTopoDate,
  setSelectTargetDate,
  setAPISurfaceDate,
  setSelectSurfaceDate,
  setLayerTitle,
} from "../../../store/actions/modelLayer";
import "./uploadModal.less";
import ModelUpload from "../../blocks/modelUpload";
import moment from "moment";
import { connect } from "react-redux";
import Axios from "axios";
import IconUpload from "../../../assets/icons/upload.svg";

import { consoleError } from "../../../middleware";
import DefaultInput from "../../elements/input/DefaultInput";
import { setModalSuccess, setModalWarning } from "../../../store/actions/Modal";
import {
  setProjectDateSet,
  setProjectSelectedDateSet,
} from "../../../store/actions/currentProject";
import {
  getProjectInformation,
  getLayerDateInfo,
  //  loadProjectInfoAll, loadAnnotationAll, layerProcess, annotationFlow
} from "../../../views/project/utils/project";
import { setDataSetInit, setLayers, setAnnotations } from "../../../store/actions/viewDataSet";
// import { visualizationOption } from '../../../views/pointCloud/util';
import { grepSelectedInfo } from "./utils/grepSelectedDate";
import { withTranslation } from "react-i18next";

class UploadModal extends React.Component {
  state = {
    visible: false,
    show: false,
    hasLayerInfomation: false,
    progressLbl: 0,
  };

  toggleCalendar = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  componentDidMount = () => { };

  modelUpload = () => {
    const { t } = this.props;
    /**
     * if using dataset from db
     */
    const { project_mng_sq } = this.props.currentProjectReducer.currentProject;
    const {
      selectedModelDate,
      selectedSurfaceDate,
      selectedTargetDate,
      selectedTopoDate,
      title,
    } = this.props.modelLayerReducer;
    const formData = new FormData();
    if (selectedSurfaceDate.length >= 1) {
      formData.append("preSurface", moment(selectedSurfaceDate).format("YYYYMMDD"));
    }
    if (selectedTargetDate.length >= 1) {
      formData.append("preTarget", moment(selectedTargetDate).format("YYYYMMDD"));
    } else {
      const { files } = this.props.modelLayerReducer;
      for (let key in files) {
        if (key === "targetDesign") {
          files[key].formData.map((file) => formData.append(key, file));
        }
      }
    }

    if (selectedTopoDate.length >= 1) {
      formData.append("preTopo", moment(selectedTopoDate).format("YYYYMMDD"));
    } else {
      const { files } = this.props.modelLayerReducer;
      for (let key in files) {
        if (key === "topographic") {
          Object.keys(files[key]).map((topokeys) =>
            files[key][topokeys].formData.map((file) => {
              formData.append(topokeys, file);
              return formData.append(
                `${key}_${topokeys}`,
                this.props.modelUploadReducer.topographicFiles[topokeys].titleName
              );
            })
          );
        } else {
          if (key !== "targetDesign") {
            files[key].formData.map((file) => formData.append(key, file));
          }
        }
      }
    }

    formData.append("targetTitle", this.props.modelUploadReducer.targetTitle);
    formData.append(
      "body",
      JSON.stringify({
        selectedModelDate: moment(selectedModelDate).format("YYYYMMDD"),
        project_mng_sq,
        title,
      })
    );

    const setPregressText = (percentCompleted) => {
      this.setState({ progressLbl: percentCompleted });
    };

    /**
    * PCD 및 TIF 확인
    * Topo 최초 업로드인 경우, 포인트클라우드 파일 + TIF 정보 존재해야 함
    * (포인트클라우드 데이터 없으면 OrthoPhoto(TIF) 단독 업로드 불가)
    */
    const topoDatesArr = this.props.modelLayerReducer.topoDates.map(i => i.replace(/\./g, ""));
    if (!selectedTopoDate) {  // 최초 업로드인 경우
      if (!topoDatesArr.includes(selectedModelDate.replace(/\./g, ""))
        && !this.props.modelUploadReducer.topographicFiles.pointCloud.fileName) {
        // 최초 업로드 이면서, 포인트클라우드 데이터(또는 파일)가 없는 경우
        this.props.setModalWarning({
          warning: true,
          title: t("title$Check Point Cloud Data"),
          message: "",
        });
        return;
      }
    }

    Axios.post("/modelLayer", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }, onUploadProgress: (progressEvent) => {
        // var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        let percentComplete = progressEvent.loaded / progressEvent.total;
        percentComplete = Math.floor(percentComplete * 100);

        setPregressText(percentComplete);
      },
    }).then((res) => {
      if (res.status && res.status === 500) {
        debugger;
      }

      if (res.status === 200) {
        setPregressText(100);
        this.setState({
          visible: false,
        }, () => {
          this.props.setModalSuccess({
            success: true,
            title: t("title$Upload Successful"),
            message: "",
          });

          getLayerDateInfo(project_mng_sq).then((projectDateList) => {
            if (projectDateList.length) {
              this.props.setProjectDateSet(projectDateList);

              getProjectInformation(
                projectDateList[0].f_input_date,
                this.props.match.params.projectId
              ).catch((error) => consoleError(error));
            }
          });
        });
      }
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  selectDate = (e) => grepSelectedInfo(e);

  render() {
    const { t } = this.props;

    const models = [
      {
        name: t("title$Topographic_Model"),
      },
      { name: t("title$Sub-surface_Model") },
      {
        name: t("title$Target_Design_Model"),
        filePath: true,
      },
      {
        name: t("title$Overlays_Information"),
        filePath: true,
      },
    ];

    return (
      <div className="upload-modal">
        <Button
          onClick={this.showModal}
          style={{
            color: this.state.visible ? "#000" : null,
          }}
          className="upload-btns acumin"
        >
          <img
            src={IconUpload}
            alt="Data Upload"
            className={this.state.visible ? "opacity-1" : ""}
            style={{
              marginRight: "11px",
              width: "11px",
              height: "18px",
              // opacity: this.state.visible ? 1 : 0.4
            }}
          />
          {t("button$Data_Setting")}
        </Button>
        <Modal
          title={t("title$Visualization Data Upload")}
          visible={this.state.visible}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className="upload-modal__modal"
          width={520}
          centered
          footer={[
            <Button
              className="upload-modal__modal__confirm"
              key="submit"
              onClick={this.modelUpload}
            >
              {/* 23.10.20 다국어 수정 */}
              {/* {t("button$Upload_and_Processing")} {this.state.progressLbl} */}
              {t("button$Upload")}
            </Button>,
          ]}
        >
          <div className="upload-modal__modal__calendar group">
            <label>
              <span className="font-sofia"> {t("title$Name")} </span>
              <DefaultInput
                value={this.props.modelLayerReducer.title}
                placeholder={t("placeholder$Enter_me")}
                onChange={(e) => this.props.setLayerTitle(e.target.value)}
              />
            </label>
            <label>
              <span className="font-sofia"> {t("title$Reference date")} </span>
              <DatePicker
                className="acumin"
                format={"YYYY.MM.DD"}
                allowClear={false}
                disabledDate={(current) => current && current > moment().endOf("day")}
                defaultValue={moment(this.props.modelLayerReducer.selectedModelDate, "YYYY.MM.DD")}
                onChange={this.selectDate}
              />
            </label>
          </div>

          <div className="background-wrapper">
            {models.map((model, i) => (
              <ModelUpload name={model.name} filePath={model.filePath} key={i} />
            ))}
          </div>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(
  connect((state) => state, {
    setModelLayerDate,
    setAPITopoDate,
    setAPITargetDate,
    setSelectTopoDate,
    setSelectTargetDate,
    setAPISurfaceDate,
    setLayerTitle,
    setSelectSurfaceDate,
    setModalSuccess,
    setModalWarning,
    setProjectDateSet,
    setDataSetInit,
    setProjectSelectedDateSet,
    setLayers,
    setAnnotations,
  })(UploadModal)
);
