import React, { useState } from "react";
import BackWorkspace from "./BackWorkspace";
import IconEdit from "../../../assets/icons/edit.svg";
import HideDashboard from "../../../components/blocks/hideDashboard";
import { currentMeasure } from "../../../store/actions/projectChildrenComponent";
import { connect } from "react-redux";
import { Input, Tooltip } from "antd";
import DeleteAnnotation from "./DeleteAnnotation";
const WorkspaceTitle = (props) => {
  const measure = props.projectChildrenComponents.currentMeasure;
  const [titleVisible, setTitleVisible] = useState(false);
  const onTitleChange = (e) => {
    measure.title = e.target.value;
    props.currentMeasure(measure);
  };

  return (
    <section className={`${props.wrapperName}__top`}>
      <BackWorkspace className={`${props.wrapperName}__top__back`} />
      {titleVisible ? (
        <Input
          onChange={onTitleChange}
          style={{
            width: 190,
            border: "unset",
            borderBottom: "2px solid #eee",
            marginLeft: "14px",
            marginTop: "-6px",
          }}
          value={measure.title}
        />
      ) : (
        <Tooltip title={measure && measure.title}>
          <h4>
            {/* Annotation For */}
            {measure && measure.title}
          </h4>
        </Tooltip>
      )}
      {/* <h4>Measurement For {measure && measure.title}</h4> */}
      <HideDashboard />
      <div className={`${props.wrapperName}__top__actions`}>
        <img
          className="i-edit"
          src={IconEdit}
          onClick={() => setTitleVisible(!titleVisible)}
          alt="edit icon"
        />
        <DeleteAnnotation measure={measure} />
      </div>
      {/* <div className="measurement__top__description">
        <CalendarSelector />
        <Button>Search Layers<Icon type="caret-down" /></Button>
      </div> */}
    </section>
  );
};

// export default WorkspaceTitle;
export default connect((state) => state, {
  currentMeasure,
})(WorkspaceTitle);
