import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentTitle from "../../../components/elements/text/contentTitle";
import Colors from "./Color";
import "./index.less";

const MeasurementVisualization = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("Line");

  const polygonOptions = [
    {
      optionName: t("title$Area"),
      optionValue: "Area",
      key: 0,
    },
    {
      optionName: t("title$Line"),
      optionValue: "Line",
      key: 1,
    },
  ];

  const handleOptionClick = (_evt, optionValue) => {
    setSelected(optionValue);
  };

  return (
    <article className="measurement__content__part">
      {!props.titleDisabled && (
        <ContentTitle title={t("title$Visualization_Option")} style={{ color: "#182e63" }} />
      )}

      {props.type === "Area" && (
        <div className="polygon__options acumin">
          <ul>
            {polygonOptions.map((option) => (
              <li
                key={option.key}
                onClick={(evt) => handleOptionClick(evt, option.optionValue)}
                className={selected === option.optionValue ? "active" : null}
              >
                {option.optionName}
              </li>
            ))}
          </ul>
        </div>
      )}

      <p style={{ paddingBottom: "15px" }}>
        {t("title$%{type} Color_&_Size", { type: props.type })}
      </p>
      <Colors type={props.type} selected={selected} />
      <br />
    </article>
  );
};

export default MeasurementVisualization;
