import React from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./index.less";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AnnotationImages extends React.Component {
  state = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    // fileList: [
    // //   {
    // //     uid: '-1',
    // //     name: 'image.png',
    // //     status: 'done',
    // //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // //   },
    // //   {
    // //     uid: '-5',
    // //     name: 'image.png',
    // //     status: 'error',
    // //   },
    // ],
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => this.props.setFileList(fileList);

  render() {
    const { fileList } = this.props;
    const props = {
      onRemove: (file) => {
        const index = this.props.fileList.indexOf(file);
        const newFileList = this.props.fileList.slice();
        newFileList.splice(index, 1);
        this.props.setFileList(newFileList);
      },
      beforeUpload: (file) => {
        console.log(fileList);
        this.props.setFileList([...fileList, file]);
        return false;
      },
      fileList,
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          {...props}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        {/* <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal> */}
      </div>
    );
  }
}

export default AnnotationImages;
