import React from "react";
import { initialize, Object3DVisibleHandle, handleAnnotationAndObject3d } from "../util/initialize";
import { connect } from "react-redux";

import BackButton from "../../../components/elements/button/BackButton";
import { nowProcessing } from "../../../utils/Hibrary";
import { softMakeMeasure } from "../CroSSection/utils/measure";

const BackWorkspace = (props) => {
  const measure = props.projectChildrenComponents.currentMeasure;
  const visibleFalse = () => {
    //if processing alert
    if (nowProcessing()) return;

    if (measure) {
      const backupMeasurePoints = props.projectChildrenComponents.backupMeasure;

      if (measure.measure) {
        const { pointColor, lineColor, faceColor, lineThickness, points } = measure.measure;
        if (points.length === 0) return initialize();

        window.viewer.scene.removeMeasurement(measure.measure);
        if (backupMeasurePoints) {
          measure.measure = softMakeMeasure(
            backupMeasurePoints.points.map((x) => x.position),
            {
              pointColor,
              lineColor,
              faceColor,
              lineThickness,
            }
          );

          measure.title = backupMeasurePoints.title;
        }

        handleAnnotationAndObject3d(measure, Object3DVisibleHandle, measure.measure.visible);

        measure.measure._cancel && measure.measure._cancel();
      }
    }

    initialize();
  };

  return (
    <BackButton
      className={props.className}
      style={{ background: "unset" }}
      onClick={visibleFalse}
    />
  );
};

// export default BackWorkspace;
export default connect((state) => state, {})(BackWorkspace);
