import React, { useState, useEffect } from "react";
import ContentTitle from "../../../components/elements/text/contentTitle";
import DefaultButton from "../../../components/elements/button/DefaultButton";
import SectionResult from "./children/SectionResult";
import WorkspaceTitle from "../children/WorkspaceTitle";
import AnnotationFooter from "../children/AnnotationFooter";
import CrossSectionProperties from "../children/CrossSectionProperties";
import MeasurementVisualization from "../children/MeasurementVisualization";
import { Checkbox } from "antd";
import { connect } from "react-redux";
import { consoleError } from "../../../middleware";
import {
  setCrossAddPoint,
  setCrossAttrList,
  setCrossSectionData,
  setCrossWidth,
  setCrossSpacing,
  setCrossInit,
} from "../../../store/actions/crossSection";
import { initDraw3D, titleAnnotationVisible } from "../util/initialize";
import { getCrossSectionInfomation, ajaxGetCrossSectionV2 } from "./utils/getInfomation";
import { initLineAttr, getAttribute } from "../util/lineAttribute";
import { createAnnotation } from "../util/fnCreateAnnotaion";
import { configAndSave } from "../util/save";
import { setHandleVisible, setHandleTargetName } from "../../../store/actions/saveHandle";
import { softMakeAddPoint } from "./utils/measure";
import { useTranslation } from "react-i18next";
import { CoordinateForm } from "../children/CoordinateForm";
import "./section.less";

function CroSSection(props) {
  const { t } = useTranslation();
  const measure = props.projectChildrenComponents.currentMeasure;
  /**
   * @initLineAttr
   * @description Line attribute Interface
   */
  // const [attributes, setAttributes] = useState(initLineAttr(t));

  // 횡단 추가 팝업에서 데이터 가져오는 펑션.
  // 확정되면 paramater로 로직 개발
  function onCrossData(text) {
    console.log(text);
  }

  const hasResultApi = () => {
    (async () => {
      try {
        initDraw3D();

        const attrInfo = await getAttribute();
        if (!measure.f_result) {
          props.setCrossSectionData({});
          props.setCrossAttrList(initLineAttr(t));
          return;
        }

        console.log(measure.f_param);
        props.setCrossSectionData(measure.f_result);

        if (measure.f_param) {
          configParamToaddPoint();
        }

        if (attrInfo) configLineAttr(attrInfo);
        else props.setCrossAttrList(initLineAttr(t));
      } catch (err) {
        console.log(err);
        consoleError(err);
        props.setCrossSectionData({});
        props.setCrossAttrList(initLineAttr(t));
      }
    })();
  };

  // see result button
  const getAttributeAPI = () => {
    (async () => {
      try {
        const [crossInfo, attrInfo] = await Promise.all([ajaxGetCrossSectionV2(), getAttribute()]);

        /**
         * @data {:object}
         * section result infomations
         */
        if (!crossInfo.data) {
          props.setCrossSectionData({});
          props.setCrossAttrList(initLineAttr(t));
          return;
        }

        props.setCrossSectionData(crossInfo.data);
        /**
         * add point
         */
        if (measure.f_param) {
          configParamToaddPoint();
        }

        if (attrInfo) configLineAttr(attrInfo);
        else props.setCrossAttrList(initLineAttr(t));
      } catch (err) {
        console.log(err);
        consoleError(err);
        props.setCrossSectionData({});
        props.setCrossAttrList(initLineAttr(t));
      }
    })();
  };

  const configParamToaddPoint = () => {
    if (measure.f_param.add_points.length) {
      const addPointsMeasurements = measure.f_param.add_points.split(",");
      const coordinate = addPointsMeasurements.map((t) => {
        const xyz = t.split(" ");
        return { x: xyz[0], y: xyz[1], z: xyz[2] };
      });

      // makeMeasure(
      //   {
      //     f_line_color: "#333",
      //     f_location: t.split(" ").join(",") + "|",
      //     f_fence_nm: "crossSectionAddPoint",
      //   },
      //   measure.measure
      // )
      // if (!measure.measure.crossSectionChildren) {
      //   measure.measure.crossSectionChildren = [];
      // }

      const addPoints = softMakeAddPoint(coordinate, measure.measure);

      measure.measure.crossSectionChildren = addPoints;
      props.setCrossAddPoint(addPoints);
    }

    // console.log(addPoints, addPointMeasurements)
    // props.setCrossAddPoint(addPointMeasurements);
    // measure.crossSectionChildren = addPointMeasurements;
    const { baselength, dist } = measure.f_param;

    //tranverse
    if (baselength) props.setCrossWidth(baselength);

    //spacing
    if (dist) props.setCrossSpacing(dist);
  };

  const configLineAttr = (attrInfo) => {
    /**
     * line infomations
     * Horizontal Length
     * Surface Length
     * Elevation
     * Gradient (per segment)
     */
    const attributes = props.CrossSectionReducer.lineAttribute;
    attributes.find((x) => x.key === 0).value = attrInfo.data[0].h_length.toFixed(3);
    attributes.find((x) => x.key === 1).value = attrInfo.data[0].s_length.toFixed(3);
    attributes.find((x) => x.key === 2).value = [
      attrInfo.data[0].zmax.toFixed(3),
      attrInfo.data[0].zmin.toFixed(3),
    ];
    attributes.find((x) => x.key === 3).value = [
      attrInfo.data[0].ang_rate_max.toFixed(3),
      attrInfo.data[0].ang_rate_min.toFixed(3),
    ];
  };

  // const [radioSelected, setRadioSelected] = useState("longitudinal");
  // const onSelectRadio = (e) => {

  //   setRadioSelected(e.target.value)
  // };

  const configDbParam = () => {
    return new Promise((resolve, reject) => {
      const dbParam = createAnnotation();

      dbParam.f_param = getCrossSectionInfomation();
      dbParam.f_param.type = "SECTION";
      dbParam.f_param = JSON.stringify(dbParam.f_param);

      return resolve(dbParam);
    });
  };

  const onSave = () => (async () => configAndSave(true, await configDbParam()))();

  useEffect(() => {
    titleAnnotationVisible(false);

    return () => {
      titleAnnotationVisible(true);
      props.setCrossSectionData({});
      props.setCrossAddPoint([]);
      props.setCrossAttrList(initLineAttr(t));
      props.setCrossInit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 결과가 있을경우
   */
  useEffect(() => {
    if (measure.annotation_mng_sq && measure.f_result) hasResultApi();
  }, [measure.f_result]);

  //종 횡단 3d옵션
  const [drawVisible, setDrawVisible] = useState(["Longitudinal", "CrossSection"]);

  const options = [
    {
      label: t("title$Longitudinal_Section"),
      value: "Longitudinal",
    },
    {
      label: t("title$Cross_Section"),
      value: "CrossSection",
    },
  ];
  const onChange = (checkedValues) => {
    const measure = props.projectChildrenComponents.currentMeasure;
    if (measure.cross3DObject) {
      const {
        _3DObject_lat,
        annotation_lat,
        _3DObject_lon,
        annotation_lon,
      } = measure.cross3DObject;

      [_3DObject_lat, annotation_lat, _3DObject_lon, annotation_lon].map(
        (item) => (item.visible = false)
      );

      if (checkedValues.length === options.length) {
        [_3DObject_lat, annotation_lat, _3DObject_lon, annotation_lon].map(
          (item) => (item.visible = true)
        );
      } else {
        checkedValues.map((checked) => {
          if (checked === "CrossSection")
            [_3DObject_lat, annotation_lat].map((item) => (item.visible = true));
          if (checked === "Longitudinal")
            [_3DObject_lon, annotation_lon].map((item) => (item.visible = true));
        });
      }
    }

    setDrawVisible(checkedValues);
  };

  return (
    <div className="section">
      <WorkspaceTitle wrapperName="section" />
      <section className="section__content">
        <article className="section__content__part">
          <ContentTitle
            title={t("title$Parameter_Option")}
            style={{ color: "#182e63", marginBottom: "21px" }}
          />
          <p className="section__content-title sub-title">{t("title$Longitudinal_Section")}</p>
          {/* Coordinate button */}
          <CoordinateForm points={measure.measure.points} />
          <p className="section__content-title sub-title"> {t("title$Cross_Section")}</p>
          <CrossSectionProperties />

          {/* See Result Button */}
          <div className="dashboard-wrap">
            <DefaultButton style={{ borderRadius: "2px" }} className="" onClick={getAttributeAPI}>
              {t("button$See_Results")}
            </DefaultButton>
          </div>

          {Object.keys(props.CrossSectionReducer.crossSectionDatasetArr).length > 0 && (
            <SectionResult />
          )}
        </article>
        <article className="section__content__part">
          <ContentTitle title={t("title$Visualization_Option")} style={{ color: "#182e63" }} />

          <Checkbox.Group
            className="crossSection__3d-wrap"
            options={options}
            defaultValue={drawVisible}
            onChange={onChange}
          />

          {/* <Radio.Group
            size="small"
            onChange={onSelectRadio}
            value={radioSelected}
          >
            <Radio value="longitudinal">Longitudinal Line</Radio>
            <Radio value="crossSection">Cross Section Line</Radio>
          </Radio.Group> */}
          <MeasurementVisualization type="Line" titleDisabled />
          {/* <p style={{ paddingBottom: "15px" }}>Point color & Size </p>
          <Colors type="Line" /> */}

          {/* <LineOptions wrapperName="section" /> */}
        </article>
      </section>
      <AnnotationFooter onClick={onSave} className="section__bottom" />
    </div>
  );
}

// export default Section;
export default connect((state) => state, {
  setCrossAddPoint,
  setCrossAttrList,
  setCrossSectionData,
  setHandleVisible,
  setHandleTargetName,
  setCrossWidth,
  setCrossSpacing,
  setCrossInit,

  // setData,
})(CroSSection);
