//types
export const CROSS_SECTION_SPACING = 'CROSS_SECTION/SPACING';
export const CROSS_SECTION_TRANSVERSE_WIDTH = 'CROSS_SECTION/TRANSVERSE_WIDTH';
export const CROSS_SECTION_INIT = 'CROSS_SECTION/INIT';
export const CROSS_SECTION_ADD_POINT = 'CROSS_SECTION/ADD_POINT';
export const CROSS_SECTION_LIST = 'CROSS_SECTION/LIST';
export const CROSS_SECTION_ATTR_LIST = 'CROSS_SECTION/LIST/ATTR';
export const CROSS_SECTION_LONGITUDITUDINAL_WIDTH = 'CROSS_SECTION/CHART/LONGITUDITUDINAL/WIDTH';


export const setCrossLongitudinalWidth = width => ({ type: CROSS_SECTION_LONGITUDITUDINAL_WIDTH, width });
export const setCrossAttrList = attr => ({ type: CROSS_SECTION_ATTR_LIST, attr });
export const setCrossSpacing = spacing => ({ type: CROSS_SECTION_SPACING, spacing });
export const setCrossWidth = width => ({ type: CROSS_SECTION_TRANSVERSE_WIDTH, width });
export const setCrossAddPoint = addPoint => ({ type: CROSS_SECTION_ADD_POINT, addPoint });
export const setCrossInit = () => ({ type: CROSS_SECTION_INIT });


//new version
export const CROSS_SECTION_LIST_V2 = 'CROSS_SECTION/LIST/V2';
export const setCrossSectionData = list => ({ type: CROSS_SECTION_LIST_V2, list });

export const SET_LONG_CHART = 'LONG/CHART';
export const setLongChartOptions = chartjsObject => ({ type: SET_LONG_CHART, chartjsObject });