/**
 * clipboard copy
 * @link https://developer.mozilla.org/ko/docs/Web/API/Clipboard_API
 * @param {string} text
 */
export const clipboardCopy = (text) => {
  // http나 브라우저 등 환경에 따른 Clipboard_API 비동작 분기
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);

    try {
      document.execCommand("copy");
    } catch (err) {
      throw err;
    } finally {
      textArea.setSelectionRange(0, 0);
      document.body.removeChild(textArea);
    }
  }
};
