import React from "react";
import { Button } from "antd";

/**
 * @component
 *    - DefaultButton
 * @description 
 *    - Button UI
 * 
 * @default concept 
 *    - color #346cee
 *    - radius 4px
 *    - font-color #fff
 *    - border none || unset
 */
const DefaultButton = (props) => {
  const Style = {
    backgroundColor: props.color ? props.color : "#346cee",
    borderRadius: props.radius ? props.radius :"4px",
    color: props.fontColor ? props.fontColor : '#fff',
    border: 'unset',
    ...props.style
  };

  return (
    <Button
      {...props}
      style={Style}
    >
      {props.children}
    </Button>
  );
};

export default DefaultButton;
