//types
export const READ_MESSAGE = 'CHAT/READ_MESSAGE';
export const setGetMsg = bool => ({ type: READ_MESSAGE, bool });

export const GET_MESSAGE_LIST = 'CHAT/GET_MESSAGE/LIST'
export const setPastMessages = items => ({ type: GET_MESSAGE_LIST, items})

export const CHAT_IS_LOADING = 'CHAT/CHAT_IS_LOADING'
export const setChatLoading = bool => ({ type: CHAT_IS_LOADING, bool });

export const CHAT_COMPONENT_VISIBLE = 'CHAT/CHAT_COMPONENT_VISIBLE'
export const setComponentVisible = bool => ({ type: CHAT_COMPONENT_VISIBLE, bool });

export const CHAT_INPUT_FOCUS = 'CHAT/CHAT_INPUT_FOCUS'
export const setChatInputFocus = bool => ({ type: CHAT_INPUT_FOCUS, bool });

export const CHAT_WRITE_MESSAGE = 'CHAT/CHAT_WRITE_MESSAGE'
export const setCurrentMessage = value => ({ type: CHAT_WRITE_MESSAGE, value });

export const CHAT_INIT = 'CHAT/CHAT_INIT'
export const setChatInitialize = () => ({ type: CHAT_INIT });

export const CHAT_LIMIT = 'CHAT/CHAT_LIMIT'
export const setChatLimit = () => ({ type: CHAT_LIMIT });

