import Axios from "axios";
import { i18n } from "react-i18next/i18next";
import { initialize } from "./initialize";
import { consoleError } from "../../../middleware";
import { store } from "../../../store/store";
import { setHandleVisible, setHandleTargetName } from "../../../store/actions/saveHandle";
import { setAnnotations } from "../../../store/actions/viewDataSet";
import { message } from "antd";
// import { fnAddAnnotation } from "../../../xitecloud3D/xiteCloud.dev";
// import { pushCurrentMeasure } from "./pushState";
import { loadAnnotationAll } from "../../../views/project/utils/project";

export async function configAndSave(isJob, dbParam) {
  const state = store.getState();
  const isDoubleSubmit = state.SaveHandleReducer.canSave;
  const measure = state.projectChildrenComponents.currentMeasure;
  if (!isDoubleSubmit) return;

  store.dispatch(setHandleVisible(false));
  store.dispatch(setHandleTargetName("annotation"));

  try {
    /**
     * annotation save
     */
    const saveAnnotationApi = isJob ? createAnnotationAndJob : saveAnnotation;
    const { data } = await saveAnnotationApi(dbParam);
    measure.annotation_mng_sq = data[0].annotation_mng_sq;

    savedThenMergeAndInit();
  } catch (err) {
    message.error(i18n.t("message$Failed_to_save_please_try_again"), 3);
    consoleError(err);
    initialize();
  }
}

export function savedThenMergeAndInit() {
  const state = store.getState();
  const measure = state.projectChildrenComponents.currentMeasure;
  const { annotations } = state.ViewDataSetReducer.projectViewDataSetModel;
  measure.hasArrow = "annotations"; // 생성시 hasArrow가 없어서 어노테이션 상세조회 버튼이 안생겨서 추가
  /**
   * @variablename isAnnotationTypeArr
   * @type {:array}
   *  onsite , offsite, analysis
   */
  const isAnnotationTypeArr = annotations[measure.annotationGroup];

  // target design 선택하여 realtime-analysis + save하면 measure.measure에 데이터가 없음.
  if (measure.measure) {
    if (
      !isAnnotationTypeArr.find((x) => x.measure !== null && x.measure.id === measure.measure.id) ||
      !measure.annotation_mng_sq
    ) {
      store.dispatch(setAnnotations(isAnnotationTypeArr.concat(measure), measure.annotationGroup));
    }
  } else {
    if (!measure.annotation_mng_sq) {
      store.dispatch(setAnnotations(isAnnotationTypeArr.concat(measure), measure.annotationGroup));
    }
  }

  // Annotation title
  // if (measure.titleAnnotation && Object.keys(measure.titleAnnotation).length) {
  //     measure.titleAnnotation.title = measure.title
  // } else {
  //     measure.titleAnnotation = fnAddAnnotation(measure.measure.points, measure.title, {
  //         customHighlighted: true,
  //         classNames: 'annotation__measurement-title',
  //         customCallback: () => {
  //             initialize();
  //             const { type, resultStatus } = measure;
  //             if (type === 'area' && resultStatus !== null && resultStatus !== 2) return;
  //             pushCurrentMeasure(measure)
  //         }
  //     })
  // }

  /**
   * initialize workspace
   */
  initialize();
}

/**
 * annotation save api
 */
export function saveAnnotation(dbParam) {
  return new Promise((resolve, reject) => {
    Axios.post(`/createAnnotation`, { params: dbParam })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

/**
 * annotation job save api
 */
export function createAnnotationAndJob(dbParam) {
  return new Promise((resolve, reject) => {
    Axios.post(`/createAnnotationAndJob`, { params: dbParam })
      .then(resolve)
      .catch(reject);
  });
}
