import React from 'react';
import { Slider } from 'antd';
import './slider.less';

// default slider width 100%
// required props - items
// optional props - label, default, disabled, width, min, max, visibleMinMax
class SliderBar extends React.Component {
  state = {
    displayValue: this.props.default || (this.props.min && this.props.max ? (this.props.min + this.props.max)/2 : 50)
  };
  onChange = value => {
    if (isNaN(value)) return;
    this.setState({ displayValue: value });
  };

  render() {
    
    return (
      <div className="slider group" style={this.props.disabled ? {opacity: 0.5} : null}>
        {this.props.label ? <label className="slider__label">{this.props.label} : {this.props.default ? this.props.default : this.state.displayValue}</label> : null}
        <Slider className="slider__bar group"
          onChange={this.props.onChange ? this.props.onChange : this.onChange}
          defaultValue={this.props.default ? this.props.default : this.state.displayValue}
          disabled={this.props.disabled}
          style={{ width: this.props.width }}
          min={this.props.min || 0}
          max={this.props.max || 100}
          step={this.props.step}
          tooltipVisible={this.props.tooltipVisible}
        >
          {this.props.visibleMinMax && this.props.min !== undefined ? <span style={{float: 'left'}}>{this.props.min}</span> : null}
          {this.props.visibleMinMax && this.props.max !== undefined ? <span style={{float: 'right'}}>{this.props.max}</span> : null}
        </Slider>
      </div>
    )
  }
}

export default SliderBar;
