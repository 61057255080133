import React from "react";
import { withTranslation } from "react-i18next";
import { Upload, Button, Icon } from "antd";
import "./chat-upload.less";

// export const devPath = `${window.location.protocol}//${window.location.hostname}:3001/`
export const hostname =
  process.env.NODE_ENV === "production" ? window.location.hostname : "58.233.207.36";

export const devPath = `${window.location.protocol}//${hostname}:3001/`;
const CHAT_SERVER_PATH = process.env.NODE_ENV === "production" ? "/chat/" : devPath;

class MyUpload extends React.Component {
  state = {
    fileList: [],
  };

  handleChange = (info) => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        this.props.isLoading(true);
        file.url = file.response.url;
      }
      return file;
    });
    this.props.setUploadFiles(fileList[0]);
    this.setState({ fileList });
  };

  render() {
    const { t } = this.props;
    return (
      <Upload
        id="chat-component-upload"
        action={`${CHAT_SERVER_PATH}chatUpload?room=${this.props.projectSq}`}
        onChange={this.handleChange}
        multiple={false}
        fileList={this.state.fileList}
      >
        <Button className="chat-component-upload-btn">
          <Icon type="upload" />
          {t("button$Upload")}
        </Button>
      </Upload>
    );
  }
}

export default withTranslation()(MyUpload);
