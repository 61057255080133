import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import ko_KR from "antd/es/locale-provider/ko_KR";
import en_US from "antd/es/locale-provider/en_US";
import { useTranslation } from "react-i18next";
import { hot } from "react-hot-loader/root";
import { setConfig } from "react-hot-loader";
import Axios from "axios";

import DashBoardLayout from "./layouts/Base";
import AuthLayout from "./layouts/auth/template/index";
import { setUser } from "./store/actions/app";
import LoadingBar from "./components/elements/LoadingBar/LoadingBar";
import Dialogs from "./components/modals/Dialogs/Dialogs";
import ReportPublish from "./views/ReportPublish/ReportPublish";
import PreviewImages from "./components/modals/PreviewImage/PreviewImages";
import { setToken } from "./store/actions/token";
import LoginExtDialog from "./components/modals/LoginExtDialog/LoginExtDialog";
import { isEqual } from "lodash";

import "./App.less";

function AppUI(props) {
  const user = useSelector((state) => state.appState.user);
  const {
    visible: dialogsVisible,
    success,
    warning,
    title,
    message,
  } = useSelector((state) => state.ModalReducer);
  const previewVisible = useSelector((state) => state.PreviewerReducer.visible);
  const reportPublishVisible = useSelector((state) => state.ReportReducer.visible);

  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isLogin, setIsLogin] = useState(!!sessionStorage.userInfo);

  const checkAuthCallback = useCallback(async () => {
    try {
      const { data } = await Axios.get("/authenticate/check-session", { credentials: "include" });
      if (data.isAuthenticated) {
        let resUser = data.userInfo;
        if (!sessionStorage.userInfo) {
          sessionStorage.setItem("userInfo", JSON.stringify(resUser));
        }

        if (user === null || !isEqual(user, resUser)) {
          dispatch(setUser(resUser));
          dispatch(setToken(resUser.token));
        }
      } else {
        if (window.location.pathname !== "/") {
          console.log(`window.location`, window.location.pathname);
          window.location.href = "/";
        }
      }

      setIsLogin(data.isAuthenticated);
    } catch (error) {
      console.error("Failed to check session", error);
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkAuthCallback();
  }, [checkAuthCallback]);

  return (
    <ConfigProvider locale={i18n.language === "ko-KR" ? ko_KR : en_US}>
      <div className="App">
        {isLogin ? (
          user !== null && (
            <>
              {dialogsVisible && (
                <Dialogs success={success} warning={warning} title={title} message={message} />
              )}

              {previewVisible && <PreviewImages />}
              {reportPublishVisible && <ReportPublish />}
              <DashBoardLayout />
              <LoadingBar />
              <LoginExtDialog />
            </>
          )
        ) : (
          <AuthLayout />
        )}
      </div>
    </ConfigProvider>
  );
}

AppUI.propTypes = {};

// react-hot-loader. To disable hooks reloading
process.env.NODE_ENV === "development" &&
  setConfig({
    reloadHooks: false,
  });

export default process.env.NODE_ENV === "development" ? hot(AppUI) : AppUI;
// export default connect((state) => state, { setView, changeLanguage })(AppUI);
