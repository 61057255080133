import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { currentMeasure } from "../../../store/actions/projectChildrenComponent";
import { Object3DVisibleHandle, handleAnnotationAndObject3d } from "../util/initialize";
import { softMakeMeasure, softMakeAddPoint } from "../CroSSection/utils/measure";
import IconCoordinate from "../../../assets/icons-2/Coordinate_Upload.svg";
import { setCrossAddPoint } from "../../../store/actions/crossSection";
import { addCommaWithRound } from "utils/StringHelper";
import { ROUND_OFF_THE_NUMBERS_COORDINATE } from "config/measure";
import { Utils } from "xitecloud3D/utils";
import _ from "lodash";
import numeral from "numeral";
import "./index.less";

/**
 * @component
 *    ParseCoordinate
 * @state
 *    coordinateValue, visible
 * @description
 *  - 좌표를 유저가 편하기 입력하기 위한 컴포넌트
 */
const ParseCoordinate = (props) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleShow = () => {
    if (props.selectedOption === 1) {
      return message.warning(t("message$Selected_is_the_Target_Design"));
    }
    setVisible(true);
  };

  const handleClose = () => setVisible(false);

  return (
    <>
      <img
        src={IconCoordinate}
        width="15"
        height="15"
        className="coordinate_icon"
        alt="coordinate"
        title={t("button$Input")}
        onClick={handleShow}
      />
      {visible && <ParseCoordinateModal visible={visible} onClose={handleClose} {...props} />}
    </>
  );
};

export default ParseCoordinate;

const ParseCoordinateModal = (props) => {
  const { visible, isAddPoints, type, onClose, onOk } = props;
  const { t } = useTranslation();

  const { measure, addPoints } = useSelector((state) => ({
    measure: state.projectChildrenComponents.currentMeasure,
    addPoints: state.CrossSectionReducer.addPoints,
  }));
  const dispatch = useDispatch();

  const [coordinateValue, setCoordinateValue] = useState("");

  /**
   * @component
   *    ParseCoordinate
   * @functionname
   *    parsingCoordinate
   * @description
   *  - 좌표를 파싱하기 위한 함수
   */
  const parsingCoordinate = () => {
    const coordinate = [];
    const splitCoord = coordinateValue.replace(/\s+/g, " ").split(" ");

    if (type === "point") {
      const point = splitCoord.slice(0, 3);
      coordinate.push({
        x: +Utils.removeCommas(point[0] || "0"),
        y: +Utils.removeCommas(point[1] || "0"),
        z: +Utils.removeCommas(point[2] || "0"),
      });
    } else {
      splitCoord.forEach((_item, i) => {
        if ((i + 1) % 3 === 0) {
          const temp = splitCoord.slice(i - 2, i + 1);
          coordinate.push({
            x: +Utils.removeCommas(temp[0] || "0"),
            y: +Utils.removeCommas(temp[1] || "0"),
            z: +Utils.removeCommas(temp[2] || "0"),
          });
        }
      });
    }

    //_object3D
    handleAnnotationAndObject3d(measure, Object3DVisibleHandle, false);

    if (isAddPoints) {
      const addPoints = softMakeAddPoint(coordinate, measure.measure);

      const temp = [].concat(addPoints);
      measure.crossSectionChildren = temp;
      dispatch(setCrossAddPoint(temp));

      dispatch(currentMeasure(measure));
      onClose();
      return;
    }

    if (measure.measure) {
      const {
        pointColor,
        lineColor,
        faceColor,
        lineThickness,
        closed,
        showArea,
        showDistances,
        showAngles,
        showCoordinates,
        maxMarkers,
      } = measure.measure;
      /**
       * @description
       *  - 파싱한 값을 기준으로 measurement 를 다시 그리기 때문에
       *  기존에 있는 measure를 지웠다가 새롭게 다시 그린 후 해당 객체를 데이터셋에 넣어줌.
       */
      window.viewer.scene.removeMeasurement(measure.measure);
      measure.measure = softMakeMeasure(coordinate, {
        pointColor,
        lineColor,
        faceColor,
        lineThickness,
        closed,
        showArea,
        showDistances,
        showAngles,
        showCoordinates,
        maxMarkers,
      });
    } else {
      measure.measure = softMakeMeasure(coordinate, {
        pointColor: {
          b: 0.9333333333333333,
          g: 0.4235294117647059,
          r: 0.20392156862745098,
          isColor: true,
        },
        faceColor: {
          b: 0.9333333333333333,
          g: 0.4235294117647059,
          r: 0.20392156862745098,
          isColor: true,
        },
        lineColor: {
          b: 0.9333333333333333,
          g: 0.4235294117647059,
          r: 0.20392156862745098,
          isColor: true,
        },
        lineThickness: 2,
      });
    }
    dispatch(currentMeasure(measure));
    onClose();
    onOk && onOk();
    return;
  };

  /**
   * @component
   *    ParseCoordinate
   * @description
   *  - 데이터 셋 로드 및 변경시에 실행되며
   * 데이터 셋 내부에 measurement 가 변경될때마다 좌표가 파싱된다.
   */
  useEffect(() => {
    if (measure.measure) {
      let coordinate = "";

      /**
       * @component
       *    ParseCoordinate
       * @state
       *    coordinateValue
       * @description
       *  - 포인트들을 순차적으로 돌며 각 x y z의 값을 파싱하여 문자값으로 치환하여 state 값으로 할당한다.
       */

      if (isAddPoints) {
        addPoints.map((addPoint) =>
          addPoint.points.map((item) =>
            Object.keys(item.position).map(
              (xyz) =>
                (coordinate +=
                  addCommaWithRound(item.position[xyz], ROUND_OFF_THE_NUMBERS_COORDINATE) + " ")
            )
          )
        );
      } else {
        measure.measure.points.map((item) =>
          Object.keys(item.position).map(
            (xyz) =>
              (coordinate +=
                addCommaWithRound(item.position[xyz], ROUND_OFF_THE_NUMBERS_COORDINATE) + " ")
          )
        );
      }

      setCoordinateValue(coordinate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure, addPoints]);

  return (
    <Modal
      title={`${t("title$Coordinate")} (X, Y, Z)`}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      footer={<button onClick={parsingCoordinate}>{t("button$OK")}</button>}
    >
      <TextArea
        rows={6}
        onChange={(e) => setCoordinateValue(e.target.value)}
        value={coordinateValue}
      />
    </Modal>
  );
};
