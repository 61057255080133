import React from 'react';
import Arrow from '../Arrow/Arrow';

const BackButton = (props) => {
    return (
        <button className={props.className} {...props}>
          <Arrow style={{
              transform: 'rotate(180deg)',
              opacity: 1,
              width: 10,
              height: 10
          }} />
        </button>
    );
}

export default BackButton;
