// import { addCommas } from "../../../xitecloud3D/xiteCloud.dev";
import { Measure } from "../../../xitecloud3D/Measure";
import {
  //  fnPreCalc,
  fnMakeParamFromMeasure,
  makeParamPoints,
} from "../../../xitecloud3D/xiteCloud.dev";
import { fnAsyncPreCalc } from "../Volume/util";
import { store } from "../../../store/store";
const THREE = window.THREE;
const xitecloud = window.xitecloud;

export function setPreCalcParamAreaCallback(
  _result,
  { srid, targetBoundUse, measure, points, viewData, srcTable, boundryLayer }
) {
  let result = _result.fnprecalc.r_result_array;

  if (measure == null) {
    measure = new Measure();

    let _points = result[0].target_boundry
      .split("((")[1]
      .replace("))", "")
      .split(",");

    for (let _pt of _points) {
      measure.addMarker(
        new THREE.Vector3(Number(_pt.split(" ")[0]), Number(_pt.split(" ")[1]), xitecloud.bore_zmin)
      );
    }
  }

  //   $('#lds-roller-container').hide();   // 그려 지는 것 보여 줄때
  // let _tmpObj = {
  //     "area": (Number(result[0].param_area)).toFixed(2)
  //     , "sectorCnt": result.length
  //     , "avg_area": (Number(result[0].area_avg)).toFixed(2)
  // };

  //     let _messageStr = ` select area(2D) :   ${addCommas(_tmpObj.area, 'A')}
  // Divide Sector Count: ${_tmpObj.sectorCnt} (Average ${addCommas(_tmpObj.avg_area, 'A')})
  // Calculate few Minuates
  // Continue?`;

  // let _tmp = window.confirm(_messageStr);
  // var rtnGeom = {};

  return {
    srid,
    points,
    sectors: result,
    srcTable,
    measure,
    viewData,
    targetBoundUse,
    boundryLayer,
  };
}

export function preCalcResult(calcParam, props, measure) {
  return new Promise((resolve, reject) => {
    fnAsyncPreCalc(calcParam).then((data) => {
      return resolve(
        setPreCalcParamAreaCallback(data, {
          srid: calcParam.srid,
          target: calcParam.targetLayer,
          targetBoundUse: calcParam.targetBoundUse,
          measure: measure ? measure.measure : measure,
          srcTable: calcParam.topoLayer,
          boundryLayer: calcParam.boundryLayer,
          points: calcParam.points,
          viewData: measure,
        })
      );
    });

    // fnPreCalc(
    //     calcParam,
    //     props.ViewDataSetReducer.projectViewDataSetModel,
    //     (data) => {
    //         const realDrawVolumOption = setPreCalcParamAreaCallback(data, {
    //             srid: calcParam.srid,
    //             target: calcParam.targetLayer,
    //             targetBoundUse: calcParam.targetBoundUse,
    //             measure: measure ? measure.measure : measure,
    //             srcTable: calcParam.topoLayer,
    //             boundryLayer: calcParam.boundryLayer,
    //             points: calcParam.points,
    //             viewData: measure,
    //         });

    //         return resolve(realDrawVolumOption);
    //     }
    // );
  });
}

export const fnCalcAreaJobDataSet = function(rtnGeom, data) {
  const props = store.getState();

  const { currentProject, selectedDate } = props.currentProjectReducer;
  let boundryLayerName = "";
  let _targetLayerName = "";

  let _param = {
    //"type" : data.viewData.toolObj.value
    type: "AREA",
    project_mng_sq: currentProject.project_mng_sq,
    input_date: selectedDate,
    //, "top": data.srcTable.f_ply_geom_tbl_nm
    top: data.srcTable,
    bottom: _targetLayerName,
    boundry: boundryLayerName,
    srid: data.srid,
    points: fnMakeParamFromMeasure(data.viewData.measure, true),
    targetBoundUse: data.targetBoundUse,
  };

  return {
    project_mng_sq_t_project_mng: currentProject.project_mng_sq,
    f_create_date: selectedDate,
    f_fence_nm: data.viewData.title,
    //f_fence_annotation_flag: objParam.toolObj.value,
    f_fence_annotation_flag: data.viewData.toolObj.value,
    c_fence_type: data.viewData.toolObj.annotationGroup,
    c_annotation_type: data.viewData.toolObj.measureType,
    f_line_color: `#${data.measure.lineColor.getHexString()}`,
    f_line_dash: 0,
    f_line_width: 0,
    f_fill_color: `#${data.measure.faceColor.getHexString()}`,
    f_fill_transparency: 100,
    f_location: makeParamPoints(data.viewData.measure, true),
    f_description: "",
    f_param: JSON.stringify(_param),
  };
};

// export function createAnnotationAndJob (dbParam) {
//     return new Promise((resolve, reject) => {
//         Axios.post(`/createAnnotationAndJob`, {
//             params: dbParam
//           })
//             .then(resolve)
//             .catch(reject);
//     })
// }

// export function createAnnotationAndJob2 (dbParam, _callback) {
//     Axios.post(`/createAnnotationAndJob`, {
//         params: dbParam
//       })
//         .then(_callback)
//         .catch((error) => consoleError(error));
// }
