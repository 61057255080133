import Axios from "axios";
// import { consoleError } from "../../../middleware";
import { setFolders, setProjects } from "../../../store/actions/projectList";
import { setReportFolder, setReportUnGroups } from "../../../store/actions/report";
import { store } from "../../../store/store";
import { includes } from "lodash";

/**
 * @description
 *  dragEnd || isDocDragEnd common functions
 */
const isCollapseHeader = (e) => includes(e.target.className, "ant-collapse-header");

const isFolderSection = (e) =>
  e.target.parentNode.parentNode.parentNode.className === "folder-section";

/**
 * @description
 *  dragEnd local functions
 * #belong to "document"
 */
const isDocFolderOfProject = (x) => x.document_group_sq === +folderKey;
const hasDocument = (x, d) => x.document_mng_sq === +d.node.dataset.project;
const documentFiltered = (x, d) => x.document_mng_sq !== +d.node.dataset.project;
const isDocContentBody = (e) => includes(e.target.className, "document__content");

/**
 * @description
 * @dependentVariable folderKey
 * @param {drop target} e
 * @param {drag target} d
 * @param {redux}
 */
let folderKey;
export function isDocDragEnd(e, d, props, isDoc) {
  if (isCollapseHeader(e) && isFolderSection(e)) {
    const props = store.getState();
    folderKey = e.target.parentNode.parentNode.parentNode.dataset.folder;

    const folderArr = props.ReportReducer.folders;
    const reportArr = props.ReportReducer.reports;

    const folderInsideProject = folderArr.find(isDocFolderOfProject).projects;
    const isProjectToFolder = folderInsideProject.find((x) => hasDocument(x, d));

    if (isProjectToFolder) return (d.node.style.transform = "translate(0, 0)");

    if (reportArr.find((x) => hasDocument(x, d))) {
      folderInsideProject.push(reportArr.find((x) => hasDocument(x, d)));
    } else {
      folderInsideProject.push(
        folderArr
          .find((y) => y.projects.find((x) => hasDocument(x, d)))
          .projects.find((x) => hasDocument(x, d))
      );

      folderArr
        .filter((x) => x.project_group_sq !== +folderKey)
        .find((y) => y.projects.find((x) => hasDocument(x, d))).projects = folderArr
        .filter((x) => x.project_group_sq !== +folderKey)
        .find((y) => y.projects.find((x) => hasDocument(x, d)))
        .projects.filter((x) => hasDocument(x, d));
    }

    store.dispatch(setReportUnGroups(reportArr.filter((x) => documentFiltered(x, d))));
    store.dispatch(setReportFolder([].concat(folderArr)));

    updateGroup(d.node.dataset.project, folderKey, isDoc);

    folderKey = null;
  } else {
    if (isDocContentBody(e)) {
      const props = store.getState();
      folderKey = d.node.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.folder;

      if (!folderKey) {
        d.node.style.transform = "translate(0, 0)";
        return (folderKey = null);
      }

      const folderArr = props.ReportReducer.folders;
      const reportArr = props.ReportReducer.reports;

      const folderInsideProjects = folderArr.find(isDocFolderOfProject).projects;

      folderArr.find(isDocFolderOfProject).projects = folderInsideProjects.filter((x) =>
        documentFiltered(x, d)
      );

      store.dispatch(
        setReportUnGroups([folderInsideProjects.find((x) => hasDocument(x, d))].concat(reportArr))
      );

      store.dispatch(setReportFolder([].concat(folderArr)));
      updateGroup(d.node.dataset.project, null, isDoc);
    }

    d.node.style.transform = "translate(0, 0)";
    folderKey = null;
  }
}

/**
 * @description
 *  dragEnd local functions
 * #belong to "project"
 */
const isContentBody = (e) => includes(e.target.className, "home__content");
const isFolderOfProject = (x) => x.project_group_sq === +folderKey;
const projectFiltered = (x, d) => x.id !== +d.node.dataset.project;
const hasProject = (x, d) => x.id === +d.node.dataset.project;
export function dragEnd(e, d, propss, isDoc) {
  if (isCollapseHeader(e) && isFolderSection(e)) {
    const props = store.getState();
    folderKey = e.target.parentNode.parentNode.parentNode.dataset.folder;

    const folderArr = props.projectReducer.folders;
    const projectArr = props.projectReducer.projects;

    const folderInsideProject = folderArr.find(isFolderOfProject).projects;
    const isProjectToFolder = folderInsideProject.find((x) => hasProject(x, d));

    if (isProjectToFolder) return (d.node.style.transform = "translate(0, 0)");

    if (projectArr.find((x) => hasProject(x, d))) {
      folderInsideProject.push(projectArr.find((x) => hasProject(x, d)));
    } else {
      folderInsideProject.push(
        folderArr
          .find((y) => y.projects.find((x) => hasProject(x, d)))
          .projects.find((x) => hasProject(x, d))
      );

      folderArr
        .filter((x) => x.project_group_sq !== +folderKey)
        .find((y) => y.projects.find((x) => hasProject(x, d))).projects = folderArr
        .filter((x) => x.project_group_sq !== +folderKey)
        .find((y) => y.projects.find((x) => hasProject(x, d)))
        .projects.filter((x) => hasProject(x, d));
    }

    store.dispatch(setProjects(projectArr.filter((x) => projectFiltered(x, d))));
    store.dispatch(setFolders([].concat(folderArr)));

    updateGroup(d.node.dataset.project, folderKey);

    folderKey = null;
  } else {
    if (isContentBody(e)) {
      const props = store.getState();
      folderKey = d.node.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.folder;

      if (!folderKey) {
        d.node.style.transform = "translate(0, 0)";
        return (folderKey = null);
      }

      const folderArr = props.projectReducer.folders;
      const projectArr = props.projectReducer.projects;

      const folderInsideProjects = folderArr.find(isFolderOfProject).projects;

      folderArr.find(isFolderOfProject).projects = folderInsideProjects.filter((x) =>
        projectFiltered(x, d)
      );

      store.dispatch(
        setProjects([folderInsideProjects.find((x) => hasProject(x, d))].concat(projectArr))
      );

      store.dispatch(setFolders([].concat(folderArr)));
      updateGroup(d.node.dataset.project, null);
    }

    d.node.style.transform = "translate(0, 0)";
    folderKey = null;
  }
}

/**
 * @description
 *      - HTTP Request { POST }
 * @param {number} target -project
 * @param {number} group - folder
 */
async function updateGroup(target, group, isDoc) {
  const path = isDoc ? `/reportGroup/${target}` : `/projectGroup/${target}`;

  return await Axios.post(path, {
    groupId: group,
  });
}

/**
 * @description
 *      - drag and drop event style effect
 *          * mouse position for folder-header
 * @dependentVariable a
 *      - a is element for document
 * @param {drop target} e
 * @param {drag target} d
 */
let a;
export function onDrag(e, d) {
  if (isCollapseHeader(e)) {
    if (a && a !== e.target) a.style.boxShadow = "";

    a = e.target;
    a.style.boxShadow = "inset 0px 0px 11px 0px #b9ccf9";
  } else {
    if (a) a.style.boxShadow = "";
  }
}
