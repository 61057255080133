import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { currentMeasure } from "../../../store/actions/projectChildrenComponent";
import {
  message,
  Select,
  Radio,
  //  Button, Alert, Spin
} from "antd";
import "./index.less";
import IconReset from "../../../assets/icons/Icon_reset.svg";
import IconShare from "../../../assets/icons-2/Icon_Polygan.svg";
import ContentTitle from "../../../components/elements/text/contentTitle";
import Axios from "axios";
import PolygonVolumeSetAttr from "../children/PolygonVolumeSetAttr";
import PolygonVolumeOverall from "../children/PolygonVolumeOverall";
import {
  startInsertion,
  fnBGCalc,
  fnCalcVolume,
  fnCalcVolumeJob,
  makeParamPoints,
} from "../../../xitecloud3D/xiteCloud.dev";
import { setPreCalcParamCallback, asyncPreCalcParam } from "./util";
import { setAnnotations } from "../../../store/actions/viewDataSet";
import { initArea3d, titleAnnotationVisible } from "../util/initialize";
import WorkspaceTitle from "../children/WorkspaceTitle";
import AnnotationFooter from "../children/AnnotationFooter";
import DefaultButton from "../../../components/elements/button/DefaultButton";
import { sortBy } from "lodash";
import { nowProcessing } from "../../../utils/Hibrary";
import { configAndSave } from "../util/save";
import { setHandleVisible, setHandleTargetName } from "../../../store/actions/saveHandle";
import ParseCoordinate from "../children/ParseCoordinate";
import { addCommaWithRound } from "utils/StringHelper";
import { ROUND_OFF_THE_NUMBERS_COORDINATE } from "config/measure";
import { CoordinateForm } from "../children/CoordinateForm";

const { Option } = Select;

const RADIO_STYLE = {
  display: "block",
  margin: "15px 0",
  fontSize: "12px",
  height: "25px",
  lineHeight: "12px",
};

const PolygonVolume = (props) => {
  const { t, i18n } = useTranslation();
  const [disabledOption, setDisalbedOption] = useState(false);
  const [measure, setMeasure] = useState(props.projectChildrenComponents.currentMeasure);
  const setLocalMeasure = () => {
    setMeasure(measure);
  };

  const getInitialAttributes = (t) => [
    {
      placeholder: t("placeholder$Target_Altitude"),
      key: 1,
    },
    {
      placeholder: t("placeholder$Lowest_Point"),
      key: 2,
    },
    {
      placeholder: t("placeholder$Highest_Point"),
      key: 3,
    },
    {
      placeholder: t("placeholder$Custom_Altitude"),
      key: 5,
    },
  ];

  const [estimationRadio, setEstimationRadio] = useState(1);

  //하단 attribute 의 선택된 값
  const [attributeRadio, setAttributeRadio] = useState(1);
  const [_3dobject, set_3dobject] = useState(); // 3d object data

  const [attributes, setAttributes] = useState(getInitialAttributes(t));
  const targetList = props.projectViewDataSetModel.layer.target;
  const subserfaceList = props.projectViewDataSetModel.layer.subsurface;

  const initInsertion = () => {
    if (measure.annotation_mng_sq) return;
    if (measure.measure) {
      measure.measure.parent.remove(measure.measure);
      measure.measure = null;
      initArea3d();
      setAttributes(getInitialAttributes(t));
      setOveralls([]);
    }
  };

  const targetArea = () => {
    if (!targetList.length) {
      return message.error({ content: t("message$There is no target"), duration: 2 });
    }

    initInsertion();
  };

  const paramOption = {
    measureType: "area",
    pickAreatarget: "model",
    annotationGroup: "onsite",
    crossection: false,
    value: "a2", //'a1'
    selectedOptions: [
      {
        annotationGroup: "onsite",
        children: [],
        crossection: false,
        label: "Volume",
        measureType: "area",
        pickAreatarget: "model",
        value: "a2",
        volume: true,
      },
      {
        label: measure.title,
        value: "TOPO",
      },
    ],
  };

  const userSelectArea = () => {
    initInsertion();
    startInsertion(paramOption, setAreaCallback);
  };

  const setAreaCallback = (_item, measure) => {
    let datasetAnnotationObj = {
      gbn: "Annotations",
      annotationGroup: _item.annotationGroup,
      title: _item.selectedOptions[1]
        ? _item.selectedOptions[1].label
        : _item.selectedOptions[0].label,
      type: _item.measureType,
      _3DObject: {},
      toolObj: _item,
      measure: measure,
      visible: true,
    };

    datasetAnnotationObj.toolObj.value = _item.selectedOptions[0].value;
    setMeasure(datasetAnnotationObj);
    props.currentMeasure(datasetAnnotationObj);
  };

  const getPolygonAttr = () => {
    if (measure.measure && measure.measure.points.length > 1) {
      const position = measure.measure.points.map((item, index) => {
        const { x, y, z } = item.position;
        return `${x} ${y} ${z}`;
      });

      position.push(position[0]);

      const topo_data_sq = props.projectViewDataSetModel.layer.topo.find(
        (x) => x.dataType && x.dataType === "pointcloud"
      ).dbObj.topo_data_sq;

      Axios.get("/api/PRJ020306_01", {
        params: {
          topo_data_sq,
          points: position.join(),
        },
      }).then(({ data }) => {
        // h_area: 4823.8740695634015
        // s_area: 6175.701587142736
        // zmax: 61.042
        // zmin: 45.917

        if (data.data) {
          const apiAltitude = [
            {
              placeholder: t("placeholder$Target_Altitude"),
              key: 1,
            },
            {
              placeholder: t("placeholder$Lowest_Point"),
              value: addCommaWithRound(data.data.zmin, ROUND_OFF_THE_NUMBERS_COORDINATE),
              key: 2,
            },
            {
              placeholder: t("placeholder$Highest_Point"),
              key: 3,
              value: addCommaWithRound(data.data.zmax, ROUND_OFF_THE_NUMBERS_COORDINATE),
            },
            // {
            //   placeholder: t("placeholder$Linear_Fit"),
            //   key: 4,
            //   value: null,
            // },
            {
              placeholder: t("placeholder$Custom_Altitude"),
              key: 5,
              value: null,
            },
          ];

          setAttributes(targetList.length ? apiAltitude : apiAltitude.filter((x) => x.key !== 1));

          altitudeConfig();
        }

        // createAnnotation(measure)
        // window.xitecloud.viewDataSet.annotations[measure.annotationGroup].push(measure);
      });
    }
  };

  const preSetOveralls = () => {
    let rst = sumOver();

    setOveralls(
      sortBy(
        measure.f_result.map((x) => ({
          fill: x.r_result.vol_fill,
          net: 0,
          cut: x.r_result.vol_cut,
          layerName: rst[x.idx],
          idx: x.idx,
        })),
        "idx"
      )
    );
  };

  // 볼륨 데이터 안에 subserface 이름이 없어 매칭 시켜주기 위한 로직
  const sumOver = () => {
    let data = [measure.f_result.length];
    for (let i = 0; i < measure.f_result.length; i++) {
      for (let j = 0; j < subserfaceList.length; j++) {
        if (i == 0) {
          data[i] = "TOTAL";
          break;
        } else {
          data[i] = subserfaceList[i - 1].title;
          break;
        }
      }
    }

    return data;
  };

  /**
   * has target ? Target Altitude: Custom Altitude
   */
  const altitudeConfig = () => setAttributeRadio(targetList.length ? 1 : 5);

  const initAttr = () => {
    /**
     * target lenth -> 0
     * selected pit plane
     * target-altitude option not rendering
     */
    if (!targetList.length) {
      setAttributes(getInitialAttributes(t).filter((x) => x.key !== 1));
      setEstimationRadio(2);
      altitudeConfig();
    }

    if (measure.f_param && measure.f_param.uiOptions) {
      setEstimationRadio(+measure.f_param.uiOptions.isFitPlane);

      if (+measure.f_param.uiOptions.isFitPlane === 2) {
        setAttributeRadio(+measure.f_param.uiOptions.altitudeOption);
      }

      setDisalbedOption(true);
    }

    if (measure.measure) getPolygonAttr();
    if (measure.f_result) preSetOveralls();
  };

  useEffect(() => {
    initAttr();
    titleAnnotationVisible(false);
    return () => {
      if (measure.f_result === null) {
        window.viewer.scene.removeMeasurement(measure.measure);
      }

      setAttributes(getInitialAttributes(t));
      titleAnnotationVisible(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, measure]);

  // real-time analysis
  const [realTimeAnalysis, setRealTimeAnalysis] = useState(null);
  const analysisAttr = () => {
    /**
     * estimationRadio
     *  - 1 : target
     *  - 2 : fit plane
     */
    // if (measure && measure.measure.points.length > 1) {

    const srid = props.currentProjectReducer.currentProject.f_epsg;

    //topo
    const topo = props.projectViewDataSetModel.layer.topo.find(
      (x) => x.dataType && x.dataType === "pointcloud"
    ).dbObj;

    //target
    //타겟이 없는 현장의 경우 fit plan 분석은 가능하도록 수정 [전종수 2020.08.14]
    const target =
      targetList && targetList.length > 0
        ? targetList[0].dbObj
        : {
            f_analytics_summary: {
              min_height: 0,
              max_height: 0,
              boundary_length: 0,
            },
            f_description: null,
            f_dwg_file_path: "",
            f_file_path: "",
            f_geometry_tbl_nm: "",
            f_input_date: "",
            f_layer_nm: null,
            project_mng_sq_t_project_mng: null,
            target_data_sq: null,
          };

    // console.log(topo);
    // //target
    // const target = targetList[0].dbObj;

    //calc param
    const calcParam = {
      srid: srid,
      points: "",
      topoLayer: topo,
      targetLayer: target,
      targetBoundUse: "true",
      boundryLayer: target,
      gbnFitPlan: attributeRadio,
    };

    if (estimationRadio === 2) {
      //flt plane 은 default 커스텀
      calcParam.targetBoundUse = "custom";

      //타겟 바운더리 기준은 false값으로 전환
      if (attributeRadio === 1) calcParam.targetBoundUse = "false";

      //point
      const position = measure.measure.points.map((item, index) => {
        const { x, y, z } = item.position;
        let zPoint = z;

        //flt plane 시 target boundary를 제외한 z point 값 전환.
        if (attributeRadio === 2 || attributeRadio === 3 || attributeRadio === 5)
          zPoint = attributes.find((x) => x.key === attributeRadio).value;
        return `${x} ${y} ${zPoint}`;
      });

      position.push(position[0]);

      const points = position.join();

      calcParam.points = points;
    }

    return calcParam;
  };

  const getResultAttr = () => {
    if (nowProcessing()) return;

    initArea3d();
    const calcParam = analysisAttr();

    asyncPreCalcParam(calcParam).then((realDrawVolumOption) => {
      setRealTimeAnalysis(realDrawVolumOption);
      const rtnGeom = {};

      const key = "updatable";

      /**
       * 2020-08-14 전종수
       * target boundary, target altitude 일 경우 각 암층 별 물량
       * pit plane - custom 일 경우에는  유저가 작성한 altitude 값 대비 topo 물량 계산
       */
      const isCustomAltitude = estimationRadio === 2 && attributeRadio !== 1;

      fnCalcVolume(rtnGeom, realDrawVolumOption, isCustomAltitude, (data, result) => {
        // console.log(rtnGeom.result);

        const overallArr = Object.keys(result).map((keyName, index) => ({
          fill: result[keyName].fill,
          net: 0,
          cut: result[keyName].cut,
          layerName: keyName,
          idx: index,
        }));

        setOveralls(overallArr);
        message.success({ content: t("message$Loaded!"), key, duration: 2 });
      });
    });
    // }
  };

  const getBGAttr = () => {
    if (nowProcessing()) return;

    initArea3d();
    const calcParam = analysisAttr();

    console.log(props.projectViewDataSetModel);

    fnBGCalc(calcParam, props.projectViewDataSetModel, (data) => {
      const realDrawVolumOption = setPreCalcParamCallback(data, {
        srid: calcParam.srid,
        target: calcParam.targetLayer,
        targetBoundUse: calcParam.targetBoundUse,
        measure: measure ? measure.measure : measure,
        points: calcParam.points,
        viewData: measure ? measure : props.measur,
      });
      setRealTimeAnalysis(realDrawVolumOption);
    }).then((result) => {
      set_3dobject(result._3dData);
      overallsSet(result.calVolume);
    });
  };

  // real-time cal voloume convert to PolygonVolumeOverall
  // 리얼타임 볼륨계산 결과를 PolygonVolumeOverall의 파라메터 형식으로 변경
  const overallsSet = (data) => {
    setOveralls(
      sortBy(
        data.map((x) => ({
          fill: x.fill,
          net: 0,
          cut: x.cut,
          layerName: x.f_layer_nm,
          idx: x.rock_sq,
        }))
      )
    );
  };

  useEffect(() => {
    // 리얼타임 클릭 후 저장 안하고 종료시, 어노테이션, three.js 도형 삭제
    return () => {
      let fnInterval = setInterval(() => {
        if (_3dobject) {
          window.viewer.scene.removeAnnotation(_3dobject.annotation);
          window.viewer.scene.scene.remove(_3dobject._3DObject);
        }

        clearInterval(fnInterval);
      }, 10);
    };
  }, [_3dobject]);

  const [overalls, setOveralls] = useState([]);

  const jobSave = (realDrawVolumOption) => {
    const rtnGeom = {};

    /**
     * selectedDate: 20231219 추가(Alex Yu) - PreCalc에서 나온 Topo 일자 배제를 위함
     */
    realDrawVolumOption.selectedDate = props.currentProjectReducer.selectedDate;

    realDrawVolumOption.uiOptions = {
      isFitPlane: estimationRadio,
      altitudeOption: attributeRadio,
    };
    const dbParam = fnCalcVolumeJob(rtnGeom, realDrawVolumOption);

    if (measure.measure) {
      dbParam.f_location = makeParamPoints(measure.measure);
    }

    measure.f_result = null;
    initArea3d();
    configAndSave(true, dbParam);
  };

  const onSave = () => {
    (async () => {
      if (nowProcessing()) return;

      let saveObject = realTimeAnalysis;
      if (!realTimeAnalysis) {
        const calcParam = analysisAttr();
        const realDrawVolumOption = await asyncPreCalcParam(calcParam);
        saveObject = realDrawVolumOption;
      }

      jobSave(saveObject);
    })();
  };

  const resetMarker = () => {
    initInsertion();
    startInsertion(paramOption, setAreaCallback);
  };
  const handleOk = () => {
    if (typeof window.viewer.measuringTool.measure._cancel === "function") {
      window.viewer.measuringTool.measure._cancel();
    }
  };
  return (
    <div className="polygon-volume">
      <WorkspaceTitle wrapperName="polygon-volume" />
      <section className="polygon-volume__content">
        <article className="polygon-volume__content__part">
          <ContentTitle title={t("title$Parameter_Option")} style={{ color: "#182e63" }} />

          {disabledOption ? (
            <p className="polygon-volume__content-title">
              {
                [
                  {
                    title: t("title$Target_Design"),
                    idx: 1,
                  },
                  {
                    title: t("title$Fit_Plane"),
                    idx: 2,
                  },
                ].find((x) => x.idx === estimationRadio).title
              }
            </p>
          ) : (
            <Radio.Group
              onChange={(e) => setEstimationRadio(e.target.value)}
              value={estimationRadio}
            >
              <Radio
                style={RADIO_STYLE}
                onClick={targetArea}
                disabled={!targetList.length}
                value={1}
              >
                {t("button$Target_Design")}
                <Select
                  size="small"
                  value={targetList.length ? targetList[0].title : ""}
                  disabled={estimationRadio !== 1 ? true : false}
                >
                  {targetList.map((target, index) => (
                    <Option key={index} value={index}>
                      {target.title}
                    </Option>
                  ))}
                </Select>
              </Radio>
              <Radio onClick={userSelectArea} style={RADIO_STYLE} value={2}>
                {t("button$Custom")}
              </Radio>
            </Radio.Group>
          )}

          {/* <ParseCoordinate /> */}
          {estimationRadio === 2 && (
            <CoordinateForm
              points={measure?.measure?.points ?? []}
              type={"polygon"}
              headerNodeItems={{
                reset: (
                  <img
                    alt="reset icon"
                    src={IconReset}
                    title={t("button$Redraw")}
                    onClick={resetMarker}
                  />
                ),
                text: <ParseCoordinate selectedOption={estimationRadio} onOk={handleOk} />,
              }}
              onSetCallback={() => {
                setLocalMeasure();
                initAttr();
              }}
            />
          )}

          <div className="altitude-wrap">
            <div>
              <p style={{ paddingTop: "18px" }}>{t("title$Set_Altitude")}</p>
            </div>
            <PolygonVolumeSetAttr
              hasTarget={targetList.length}
              setattributeRadio={setAttributeRadio}
              attributeRadio={attributeRadio}
              attributes={attributes}
              setAttributes={setAttributes}
            />
          </div>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <DefaultButton
              onClick={getBGAttr}
              // disabled={
              //   estimationRadio === 2 && (!measure.measure || measure.measure.points.length < 0)
              // }
            >
              {t("button$Real-Time_Analysis")}
            </DefaultButton>
          </div>
        </article>
        <article className="polygon-volume__content__part">
          <ContentTitle
            title={t("title$Result", { count: 2 })}
            style={{ color: "#182e63" }}
            onClick={getBGAttr}
          />
          <p className="polygon-volume__content-title">{t("title$Overall_Estimation")}</p>
          <PolygonVolumeOverall overalls={overalls} />
          <br />
        </article>
      </section>
      <AnnotationFooter onClick={onSave} className="polygon-volume_footer" />
    </div>
  );
};

// export default PolygonVolume;
export default connect(
  (state) => ({
    projectChildrenComponents: state.projectChildrenComponents,
    projectViewDataSetModel: state.ViewDataSetReducer.projectViewDataSetModel,
    currentProjectReducer: state.currentProjectReducer,
  }),
  {
    currentMeasure,
    setAnnotations,
    setHandleVisible,
    setHandleTargetName,
    // setData
  }
)(PolygonVolume);
