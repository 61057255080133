import axios from "axios";
import { useEffect, useRef } from "react";
import {
  initViewDataSet,
  loadPointCloud,
  fnLoadTarget,
  loadWMS,
  setProjection,
  loadBoreholeGeom,
  fnLoadGLTF,
} from "../../../xitecloud3D/xiteCloud.dev";
import _ from "lodash";

import { consoleError } from "../../../middleware";
import { store } from "../../../store/store";
import {
  // setLayers,
  setDataSetInit,
  setViewDataKey,
  setAnnotations,
  setAnnotationMany,
  setLayersMany,
} from "../../../store/actions/viewDataSet";
import { visualizationOption } from "../../pointCloud/util";
import { setProjectSelectedDateSet } from "../../../store/actions/currentProject";
import { workerLoadPly } from "./loadPlyWorker";

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

/**
 * @dependencies { redux}
 * setLayers,
 * setAnnotations,
 * setProjectSelectedDateSet,
 * setDataSetInit,
 */
export function getLayerDateInfo(projectId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/layerDateInfo`, {
        params: {
          project_mng_sq: projectId,
        },
      })
      .then(({ data }) => resolve(data))
      .catch((err) => {
        consoleError(err);
        return reject(err);
      });
  });
}

/**
 * selectedDate : project input_date / f_input_date
 */
// const CancelToken = axios.CancelToken;
export function getProjectInformation(selectedDate, projectId) {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    /**
     * transfer (project, update) to window.3DObject
     * migrate 3d view data set
     */
    window.xitecloud.viewDataSet.project = state.currentProjectReducer.currentProject;
    window.xitecloud.updateLayerListInComponent = () => {
      console.log("updateLayerListInComponent");
    };
    window.viewer.scene.annotations.removeAllChildren();

    store.dispatch(setProjectSelectedDateSet(selectedDate));

    axios
      .get(`/loadProjectInfoAll`, {
        params: {
          project_mng_sq: projectId,
          data_date: selectedDate,
        },
      })
      .then(loadProjectInfoAll)
      .then(layerProcess)
      .then(loadAnnotationAll)
      .then(resolve)
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function loadProjectInfoAll({ data }) {
  const projectSetProject = async () => {
    store.dispatch(setDataSetInit());

    const prjObj = store.getState().currentProjectReducer.currentProject;
    store.dispatch(setViewDataKey(prjObj.project_mng_sq));

    setProjection(prjObj);

    const options = {
      err: false,
    };

    const errorHandler = (err, key) => {
      console.error(err);
      options.err = true;
      options[key] = null;
    };

    try {
      if (data.subsurface.length >= 1) {
        const boreholes = await loadBoreholeGeom(prjObj, data.boreholeDate, {
          title: "BoreHole",
          dbObj: {},
          _3DObject: {},
          hasArrow: false,
          visible: true,
          gbn: "Layers",
          group: "topo",
          dataType: "datarows",
        });
        options.boreholes = boreholes;
      } else {
        options.err = true;
        options.boreholes = null;
      }
    } catch (err) {
      errorHandler(err, "boreholes");
    }

    try {
      const pointClouds = await Promise.all(
        data.topo.map((_item, key) =>
          loadPointCloud(_item, prjObj.f_proj_nm + "_" + _item.f_input_date, {
            hasArrow: "pointcloud",
            title: _item.f_layer_nm,
            dbObj: _item,
            _3DObject: {},
            visible: true,
            gbn: "Layers",
            group: "topo",
            dataType: "pointcloud",
          })
        )
      );

      // wms layer 추가 로직
      const filePath = `/uploads/data_pcd/${data.project_mng_sq}/${data.topo[0].f_input_date}/wms/tilemapresource.xml`;
      const checkFile = await axios.get(filePath);

      // 파일이 있으면, string형식으로 리턴
      // 파일이 없으면, object형식으로 리턴
      if (typeof checkFile.data === "string") {
        const wmsObj = await loadWMS(
          data.topo[0],
          prjObj.f_proj_nm + "_" + data.topo[0].f_input_date,
          {
            hasArrow: false,
            title: data.topo[0].f_layer_nm,
            dbObj: data.topo[0],
            _3DObject: {},
            visible: true,
            gbn: "Layers",
            group: "topo",
            dataType: "datarows",
          }
        );
        wmsObj.title = convertDescription(
          data.topo[0].job_status,
          data.topo[0].f_description,
          wmsObj.title
        );
        pointClouds.push(wmsObj);
      } else {
        const viewer = window.cesiumViewer;
        let layers = viewer.imageryLayers;

        if (layers.length > 1) {
          layers.remove(layers._layers[1]);
        }
      }

      options.pointClouds = pointClouds;
    } catch (err) {
      errorHandler(err, "pointClouds");
    }

    try {
      const contours = await Promise.all(
        data.topo
          .filter((x) => x.f_contour_geojson_file_nm !== null)
          .map((_item, key) =>
            (() => ({
              hasArrow: "contour",
              isContour: true,
              visible: false,
              isLoaded: false,
              _3DObject: {},
              gbn: "Layers",
              group: "topo",
              dataType: "geojson",
              title: _item.f_layer_nm + " ContourLine",
              dbObj: _item,
            }))()
          )
      );

      options.contours = contours;
    } catch (err) {
      errorHandler(err, "contours");
    }

    try {
      const targets = await Promise.all(
        data.target.map((_item, key) => {
          const isGlb = _item.f_file_path.indexOf("glb") > -1;
          const isPly = _item.f_file_path.indexOf("ply") > -1;

          if (isGlb) {
            return fnLoadGLTF(
              prjObj,
              _item,
              _item.f_layer_nm,
              _item.f_file_path,
              window.xitecloud.targetColor.faceColor
            ).catch((error) => {
              console.error(`Error fnLoadGLTF : `, error);
              throw new Error(error);
            });
          } else if (isPly) {
            return workerLoadPly(_item, window.xitecloud.targetColor.faceColor, {
              title: _item.f_layer_nm,
              dbObj: _item,
              _3DObject: {},
              hasArrow: false,
              visible: true,
              gbn: "Layers",
              group: "target",
              dataType: "ply",
            }).catch((error) => {
              console.error(`Error workerLoadPly : `, error);
              throw new Error(error);
            });
          } else {
            return fnLoadTarget(_item, {
              title: _item.f_layer_nm,
              dbObj: _item,
              _3DObject: {},
              hasArrow: false,
              visible: true,
              gbn: "Layers",
              group: "target",
              dataType: "geojson",
            }).catch((error) => {
              console.error(`Error fnLoadTarget : `, error);
              throw new Error(error);
            });
          }
        })
      );

      options.targets = targets;
    } catch (err) {
      errorHandler(err, "targets");
    }

    try {
      const plyLayer = data.subsurface.filter((item) => item.f_file_path.indexOf("ply") > -1);
      const plyPromise = plyLayer.map((_item, key) =>
        workerLoadPly(_item, window.xitecloud.ArrColor[key], {
          title: _item.f_layer_nm,
          dbObj: _item,
          _3DObject: {},
          visible: true,
          hasArrow: false,
          gbn: "Layers",
          group: "subsurface",
          dataType: "ply",
        })
      );

      const glbLayer = data.subsurface.filter((item) => item.f_file_path.indexOf("glb") > -1);
      const glbPromise = glbLayer.map((_item, key) =>
        fnLoadGLTF(
          prjObj,
          _item,
          _item.f_layer_nm,
          _item.f_file_path,
          window.xitecloud.ArrColor[key]
        )
      );

      const subsurfaces = await Promise.all([...plyPromise, ...glbPromise]);

      options.subsurfaces = subsurfaces;
    } catch (err) {
      errorHandler(err, "subsurfaces");
    }

    try {
      const overlays = await Promise.all(
        data.overlay.map((overlayItem) => {
          const obj = {
            gbn: "Layers",
            hasArrow: ["tif", "dxfwms", "GIS"].includes(overlayItem.f_file_type) ? null : "overlay",
            group: "overlay",
            isOverlay: true,
            isLoaded: false,
            originalData: overlayItem,
            dataType: overlayItem.f_file_type,
            title: convertDescription(
              overlayItem.job_status,
              overlayItem.f_description,
              overlayItem.f_layer_nm
            ),
            dbObj: overlayItem,
            _3DObject: {},
            visible: false,
            disable:
              ["tif", "dxfwms"].includes(overlayItem.f_file_type) && overlayItem.f_status === "0",
          };

          return obj;
        })
      );

      options.overlays = overlays;
    } catch (err) {
      errorHandler(err, "overlays");
    }

    if (options.pointClouds === null) {
      window.viewer.scene.scene.add(new window.THREE.HemisphereLight(0xffffff, 0xffffff));
      window.viewer.scene.scene.add(new window.THREE.DirectionalLight(0xffffff, 2));
    }

    return options;
  };

  return new Promise((resolve, reject) => {
    initViewDataSet().then(projectSetProject).then(resolve).catch(reject);
  });
  //프로젝트 데이터 트리 초기화
}

export async function loadAnnotationAll() {
  const state = store.getState();

  const { data } = await axios.get(`/listAnnotation`, {
    params: {
      project_mng_sq: state.currentProjectReducer.currentProject.project_mng_sq,
      data_date: state.currentProjectReducer.selectedDate,
    },
  });

  /**
   * annotation temp object
   */
  const annotations = {
    analytics: [],
    offsite: [],
    onsite: [],
  };

  for (let _item of data) {
    const _toolObjValue = _item.f_fence_annotation_flag.split("|");

    const _toolObj = _.cloneDeep(window.xitecloud.toolAttribute[_toolObjValue[0]]);
    _toolObj.value = _item.f_fence_annotation_flag;

    const datasetAnnotationObj = {
      gbn: "Annotations",
      hasArrow: "annotations",
      annotationGroup: _item.c_fence_type,
      title: _item.f_fence_nm,
      type: _item.c_annotation_type,
      _3DObject: {},
      toolObj: _toolObj,
      /**
       * Create Annotation 3D Measure
       */
      measure: null,
      f_param: _item.f_param,
      visible: false,
      titleAnnotation: null,
      annotation_mng_sq: _item.annotation_mng_sq,
      description: _item.f_description,
      resultStatus: _item.f_status,
      // makeMeasure: false,
      preItems: _item,
    };

    annotations[datasetAnnotationObj.annotationGroup].push(datasetAnnotationObj);
  }

  // store.dispatch(setAnnotationMany(annotations, data.length))
  return store.dispatch(setAnnotationMany(annotations, data.length));
}

export function layerProcess(options) {
  // if (options.err) {
  //   console.error(options.err);
  // }

  return new Promise((resolve, reject) => {
    try {
      let { boreholes, pointClouds, targets, subsurfaces, overlays, contours } = options;
      const topoArr = [];

      if (boreholes) {
        topoArr.push(boreholes);
      }

      if (pointClouds && pointClouds.length) {
        topoArr.push(pointClouds[0]);
        //pointcloud visualization
        visualizationOption(pointClouds[0]);
      }

      if (contours && contours.length) {
        topoArr.push(contours[0]);
      }

      // wms layer 유무 확인
      if (pointClouds.length > 1) {
        topoArr.push(pointClouds[1]);
      }

      /**
       * targets, subsurfaces, overlays ? [] : null
       */
      targets = targets ? targets : [];
      subsurfaces = subsurfaces ? subsurfaces : [];
      overlays = overlays ? overlays : [];
      const totalCount = targets.length + subsurfaces.length + overlays.length + topoArr.length;

      store.dispatch(
        setLayersMany(
          {
            topo: topoArr,
            target: targets,
            subsurface: subsurfaces,
            overlay: overlays,
          },
          totalCount
        )
      );

      return resolve();
    } catch (err) {
      return reject(err);
    }
  });
}

/**
 * @deprecated
 */
export function annotationFlow(annotationList) {
  return Promise.all(
    annotationList.map((data) =>
      store.dispatch(
        setAnnotations(
          store
            .getState()
            .ViewDataSetReducer.projectViewDataSetModel.annotations[
              data.annotationGroup
            ].concat([data]),
          data.annotationGroup
        )
      )
    )
  );
}

/** 프로젝트 일자별 작업 진행 현황(Service Broker) 업데이트 */
export const getUpdateLayerDateInfo = async (projectId) => {
  if (!projectId) return [];

  try {
    const config = {
      params: {
        project_mng_sq: projectId,
      },
    };
    const { data } = await axios.get(`/layerDateInfo/check`, config);

    return data;
  } catch (err) {
    consoleError(err);
    return [];
  }
};

/**
 *  WMS, 오버레이 Job 진행 정보 문자열 변환
 *  Input: job_status(jobStatus), f_description(description), f_layer_nm(layerName)
 *  Output: (layerName) (BaseTile/OverviewTile: XX%)
 */
export const convertDescription = (jobStatus, description, layerName) => {
  if (
    description &&
    jobStatus === "0" &&
    description !== "Complete" &&
    description.includes("PER")
  ) {
    const progressDigit = description.split("_")[1]; // 'PER_30', 'PER_20_BT', 'PER_90_OT'
    const prefix = layerName.includes("WMS") ? "WMS" : "";
    const type = description.includes("BT") ? "BaseTile" : "OverviewTile";

    return `${
      layerName.includes("WMS") ? layerName.replace(" WMS", "") : layerName
    } ${prefix} (${type}: ${progressDigit}%)`;
  } else {
    return layerName;
  }
};
