import React, { useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPublishDataSet, setReportVisible } from "../../store/actions/report";
import Axios from "axios";
import moment from "moment";
import ReportChart from "./ReportChart";
import { Trans, useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";
import _ from "lodash";
import { setGlobalLoading } from "components/elements/LoadingBar/LoadingBar";
import "./ReportPublish.less";

const ReportPublish = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { reportReducer, selectedDate, projectSq } = useSelector(
    (state) => ({
      reportReducer: state.ReportReducer,
      selectedDate: state.ReportReducer.dataset.selectedDate,
      projectSq: state.currentProjectReducer.currentProject.project_mng_sq,
    }),
    shallowEqual
  );

  const pdfRef = useRef(null);

  const [targetImgSrc, setTargetImgSrc] = useState(null);
  const [reportResult, setReportResult] = useState({
    geoFences: [],
    analytics: [],
    volume: [],
    crossSection: [],
    annotationMark: [],
    measurementPoint: [],
  });

  const handlePrintClick = (_evt) => {
    /**
     * preview wrapper
     */
    if (!document.querySelector(".print-wrapper")) {
      const createEl = document.createElement("div");

      createEl.className = "print-wrapper";
      //print attribute
      createEl.align = "center";
      document.body.appendChild(createEl);
    }

    /**
     * migrate default chart wrapper to cross-section-print-wrap
     * created div.cross-section-print-wrap for CrossSectionDashBoard component
     */
    Array.from(document.getElementsByClassName("cross-section-print-wrap")).map((child) =>
      document.getElementById("cross-section-chart").appendChild(child)
    );

    /**
     * print start
     */
    const defaultContent = document.querySelector("#report-publish__container");
    document.querySelector(".print-wrapper").appendChild(defaultContent);
    document.querySelector("#root").style.display = "none";
    window.print();

    /**
     * print end
     */
    document.querySelector("#print-scroll").appendChild(defaultContent);
    document.querySelector("#root").style.display = "block";
  };

  const handleCloseClick = (_evt) => {
    dispatch(setReportVisible(false));
    dispatch(setPublishDataSet());
  };

  useEffect(() => {
    if (reportReducer.dataset) {
      (async () => {
        setGlobalLoading(true);
        const canvas = await html2canvas(document.querySelector(".xitecloud3d__potree_container"));
        setTargetImgSrc(canvas.toDataURL("image/png"));

        /**
         * grep selected project infomation
         */
        const { data } = await Axios.get("/api/reportData", {
          params: {
            project_mng_sq: projectSq,
            input_date: selectedDate,
            document_mng_sq: reportReducer.dataset.selectedDocSq,
          },
        });

        /**
         * config print dataset
         */
        const obj = {
          geoFences: [],
          analytics: [],
          volume: [],
          crossSection: [],
          annotationMark: [],
          measurementPoint: [],
        };

        /**
         * migrate dataset
         * keys
         *  ㄴ [geoFences:Arr, analytics:Arr, volume:Arr, crossSection:Arr, annotationMark:Arr, measurementPoint:Arr]
         * using target keys
         *  ㄴ [ c_fence_type -> [offsite, onsite, analysis], annotation_flag : [p -> point, l -> line, a -> area]]
         */
        _.map(data.report_data?.r_result_array, (x) => {
          let objectKey = "";
          if (x.c_fence_type === "offsite") {
            objectKey = "geoFences";
          } else {
            switch (x.f_fence_annotation_flag) {
              case "p0":
                objectKey = "measurementPoint";
                break;
              case "p2":
                objectKey = "annotationMark";
                break;
              case "l2":
                objectKey = "crossSection";
                break;
              case "a2":
                objectKey = "volume";
                break;
              default:
                objectKey = "analytics";
                break;
            }
          }

          return obj[objectKey].push(x);
        });

        /**
         * dataset -> useState(reportResult) -> react re-render -> view
         */
        setReportResult(obj);

        setGlobalLoading(false);
      })();
    }
  }, [reportReducer.dataset]);

  return (
    <div id="print-scroll">
      <section id="report-publish__container">
        <header>
          <span onClick={handlePrintClick}>{t("button$Print")}</span>
          <span onClick={handleCloseClick}>X</span>
        </header>
        <section>
          <main ref={pdfRef}>
            <h2>{reportReducer.dataset.reportName}</h2>
            <p>
              {t("title$Created_Date")} : {moment().format("YYYY/MM/DD")}
            </p>
            <div className="imgbox">
              <img src={targetImgSrc} alt="3d model" />
            </div>
            {reportResult.geoFences.length > 0 && (
              <article className="geo-fences-section">
                <h4>{capitalizeFirstLetter(t("title$Geo-fences"))}</h4>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{capitalizeFirstLetter(t("column$Label"), { count: 1 })}</th>
                      <th>{t("column$Geofence_Name")}</th>
                      <th>{capitalizeFirstLetter(t("column$Type", { count: 1 }))}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(reportResult.geoFences, (geo, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>\</td>
                        <td>{t("column$Cut_A_to_Disposal_Area")}</td>
                        <td>{t("column$Hauling_Route(off-site)")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
            )}
            {reportResult.measurementPoint.length > 0 && (
              <article className="analytics-section">
                <h4>{capitalizeFirstLetter(t("title$Analytic", { count: 2 }))}</h4>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{capitalizeFirstLetter(t("column$Label", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Category", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Name"))}</th>
                      <th>{t("column$Altitude_of_each_layer")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(reportResult.measurementPoint, (point, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>\</td>
                        <td>{t("column$Cut_A_to_Disposal_Area")}</td>
                        <td>{point.f_fence_nm}</td>
                        <td>
                          <ol>
                            {_.map(point.result_data, (layer, i) => (
                              <li key={i}>{`${layer.layer_name}_${layer.z}m`}</li>
                            ))}
                          </ol>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
            )}
            {reportResult.analytics.length > 0 && (
              <article className="analytics-section">
                <h4>{capitalizeFirstLetter(t("title$Analytic", { count: 2 }))}</h4>
                <table className="analytics-measurements">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{capitalizeFirstLetter(t("column$Label", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Category", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Name"))}</th>
                      <th>
                        {capitalizeFirstLetter(t("column$Horizontal"))} <br />
                        {capitalizeFirstLetter(t("column$Length"))}
                      </th>
                      <th>
                        {capitalizeFirstLetter(t("column$Surface"))} <br />
                        {capitalizeFirstLetter(t("column$Length"))}
                      </th>
                      <th>{capitalizeFirstLetter(t("column$Elevation"))}</th>
                      <th>
                        <Trans i18nKey="html$Report analytic column">
                          Gradient <br />
                          (per segment)
                        </Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(reportResult.analytics, (measure, index) =>
                      measure.c_annotation_type === "area" ? (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>\</td>
                          <td>
                            {capitalizeFirstLetter(
                              t("column$%{type} Measurement", {
                                type: measure.c_annotation_type,
                              })
                            )}
                          </td>
                          <td>{measure.f_fence_nm}</td>
                          <td>{measure.h_area}</td>
                          <td>{measure.s_length}</td>
                          <td>
                            <ul>
                              <li>{measure.zmax}</li>
                              <li>{measure.zmin}</li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li>{measure.ang_rate_max}</li>
                              <li>{measure.ang_rate_min}</li>
                            </ul>
                          </td>
                        </tr>
                      ) : (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>\</td>
                          <td>
                            {capitalizeFirstLetter(
                              t("column$%{type} Measurement", {
                                type: measure.c_annotation_type,
                              })
                            )}
                          </td>
                          <td>{measure.f_fence_nm}</td>
                          <td>{measure.result_data[0].h_length.toFixed(2)}</td>
                          <td>{measure.result_data[0].s_length.toFixed(2)}</td>
                          <td>
                            {_.map(measure.result_data, (res, resIndex) => (
                              <ul key={resIndex}>
                                <li>{res.zmax.toFixed(2)}</li>
                                <li>{res.zmin.toFixed(2)}</li>
                              </ul>
                            ))}
                          </td>
                          <td>
                            {_.map(measure.result_data, (res, resIndex) => (
                              <ul key={resIndex}>
                                <li>{res.ang_rate_max.toFixed(2)}</li>
                                <li>{res.ang_rate_min.toFixed(2)}</li>
                              </ul>
                            ))}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </article>
            )}
            {_.map(reportResult.crossSection, (cross, index) => (
              <article key={index} className="analytics-section">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{capitalizeFirstLetter(t("column$Label", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Category", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Name"))}</th>
                      <th>{capitalizeFirstLetter(t("column$Section"))}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{index + 1}</td>
                      <td>/</td>
                      <td>{t("column$Section_Analysis")}</td>
                      <td>{cross.f_fence_nm}</td>
                      <td>
                        <ul>
                          <li>{t("title$Longitudinal_Section")}</li>
                          <li>{t("title$Typical_Cross_Section")}</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {cross.result_data && <ReportChart data={cross.result_data} />}
              </article>
            ))}
            <div id="cross-section-chart"></div>
            {_.map(reportResult.volume, (volumeItem, index) => (
              <article className="analytics-section" key={index}>
                <table className="analytics-volume">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{capitalizeFirstLetter(t("column$Label", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Category", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Name"))}</th>
                      <th>
                        <Trans i18nKey="html$Report column header Overall Estimation">
                          Overall
                          <br />
                          Estimation
                        </Trans>
                      </th>
                      <th>
                        <Trans i18nKey="html$Report column header Sub-Layer Estimation for CutQuantity">
                          Sub-Layer Estimation
                          <br />
                          for Cut Quantity
                        </Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(volumeItem.result_data, (result, trTndex) => (
                      <tr key={trTndex}>
                        <td>{index + 1}</td>
                        <td>ㅇ</td>
                        <td>{result.rock_type}</td>
                        <td>{volumeItem.f_fence_nm}</td>
                        <td>
                          <ul>
                            <li>{t("title$Fill_%{result}", { result: result.vol_fill })}</li>
                            <li>{t("title$Area_%{result}", { result: result.area })}</li>
                            <li>{t("title$Cut_%{result}", { result: result.vol_cut })}</li>
                          </ul>
                        </td>
                        <td>{result.srcname}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
            ))}
            {_.map(reportResult.annotationMark, (annotationMark, index) => (
              <article className="annotation-section" key={index}>
                <h4>{t("title$Annotation_Mark")}</h4>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{capitalizeFirstLetter(t("column$Label", { count: 1 }))}</th>
                      <th>{capitalizeFirstLetter(t("column$Name"))}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>!</td>
                      <td>{annotationMark.f_fence_nm}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="description">
                  <h6>- {capitalizeFirstLetter(t("title$Description"))}</h6>
                  <p>{annotationMark.f_description}</p>
                </div>
              </article>
            ))}
          </main>
        </section>
      </section>
    </div>
  );
};

export default ReportPublish;
