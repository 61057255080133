import Axios from "axios"
import { setReportFolder, setReportList, setReportUnGroups } from "../../../store/actions/report";
import { store } from "../../../store/store";

/**
 * Report API
 */
async function getReportAll() {
    try {
        const projectId = store.getState().currentProjectReducer.currentProject.project_mng_sq
        const reports = await Axios.get("/report", {
            params: {
                projectId,
            },
        });
        return reports;
    } catch (err) {
        return err;
    }
}

/**
 * Report Folder API
 */
async function getReportFolderAll() {
    try {
        const projectId = store.getState().currentProjectReducer.currentProject.project_mng_sq
        const folders = await Axios.get(`/reportFolder/${projectId}`);
        return folders;
    } catch (err) {
        return err;
    }
}

export async function configReportAndFolder() {
    try {
        const [allProjects, folders] = await Promise.all(
            [getReportAll(), getReportFolderAll()]
        )

        /**
           * grep doc group items
           */
        store.dispatch(setReportFolder(
            folders.data.map((folder) => ({
                ...folder,
                projects: allProjects.data.filter(
                    (x) =>
                        x.document_group_sq_t_document_group === folder.document_group_sq
                ),
            }))
        ));

        /**
         * grep doc items belong to unGroup
         */
        store.dispatch(setReportUnGroups(
            allProjects.data.filter(
                (x) => !x.document_group_sq_t_document_group
            )
        ));

        store.dispatch(setReportList(allProjects.data, "document_mng_sq"));
        return;
    } catch (err) {
        return err;
    }
}