import React from "react";
import moment from "moment";
import IconDown from "../../../../../assets/icons-2/Icon_Download.svg";
import {
  // axiosForChat,
  CHAT_SERVER_PATH,
} from "../env";
const FileDownload = (props) => {
  return (
    <span className="msg-file-wrapper">
      <p>{props.item.file.name}</p>
      <p>
        Expiry: ~
        {moment(props.item.file.created)
          .add(1, "M")
          .format("YYYY/MM/DD")}
      </p>
      {/* <p>Size: {formatBytes(props.item.file.size)}</p> */}
      <div>
        <a
          className="font-size12"
          onClick={() =>
            window.open(
              CHAT_SERVER_PATH + `fileDownload?fileId=${props.item.file.sq}`
            )
          }
        >
          Save /
        </a>
        <a className="font-size12">
          {/* SaveAs */}
        </a>
        <div>
          <img className="icon-download" src={IconDown} alt="download icon" />
        </div>
      </div>
    </span>
  );
};

// function formatBytes(bytes, decimals = 2) {
//     if (bytes === 0) return '0 Bytes';

//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

//     const i = Math.floor(Math.log(bytes) / Math.log(k));

//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
//   }

export default React.memo(FileDownload);
