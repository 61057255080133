import React, { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { setWorkspaceVisible } from "../../store/actions/projectList";
import "./index.less";
import PointCloud from "../../views/pointCloud";
import NewDocument from "../../views/NewDocument/NewDocument";
import Documents from "../../views/documents/Documents";
import Arrow from "../../components/elements/Arrow/Arrow";
import { capitalizeFirstLetter, combineClassName } from "utils/StringHelper";

const ErrorPage = React.lazy(() => import("../../views/errorPages/errorPages"));
const Fleet = React.lazy(() => import("../../views/Fleet/Fleet"));
const Home = React.lazy(() => import("../../views/home/template"));
const Project = React.lazy(() => import("../../views/project/template"));
const NewProject = React.lazy(() => import("../../views/newProject/index"));
const CreateFleet = React.lazy(() => import("../../views/Fleet/createAndUpdate/CreateFleet"));
const SectionCommit = React.lazy(() => import("../../views/Section/SectionCommit"));
const SectionDesign = React.lazy(() => import("../../views/Section/SectionDesign"));
const SectionLineViewer = React.lazy(() => import("../../views/Section/SectionLineViewer"));

function ProjectRoutes(props) {
  const { t } = useTranslation();
  const workspaceVisible = useSelector((state) => state.projectReducer.workspaceVisible);
  const cssStyle = {
    Button: {
      display: workspaceVisible ? "none" : "block",
    },
    Arrow: {
      width: "10px",
      height: "10px",
      position: "absolute",
      top: "10px",
      right: "10px",
      opacity: 0.4,
    },
  };

  const dispatch = useDispatch();
  const onActiveWorkspace = () => dispatch(setWorkspaceVisible());
  return (
    <Suspense fallback={<div>{t("message$loading")}</div>}>
      <Button onClick={onActiveWorkspace} className="dashbord-active-btn" style={cssStyle.Button}>
        {capitalizeFirstLetter(t("button$Workspace"))} <Arrow style={cssStyle.Arrow} />
      </Button>
      <MemoRoutes display={workspaceVisible} />
    </Suspense>
  );
}

const MemoRoutes = React.memo(function ProjectRoutesList(props) {
  const c_user_type = useSelector((state) => state.appState.user.c_user_type);
  const extra = (routeProps, route) => {
    return route.requireAuth ? (
      <route.component {...routeProps} />
    ) : (
      // c_user_type !== 'USR003' ?   : <ErrorPage {...routeProps} />
      <route.component {...routeProps} />
    );
  };

  return (
    <div className={combineClassName("workspace-wrapper", props.display ? "visible" : "hidden")}>
      <Switch>
        {/* 현재 View */}
        {CONFIG_PROJECT_ROUTES.map((route, index) => {
          return (
            <Route
              path={route.path}
              exact={route.exact}
              key={index}
              render={(routeProps) => extra(routeProps, route)}
            />
          );
        })}
      </Switch>
    </div>
  );
});

const CONFIG_PROJECT_ROUTES = [
  {
    path: "/",
    component: Home,
    exact: true,
    requireAuth: false,
  },
  {
    path: "/projects/show/:projectId",
    component: Project,
    requireAuth: true,
  },
  {
    path: "/projects/documents/create/:projectId",
    component: NewDocument,
    requireAuth: true,
  },
  {
    path: "/projects/create",
    component: NewProject,
    requireAuth: true,
  },
  {
    path: "/projects/edit/:projectId",
    component: NewProject,
    requireAuth: true,
  },
  {
    path: "/projects/pointcloud/:projectId",
    component: PointCloud,
    requireAuth: true,
  },
  {
    path: "/projects/documents/:projectId",
    component: Documents,
    requireAuth: true,
  },
  {
    path: "/fleet/:projectId",
    exact: true,
    component: Fleet,
    requireAuth: true,
  },
  {
    path: "/section/commit",
    component: SectionCommit,
    requireAuth: true,
  },
  {
    path: "/section/design",
    component: SectionDesign,
    requireAuth: true,
  },
  {
    path: "*",
    component: ErrorPage,
  },
];

// export default connect((state) => state, {  })(ProjectRoutes);
export default ProjectRoutes;
