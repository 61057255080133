import { Button, Modal, Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.less";
import { connect } from "react-redux";
import { setProjectSelectedDateSet } from "../../../../store/actions/currentProject"; // 선택 날짜 store
import moment from "moment"; // 날짜 포맷 라이브러리
import { capitalizeFirstLetter } from "utils/StringHelper";

const { Option } = Select;

const TempTables = (props) => {
  const { t } = useTranslation();
  // redux로 받아온 날짜 데이터 포맷 변경
  let select_date = moment(props.date.currentProjectReducer.selectedDate).format(t("YYYY년M월D일"));
  const [chk, setChk] = useState(true);
  let columns = [];

  // 총괄표, station별 노출되는 컬럼 변경
  // true = 총괄표, false = station
  if (chk) {
    columns = [
      {
        title: t("column$Station"),
        dataIndex: "station_name",
        key: "station_name",
        width: 130,
        fixed: "left",
      },
      {
        title: t("column$총연장_(Distance)"),
        dataIndex: "distance",
        key: "distance",
        width: 130,
        fixed: true,
        render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
      },
      {
        title: t("column$절토"),
        children: [
          {
            title: t("column$토사"),
            children: [
              {
                title: select_date,
                width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_0_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_0_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_0_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                width: 130,
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_0_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$풍화암"),
            children: [
              {
                title: select_date,
                // width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_1_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_1_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_1_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_1_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$연암"),
            children: [
              {
                title: select_date,
                // width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_2_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_2_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_2_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_2_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$보통암"),
            children: [
              {
                title: select_date,
                // width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_3_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_3_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_3_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_3_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$계"),
            children: [
              {
                // title: "2020년 12월", // 기존 날짜 하드코딩 -> redux 사용으로 변경.
                title: moment(props.date.currentProjectReducer.selectedDate).format("YYYY년 M월"),
                width: 100,
                dataIndex: "c_rock_vol_sum",
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$설계_물량"),
                dataIndex: "b_rock_vol_sum",
                width: 100,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$잔여량"),
                // width: 130,
                dataIndex: "rock_remain",
                width: 100,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$진도율"),
                dataIndex: "rock_percente",
                width: 100,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
            ],
          },
        ],
      },
      {
        title: t("column$성토"),
        children: [
          {
            title: t("column$비다짐"),
            children: [
              {
                title: select_date,
                // width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "c_fill_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_fill_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V/C",
                    dataIndex: "c_fill_vol_c",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_fill_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$계"),
            children: [
              {
                // title: "2020년 12월", // 기존 날짜 하드코딩 -> redux 사용으로 변경.
                title: moment(props.date.currentProjectReducer.selectedDate).format("YYYY년 M월"),
                width: 100,
                dataIndex: "c_fill_vol_sum",
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$설계_물량"),
                dataIndex: "b_fill_vol_sum",
                width: 80,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$잔여량"),
                // width: 130,
                dataIndex: "fill_remain",
                width: 80,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$진도율"),
                // width: 130,
                dataIndex: "fill_percente",
                width: 80,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
            ],
          },
        ],
      },
    ];
  } else {
    columns = [
      {
        title: `${t("column$측점")} (Station)`,
        dataIndex: "station_name",
        key: "station_name",
        width: 130,
        fixed: "left",
      },
      {
        title: `${t("column$Distance")}`,
        dataIndex: "distance",
        key: "distance",
        width: 130,
        fixed: "left",
        render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
      },
      {
        title: t("column$절토"),
        children: [
          {
            title: t("column$토사"),
            children: [
              {
                title: select_date,
                width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_0_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_0_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_0_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                width: 130,
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_0_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$풍화암"),
            children: [
              {
                title: select_date,
                width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_1_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_1_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_1_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_1_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$연암"),
            children: [
              {
                title: select_date,
                width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_2_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_2_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_2_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_2_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$보통암"),
            children: [
              {
                title: select_date,
                // width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "cur_rock_3_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_rock_3_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V*C",
                    dataIndex: "c_rock_3_c_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_rock_3_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: t("column$성토"),
        children: [
          {
            title: t("column$비다짐"),
            children: [
              {
                title: select_date,
                // width: 230,
                children: [
                  {
                    title: "A",
                    dataIndex: "c_fill_area",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V",
                    dataIndex: "c_fill_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                  {
                    title: "V/C",
                    dataIndex: "c_fill_vol_c",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
              {
                title: t("column$설계_물량"),
                children: [
                  {
                    title: "V0",
                    dataIndex: "b_fill_vol",
                    width: 80,
                    align: "center",
                    render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
                  },
                ],
              },
            ],
          },
          {
            title: t("column$계"),
            children: [
              {
                // title: "2020년 12월", // 기존 날짜 하드코딩 -> redux 사용으로 변경.
                title: moment(props.date.currentProjectReducer.selectedDate).format("YYYY년 M월"),
                width: 100,
                dataIndex: "c_fill_vol_sum",
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
              {
                title: t("column$설계_물량"),
                dataIndex: "b_fill_vol_sum",
                width: 100,
                align: "center",
                render: (rowdata) => <div style={{ textAlign: "right" }}>{rowdata}</div>,
              },
            ],
          },
        ],
      },
    ];
  }

  const [visible, setvisible] = useState(false);
  const [orignaltableRows, orignalsettableRows] = useState([]);
  const [convertedTableRows, setconvertedTableRows] = useState({});
  const [selectedTableRows, setselectedTableRows] = useState([]);

  // excel export(출력)
  // 서버에서 엑셀파일을 생성해서 넘겨주는 로직
  const onDataViewer = () => {
    let maxlength = props.date.currentProjectReducer.dateSet["length"] - 1;

    function getPredate() {
      let index = props.date.currentProjectReducer.dateSet.findIndex((c) => {
        return c.f_input_date === props.date.currentProjectReducer.selectedDate;
      });

      if (maxlength === index) {
        return props.date.currentProjectReducer.dateSet[maxlength].f_input_date;
      } else {
        return props.date.currentProjectReducer.dateSet[index + 1].f_input_date;
      }
    }

    let baseDate = props.date.currentProjectReducer.dateSet[maxlength].f_input_date;
    let preDate = getPredate();

    Axios.get(
      "/api/compareSectionSummary?project_mng_sq=" +
        props.date.currentProjectReducer.currentProject.project_mng_sq +
        "&baseDate=" +
        baseDate +
        "&preDate=" +
        preDate +
        "&curDate=" +
        props.date.currentProjectReducer.selectedDate,
      {
        headers: {
          "Content-Disposition": "attachment; filename=compareSectionSummary.xlsx",
          "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }
    ).then((response) => {
      const bloburl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = bloburl;
      link.setAttribute("download", "compareSectionSummary.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(bloburl);
    });
  };
  // window.open(
  //   "http://192.168.0.101:3030/api/compareSectionSummary?project_mng_sq=46&baseDate=20200701&preDate=20200701&curDate=20201125",
  //   "_blank"
  // );

  const onModal = () => {
    setvisible(!visible);
  };

  // 데이터 조회
  const grepData = () => {
    let maxlength = props.date.currentProjectReducer.dateSet["length"] - 1;

    function getPredate() {
      let index = props.date.currentProjectReducer.dateSet.findIndex((c) => {
        return c.f_input_date === props.date.currentProjectReducer.selectedDate;
      });

      if (maxlength === index) {
        return props.date.currentProjectReducer.dateSet[maxlength].f_input_date;
      } else {
        return props.date.currentProjectReducer.dateSet[index + 1].f_input_date;
      }
    }

    let baseDate = props.date.currentProjectReducer.dateSet[maxlength].f_input_date;
    let preDate = getPredate();

    (async () => {
      const { data } = await Axios.get(
        "/react-api/compareSectionSummary?project_mng_sq=" +
          props.date.currentProjectReducer.currentProject.project_mng_sq +
          "&baseDate=" +
          baseDate +
          "&preDate=" +
          preDate +
          "&curDate=" +
          props.date.currentProjectReducer.selectedDate
      );
      //   console.log(data);

      orignalsettableRows(data);
      onModal();
    })();
  };

  useEffect(() => {
    const temp = {};
    temp[t("title$총괄표")] = [{ temp: "temp" }]; // 셀렉트박스 최상단에 총괄표가 있게하기 위해 미리 생성

    for (let i = 0; i < orignaltableRows.length; i++) {
      if (temp[orignaltableRows[i].fence_nm]) {
        temp[orignaltableRows[i].fence_nm].push(orignaltableRows[i]);
      } else {
        if (i !== orignaltableRows.length - 1) {
          temp[orignaltableRows[i].fence_nm] = [orignaltableRows[i]];
        }
      }

      // 소계 데이터 input
      if (orignaltableRows[i].station_name === "소 계" && orignaltableRows[i].st_idx === null) {
        // 총계 row는 하기 데이터만 들어가게 하기 위한 로직
        // if(orignaltableRows[i].fence_nm == "총계"){

        //   let _total = {'station_name':"총계"
        //   ,'distance':orignaltableRows[i].distance
        //   ,'cur_rock_0_area':orignaltableRows[i].cur_rock_0_area
        //   ,'c_rock_0_vol': orignaltableRows[i].c_rock_0_vol
        //   ,'c_rock_0_c_vol': orignaltableRows[i].c_rock_0_c_vol
        //   ,'b_rock_0_vol': orignaltableRows[i].b_rock_0_vol
        //   ,'remain': orignaltableRows[i].remain
        //   ,'percente': orignaltableRows[i].percente };

        //   temp["총괄표"].push(_total);

        // }
        // else{
        orignaltableRows[i]["station_name"] = orignaltableRows[i]["fence_nm"];
        temp[t("title$총괄표")].push(orignaltableRows[i]);
        // }
      }
    }
    // 총괄표의 첫 번째(temp) 배열 삭제
    temp[t("title$총괄표")].shift();

    setconvertedTableRows(temp);

    if (temp[Object.keys(temp)[0]]) {
      setselectedTableRows(
        temp[Object.keys(temp)[0]].map((item, i) => {
          item.key = i;
          return item;
        })
      );
    }
  }, [orignaltableRows]);

  const handleChange = (keyName) => {
    setselectedTableRows(
      convertedTableRows[keyName].map((item, i) => {
        item.key = i;
        return item;
      })
    );

    if (keyName === t("title$총괄표")) {
      setChk(true);
    } else {
      setChk(false);
    }
  };

  return (
    <>
      <Button type="primary" id="temp-btn" onClick={grepData}>
        {t("button$토적표")}
      </Button>
      <Modal
        title={t("title$토적표")}
        visible={visible}
        onOk={onModal}
        width={1200}
        onCancel={onModal}
        wrapClassName="excel-wrapper"
        footer={[
          <Button type="primary" onClick={onDataViewer} key="excel download">
            {capitalizeFirstLetter(t("button$Excel_Download"))}
          </Button>,
          <Button type="white" onClick={onModal} key="close">
            {capitalizeFirstLetter(t("button$Close"))}
          </Button>,
        ]}
      >
        <div
          style={{
            textAlign: "right",
            margin: "4px 0",
          }}
        >
          <Select
            defaultValue={
              Object.keys(convertedTableRows).length ? Object.keys(convertedTableRows)[0] : null
            }
            style={{ width: 300 }}
            onChange={handleChange}
          >
            {Object.keys(convertedTableRows).map((keyName) => (
              <Option value={keyName} key={keyName}>
                {keyName}
              </Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            width: "100%",
            overflow: "auto",
          }}
        >
          {chk ? (
            <div className="table-wrapper">
              <Table
                columns={columns}
                dataSource={selectedTableRows}
                bordered
                pagination={false}
                size="middle"
                scroll={{ x: false, y: 400 }}
              />
            </div>
          ) : (
            <div className="table-wrapper1">
              <Table
                columns={columns}
                dataSource={selectedTableRows}
                bordered
                pagination={false}
                size="middle"
                scroll={{ x: false, y: 400 }}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

// export default TempTables;
// redux 사용을 위해 connect 사용.
export default connect(setProjectSelectedDateSet)(TempTables);
