//types
export const USER = "APP/USER";
export const VIEW = "APP/VIEW";
export const IS_SHOW = "APP/XHR_IS_SHOW";
export const Language = "APP/LANGUAGE";

export const LOGIN = "APP/LOGIN";
export const LOGIN_SUCCESS = "APP/LOGIN_SUCCESS";
export const LOGIN_ERROR = "APP/LOGIN_ERROR";
export const LOGIN_TIME = "APP/LOGIN_TIME";

//setter
export const login = (userLogin) => ({ type: LOGIN, userLogin });
export const setUser = (user) => ({ type: USER, user });
export const setView = (view) => ({ type: VIEW, view });
export const setIsShow = (isShow) => ({ type: IS_SHOW, isShow });
export const changeLanguage = (lg) => {
  let lang = "";

  if (lg === "en") lang = "ko";
  if (lg === "ko") lang = "en";

  return { type: Language, lang };
};

export const ADD_HTTP_LIST = "PROGRESS/ADD/HTTP_LIST";
export const REMOVE_HTTP_LIST = "PROGRESS/REMOVE/HTTP_LIST";

export const setAddRequestList = (requestConfig) => ({ type: ADD_HTTP_LIST, requestConfig });
export const setRemoveRequestList = (requestConfig) => ({ type: REMOVE_HTTP_LIST, requestConfig });

export const setLoginTime = (loginTime) => ({ type: LOGIN_TIME, loginTime });
