import React from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";

//interface
// const properties = [
//   {
//     title: "Horizontal Length",
//     value: "000",
//     key: 0,
//   },
//   {
//     title: "Surface Length",
//     value: "000",
//     key: 1,
//   },
//   {
//     title: "Elevation",
//     value: ["Max", "Min"],
//     key: 2,
//   },
//   {
//     title: `Gradient`,
//     value: ["Max", "Min"],
//     key: 3,
//   },
// ];

const AnnotationProperties = (props) => {
  const { t } = useTranslation();
  const labels = [t("format$Max"), t("format$Min")];
  const labelForLine = [t("format$Degree", { count: 1 }), t("format$Rate", { count: 1 })];
  const getHeight = (item) => {
    if (item.title.includes(t("title$Slope"))) {
      return "25px";
    }

    if (item.key > 2 || item.isLine || item.visibility || item.title === t("title$Elevation")) {
      return "50px";
    }
  };
  return (
    <>
      {props.properties.map((prop, index) => (
        <div
          className={`annotation-properties-wrap`}
          key={index}
          style={{
            height: getHeight(prop),
          }}
        >
          {prop.title}
          <span>
            {typeof prop.value === "object" ? (
              prop.value.map((x, i) => {
                return (
                  <label key={i}>
                    {prop.isLine ? labelForLine[i] : labels[i]}
                    <Input size="small" readOnly value={x} />
                  </label>
                );
              })
            ) : (
              <Input
                size="small"
                readOnly
                style={{
                  visibility: prop.visibility ? "hidden" : "unset",
                }}
                value={prop.value}
              />
            )}
          </span>
        </div>
      ))}
    </>
  );
};

export default AnnotationProperties;
