import React from "react";
import { Select } from "antd";
import "./index.less";

const { Option } = Select;

// default selectbox width 100%
// required props - items
// optional props - label, default, width
class SelectBox extends React.Component {
  render() {
    return (
      <div className="select-box group ">
        {this.props.label && <label className="select-box__label">{this.props.label}</label>}
        <Select
          // value={this.props.value}
          className="select-box__select acumin"
          disabled={this.props.disabled}
          // onSelect={ (e) => this.props.default}
          onChange={(e) => this.props.select(e)}
          value={this.props.default}
          style={{ width: this.props.width }}
        >
          {this.props.items &&
            this.props.items.map((x, i) => {
              return typeof x === "object" ? (
                <Option value={x.key} key={i} data-set-value={x.key}>
                  {x.name}
                </Option>
              ) : (
                <Option value={typeof x === "string" ? x : i} key={i}>
                  {x}
                </Option>
              );
            })}
        </Select>
      </div>
    );
  }
}

export default SelectBox;
