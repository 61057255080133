import React, { useState } from "react";
import { Table, Input } from "antd";
import { setTypes } from "../../../store/actions/rockTypes";
import { setSubSurface } from "../../../store/actions/uploadModel";
import { connect } from "react-redux";
import Axios from "axios";
import iconPlus from "../../../assets/icons-2/Icon_Plus-Rectangle.svg";
import { removeItem } from "./utils";
import TableAction from "./childrens/TableAction";
import { SketchPicker } from "react-color";
import PickColorUI from "./childrens/PickColorUI";
import { consoleError } from "../../../middleware";
import { setRockTypeColorList } from "../../../store/actions/borehole";
import { defaultColors } from "./childrens/env";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils/StringHelper";
const { Column } = Table;

function ModalTypeTable(props) {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState("#fff");
  const [target, settarget] = useState(null);
  const [colorVisible, setcolorVisible] = useState(false);

  const onEdit = (e, record, propertyKeyName) => {
    props.BoreHoleReducer.rockTypeColorRows.find((item) => item.key === record.key)[
      propertyKeyName
    ] = e.target.value;
    props.setRockTypeColorList([].concat(props.BoreHoleReducer.rockTypeColorRows));
  };

  const [addRowActive, setAddRowActive] = useState([]);

  const [createCount, setCreateCount] = useState(0);
  const addRow = () => {
    if (addRowActive.length < 1) {
      let color = "";
      if (props.BoreHoleReducer.rockTypeColorRows.length <= defaultColors.length) {
        color = defaultColors[props.BoreHoleReducer.rockTypeColorRows.length];
      }

      props.setRockTypeColorList(
        [
          {
            key: `create_${createCount}`,
            rockTypeName: "",
            colors: color,
            editable: true,
          },
        ].concat(props.BoreHoleReducer.rockTypeColorRows)
      );

      settarget(`create_${createCount}`);
      setcolorVisible(!colorVisible);
      setCreateCount(createCount + 1);
      setAddRowActive([createCount].concat(addRowActive));
    }
  };

  const activeEdit = (record, bool) => {
    (async () => {
      let temp = [];
      if (bool === false) {
        const reqPath = record.sqKey ? `/boreholeType/${record.sqKey}` : "/boreholeType";

        const { data } = await Axios.post(reqPath, {
          project_mng_sq: props.currentProjectReducer.currentProject.project_mng_sq,
          rockName: record.rockTypeName,
          color: record.colors,
          typeSq: record.sqKey,
        });

        if (!record.sqKey) {
          temp = data.map((x) => ({
            key: x.bh_rock_type_sq,
            sqKey: x.bh_rock_type_sq,
            rockTypeName: x.f_rock_type_nm,
            colors: x.f_rock_type_color,
            editable: false,
          }));

          props.BoreHoleReducer.rockTypeColorRows = props.BoreHoleReducer.rockTypeColorRows.filter(
            (x) => x.key !== record.key
          );
        }

        initColorPicker();
      }

      if (bool === true) {
        if (addRowActive.length === 1) return;
        setAddRowActive([record.key].concat(addRowActive));
      }

      settarget(record.key);
      setcolorVisible(!colorVisible);

      if (props.BoreHoleReducer.rockTypeColorRows.find((x) => x.key === record.key)) {
        props.BoreHoleReducer.rockTypeColorRows.find((x) => x.key === record.key).editable = bool;
      }

      props.setRockTypeColorList(temp.concat(props.BoreHoleReducer.rockTypeColorRows));
    })().catch(consoleError);
  };

  const handleDelete = (key) => {
    removeItem({
      onOk: () => {
        // console.log(key);
        // debugger;
        props.setRockTypeColorList(
          props.BoreHoleReducer.rockTypeColorRows.filter((item) => item.key !== key)
        );
      },
      onCancel() {},
    });
  };

  const onCancel = (key) => {
    if (`${key}`.indexOf("create_") > -1) {
      props.setRockTypeColorList(
        props.BoreHoleReducer.rockTypeColorRows.filter((x) => x.key !== key)
      );
    } else {
      props.BoreHoleReducer.rockTypeColorRows.find((x) => x.key === key).editable = false;
      props.setRockTypeColorList([].concat(props.BoreHoleReducer.rockTypeColorRows));
    }

    initColorPicker();
  };

  const handleChangeComplete = (color) => {
    props.BoreHoleReducer.rockTypeColorRows.find((item) => item.key === target).colors = color.hex;
    props.setRockTypeColorList([].concat(props.BoreHoleReducer.rockTypeColorRows));
    setSelectedColor(color.hex);
  };

  const initColorPicker = () => {
    setAddRowActive([]);
    settarget(null);
    setcolorVisible(!colorVisible);
  };

  const onInputDoubleClick = (record) => {
    settarget(record.key);
    setcolorVisible(!colorVisible);
  };
  return (
    <>
      <img
        className="add-row"
        onClick={addRow}
        src={iconPlus}
        style={{ width: "13px", height: "13px" }}
        alt="add icon"
      />
      <Table
        size="small"
        dataSource={props.BoreHoleReducer.rockTypeColorRows}
        bordered={true}
        pagination={false}
        scroll={{ y: 150 }}
      >
        <Column
          title={t("column$Soil_Type_Name")}
          dataIndex="rockTypeName"
          key="rockTypeName"
          render={(text, record) =>
            record.editable ? (
              <Input
                size="small"
                onChange={(e) => onEdit(e, record, "rockTypeName")}
                value={text}
              />
            ) : (
              text
            )
          }
        />

        <Column
          title={capitalizeFirstLetter(t("column$Color"))}
          dataIndex="colors"
          key="colors"
          render={(text, record) =>
            record.editable ? (
              <Input
                onDoubleClick={() => onInputDoubleClick(record)}
                size="small"
                onChange={(e) => onEdit(e, record, "colors")}
                value={text}
              />
            ) : (
              <PickColorUI text={text} />
            )
          }
        />
        <Column
          title={capitalizeFirstLetter(t("column$Edit"))}
          key="action"
          render={(text, record) => (
            <TableAction
              record={record}
              activeEdit={activeEdit}
              onCancel={onCancel}
              handleDelete={handleDelete}
            />
          )}
        />
      </Table>
      {colorVisible && (
        <span>
          <SketchPicker color={selectedColor} onChange={handleChangeComplete} />
        </span>
      )}
    </>
  );
}

// class ModalTypeTableBack extends React.Component {
//     state = {
//         visible: false,
//         target: null,
//         selectedColor: '#fff'
//     }
//     addRow = () => {
//         const reducer = this.props.rockTypeReducer;
//         const { rockTypes, count } = reducer;

//         const newData = {
//             key: `${count + 1}`,
//             rockTypeName: '',
//             colors: '',
//             editable: true
//         }

//         reducer.count = count + 1;
//         reducer.rockTypes = rockTypes.concat([newData]);

//         this.props.setTypes(reducer.rockTypes);
//     }

//     onEdit = (e, record, propertyKeyName) => {
//         const reducer = this.props.rockTypeReducer.rockTypes;
//         let value = e.target.value;
//         if (propertyKeyName === 'colors') {
//             value = e.target.value.indexOf('#') === -1 ? '#' + e.target.value : e.target.value;
//         }
//         reducer.filter(item => item.key === record.key)[0][propertyKeyName] = value;
//         this.props.setTypes(reducer);
//     }

//     activeEdit = async (record, bool, title) => {
//         const reducer = this.props.rockTypeReducer;

//         if (bool === false) {
//             // reducer.splice(reducer.indexOf(record), 1)
//             try {
//                 const reqPath = record.sqKey ? `/boreholeType/${record.sqKey}` : '/boreholeType';
//                 const { data } = await Axios.post(reqPath, {
//                     project_mng_sq: this.props.currentProjectReducer.currentProject.project_mng_sq,
//                     rockName: record.rockTypeName,
//                     color: record.colors,
//                     typeSq: record.sqKey
//                 })

//                 const res = await Axios.get(`/boreholeType/${this.props.currentProjectReducer.currentProject.project_mng_sq}`);

//                 reducer.rockTypes = res.data.map((x, index) => ({
//                     key: `${index}`,
//                     sqKey: x.bh_rock_type_sq,
//                     rockTypeName: x.f_rock_type_nm,
//                     colors: x.f_rock_type_color,
//                     editable: false
//                 }))

//                 reducer.count = res.data.length;

//             } catch (err) {
//                 consoleError(err);
//             }
//         } else {

//             reducer.rockTypes.filter(item => item.key === record.key)[0].editable = bool;
//         }

//         this.props.setTypes(reducer.rockTypes)
//     }

//     handleDelete = key => {
//         removeItem({
//             onOk: () => {
//                 this.props.setTypes(this.props.rockTypeReducer.rockTypes.filter(item => item.key !== key));
//             },
//             onCancel() { }
//         })
//     }

//     onCancel = (key) => {
//         const target = this.props.rockTypeReducer.rockTypes.filter(item => item.key === key);
//         target[0].editable = !target[0].editable
//         this.props.setTypes(this.props.rockTypeReducer.rockTypes);
//     }

//     handleChangeComplete = (color) => {
//         this.setState({ selectedColor: color.hex });
//     };

//     onDoubleClickClose = () => {
//         this.setState(prev => ({ visible: !prev.visible }),
//             () => {
//                 this.props.rockTypeReducer.rockTypes.filter(item => item.key === this.state.target)[0].colors = this.state.selectedColor;
//                 this.props.setTypes(this.props.rockTypeReducer.rockTypes)
//             })
//     }

//     render() {
//         return (
//             <>
//                 <img className="add-row" onClick={this.addRow} src={iconPlus} style={{ width: '13px', height: '13px' }} />
//                 <Table
//                     size="small"
//                     dataSource={this.props.BoreHoleReducer.rockTypeColorRows}
//                     bordered={true}
//                     pagination={false}
//                     scroll={{ y: 150 }}
//                 >
//                     <Column title="Soil Type Name" dataIndex="rockTypeName" key="rockTypeName" render={(text, record) => (
//                         record.editable ? <Input
//                             size="small"
//                             onChange={(e) => this.onEdit(e, record, 'rockTypeName')}
//                             value={text} /> : text
//                     )} />

//                     <Column title="Color" dataIndex="colors" key="colors" render={(text, record) => (
//                         record.editable ? <Input
//                             onDoubleClick={() => this.setState(prev => ({ visible: !prev.visible, target: record.key }))}
//                             size="small"
//                             onChange={(e) => this.onEdit(e, record, 'colors')}
//                             value={text} /> : <PickColorUI text={text} />
//                     )} />
//                     <Column
//                         title="Action"
//                         key="action"
//                         render={(text, record) => <TableAction record={record} activeEdit={this.activeEdit} onCancel={this.onCancel} handleDelete={this.handleDelete} />}
//                     />
//                 </Table>
//                 {
//                     this.state.visible && <span onDoubleClick={this.onDoubleClickClose}>
//                         <SketchPicker
//                             color={this.state.selectedColor}
//                             onChangeComplete={this.handleChangeComplete}
//                         />
//                     </span>
//                 }

//             </>
//         )
//     }
// }

export default connect((state) => state, { setTypes, setSubSurface, setRockTypeColorList })(
  ModalTypeTable
);
