import React from "react";
import { Input } from "antd";
import "./index.less";

/**
 * @component
 *    - DefaultInput
 * 
 * @description 
 *    - Input UI
 * 
 * @default concept 
 *    - backgroundColor #edeff2
 *    - radius 2px
 */
const DefaultInput = (props) => {
  const style = {
    backgroundColor: "#edeff2",
    borderRadius: "2px",
    ...props.style
  };
  return (
    <Input
      {...props}
      style={style}
      className={`df-input acumin ${props.className}`}
    />
  );
};

export default DefaultInput;
