import React from "react";
import { Input } from "antd";
import IconEdit from "../../../assets/icons/edit.svg";
import { connect } from "react-redux";

import { currentMeasure } from "../../../store/actions/projectChildrenComponent";

const CrossSectionCoordinate = (props) => {
  
    const onChangePosition = (value, key, index) => {
      if (isNaN(+value)) return;
      props.measure.points[index].position[key] = +value;
      // change parent mesure
      // props.currentMeasure(props.measure);
      if (props.callback) {
        props.callback();
      }
    };

  /**
   * props.measure
   */
  return (
    <>
      {props.measure &&
        props.measure.points.map((mark, index) => (
          <div key={index}>
            {props.measure.points.length !== 1 && (
              <span style={{ marginRight: "5px" }}>{index + 1}</span>
            )}
            {Object.keys(mark.position).map((positionKey, i) => (
              <Input
                key={i}
                onChange={(e) =>
                  onChangePosition(e.target.value, positionKey, index)
                }
                value={props.measure && `${mark.position[positionKey]}`}
                className="position-field"
              />
            ))}
            <img
              className="i-edit"
              style={{ margin: 0 }}
              src={IconEdit}
              alt="edit icon"
            />
          </div>
        ))}
    </>
  );
};

// export default Coordinate;
export default connect((state) => state, {
  currentMeasure,
})(CrossSectionCoordinate);
