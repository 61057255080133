import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Popover, Icon, Button, message } from "antd";
import IconProfile from "../../assets/icons/profile.svg";
import IconProfileOn from "../../assets/icons/profile-on.svg";
import "./notification.less";
import { getSocketNotify, SOCKET_IO_PATH_NOTI } from "../../components/blocks/drawers/chat/env";
import { capitalizeFirstLetter } from "utils/StringHelper";

const NotiParent = (props) => {
  const { t } = useTranslation();
  const [notificationList, setNotificationList] = useState([]);
  const conn = getSocketNotify(SOCKET_IO_PATH_NOTI, JSON.parse(sessionStorage?.userInfo)?.token);

  const header = (
    <p className="notification__header font-sofia group">
      {t("title$Notification", { count: 1 })}
      <Button size="small">{capitalizeFirstLetter(t("button$모두_읽음_표시"))}</Button>
    </p>
  );

  useEffect(() => {
    conn?.on("watch", (msg) => {
      message.info(msg.f_message);
    });
  }, []);

  if (props.disabled) {
    return <>{props.children}</>;
  } else {
    const content = (
      <ul>
        {notificationList.map((item) => {
          return (
            <li key={item.id} className="notification__item group">
              <img src={item.isLogin ? IconProfileOn : IconProfile} alt="profile icon" />
              <p>
                {item.message}
                <span>{item.updatedDate}</span>
              </p>
            </li>
          );
        })}
      </ul>
    );

    return (
      <Popover placement="rightBottom" title={header} content={content} trigger="click">
        {props.children}
      </Popover>
    );
  }
};

class Notification extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <NotiParent disabled={this.props.disabled}>
        <Icon
          type="bell"
          theme="filled"
          style={{ fontSize: "13px", lineHeight: "33px", opacity: this.props.disabled ? 0.7 : 1 }}
        />
        <span>{t("title$Notification", { count: 2 })}</span>
      </NotiParent>
    );
  }
}

export default React.memo(withTranslation()(Notification));
