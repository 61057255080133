import { applyMiddleware, compose, createStore } from "redux";
import appReducers from "./reducers";
// import { logger } from 'redux-logger';

const middleWares = [];

if (process.env.NODE_ENV === `development`) {
  // middleWares.push(logger);
}

const isDevelop = process.env.NODE_ENV === `development` && typeof window !== "undefined";
const composeEnhancers = (isDevelop && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
export const store = composeEnhancers(applyMiddleware(...middleWares))(createStore)(appReducers);
