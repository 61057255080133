import React from "react";
import { i18n } from "react-i18next/i18next";
import { Modal } from "antd";
import CreateFolderUI from "./template/index";
import { consoleError } from "../../../middleware";
import { connect } from "react-redux";
import { setReportFolder } from "../../../store/actions/report";

const axios = require("axios");

class HocDocuementFolder extends React.Component {
  state = {
    visible: false,

    textContent: "",

    changeValue: (e) => this.setState({ textContent: e.target.value }),

    showModal: () => this.setState({ visible: true }),

    handleOk: (e) => {
      axios
        .post(`/reportFolder/${this.props.currentProjectReducer.currentProject.project_mng_sq}`, {
          f_group_nm: this.state.textContent,
        })
        .then(this.successModal)
        .then(({ data }) => {
          data[0].projects = [];
          this.props.setReportFolder([].concat(this.props.ReportReducer.folders.concat(data)));

          this.setState({
            visible: false,
          });
        })
        .catch((error) => consoleError(error));
    },

    successModal: (res) => {
      return new Promise((resolve, reject) => {
        Modal.success({
          content: i18n.t(`message$%{text} created`, { text: this.state.textContent }),
          onOk: () => {
            return resolve(res);
          },
        });
      });
    },

    handleCancel: (e) => {
      this.setState({
        visible: false,
      });
    },
  };

  render = () => <CreateFolderUI {...this.state} />;
}

export default connect((state) => state, {
  setReportFolder,
})(HocDocuementFolder);
